import { ErrorHandler, NgModule, isDevMode } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { RouteReuseStrategy } from "@angular/router";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { AppRoutingModule } from "src/app/app-routing.module";
import { AppComponent } from "src/app/app.component";

import { ComponentsModule } from "src/app/components/components.module";
import { ModalsModule } from "src/app/modals/modals.module";
import { OverlayModalModule } from "src/app/overlay/overlay.module";
import { TokenInterceptorService } from "src/app/services/token-interceptor.service";
import { GlobalErrorHandler } from "./global-error-handler";
import { IdleTimerService } from "./services";
import { ServiceWorkerModule } from "@angular/service-worker";

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		ReactiveFormsModule,
		IonicModule.forRoot({
			innerHTMLTemplatesEnabled: true,
		}),
		AppRoutingModule,
		ComponentsModule,
		ModalsModule,
		OverlayModalModule,
		HttpClientModule, // import HttpClientModule after BrowserModule.
		FormsModule,
		FontAwesomeModule,
		ServiceWorkerModule.register("ngsw-worker.js", {
			enabled: !isDevMode(),
			// Register the ServiceWorker as soon as the application is stable
			// or after 30 seconds (whichever comes first).
			registrationStrategy: "registerWhenStable:30000",
		}),

	],
	providers: [
		{ provide: ErrorHandler, useClass: GlobalErrorHandler },
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true,
		},
		IdleTimerService
	],
	bootstrap: [AppComponent],
})
export class AppModule {}

import { Injectable } from "@angular/core";
import {
	Subscription,
	fromEvent,
	interval,
	startWith,
	takeWhile,
} from "rxjs";
import { StorageService } from "./storage.service";
import { Router } from "@angular/router";
import { AuthService } from "./auth.service";
import { ModalType } from "../app.constants";
import { ModalController } from "@ionic/angular";
import {
	LogoutWarningComponent,
	LogoutWarningDismissResult,
} from "../components/logout-warning/logout-warning.component";
import { environment } from "../../environments/environment";
import dayjs, { Dayjs } from "dayjs";

@Injectable({
	providedIn: "root",
})
export class IdleTimerService {
	countdownTimer = Subscription.EMPTY;
	second = 1000;

	logoutWarningTimestamp: Dayjs;

	visibilitySubscription: Subscription;

	isWindowActive: boolean;

	constructor(
		public authService: AuthService,
		public router: Router,
		public modalCtrl: ModalController
	) {
		this.initVisibility();
	}

	/**
	 * If the user has switched to a different tab, go ahead and
	 * log them out. Here, we tap into the documents "visibility"
	 * to see if the user has the page "active".
	 */
	initVisibility() {
		const visibilitychange$ = fromEvent(document, "visibilitychange");

		this.visibilitySubscription = visibilitychange$
			.pipe(startWith(true))
			.subscribe(() => {
				this.isWindowActive =
					document.visibilityState === "visible" ? true : false;
			});
	}


	setlogoutWarningTimestamp(sessionLengthInMinutes: number) {
		this.logoutWarningTimestamp = dayjs().add(sessionLengthInMinutes, "minute")
	}

	isLogoutWarningTimeoutEnded(): boolean {
		const now = dayjs();
		return  this.logoutWarningTimestamp.isAfter(now)
	}

	/**
	 * Timer resets when:
	 *   - the user has navigated to a different page
	 *   - the user has completed an action that requires an API call
	 *
	 * When the timer runs out:
	 *   - an alert modal is displayed
	 *     - the user can continue (extend) their session
	 *     - the user can logout
	 *     - the user is logged out automatically after 1 minute has elapsed
	 *
	 * Time is defined by `sessionLengthInMinutes` set by app configs
	 * with a fallback set in the environment.
	 */
	initIdleTimer() {
		// always unsubscribe and spin up a new timer.
		this.countdownTimer.unsubscribe();

		// Ignore pages when the user isn't logged in.
		if (!StorageService.authToken) {
			return;
		}

		const sessionLengthInMinutes =
			StorageService.appConfigs?.sessionLengthInMinutes ||
			environment.settings.sessionLengthMinutes;

		this.setlogoutWarningTimestamp(sessionLengthInMinutes);

		const time = interval(this.second);

		this.countdownTimer = time.pipe(
			takeWhile(() => this.isLogoutWarningTimeoutEnded())
		).subscribe({
			complete: async () => {
				if (!this.isWindowActive) {
					console.log("Inactive window");
					await this.logout();
					return;
				}

				const data: LogoutWarningDismissResult =
					await this.showLogoutWarning();

				if (data.action === "extendSession") {
					this.initIdleTimer();
					return;
				}

				if (data.action === "logout") {
					this.logout();
					return;
				}
			},
		});
	}

	async logout() {
		await this.authService.logout();
		await this.router.navigate(["login"]);
	}

	async showLogoutWarning(): Promise<LogoutWarningDismissResult> {
		const modal = await this.modalCtrl.create({
			component: LogoutWarningComponent,
			cssClass: ModalType.LogoutWarning,
			animated: false,
			backdropDismiss: false,
		});

		await modal.present();

		const { data } = await modal.onDidDismiss();

		return data;
	}
}

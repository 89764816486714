<ion-row class="milk-info__header__toggle">
	<ion-col>
		<div>
			<button *ngFor="let o of options"
					(click)="selectModifier($event, o)"
					[disabled]="isDisabled(o)"
					[ngClass]="{ 'selected': option === o, 'disabled': isDisabled(o) }">
				{{ o }}
			</button>
		</div>
	</ion-col>
</ion-row>

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { MilkBottleModel } from "src/app/models/milk.model";
import { PatientModel } from "src/app/models/patient.model";
import { PrinterModel } from "src/app/models/printer.model";
import { PrinterService } from "src/app/services/printer.service";
import { StorageService } from "src/app/services/storage.service";
import { defaultSelectOptions } from "src/app/components/default-options";
import { capitalize } from "src/app/app.util";
import { BaseService } from "src/app/services/base.service";
import * as _ from "lodash";
import {
	getBottleNumberText,
	getPatientsFromMilks,
} from "../../utils/milk-label.util";
import { NavigationExtras } from "@angular/router";
import {
	CustomPrintModel,
	MilkBankProductPrintModel,
	MilkBottlePrintModel,
} from "../../models/print-preview.model";
import {
	getPrintDataByMode,
	getPrintedToastMessage,
	isPrintable,
	isSkippable,
} from "./print-label-with-retry.util";
import { FeedObjectModel } from "../../models/feed-object.model";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export interface PrintLabelInputs {
	patients?: PatientModel[];
	milkBottles?: MilkBottleModel[];
	milkBankProducts?: MilkBankProductModel[];
}

export interface CustomPrintLabelInput {
	patient: PatientModel;
	line1: string;
	line2: string;
}

export type MilkLabelType = "milkbottle" | "milkbankproduct";

export type PrintedMilkBottlesEvent = {
	type: "milkbottle";
	patients?: PatientModel[];
	milkBottles: MilkBottleModel[];
};

export type PrintedMilkBankProductsEvent = {
	type: "milkbankproduct";
	patients?: PatientModel[];
	milkBankProducts: MilkBankProductModel[];
};

export type PrintedLabelsEvent =
	| PrintedMilkBottlesEvent
	| PrintedMilkBankProductsEvent;

interface PrintButtons {
	isPrintable: boolean; // Mix of New and updated, it says “Print new”
	isSkippable: boolean; // If you have ONLY updated , it says “Skip Printing”
}

@Component({
	selector: "app-print-label-with-retry",
	templateUrl: "./print-label-with-retry.component.html",
	styleUrls: ["./print-label-with-retry.component.scss"],
})
export class PrintLabelWithRetryComponent implements OnInit {
	@Input() selectedPrinter: PrinterModel;
	@Input() customLabels: CustomPrintLabelInput[] = [];
	@Input() isScanOrdered = false;
	@Input() patients: PatientModel[] | [];
	@Input() showPrintPreview = true;
	@Input() showPrintedToast = true;
	@Output() selectPrinterPress = new EventEmitter<PrinterModel>();
	@Output() printed = new EventEmitter<PrintedLabelsEvent>();

	private _milkBottles: MilkBottleModel[] = [];
	private _milkBankProducts: MilkBankProductModel[] = [];

	// printers: PrinterModel[];
	isRetry = false;

	printButtons: PrintButtons = {
		isPrintable: false,
		isSkippable: false,
	};

	capitalize = capitalize;
	faChevronRight = faChevronRight;

	customAlertOptions = {
		...defaultSelectOptions,
		translucent: true,
	};

	/**
	 * Returns milk bank products in ascending bottle number order.
	 */
	get milkBottles() {
		return this.isScanOrdered
			? this._milkBottles
			: _.chain(this._milkBottles)
					.sortBy((b) => b.bottleNumber)
					.value();
	}

	/**
	 * Found a quirk where if items are pushed to the array instead of the entire
	 * array being replaced, the `set milkBottles` doesn't trigger because it is
	 * technically already set.
	 *
	 * e.g.
	 * triggers = milkBottles = [...];
	 * updates but doesn't trigger = milkBottles.push({...});
	 */
	@Input()
	set milkBottles(value: MilkBottleModel[]) {
		if (!value || value.length === 0) {
			return;
		}

		this._milkBottles = value;
		this.patients = getPatientsFromMilks(this._milkBottles);

		this.setButtonState({
			milkBankProducts: this._milkBankProducts,
			milkBottles: this._milkBottles,
		});
	}

	/**
	 * Returns milk bank products in ascending bottle number order.
	 */
	get milkBankProducts() {
		return this.isScanOrdered
			? this._milkBankProducts
			: _.chain(this._milkBankProducts)
					.sortBy((p) => p.bottleNumber)
					.value();
	}

	@Input()
	set milkBankProducts(value: MilkBankProductModel[]) {
		if (!value || value.length === 0) {
			return;
		}

		this._milkBankProducts = value;
		this.setButtonState({
			milkBankProducts: this._milkBankProducts,
			milkBottles: this._milkBottles,
		});
	}

	constructor(
		public printerService: PrinterService,
		public base: BaseService
	) {}

	ngOnInit() {
		// do nothing
	}

	setButtonState(params: {
		milkBankProducts: MilkBankProductModel[];
		milkBottles: MilkBottleModel[];
	}) {
		const { milkBankProducts, milkBottles } = params;

		const feedObjects = [
			...(milkBankProducts as FeedObjectModel[]),
			...(milkBottles as FeedObjectModel[]),
		];

		const permission =
			StorageService.activeTenant?.permissions
				.global_SkipPrintingUpdatedLabels;

		this.printButtons = {
			isPrintable: permission && isPrintable(feedObjects),
			isSkippable: permission && isSkippable(feedObjects),
		};
	}

	/**
	 * Get ZPL printer string
	 */
	getAllPrintData(params: {
		customLabels: CustomPrintLabelInput[];
		milkBottles: MilkBottleModel[];
		milkBankProducts: MilkBankProductModel[];
		printer: PrinterModel;
	}): string {
		// Zpl should only be used on the print preview page.
		if (params.customLabels.length > 0) {
			const zpl = this.base.labelService.createCustomLabel({
				customLabels: params.customLabels,
				printer: params.printer,
			});

			return zpl;
		}

		const milkBankProducts =
			this.base.labelService.createMilkBankProductLabels({
				milkBankProducts: params.milkBankProducts,
				printer: params.printer,
			});

		const milkBottles = this.base.labelService.createMilkBottleLabels({
			milkBottles: params.milkBottles,
			printer: params.printer,
		});

		const data = [milkBankProducts, milkBottles].join("");

		return data;
	}

	handleSkipPrint() {
		this.printed.emit();
	}

	async handlePrint(mode: string) {
		const data = getPrintDataByMode({
			mode,
			milkBankProducts: this.milkBankProducts,
			milkBottles: this.milkBottles,
			customLabels: this.customLabels,
		});

		if (this.selectedPrinter.isVirtual) {
			this.goToPrintPreview(data);
			this.emitPrintedAndPresentPrintedToast(data);
			return;
		}

		try {
			const printData = this.getAllPrintData({
				...data,
				printer: this.selectedPrinter,
			});

			await this.base.presentLoading("Printing");
			await this.printerService.print(this.selectedPrinter, printData);
		} catch (error) {
			this.displayRetry(error?.message);
			return;
		} finally {
			await this.base.dismissLoading();
		}

		this.emitPrintedAndPresentPrintedToast();
	}

	async goToPrintPreview(data: {
		milkBottles: MilkBottleModel[];
		milkBankProducts: MilkBankProductModel[];
		customLabels: CustomPrintLabelInput[];
	}) {
		const navigationExtras: NavigationExtras = {
			state: {
				milkBankProducts: data.milkBankProducts.map(
					(x) => new MilkBankProductPrintModel(x)
				),
				milkBottles: data.milkBottles.map(
					(x) => new MilkBottlePrintModel(x)
				),
				customLabels: data.customLabels.map(
					(x) => new CustomPrintModel(x)
				),
			},
		};

		StorageService.printPreview = navigationExtras;
		window.open("print-preview");
	}

	async displayRetry(errorMessage?: string) {
		const firstPatientLastName =
			this.patients?.length > 0 && this.patients[0].lastName
				? this.patients[0].lastName.toUpperCase()
				: "";
		const milkBottlesText = this.milkBottles
			?.map(
				(b) =>
					`#${getBottleNumberText(b.bottleNumber)} ${capitalize(b.milkState)} ${
						b.milkType
					} ${firstPatientLastName}<br/>`
			)
			.join("");
		const milkBankProductsText = this.milkBankProducts
			?.map(
				(p) =>
					`#${getBottleNumberText(p.bottleNumber)} ${capitalize(p.productState)} ${
						p.name
					}<br/>`
			)
			.join("");
		const please =
			"Please check to confirm the following bottles printed successfully: <br/>";
		const details = [
			please,
			"<br/>",
			milkBottlesText,
			milkBankProductsText,
		].join("");

		return await this.base.alerts.presentAlert({
			header: "Print Error",
			subHeader: [
				errorMessage || "An error occurred while printing.",
				" If you continue to receive this error, please contact the help desk.",
			].join(""),
			message: details,
			backdropDismiss: false,
			buttons: [
				{
					text: "Print Successful",
					role: "cancel",
					handler: () => {
						this.base.alerts.dismissAlert();
						this.emitPrintedAndPresentPrintedToast();
					},
				},
				{
					text: "Retry Print",
					handler: () => {
						this.isRetry = true;
						this.base.alerts.dismissAlert();
					},
				},
			],
		});
	}

	/**
	 * This is emitted when print is successful
	 */
	emitPrintedAndPresentPrintedToast(data?: {
		milkBottles: MilkBottleModel[];
		milkBankProducts: MilkBankProductModel[];
		customLabels: CustomPrintLabelInput[];
	}) {
		this.printed.emit();
		if (this.showPrintedToast) {
			this.presentPrintedToast(data);
		}
	}

	/**
	 * @deprecated This displays a warning toast message for 5 seconds because automatically
	 * dismissing itself.
	 *
	 * @param message
	 * @private
	 */
	presentErrorToast(message?: string) {
		const uniqueText = message || "An error occurred while printing. ";
		const fullText = [
			uniqueText,
			"Please check to confirm a successful print or retry. ",
			"If you continue to receive this error, please contact the help desk.",
		].join("");

		return this.base.toastService.presentWarningToast(fullText, 5 * 1000);
	}

	async presentPrintedToast(data?: {
		milkBottles: MilkBottleModel[];
		milkBankProducts: MilkBankProductModel[];
		customLabels: CustomPrintLabelInput[];
	}) {
		try {
			const message = getPrintedToastMessage({
				milkBottles: data?.milkBottles ?? this.milkBottles,
				milkBankProducts:
					data?.milkBankProducts ?? this.milkBankProducts,
			});

			this.base.toastService.presentSuccessToast(message, 0, [
				{
					text: "OK",
					role: "cancel",
				},
			]);
		} catch (error) {
			// biome-ignore lint/nursery/noSecrets: <explanation>
			console.error("Error in presentPrintedToast", {
				error,
				data,
			});
		}
	}

	/**
	 * @deprecated
	 */
	async handleSelectedPrinterChange(printer: PrinterModel | null) {
		if (!printer) {
			return;
		}

		this.base.authService.updateUserProfile({
			currentPrinterId: printer.id,
		});
		StorageService.lastUsedCachedPrinter = printer;
	}

	/**
	 * Open the {@link SelectItemModal} to select a printer in the parent class.
	 */
	async handleSelectPrinterPress(event: MouseEvent) {
		console.log("Debug ML-2522 >>", event);
		event.preventDefault();

		this.selectPrinterPress.emit();
	}

	protected readonly StorageService = StorageService;
}

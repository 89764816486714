import { Component, Input, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { BaseService } from "../../services/base.service";
import {
	ScannedProduct,
	ScanningService,
} from "../../services/scanning.service";
import { IonSearchbar, ModalController } from "@ionic/angular";
import { StorageService } from "../../services/storage.service";
import { ProductType, ScanLogType, ScanLogWorkflow } from "../../app.enums";
import { PatientEhrOrderModel, PatientModel } from "../../models/patient.model";
import { Product } from "../../models/product.model";
import { BaseSelectProduct } from "./base-select-product";
import { ScannedRecipeData } from "../../models/recipe.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import {
	LogMilkBankProductModel,
	LogScannedObjectModel,
} from "../../models/log-scan.model";
import { ScanFooterComponent } from "../../components/scan-footer/scan-footer.component";
import { Subscription } from "rxjs";
import { FeedObject } from "../../models/feed-object.model";
import { Features } from "../../features.enums";
import { FeatureFlagService } from "../../services/feature-flag.service";
import { SoundService } from "../../services/sound.service";
import {
	FrozenProductValidationError,
	ScanMilkBankProductValidationError,
} from "src/app/decorators/scan-validation/scan-validation-error";
import {
	isExpended,
	isDuplicate,
	getSelectedProduct,
	isMilkBankProductExpired,
	isRecalled,
	isFrozen,
} from "./base-select-product";

@Component({
	selector: "app-select-product",
	templateUrl: "./select-product.modal.html",
	styleUrls: ["./select-product.modal.scss"],
})
export class SelectProductModal
	extends BaseSelectProduct
	implements OnInit, OnDestroy
{
	// biome-ignore lint/nursery/noSecrets: <explanation>
	@ViewChild("selectProductModalSearchInput", { static: false })
	searchInput: IonSearchbar;
	@ViewChild("scanner") public scanner: ScanFooterComponent;

	@Input() patients: PatientModel[] = [];
	@Input() selectedOrder: PatientEhrOrderModel;

	private scanSubscription: Subscription;

	selectableProducts: SelectedProduct[] = [];
	filteredSelectableProducts: SelectedProduct[] = [];
	selectedProducts: SelectedProduct[] = [];
	scannedObjects: FeedObject[] = [];

	workflow = ScanLogWorkflow.Select_Product;

	constructor(
		public base: BaseService,
		public scanningService: ScanningService,
		public modalCtrl: ModalController,
		public soundService: SoundService
	) {
		super(base, scanningService, modalCtrl);
	}

	ngOnInit() {
		this.scanSubscription = this.scanningService.scanReady$.subscribe(
			(isReady) => {
				if (isReady) {
					this.scanner.startScan();
				}
			}
		);

		this.selectableProducts = StorageService.configs.products
			.filter((p) => p.type !== ProductType.Water)
			.filter((p) => p.type !== ProductType.Donor)
			.filter((p) => p.type !== ProductType.EBM)
			.map((p) => ({
				product: p,
				isChecked: false,
				isMatched: false,
			}))
			.sort((a, b) => a.product.name.localeCompare(b.product.name));

		// Ensure that the modal has finished loading otherwise
		// searchInput will be undefined.
		// biome-ignore lint/nursery/noSecrets: <explanation>
		document.addEventListener("ionModalDidPresent", () => {
			this.searchInput.setFocus();
		});
	}

	ngOnDestroy() {
		this.scanSubscription.unsubscribe();
	}

	// View management

	handleLeaving() {
		this.selectedProducts = [];
		this.scannedObjects = [];
	}

	async done() {
		this.stopScanListener();
		await this.modalCtrl.dismiss({
			selectedProducts: this.selectedProducts,
			scannedProducts: this.scannedProducts,
		} as ScannedRecipeData);
		this.handleLeaving();
	}

	async presentLeavingAlert() {
		await this.base.alerts.presentAlert({
			header: "Unsaved Changes",
			message: "Scanned additives will not be saved",
			backdropDismiss: false,
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
				},
				{
					text: "Go Back",
					handler: async () => {
						this.scannedProducts = [];
						this.selectedProducts = [];
						this.state = 1;
						this.cleanup();

						await this.modalCtrl.dismiss({
							selectedProducts: this.selectedProducts,
							scannedProducts: this.scannedProducts,
						} as ScannedRecipeData);
					},
				},
			],
		});
	}

	// Navigation

	async prev() {
		if (this.state === 2) {
			await this.presentLeavingAlert();
		} else {
			await this.modalCtrl.dismiss();
			this.stopScanListener();
		}
	}

	next() {
		super.next();
		if (this.state === 2) {
			this.selectedProducts = [
				...this.selectableProducts.filter((s) => s.isChecked),
			];
			this.startScanListener();
			this.resetFilter();
		}
	}

	// Scan processing
	async handleValidationSuccess(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: SelectedProduct;
		milkBankProduct: MilkBankProductModel;
	}) {
		const { scannedProduct, selectedProduct, milkBankProduct } = params;

		// web
		if (StorageService.isWeb) {
			await this.base.modalService.presentVerified();
		}

		// mobile
		if (!StorageService.isWeb) {
			if (FeatureFlagService.isLatest(Features.Mobile_Scanner)) {
				this.scannedObjects.push(milkBankProduct);
				await this.soundService.playSuccess();
				this.scanningService.setScanReady(true);
			}

			if (FeatureFlagService.isStable(Features.Mobile_Scanner)) {
				await this.base.modalService.presentVerified();
			}
		}

		scannedProduct.originalMilkBankProduct =
			JSON.stringify(milkBankProduct);
		scannedProduct.milkBankProduct = milkBankProduct;
		selectedProduct.isMatched = true;

		this.scannedProducts.push(scannedProduct);

		await this.base.loggingService.logScan(
			new LogScannedObjectModel({
				type: ScanLogType.Product_Success,
				workflow: this.workflow,
				productId: params.selectedProduct.product.id,
			})
		);
	}

	/**
	 * Update milk bank product with thawed date
	 * Handle validation success
	 */
	async handleThawProduct(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: SelectedProduct;
		milkBankProduct: MilkBankProductModel;
	}) {
		const updated = await this.base.inventoryService.updateMilkBankProduct(
			params.milkBankProduct
		);
		updated.isInlineThawed = true;
		await this.handleValidationSuccess({
			scannedProduct: params.scannedProduct,
			selectedProduct: params.selectedProduct,
			milkBankProduct: updated,
		});
	}

	getItems(ev) {
		this.filteredSelectableProducts = this.selectableProducts; // Reset items back to all of the items
		const val = ev.target.value; // set val to the value of the searchbar

		// if the value is an empty string don't filter the items
		if (val && val.trim() !== "") {
			this.isItemAvailable = true;
			this.filteredSelectableProducts = this.selectableProducts.filter(
				(selectableProduct) => {
					const searchValue = val.toLowerCase();
					const productName =
						selectableProduct.product.name.toLowerCase();
					const productCode =
						selectableProduct.product.productCode.toLowerCase();
					return (
						productName.indexOf(searchValue) > -1 ||
						productCode.indexOf(searchValue) > -1
					);
				}
			);
		} else {
			this.isItemAvailable = false;
		}
	}

	/**
	 *
	 */
	onProductSelected(selectedProduct: SelectedProduct) {
		// const isChecked = ev.detail.checked;
		const isChecked = selectedProduct.isChecked;

		for (const o of this.selectableProducts) {
			if (o.product === selectedProduct.product) {
				console.log(
					`found ${JSON.stringify(selectedProduct, null, 2)}`
				);
				o.isChecked = !isChecked;
			}
		}
		for (const o of this.filteredSelectableProducts) {
			if (o.product === selectedProduct.product) {
				console.log(
					`found ${JSON.stringify(selectedProduct, null, 2)}`
				);
				o.isChecked = !isChecked;
			}
		}
	}

	/**
	 * Clears selected and resets filter
	 */
	resetFilter() {
		// biome-ignore lint/suspicious/noAssignInExpressions: <explanation>
		this.selectableProducts.forEach((r) => (r.isChecked = false));
		this.filteredSelectableProducts = this.selectableProducts; // reset filter
	}

	// Getters

	/**
	 * Returns only selected products that haven't been scanned yet
	 */
	getUnscannedProducts(): SelectedProduct[] {
		return this.selectedProducts.filter((s) => !s.isMatched);
	}

	// Booleans

	hasChecked(): boolean {
		return !!this.selectableProducts.find((o) => o.isChecked);
	}

	/**
	 * Only if all required products have been scanned can you continue
	 */
	hasUnscanned(): boolean {
		return !!this.getUnscannedProducts().filter(
			(s) => !s.isMatched && s.product.enforceScanning
		).length;
	}

	async handleMilkBankProductScanned(scannedProduct: ScannedProduct) {
		let milkBankProduct: MilkBankProductModel = null;
		let selectedProduct: SelectedProduct = null;

		try {
			milkBankProduct =
				await this.base.inventoryService.getMilkBankProductByQRCode(
					scannedProduct.milkTrackerQRCode
				);

			isExpended(milkBankProduct);
			isDuplicate(this.scannedProducts, milkBankProduct);
			isMilkBankProductExpired(milkBankProduct);
			isRecalled(milkBankProduct);
			isFrozen(milkBankProduct);

			selectedProduct = getSelectedProduct({
				productId: milkBankProduct.productId,
				selectedProducts: this.selectedProducts,
				scannedProduct,
			});

			scannedProduct.product = (
				selectedProduct as SelectedProduct
			).product;

			// Passed all validation checks
			await this.handleValidationSuccess({
				scannedProduct,
				selectedProduct,
				milkBankProduct,
			});
		} catch (error) {
			if (error instanceof FrozenProductValidationError) {
				this.base.loggingService.logScan(
					new LogMilkBankProductModel({
						type: ScanLogType.Product_Incompatible_State,
						workflow: this.workflow,
						milkBankProductId: milkBankProduct.id,
						patientId: this.patients[0].id,
					})
				);

				await this.presentThawProductModal({
					scannedProduct,
					selectedProduct,
					milkBankProduct,
				});

				return;
			}

			if (error instanceof ScanMilkBankProductValidationError) {
				const { header, message, modalType } = error.modal;
				await this.base.modalService.presentOverlayModal(
					modalType,
					header,
					[message]
				);

				// It's possible that we don't have a milk bank product id
				// since the response for an expended mbp returns null.
				await this.base.loggingService.logScan(
					new LogMilkBankProductModel({
						type: error.scanLogType,
						workflow: this.workflow,
						patientId: this.patients[0].id,
						milkBankProductId: milkBankProduct?.id || null,
					})
				);

				return;
			}

			// TODO - Add error handling https://angeleyehealth.atlassian.net/browse/ML-2928
			console.error(error);
		}
	}
}

/**
 * isMatched means that the product has been scanned
 *
 * TODO: I think this abstraction creates more confusion and fragmentation.
 *  Because we can assigned extra properties to the Product model as needed,
 *  we can just use the Product model directly and add the isChecked and
 *  isMatched. This would also allow us to use the same model for the
 *  selectableProducts and the scannedProducts.
 */
export interface SelectedProduct {
	product: Product;
	isChecked: boolean;
	isMatched: boolean;
}

<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="modalCtrl.dismiss()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">Enter Product Info</div>
		</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="clear()">clear</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div class="step">
		<ion-list lines="none">
			<ion-item lines="none" class="milkBottle">
				<ion-label>
					<div class="milkBottle__label">Product</div>
					{{ product?.name }}
				</ion-label>
			</ion-item>

			<app-inline-date
				*ngIf="product"
				label="Expiration Date"
				presentation="date"
				[defaultDate]="expirationDate"
				[minDate]="dateRange.expiration.min"
				[maxDate]="dateRange.expiration.max"
				(dateTimeChange)="expirationDate = $event"
				[autofocusState]="true"
				[isExpirationDate]="true"
				[disabled]="false"
				>
			</app-inline-date>

			<!-- Thaw Date -->
			<app-inline-date
				*ngIf="productState === ProductState.Thawed"
				label="Thaw Date"
				[defaultDate]="thawedDate"
				[minDate]="dateRange.thawed.min"
				[maxDate]="dateRange.thawed.max"
				(dateTimeChange)="thawedDate = $event"
				[isExpirationDate]="false"
				[disabled]="false"
				>
			</app-inline-date>

			<ion-item class="input-field" lines="none">
				<ion-label>
					<p>Lot Number</p>
					<ion-input
						[(ngModel)]="lotNumber"
						placeholder="Enter Lot Number"
						aria-label="Lot Number"
					></ion-input>
				</ion-label>
			</ion-item>
		</ion-list>

		<div class="buttons">
			<button (click)="confirm()" [disabled]="!isValid()" class="nav-btn">
				Confirm
			</button>
		</div>
	</div>
</ion-content>

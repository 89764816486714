import {
	FeedState,
	MilkState,
	ProductState,
	ScanLogType,
} from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBottleModel } from "../../models/milk.model";
import {
	ScanMilkBankProductValidationError,
	ScanMilkBottleValidationError,
} from "./scan-validation-error";
import { IScanValidation } from "./scan-validation.model";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";

/**
 * Verify the state of the milk/product.
 *
 * For example: `RejectState(MilkState.Frozen)` would throw an error
 * if the milk state is frozen.
 */
export function RejectState(state: FeedState) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (obj.milkBottle.milkState === state) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Incompatible_State,
						modalMessage.state_mismatch(obj.milkBottle.milkState)
					);
				}
			}

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBankProduct.productState === state) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Incompatible_State,
						modalMessage.state_mismatch(
							obj.milkBankProduct.productState
						)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/member-ordering */
import { Component, EventEmitter, Input, Output } from "@angular/core";
import {
	IOrderFulfillment,
	PatientEhrOrderModel,
} from "../../models/patient.model";

@Component({
	selector: "app-order-fulfillment",
	templateUrl: "./order-fulfillment.component.html",
	styleUrls: ["./order-fulfillment.component.scss"],
})
export class OrderFulfillmentComponent {
	fulfillments: IOrderFulfillment[];
	defaultFulfillment: IOrderFulfillment;

	@Input()
	set order(order: PatientEhrOrderModel) {
		if (order instanceof PatientEhrOrderModel) {
			this.fulfillments = order.fulfillments;

			// Select the first fulfillment by default.
			this.defaultFulfillment = this.fulfillments[0];

			// Some orders may only have 1 fulfillment. If that's the case,
			// go ahead and emit the selection since users can't select
			// a different radio option.
			if (this.fulfillments.length === 1) {
				this.handleSelection(this.defaultFulfillment);
			}
		}
	}

	@Output() valueChange = new EventEmitter<IOrderFulfillment>();

	constructor() {}

	getContentsLabel(fulfillment: IOrderFulfillment): string {
		return fulfillment.contents
			.filter((fortifier) => !fortifier.isBase)
			.map((fortifier) => fortifier.productName)
			.join(", ");
	}

	handleSelection(fulfillment: IOrderFulfillment) {
		this.valueChange.emit(fulfillment);
		console.log(`emit ${JSON.stringify(fulfillment, null, 2)}`);
	}
}

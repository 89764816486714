import { InventoryCategory } from "../../app.enums";

export interface MilkBottleInventoryItems {
	label: string;
	value: number;
	cssClass: InventoryCategory;
}

export const getMilkBottleInventoryItem = (
	count: number,
	category: InventoryCategory
): MilkBottleInventoryItems[] => {

	const label = category.charAt(0).toUpperCase() + category.slice(1);

	return [
		{
			label,
			value: count,
			cssClass: category
		},
	];
};

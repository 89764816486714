import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBottleModel } from "../../models/milk.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { ScannedProduct } from "../../services/scanning.service";
import { FeedObject } from "../../models/feed-object.model";

/**
 * A modifier is an additional radio button that can modify the state of
 * the milk. Modifiers include:
 * - Expending/Emptied
 * - Partially assigning/has remaining
 * - Assign all
 */
@Component({
	selector: "app-milk-modifiers",
	templateUrl: "./milk-modifiers.component.html",
	styleUrls: ["./milk-modifiers.component.scss"],
})
export class MilkModifiersComponent implements OnInit {
	@Input() option: ModifierOption;
	@Input() options: ModifierOption[];
	@Input() disabledOptions: ModifierOption[];
	@Output() optionChange = new EventEmitter<ModifierOption>();

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	selectModifier(ev, option: ModifierOption) {
		ev.stopPropagation();
		this.option = option;
		this.optionChange.emit(this.option);
	}

	isDisabled(option: ModifierOption): boolean {
		return this.disabledOptions && this.disabledOptions.includes(option);
	}
}

type Emptied = "emptied";
type Assign_All = "assign all";
type Has_Remaining = "has remaining";
type Partial_Use = "partial use";
type Use_All = "use all";
type Not_Fortified = "not fortified";
type Fortified = "fortified";
type Feed_All = "feed all";

export type ModifierOption =
	| Emptied
	| Has_Remaining
	| Assign_All
	| Partial_Use
	| Use_All
	| Not_Fortified
	| Fortified
	| Feed_All;

export interface MilkModifierEvent {
	option: ModifierOption;
	milk?: MilkBottleModel;
	milkBankProduct?: MilkBankProductModel;
	scannedProduct?: ScannedProduct;
}

export interface FeedModifierEvent extends MilkModifierEvent {
	option: ModifierOption;
	feedObject: FeedObject;
}

import { Injectable } from "@angular/core";
import { AlertController, AlertInput, LoadingController } from "@ionic/angular";

@Injectable({
	providedIn: "root",
})
export class UiMessagesService {
	private _loading: HTMLIonLoadingElement;
	private _loadings: HTMLIonLoadingElement[] = [];

	private _alert: HTMLIonAlertElement;
	private _modal: HTMLIonModalElement;

	constructor(
		private loadingCtrl: LoadingController,
		private alertCtrl: AlertController
	) {}

	clean() {
		if (this._alert) {
			this._alert.dismiss();
			this._alert = null;
			console.log("UiMessagesService: Clear _alert");
		}

		if (this._loading) {
			this._loading.dismiss();
			this._loading = null;
			console.log("UiMessagesService: Clear _loading");
		}

		if (this._modal) {
			this._modal.dismiss();
			this._modal = null;
			console.log("UiMessagesService: Clear _modal");
		}
	}

	/*
	  LoadingController
	*/

	async presentLoading(message: string = "Loading") {
		// dismiss previous loading messages
		await this.dismissLoading();

		this._loading = await this.loadingCtrl.create({
			message,
		});

		this._loadings.push(this._loading);
		await this._loading.present();

		return this._loading;
	}

	async dismissLoading() {
		for (const l of this._loadings) {
			l.dismiss();
		}
		this._loadings = [];

		if (this._loading) {
			await this._loading.dismiss();
		}
	}

	/*
	  AlertController
	*/

	/**
	 * Returns alert object use to customize when to present and handle onDismiss
	 */
	async createAlert(options: {
		header: string;
		subHeader?: string;
		message: string;
		backdropDismiss?: boolean;
		inputs?: AlertInput[];
		buttons?: any[];
	}): Promise<HTMLIonAlertElement> {
		this._alert = await this.alertCtrl.create({
			header: options.header,
			subHeader: options.subHeader,
			message: options.message,
			inputs: options.inputs,
			backdropDismiss: options.backdropDismiss, // false if not provided?
			buttons: options.buttons ? options.buttons : ["OK"],
		});
		return this._alert;
	}

	async presentAlert(options: {
		header: string;
		subHeader?: string;
		message: string;
		backdropDismiss?: boolean;
		inputs?: AlertInput[];
		buttons?: any[];
	}) {
		this._alert = await this.alertCtrl.create({
			header: options.header,
			subHeader: options.subHeader,
			message: options.message,
			inputs: options.inputs,
			backdropDismiss: options.backdropDismiss, // false if not provided?
			buttons: options.buttons ? options.buttons : ["OK"],
		});
		await this._alert.present();
	}

	async dismissAlert() {
		await this.alertCtrl.dismiss();
	}

	// Custom alerts

	async showDiscardDefectiveAlert() {
		await this.presentAlert({
			header: "Check For Defects",
			message:
				"The defect you selected requires that this bottle be discarded per hospital policy. " +
				"MilkTracker will not print a label for this bottle or add it to inventory.",
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					role: "cancel",
					handler: () => {
						// do nothing
					},
				},
			],
		});
	}

	/**
	 * When fortifying and scanning/receiving products, warn users that they must
	 * affix a newly printed label if that product has been partially used and
	 * opened and/or thawed.
	 */
	async showRelabelProducts() {
		await this.presentAlert({
			header: "Relabel Products",
			message:
				"Please make sure to relabel scanned products that have been opened and/or thawed after printing.",
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					role: "cancel",
					handler: () => {
						// do nothing
					},
				},
			],
		});
	}

	async presentDefaultReceiveProductErrorAlert() {
		await this.presentAlert({
			header: "Please Try Again",
			message: `It looks like the data from one or more products didn't send. If the issue continues, please contact your administrator for assistance.`,
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					handler: () => {
						//do nothing
					},
				},
			],
		});
	}

	async presentReceiveProductAlreadyReceivedErrorAlert() {
		await this.presentAlert({
			header: `Product already received`,
			message: `One or more milk bank products has already been received`,
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					handler: () => {
						//do nothing
					},
				},
			],
		});
	}
}

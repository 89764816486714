import {
	Component,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
} from "@angular/core";
import { ModalController } from "@ionic/angular";
import dayjs from "dayjs";
import { ProductState } from "src/app/app.enums";
import { Product } from "src/app/models/product.model";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { RejectRecalledProductOnReceive } from "src/app/decorators/scan-validation/reject-recalled-product-on-receive.decorator";
import { ModalService, StorageService } from "src/app/services";
import { InlineDateComponent } from "../../components/inline-date/inline-date.component";
import { getMinDate } from "src/app/app.util";

@Component({
	selector: "app-receive-product-feed-patient",
	templateUrl: "./receive-product-feed-patient.component.html",
	styleUrls: ["./receive-product-feed-patient.component.scss"],
})
export class ReceiveProductFeedPatientComponent implements OnInit {
	@ViewChildren(InlineDateComponent)
	inlineDateComponents: QueryList<InlineDateComponent>;

	@Input() product: Product;

	expirationDate: dayjs.Dayjs = null;
	thawedDate: dayjs.Dayjs = null;
	lotNumber: string;
	dayjs = dayjs;
	ProductState = ProductState;
	openedDate: dayjs.Dayjs = null;

	// TODO copied from MilkBankProductEditComponent. We probably want to define this as a global.
	dateRange = {
		expiration: {
			min: dayjs().startOf("day"),
			max: dayjs().add(5, "year"),
		},
		thawed: {
			min: getMinDate(StorageService.expirationPolicy.thawedDbm),
			max: dayjs().endOf("day")
		},
	};

	/** The productState that will be set when this product is received as a MilkBankProduct.
	 * Calculated from Product.defaultState.
	 */
	get productState() {
		switch (this.product.defaultState) {
			case ProductState.Frozen:
				return ProductState.Thawed;
			case ProductState.Stable:
				return ProductState.Opened;
			case ProductState.Opened:
				return ProductState.Opened;
			default:
				throw new Error("Not implemented.");
		}
	}

	constructor(public modalCtrl: ModalController, public modalService: ModalService) { }

	ngOnInit() {
		if (this.productState === ProductState.Opened) {
			this.openedDate = dayjs();
		}
	}

	clear() {
		this.expirationDate = null;
		this.lotNumber = null;
		this.thawedDate = null;
		this.openedDate = null;
	}

	cancel() {
		return this.modalCtrl.dismiss(null, "cancel");
	}

	isValid() {
		return !!(
			this.expirationDate &&
			this.lotNumber &&
			(this.productState !== ProductState.Thawed || this.thawedDate)
		);
	}

	@RejectRecalledProductOnReceive()
	async checkIfRecalled(params: {
		product: Product;
		lotNumber: string;
	}): Promise<boolean> {
		if (params.product && params.lotNumber) {
			return;
		}
	}

	async validateLotNumber(){
		if(this.lotNumber && this.product.recalledLotNumbers) {
			try {
			  await this.checkIfRecalled({product: this.product, lotNumber: this.lotNumber});
			} catch(e) {
				// display the associated modal
				const { header, message, modalType } = e.modal;
				await this.modalService.presentOverlayModal(modalType, header, [
					message,
				]);
				throw e;
			}

		}
	}

	/**
	 * Programmatically click "done" for each datetime component.
	 */
	async confirmDatePickerSelection() {
		this.inlineDateComponents.forEach((inlineDateComponent) =>
			inlineDateComponent.done()
		);
	}

	async confirm() {

		await this.validateLotNumber();

		await this.confirmDatePickerSelection();

		const confirmData: ReceiveProductFeedPatientConfirmed = {
			product: this.product,
			lotNumber: this.lotNumber,
			manufacturerExpirationDate: this.expirationDate,
			productState: this.productState,
			thawedDate: this.thawedDate,
			openedDate: this.openedDate,
		};

		return this.modalCtrl.dismiss(confirmData, "confirm");
	}

	protected readonly getBottleNumberText = getBottleNumberText;
}

export type ReceiveProductFeedPatientConfirmed = {
	product: Product;
	manufacturerExpirationDate: dayjs.Dayjs;
	lotNumber: string;
	productState: ProductState;
	thawedDate?: dayjs.Dayjs;
	openedDate?: dayjs.Dayjs;
};

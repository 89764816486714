import { Injectable } from "@angular/core";

import {
	InventoryService,
} from "./inventory.service";
import dayjs from "dayjs";
import _ from "lodash";
import { MilkBottleFeedPatientListItem } from "../administer-feed/feed-patient/list-item";
import {
	MilkBottleUseType,
	assertExhaustive,
} from "../app.enums";
import { MilkBottleModel } from "../models/milk.model";
import { PatientModel, PatientEhrOrderModel } from "../models/patient.model";
import {
	setStableMilkBottlesToOpened,
	createMilkBottlePayload,
} from "../utils/feeding.util";
import { PrintPrompts } from "../app-print-label-messages";
import {
	FeedPatientCommandResult,
	MarkMilkBottleAsAdministeredParams,
} from "./feed.service";

@Injectable({
	providedIn: "root",
})
export class FeedMilkBottleService {
	constructor(public inventoryService: InventoryService) {}

	async processMilkBottles(
		scannedItemMilkBottles: MilkBottleFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		if (scannedItemMilkBottles.length === 1) {
			return await this.processSingleMilkBottle(
				scannedItemMilkBottles[0],
				patient,
				selectedOrder
			);
		} else {
			return await this.processMultipleMilkBottles(
				scannedItemMilkBottles,
				patient,
				selectedOrder
			);
		}
	}

	private async processMultipleMilkBottles(
		milkBottleListItems: MilkBottleFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		const allFeedAllAction = milkBottleListItems.every(
			(m) => m.action === "feed all"
		);

		if (allFeedAllAction) {
			return await this.processMultipleMilkBottlesFeedAll(
				milkBottleListItems,
				patient,
				selectedOrder
			);
		} else {
			return await this.processMultipleMilkBottlesHasRemaining(
				milkBottleListItems,
				patient,
				selectedOrder
			);
		}
	}

	private async processMultipleMilkBottlesHasRemaining(
		listItemMilkBottles: MilkBottleFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		const milkBottles = listItemMilkBottles.map((i) => i.milkBottle);

		// Set stable to opened
		const { openedMilkBottles } = setStableMilkBottlesToOpened(milkBottles);

		// Update each milkbottle fed fully as expended (exclude the has remaining bottle)
		const fullyFedMilkBottles = listItemMilkBottles
			.filter((i) => i.action === "feed all")
			.map((i) => i.milkBottle);
		for (const milkBottle of fullyFedMilkBottles) {
			milkBottle.expendedDate = dayjs();
		}

		// Update all of the bottles that have been modified
		await this.updateMilkBottles(
			_.uniqBy(
				[...fullyFedMilkBottles, ...openedMilkBottles],
				(b) => b.id
			)
		);

		const createdMilkBottle = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, milkBottles)
		);

		const administeredBottle = await this.markMilkBottleAsAdministered({
			milkBottle: createdMilkBottle,
			patient,
			selectedOrder,
			isOverride: false,
			reason: MilkBottleUseType.Feeding,
		});

		return {
			milkBottleLabels: [],
			optionalMilkBottleLabels: {
				prompt: PrintPrompts.created,
				milkBottle: administeredBottle,
			},
		};
	}

	/** Process a Feed when all bottles are marked as Feed All */
	private async processMultipleMilkBottlesFeedAll(
		listItemMilkBottles: MilkBottleFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		const milkBottles = listItemMilkBottles.map((item) => item.milkBottle);

		// Update the fed fully bottles
		// Update stable to opened
		// Update each milkbottle fed fully as expended https://angeleyehealth.atlassian.net/browse/ML-674
		setStableMilkBottlesToOpened(milkBottles);
		for (const milkBottle of milkBottles) {
			milkBottle.expendedDate = dayjs();
		}
		await this.updateMilkBottles(milkBottles);

		const createdMilkBottle = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, milkBottles)
		);

		const administeredBottle = await this.markMilkBottleAsAdministered({
			milkBottle: createdMilkBottle,
			patient,
			selectedOrder,
			reason: MilkBottleUseType.Feeding,
			isOverride: false,
		});

		return {
			milkBottleLabels: [],
			optionalMilkBottleLabels: {
				prompt: PrintPrompts.created,
				milkBottle: administeredBottle,
			},
		};
	}

	private async markMilkBottleAsAdministered(
		params: MarkMilkBottleAsAdministeredParams
	) {
		return this.inventoryService.administerMilkBottle({
			milkBottleId: params.milkBottle.id,
			patientId: params.patient.id,
			reason: params.reason,
			orderId: params.selectedOrder?.id ?? null,
			isOverride: params.isOverride,
			overrideReason: params.overrideReason,
			overrideVerifiedBy: params.overrideVerifiedBy,
		});
	}

	private async updateMilkBottles(milkBottles: MilkBottleModel[]) {
		for (const milkBottle of milkBottles) {
			await this.inventoryService.updateMilkBottle3(milkBottle);
		}
	}

	private async processSingleMilkBottle(
		listItem: MilkBottleFeedPatientListItem,
		patient: PatientModel,
		selectedOrder?: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		const milkBottle = listItem.milkBottle;

		const action = listItem.action;
		switch (action) {
			case "feed all":
				await this.markMilkBottleAsAdministered({
					milkBottle,
					patient,
					selectedOrder,
					reason: MilkBottleUseType.Feeding,
					isOverride: listItem.isOverride,
					overrideReason: listItem.resultMessage,
					overrideVerifiedBy: listItem.secondNurse?.email,
				});
				return {
					milkBottleLabels: [],
					optionalMilkBottleLabels: null,
				};
			case "oral-care":
				await this.markMilkBottleAsAdministered({
					milkBottle,
					patient,
					selectedOrder,
					reason: MilkBottleUseType.OralCare,
					isOverride: listItem.isOverride,
					overrideReason: listItem.resultMessage,
					overrideVerifiedBy: listItem.secondNurse?.email,
				});
				return {
					milkBottleLabels: [],
					optionalMilkBottleLabels: null,
				};
			case "has remaining":
				return await this.processSingleMilkBottleHasRemaining(
					listItem,
					patient,
					selectedOrder
				);
			default:
				assertExhaustive(action);
		}
	}

	private async processSingleMilkBottleHasRemaining(
		milkBottleListItem: MilkBottleFeedPatientListItem,
		patient: PatientModel,
		selectedOrder?: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		const milkBottle = milkBottleListItem.milkBottle;

		// transition a stable milk bottle to opened
		const {
			openedMilkBottles: [openedMilkBottle], // there is only one
		} = setStableMilkBottlesToOpened([milkBottle]);
		if (openedMilkBottle) {
			await this.updateMilkBottles([openedMilkBottle]);
		}

		// create a new bottle with the source milk bottle as contents
		const createdMilkBottle = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, [milkBottle])
		);

		const administeredBottle = await this.markMilkBottleAsAdministered({
			milkBottle: createdMilkBottle,
			patient,
			selectedOrder,
			reason: MilkBottleUseType.Feeding,
			isOverride: milkBottleListItem.isOverride,
			overrideVerifiedBy: milkBottleListItem.secondNurse?.email,
			overrideReason: milkBottleListItem.resultMessage,
		});

		return {
			milkBottleLabels: [openedMilkBottle].filter(Boolean), // always print a label for a milk bottle transitioned from Stable to Opened
			optionalMilkBottleLabels: {
				prompt: PrintPrompts.created,
				milkBottle: administeredBottle,
			},
		};
	}
}

export enum FeatureStatus {
	Latest = "latest",
	Stable = "stable",
	Off = "off"
}

export enum Features {
	Admin_Portal = "adminPortal",
	Add_Fortification = "addFortification",
	Angel_Eye_Integration = "angelEyeIntegration",
	Assign_Product = "assignProduct",
	Auth_Guard = "authGuard",
	Authenticator = "authenticator",
	Badge_Scanning = "badgeScanning",
	Direct_Breastfeed = "directBreastfeed",
	Discharge_Parents_Milk = "dischargeParentsMilk",
	Divide_Feed = "divideFeed",
	Edit_And_Reprint = "editAndReprint",
	Edit_And_Reprint_Unassigned = "editAndReprintUnassigned",
	Feed_Patient = "feedPatient",
	Feeding_Report = "feedingReport",
	Freeze_Parents_Milk = "freezeParentsMilk",
	Fulfillment_Equivalents = "fulfillmentEquivalents",
	Internal_Tools = "internalTools",
	Layout_And_Navigation = "layoutAndNavigation",
	Link_Siblings = "linkSiblings",
	Location_Tracking = "locationTracking",
	Milk_Room_Prep = "milkRoomPrep",
	Mobile_Scanner = "mobileScanner",
	Oral_Care = "oralCare",
	Order_Validation = "orderValidation",
	Patient_Inventory = "patientInventory",
	Prep_Formula = "prepFormula",
	Prep_Milk = "prepMilk",
	Print_Custom_Label = "printCustomLabel",
	Print_Fresh_Milk_Label = "printFreshMilkLabel",
	Print_Parent_Label = "printParentLabel",
	Printing_Service = "printingService",
	Receive_Parents_Milk = "receiveParentsMilk",
	Receive_Product = "receiveProduct",
	Recipe_Calculator = "recipeCalculator",
	Select_Milk = "selectMilk",
	Select_Patient = "selectPatient",
	Thaw_Patients_Milk = "thawPatientsMilk",
	Thaw_Product = "thawProduct",
	Verify_Milk_Label = "verifyMilkLabel",
	Virtual_Printer = "virtualPrinter"
}

export type IFeatureFlag = {
	[key: string]: FeatureStatus;
}

import dayjs from "dayjs";
import {
	MilkType,
	ProductType,
	FeedLabelStatus,
	FeedState,
} from "../app.enums";
import { IContent, MilkBottleModel } from "./milk.model";
import { ModifierOption } from "../components/milk-modifiers/milk-modifiers.component";
import { MilkBankProductModel } from "./milk-bank-product.model";
import { Product } from "./product.model";

export type FeedObject = MilkBottleModel | MilkBankProductModel | Product;

export abstract class FeedObjectModel {
	/**
	 * Short id. 64 bit HEX32 string, 13 characters in length.
	 * Generated by the {@link https://www.nuget.org/packages/Unified UnifiedID NuGet package}
	 */
	unifiedId: string;
	id: string;
	bottleNumber: number;
	calorieDensity: number;
	contents: IContent[];
	expendedDate: dayjs.Dayjs; // TODO: should this be optional? It is in MilkBankProductModel for some reason
	expirationDate: dayjs.Dayjs;
	feedState: FeedState;
	feedType: MilkType | ProductType;
	created: dayjs.Dayjs; // Stored in UTC
	modified: dayjs.Dayjs; // Stored in UTC

	/*
	 	TODO:
	 	 Can this possibly help with allowing any "front end" attributes to be added to the model?
	 	 https://www.typescriptlang.org/docs/handbook/2/objects.html#excess-property-checks
	 */
	// front-end attributes
	labelStatus: FeedLabelStatus;
	modifierOption: ModifierOption;

	/**
	 * Created within a specified number of seconds.
	 */
	hasBeenCreated(feedObjectModel: FeedObjectModel) {
		const timeElapsedInSeconds = 10;

		if (!feedObjectModel.created) {
			return null;
		}

		const created = dayjs(feedObjectModel.created);
		const now = dayjs();

		const difference = now.diff(created, "seconds");

		return timeElapsedInSeconds > difference;
	}
}

import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import type { IonInput } from "@ionic/angular";

@Component({
	selector: "app-label-quantity",
	templateUrl: "./label-quantity.component.html",
	styleUrls: ["./label-quantity.component.scss"],
})
export class LabelQuantityComponent implements OnInit {
	@ViewChild("ionInputEl", { static: true }) ionInputEl!: IonInput;

	@Input() minimumValue = 1;
	@Input() initialValue: number;
	@Input() disabled: boolean;
	@Input() step = "1";

	@Output() changeLabelQuantity = new EventEmitter<number>();
	@Output() changeValidity = new EventEmitter<boolean>();

	input: number;
	hasError: boolean = false;

	ngOnInit(): void {
		const isValidInitialValue = (this.initialValue && this.initialValue >= this.minimumValue);
		this.input = isValidInitialValue ? this.initialValue : this.minimumValue;
		this.changeValidity.emit(true);
	}

	handleLabelQuantity($event: number) {
		if (!this.isValidValue($event, this.minimumValue)) {
			this.hasError = true;
			this.changeValidity.emit(false);
			return;
		}

		this.hasError = false;
		this.changeLabelQuantity.emit($event);
		this.changeValidity.emit(true);
	}

	isValidValue = (value: number, minimumValue: number): boolean =>
		!!value && Number.isInteger(value) && value >= minimumValue;
}

import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

/**
 * @deprecated - Should eventually be superseded by {@link FeedPropertyItem}
 */
export interface MilkProductDetails {
	label: string;
	icon: IconDefinition;
	values: string[];
}

/**
 * @deprecated - Should eventually be superseded by FeedProperty
 */
export enum MilkDetailItem {
	CALORIC_DENSITY = "Calorie Density",
	CONTENTS = "Contents",
	DISCHARGE_DATE = "Discharge Date",
	DISTRIBUTED_TO = "Distributed To",
	EXPENDED_DATE = "Expended Date",
	EXPIRATION_DATE = "Expiration Date",
	FROZEN_DATE = "Frozen Date",
	LOT_NUMBER = "Lot Number",
	OPENED_DATE = "Opened Date",
	PRODUCT_CALORIE = "Product Calorie",
	PRODUCT_MANUFACTURER = "Product Manufacturer",
	PRODUCT_NAME = "Product Name",
	RECEIVED_DATE = "Received Date",
	VOLUME = "Volume",
}

import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import {
	FeedingEntry,
	IFeedingEntry,
} from "src/app/models/feeding-entry.model";
import {
	IPatient,
	IPatientEhrOrder,
	PatientEhrOrderModel,
	PatientModel,
} from "src/app/models/patient.model";

import { RestApiService } from "src/app/services/rest-api.service";
import { environment } from "../../environments/environment";
import { lastValueFrom } from "rxjs";
import { IWristband } from "./scanning.service";
import dayjs from "dayjs";
import { MilkBottleModel } from "../models/milk.model";

@Injectable({
	providedIn: "root",
})
export class PatientService {
	constructor(private http: RestApiService) {}

	getPatients(): Promise<PatientModel[]> {
		const url = "/patients";

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IPatient[]) =>
						res.map((p) => new PatientModel(p))
					)
				)
		);
	}

	async getPatient(params: string) {
		// artificial delay
		await new Promise((resolve) =>
			setTimeout(resolve, environment.settings.apiDelay)
		);

		const url = `/patients/details${params}`;
		return lastValueFrom(
			this.http
				.get(url)
				.pipe(map((res: IPatient) => new PatientModel(res)))
		);
	}

	async getPatientDetailsByMilkBottle(
		milkBottle: MilkBottleModel
	): Promise<PatientModel[]> {
		if (!milkBottle) {
			return [];
		}

		return await Promise.all(
			milkBottle.patients.map(
				async (patient) => await this.getPatient(`?mrn=${patient.mrn}`)
			)
		);
	}

	/**
	 * @deprecated Use getPatient
	 * Get a single patient by id, mrn, or ecd
	 */
	async getPatientDetails(
		params: GetPatientDetailsParams
	): Promise<PatientModel> {
		// artificial delay
		await new Promise((resolve) =>
			setTimeout(resolve, environment.settings.apiDelay)
		);

		const url = "/patients/details";

		return lastValueFrom(
			this.http
				.get(url, params)
				.pipe(map((res: IPatient) => new PatientModel(res)))
		);
	}

	linkPatients(payload: {
		patientIds: PatientModel["id"][];
		secondNurseBadge: string;
	}): Promise<PatientModel[]> {
		const url = "/patients/link";

		return lastValueFrom(
			this.http
				.put(url, payload)
				.pipe(
					map((res: IPatient[]) =>
						res.map((p) => new PatientModel(p))
					)
				)
		);
	}

	/**
	 * By default, filtered by 48 hours.
	 * TODO: ML-2125
	 */
	getFeedingReport(
		mrn: PatientModel["mrn"],
		durationInHours = 48
	): Promise<FeedingEntry[]> {
		const url = `/reports/feeding?mrn=${mrn}`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IFeedingEntry[]) =>
						res
							.map((f) => new FeedingEntry(f))
							.filter((f) =>
								f.created.isAfter(
									dayjs().subtract(durationInHours, "hour")
								)
							)
					)
				)
		);
	}

	/**
	 * Get patient EHR orders by patient id.
	 *
	 */
	getPatientEhrOrders(id: PatientModel["id"]) {
		const url = `/patients/${id}/orders`;

		return lastValueFrom(
			this.http
				.get(url)
				.pipe(
					map((res: IPatientEhrOrder[]) =>
						res.map((f) => new PatientEhrOrderModel(f))
					)
				)
		);
	}
}

/** params for /patients/details
 * send one of id, mrn, or ecd
 *
 * @deprecated getWristbandParams should be used instead. There is also no
 * indication in the code that id is ever used?
 */
export interface GetPatientDetailsParams {
	/** the milk tracker patient primary key ID */
	id?: number;
	mrn?: string;
	/** the hospital encounter id, set by the HL7 integration. the patient has one current encounter id */
	ecd?: string;
}

/**
 * returns the params for /patients/details as a string
 */
export const getWristbandParams = (wristband: IWristband) => {
	if (wristband.type === "mrn") {
		return `?mrn=${wristband.value}`;
	}

	if (wristband.type === "ecd") {
		return `?ecd=${wristband.value}`;
	}
};

import { ScanLogType } from '../../app.enums';
import { modalMessage } from '../../app.modal-messages';
import { ScanMilkBankProductValidationError } from './scan-validation-error';
import { IScanProductValidation } from './scan-validation.model';
import { StorageService } from 'src/app/services/storage.service';

/**
 * Reject the product from being received if the product has a lot number that has been flagged as recalled.
 */

export function RejectRecalledProductOnPrint() {
	return (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanProductValidation[]) {
			const formValues = this.getFormValues();

			let productId: any;
			let productLotNumber: any;
			let productName: any;

			const recalledProducts = StorageService.configs.products.reduce(
				(acc, product) => {
					if (product.recalledLotNumbers?.length) {
						// Create an object for each recalled lot number that includes the product name
						const recalledWithNames = product.recalledLotNumbers.map(
							(lotNumber: any) => ({
								productId: product.id,
								productName: product.name,
								lotNumber: lotNumber,
							}),
						);
						acc.push(...recalledWithNames);
					}
					return acc;
				},
				[],
			);

			// check if the product is recalled by comparing the product id and lot number to the recalled products
			let isProductRecalled: boolean;
			for (const product of formValues) {
				productId = product.productId;
				productLotNumber = product.lotNumber;

				if (recalledProducts.some(
					(recalledProduct) =>
						recalledProduct.productId === productId &&
						recalledProduct.lotNumber === productLotNumber,
				)) {
					isProductRecalled = true;
					productName = product.name;
					break;
				}
			}

			// if the product is recalled pass the recalled product info to the error message
			if (isProductRecalled) {
				const recalledProductInfo = {
					lotNumber: productLotNumber,
					productName: productName,
				};
				throw new ScanMilkBankProductValidationError(
					ScanLogType.Product_Recalled,
					modalMessage.product_recalled_not_receivable_on_print(recalledProductInfo),
				);
			}

			return originalMethod.apply(this, args);
		};
	};
}

<ion-list>
	<app-patient-info *ngIf="patients && patients.length"
					  [patients]="patients"
					  [hasSelectPatientButton]="false"
					  title="Assigned Patients"
					  instructions="Loading...">
	</app-patient-info>

	<ion-list-header *ngIf="showPrintPreview">
		<ion-label>
			Print Preview
			<ion-chip color="primary">
				<ion-label>{{milkBottles?.length + milkBankProducts?.length}}</ion-label>
			</ion-chip>
		</ion-label>
	</ion-list-header>

	<app-bottle-info *ngIf="milkBottles?.length"
					 [isHeaderVisible]="false"
					 [milks]="milkBottles"
					 [hasButtons]="false"
					 instructions="Loading...">
	</app-bottle-info>

	<app-product-info *ngIf="milkBankProducts?.length"
					  [isHeaderVisible]="false"
					  [milkBankProducts]="milkBankProducts"
					  [hasButtons]="false"
					  instructions="Loading...">
	</app-product-info>

	<ion-list-header>
		<ion-label>Select Printer</ion-label>
	</ion-list-header>
	<ion-item class="select-item"
	          lines="none"
	          (click)="handleSelectPrinterPress($event)">
		<ion-input aria-label="select printer"
					fill="outline"
					placeholder="Select a printer"
					readonly="true"
					[value]="selectedPrinter?.name"
					data-testid="select_printer_ion_input">
		</ion-input>
		<ion-button fill="clear" slot="end" aria-label="Select Printer">
			<fa-icon [icon]="faChevronRight" [fixedWidth]="true" size="1x"></fa-icon>
		</ion-button>
	</ion-item>
</ion-list>

<div class="buttons">
	<button *ngIf="printButtons.isPrintable"
		(click)="handlePrint('created')"
		class="nav-btn"
		[disabled]="!selectedPrinter">
		{{ isRetry ? "Retry " : "" }}Print New
	</button>

	<button *ngIf="printButtons.isSkippable"
			(click)="handleSkipPrint()"
			class="nav-btn"
			[disabled]="!selectedPrinter">
			Skip Printing
	</button>

	<button (click)="handlePrint('all')" class="nav-btn" [disabled]="!selectedPrinter" data-testid="print_all_button">{{ isRetry ? "Retry " : "" }}Print
		All</button>
</div>

import { Injectable } from "@angular/core";
import { Nurse } from "../components/nurse-info/nurse-info.component";
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";
import { ScanLogWorkflow } from "../app.enums";
import { ModalService } from "./modal.service";
import { datadogLogs } from "@datadog/browser-logs";

enum NurseValidationErrorCodes {
	UNAUTHORIZED = "Unauthorized",
	DUPLICATE = "Duplicate",
}

@Injectable({
	providedIn: "root",
})
export class NurseValidationService {
	constructor(
		private authService: AuthService,
		private modalService: ModalService,
	) {}

	/**
	 * Validate that the second nurse id exists and has the AdministerFeed permission,
	 * and does not match the logged in user
	 *
	 * @param nurseId - The ID used when logging in
	 */
	async isAuthorizedNurse(params: {
		nurseId: string;
	}): Promise<Nurse> {
		const { nurseId } = params;

		// TODO: ML-2629 - Add additional error handling for failed API calls.
		const result = await this.authService.whois({
			tenantId: StorageService.activeTenant?.tenantId,
			secondaryId: nurseId,
		});

		if (
			!result ||
			!result.hasAdministerFeedPermission(
				StorageService.activeTenant.tenantId
			)
		) {
			return Promise.reject(
				new Error(NurseValidationErrorCodes.UNAUTHORIZED)
			);
		}

		if (result.email === StorageService.user.email) {
			return Promise.reject(
				new Error(NurseValidationErrorCodes.DUPLICATE)
			);
		}

		return Promise.resolve({ id: nurseId, email: result.email });
	}

	getMessageInstructions(params: {
		workflowName: string;
		isEnabled: boolean;
		isEnforced: boolean;
	}): string {
		const { isEnabled, isEnforced, workflowName } = params;

		if (!isEnabled) {
			return `${workflowName} requires approval from a second nurse. Please input the second nurse’s ID to continue.`;
		}

		if (!isEnforced) {
			return `${workflowName} requires approval from a second nurse. Please scan second nurse badge or input the second nurse’s ID to continue.`;
		}

		return `${workflowName} requires approval from a second nurse. Please scan second nurse badge to continue.`;
	}

	/**
	 * Display relevant error messages to the user & log.
	 */
	async handleNurseValidationFailure(params: {
		error: Error;
		workflow: ScanLogWorkflow;
	}) {
		const { error, workflow } = params;

		switch ((error as Error).message) {
			case NurseValidationErrorCodes.UNAUTHORIZED:
				await this.modalService.presentInvalidSecondBadgeModal({ workflow });
				break;
			case NurseValidationErrorCodes.DUPLICATE:
				await this.modalService.presentDuplicateNurseId({
					user: StorageService.user,
					workflow,
				});
				break;
			default:
				datadogLogs.logger.error("Cannot validate second nurse", error);
				throw new Error(`handleNurseId ${error}`);
		}
	}
}

import {
	MilkState,
	MilkType,
	ContentProductType,
	MilkLocation,
	InventoryCategory,
	ProductType,
	ProductState,
} from "../app.enums";
import { FeedObject } from "../models/feed-object.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { MilkBottleExpiringSoonest } from "../models/milk-bottle-expiring-soonest";
import { IContent, MilkBottleModel } from "../models/milk.model";
import { IPatientInventoryCount } from "../models/patient.model";
import { getSoonestExpiringMilk } from "./expiration.util";

export type MilkBottleCategoryRecord = {
	[key in InventoryCategory]: MilkBottleModel[];
};

export type MilkBankProductCategoryRecord = {
	[key in InventoryCategory]: MilkBankProductModel[];
};

/**
 * @deprecated
 * Frozen:
 * Milk State is Frozen
 * Does not contain prepared contents
 */
export const isFrozen = (milkBottle: MilkBottleModel): boolean => {
	const frozen = milkBottle.milkState === MilkState.Frozen;

	return frozen && !hasPreparedContents(milkBottle);
};

/**
 * Frozen:
 *
 * Milk Bank Product:
 * 	Product state is frozen
 *
 * Milk Bottle:
 * 	Milk State is Frozen
 * 	Does not contain prepared contents
 */
export const isFrozen2 = (feedObject: FeedObject): boolean => {
	if (feedObject instanceof MilkBankProductModel) {
		return feedObject?.productState === ProductState.Frozen;
	}

	if (feedObject instanceof MilkBottleModel) {
		const frozen = feedObject?.milkState === MilkState.Frozen;
		return frozen && !hasPreparedContents(feedObject);
	}
};

/**
 * Fresh:
 * Milk State is Fresh
 * Milk Type is EBM
 * Not fortified
 */
export const isFresh = (milkBottle: MilkBottleModel): boolean => {
	const fresh = milkBottle.milkState === MilkState.Fresh;
	const ebm = milkBottle.milkType === MilkType.EBM; // If contain anything other than EBM, milk type will be EBM + * so
	// we can safely look at only EBM,
	const fortified = milkBottle.isFortified;

	return fresh && ebm && !fortified;
};

/**
 * Thawed:
 * Milk State is Thawed
 * Does not contain prepared contents
 */
/**
 * The “thawed” section could contain EBM or DM but if other things
 * are added to the bottle to fortify its contents (whether rtf or additive)
 * it should end up in prepared.
 */
export const isThawed = (milkBottle: MilkBottleModel): boolean => {
	const thawed = milkBottle.milkState === MilkState.Thawed;

	return thawed && !hasPreparedContents(milkBottle);
};

/**
 * Discharged:
 * Has discharge date
 */
export const isDischarged = (milkBottle: MilkBottleModel): boolean =>
	!!milkBottle.dischargeDate;

/**
 * Stable:
 * Milk State is Stable
 * Does not contain prepared contents
 * - OR-
 * Contents are RTF and does not have opened date
 */
export const isStable = (milkBottle: MilkBottleModel): boolean => {
	const stable = milkBottle.milkState === MilkState.Stable;
	const stableMilkBottle = stable && !hasPreparedContents(milkBottle);

	// RTF
	const preparedBottle =
		milkBottle.contents.every(
			(x) => x.contentProductType === ProductType.RTF
		) && !milkBottle.openedDate;

	return stableMilkBottle || preparedBottle;
};

/**
 * Prepared:
 * Milk has been opened
 * - OR -
 * Milk has been combined (ebm_dm)
 * - OR -
 * Contents contain anything other than ebm/dm
 */
export const isPrepared = (milkBottle: MilkBottleModel): boolean =>
	!!milkBottle.openedDate || hasPreparedContents(milkBottle);

export const isPrepared2 = (
	feed: MilkBottleModel | MilkBankProductModel
): boolean => {
	if (!feed) {
		return;
	}

	// check if class of feed is MilkBottleModel
	if (feed instanceof MilkBottleModel) {
		return !!feed.openedDate || hasPreparedContents(feed);
	}

	if (feed instanceof MilkBankProductModel) {
		return false;
	}

	throw Error("ngOnInit: milkBottle xor milkBankProduct not found.");
};

/**
 * The item has been recalled. Users enter recalled information in MAP.
 */
export const isRecalled = (feed: FeedObject) => {
	if (feed instanceof MilkBottleModel) {
		return feed.contents.some((x) => x.recalled);
	}

	if (feed instanceof MilkBankProductModel) {
		return feed.recalled;
	}

	throw Error("recalled: milkBottle xor milkBankProduct not found.");
};

/**
 * Location is Home
 * Has pump date
 */
export const isHome = (milkBottle: MilkBottleModel): boolean =>
	milkBottle.location === MilkLocation.Home && !!milkBottle.pumpDate;

/**
 * Check to see if the inventory has a bottle that expires sooner than the scanned bottle.
 * If a bottle is found, return it so we can display the bottle number to the user.
 */
export const getSoonerExpiringMilkBottleFromInventory = (params: {
	milkBottles: MilkBottleModel[];
	soonestExpiringMilkBottles: MilkBottleExpiringSoonest[];
}): MilkBottleExpiringSoonest => {
	const { milkBottles, soonestExpiringMilkBottles } = params;
	if (milkBottles.length === 0 || soonestExpiringMilkBottles.length === 0) {
		return null;
	}

	const milkBottle = getSoonestExpiringMilk(milkBottles);
	const soonestExpiring = soonestExpiringMilkBottles[0];

	if (soonestExpiring.expirationDate.isBefore(milkBottle.expirationDate)) {
		return soonestExpiring;
	}

	return null;
};

/**
 * Calculates the total count of bottles in a patient's inventory.
 * Takes a IPatientInventoryCount object and returns the sum of
 * all bottles considered to be in the hospital.
 */
export const getHospitalBottleCount = (
	patientInventoryCount: IPatientInventoryCount
): number => {
	const { prepared, fresh, thawed, frozen, stable, recalled, discharged } =
		patientInventoryCount;

	const hospitalInventory = [
		prepared,
		fresh,
		thawed,
		frozen,
		stable,
		recalled,
		discharged,
	];

	return hospitalInventory.reduce((acc, curr) => acc + curr, 0);
};

/**
 * A bottle that is prepared contains contents that are
 * anything other than EMB or DM. A bottle that has EBM_DM
 * has been combined and should also considered prepared.
 */
export const hasPreparedContents = (milkBottle: MilkBottleModel): boolean => {
	if (milkBottle.milkType === MilkType.EBM_DM) {
		return true;
	}

	const contents = milkBottle.contents?.reduce(
		(result: IContent[], content: IContent) => {
			// reject ebm
			if (content.contentProductType === ContentProductType.EBM) {
				return result;
			}

			// reject dm
			if (content.contentProductType === ContentProductType.DM) {
				return result;
			}

			result.push(content);
			return result;
		},
		[]
	);

	return contents.length > 0;
};

<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="cancel()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{ title }}</div>
		</ion-title>
		<ion-buttons slot="end">
			<ion-button (click)="clearFields()">clear</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div class="step">
		<p *ngIf="hasUnreadableBarcode">
			Please set this bottle aside. A label will be printed after you receive the batch.
		</p>

		<ion-list lines="none">
			<ion-item *ngIf="!hasUnreadableBarcode && scannedObject" lines="none">
				<ion-label>
					<p>Barcode</p>
					<ion-text>{{scannedObject.barcode.text}}</ion-text>
				</ion-label>
			</ion-item>

			<form [formGroup]="receiveProductForm">
				<ion-item class="select-item" lines="none">
					<ion-label>
						<p class="select-item__title">Product</p>
						<ion-select
							aria-label="Select Product"
							fill="outline"
							labelPlacement="stacked"
							interface="popover"
							placeholder="Select a product"
							[interfaceOptions]="defaultSelectOptions"
							formControlName="product"
							[compareWith]="compareProduct"
							>
							<ion-select-option *ngFor="let p of products" [value]="p">
								{{p.name}}, {{p.manufacturerName}}
							</ion-select-option>
						</ion-select>
					</ion-label>
				</ion-item>

				<ion-item *ngIf="product" lines="none" class="input-field">
					<ion-label>
						<p>State</p>
						<span>{{ product?.defaultState | titlecase }}</span>
					</ion-label>
				</ion-item>

				<app-inline-date
					label="Expiration Date"
					[defaultDate]="manufacturerExpirationDate"
					[minDate]="minDate"
					[maxDate]="maxManufacturerExpirationDate"
					(dateTimeChange)="onDateChanged($event)"
					[isExpirationDate]="true"
					[disabled]="false"
					#recieveProductDatePicker>
				</app-inline-date>

				<ion-item class="input-field" lines="none">
					<ion-label>
						<p>Lot Number</p>
						<ion-input
							formControlName="lotNumber"
							placeholder="Enter Lot Number"
							aria-label="Lot Number">
						</ion-input>
					</ion-label>
				</ion-item>

				<ion-item *ngIf="!isEdit" class="select-item" lines="none">
					<ion-label>
						<p class="select-item__title">Defect</p>
						<ion-select
							aria-label="Select Defect Reason"
							fill="outline"
							labelPlacement="stacked"
							interface="popover"
							placeholder="Select a defect reason"
							formControlName="defectInfo"
							[compareWith]="compareDefect"
							[interfaceOptions]="defaultSelectOptions"
							(ionChange)="setDefectiveReason($event)">
							<ion-select-option *ngFor="let r of defectiveReasons" [value]="r">
								{{r.reason}}
							</ion-select-option>
						</ion-select>

					</ion-label>
				</ion-item>

				<ion-item *ngIf="!isEdit" class="input-field" lines="none">
					<ion-label>
						<p>Bottle Number</p>
						<ion-input
							formControlName="bottleNumber"
							type="number"
							placeholder="Enter bottle number"
							aria-label="Bottle Number">
						</ion-input>
					</ion-label>
					<ion-note *ngIf="invalidBottleNumber" slot="helper" color="danger">
						Bottle number must be unique
					</ion-note>
				</ion-item>
			</form>
		</ion-list>

		<div *ngIf="!isEdit" class="buttons">
			<button (click)="handleAddButtonPressed()" [disabled]="!isValid()" class="action-btn">
				Add to queue
			</button>
			<button (click)="handleSaveButtonPressed()" [disabled]="!isValid()" class="nav-btn">
				Save batch info & Add
			</button>
		</div>
		<div *ngIf="isEdit" class="buttons">
			<button (click)="handleSaveButtonPressed()" [disabled]="!isValid()" class="nav-btn">
				Save
			</button>
		</div>
	</div>
</ion-content>

import { Component, Input, OnChanges, OnInit } from "@angular/core";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { isExpired } from "../../utils/expiration.util";
import {
	getProductTypeText,
	isNewlyCreated,
} from "../../utils/milk-bank-product.util";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { Product } from "../../models/product.model";
import { FeedState, ProductState, assertExhaustive } from "../../app.enums";
import { SavedData } from "../../models/unreceived-product";

@Component({
	selector: "app-product-info-collapsed",
	templateUrl: "./product-info-collapsed.component.html",
	styleUrls: ["./product-info-collapsed.component.scss"],
})
export class ProductInfoCollapsedComponent implements OnInit, OnChanges {
	@Input() milkBankProduct: MilkBankProductModel = null;
	@Input() product: Product = null;
	@Input() savedData: SavedData = null;
	@Input() expirationDate: string;

	productInfo: ProductInfo;

	ngOnChanges() {
		this.setDetails();
	}

	ngOnInit() {
		this.setDetails();
	}

	setDetails() {
		if (this.milkBankProduct) {
			this.productInfo = this.setMilkBankProductInfo();
		}

		if (this.product) {
			this.productInfo = this.setProductInfo();
		}

		if (this.savedData) {
			this.productInfo = this.setSavedDataProductInfo();
		}
	}

	setMilkBankProductInfo(): ProductInfo {
		return {
			detailLabel: "Bottle #",
			detailValue: getBottleNumberText(this.milkBankProduct.bottleNumber),
			productColor: getProductColor(this.milkBankProduct.productState),
			productState: this.milkBankProduct.productState,
			productTypeText: getProductTypeText(this.milkBankProduct),
			expirationDate: this.expirationDate,
			expirationChipColor: isExpired(this.milkBankProduct.expirationDate)
				? "danger"
				: "",
			displayNewChip: isNewlyCreated(this.milkBankProduct),
		};
	}

	setProductInfo(): ProductInfo {
		return {
			detailLabel: "Bottle #",
			detailValue: getBottleNumberText(null),
			productColor: getProductColor(this.product.defaultState),
			productState: this.product.defaultState,
			productTypeText: this.product.name,
			expirationDate: this.expirationDate,
			expirationChipColor: "",
			displayNewChip: false,
		};
	}

	setSavedDataProductInfo(): ProductInfo {
		return {
			detailLabel: "Lot #",
			detailValue: this.savedData.lotNumber,
			productColor: getProductColor(this.savedData.product.defaultState),
			productState: this.savedData.product.defaultState,
			productTypeText: this.savedData.product.name,
			expirationDate: this.expirationDate,
			expirationChipColor: "",
			displayNewChip: false,
		};
	}
}

interface ProductInfo {
	detailLabel: string;
	detailValue: string;
	productColor: string;
	productState: string;
	productTypeText: string;
	expirationDate: string;
	expirationChipColor: string;
	displayNewChip: boolean;
}

export const getProductColor = (feedState: FeedState): string => {
	const cssClass = "milk-info__header__state";
	let modifier = "";

	if (!feedState) {
		return cssClass;
	}

	if (feedState === "fresh") {
		return `${cssClass}--fresh`;
	}

	switch (feedState) {
		case ProductState.Frozen:
			modifier = "frozen";
			break;
		case ProductState.Thawed:
			modifier = "thawed";
			break;
		case ProductState.Stable:
		case ProductState.Opened:
			modifier = "stable";
			break;
		default:
			assertExhaustive(feedState);
	}

	return `${cssClass}--${modifier}`;
};

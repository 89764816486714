import { Injectable } from "@angular/core";

import { StorageService } from "./storage.service";
import { FeatureStatus, Features, IFeatureFlag } from "../features.enums";

@Injectable({
	providedIn: "root",
})
export class FeatureFlagService extends StorageService {
	constructor() {
		super();
	}

	static get features(): IFeatureFlag {
		return StorageService.features;
	}

	static get featureOverrides(): IFeatureFlag {
		return StorageService.configs.featureOverrides;
	}

	static isStable(feature: Features): boolean {
		return !this.isOff(feature) && this.features[feature] === FeatureStatus.Stable;
	}

	static isLatest(feature: Features): boolean {
		return !this.isOff(feature) && this.features[feature] === FeatureStatus.Latest;
	}

	static isOff(feature: Features): boolean {
		return this.features[feature] === FeatureStatus.Off;
	}
}

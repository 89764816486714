import { defineEnvironment } from "./common";
import { FeatureStatus, Features } from "../app/features.enums";
import { IPermissions } from "src/app/models/user-profile.model";
import { FeatureFlagFactory } from "src/app/factories/feature-flag.factory";

export const environment = defineEnvironment({
	production: false,
	type: "dev",
	baseUrl: "https://development.milktracker.angeleyehealth.com",
	apiUrl: "https://api.development.milktracker.angeleyehealth.com/api/v1",
	portalUrl: "https://api.development.milktracker.angeleyehealth.com/portal",
	settings: {
		authGuard: false,
		sessionLengthMinutes: 20,
		timeoutDuration: 15000,
		enableBadgeScanning: true,
		enableSelectMilk: true,
		bypassExpiration: true,
		showMobileDateTimePicker: false,
		bypassOrderMatching: false, // Set to true for testing manual entry
		apiDelay: 0,
		removeAllOrders: false,
		clerkSignInURL:
			"https://auth.development.angeleyehealth.com/milktracker/sign-in",
		clerkSignOutURL:
			"https://auth.development.angeleyehealth.com/milktracker/sign-out",
		// You can comment this whole thing out if you want to use the default permissions
		overridePermissions: { global_VirtualPrinting: true } as unknown as IPermissions,
		overrideFeatureFlags: {
			[Features.Virtual_Printer]: FeatureStatus.Latest,
		}
	},
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<div *ngIf="chips">
	<div class="section-header" *ngIf="showHeader">
		Patient Inventory
	</div>

	<ng-container *ngFor="let chip of chips">
		<ion-chip [disabled]="true" [ngClass]="chip.cssClass">
			<ion-label>{{ chip.value }} {{ chip.label }}</ion-label>
		</ion-chip>
	</ng-container>
</div>

import { Component, OnDestroy, OnInit } from "@angular/core";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { ModalController } from "@ionic/angular";
import dayjs, { Dayjs } from "dayjs";
import { Subscription, scan, takeWhile, timer } from "rxjs";

@Component({
	selector: "app-logout-warning",
	templateUrl: "./logout-warning.component.html",
	styleUrls: ["./logout-warning.component.scss"],
})
export class LogoutWarningComponent implements OnInit, OnDestroy {
	timer$: Subscription;
	countdownClock: string;
	countdownInSeconds = 60;
	faClock = faClock;

    //now + countdown seconds
	sessionTimeoutTimestamp: Dayjs;

	constructor(public modalCtrl: ModalController) {
		this.sessionTimeoutTimestamp = dayjs().add(this.countdownInSeconds, "second");
		this.countdownClock = `0:${this.countdownInSeconds}`;
	}

	ngOnInit() {
		this.initCountdownTimer();
	}

	ngOnDestroy() {
		this.timer$.unsubscribe();
	}

	isSessionValid(): boolean {
		const now = dayjs()
		return this.sessionTimeoutTimestamp.isAfter(now)
	}

	/**
	 * Countdown from 60 seconds. Once the timer reaches 0:00,
	 * log the user out since they are unresponsive.
	 */
	initCountdownTimer() {
		const intervalInMilliseconds = 1000; // one second


		// Let dayjs handle the formatting. To do that,
		// we'll create a date and ensure the seconds starts
		// at 60. By the time the view loads, users see: 0:59.
		const minute = dayjs().second(60);

		const result$ = timer(0, intervalInMilliseconds).pipe(
			scan((value) => --value, this.countdownInSeconds),
			takeWhile((value) => this.isSessionValid() && value >= 0)
		);

		this.timer$ = result$.subscribe({
			next: (secondsRemaining) => {
				const seconds = minute.second(secondsRemaining).format("ss");

				// Since we are counting down from one minute, we display
				// 0:xx for minutes and display the seconds. This ensures
				// we get two digit seconds.
				this.countdownClock = `0:${seconds}`;
			},
			complete: async () => {
				this.logout();
			},
		});
	}

	confirm() {
		if(this.isSessionValid())
			this.modalCtrl.dismiss({
				action: "extendSession",
			} as LogoutWarningDismissResult);
		else
			this.logout()

	}

	logout() {
		this.modalCtrl.dismiss({
			action: "logout",
		} as LogoutWarningDismissResult);
	}
}

export interface LogoutWarningDismissResult {
	action: "extendSession" | "logout";
}

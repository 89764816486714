import {
	AfterViewInit,
	Component,
	ElementRef,
	Input,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	Barcode,
	BarcodeScanner,
	LensFacing,
	StartScanOptions,
} from "@capacitor-mlkit/barcode-scanning";
import { FeedObject } from "src/app/models/feed-object.model";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { MilkBottleModel } from "src/app/models/milk.model";
import { BaseService } from "src/app/services/base.service";
import {
	getBottleNumberText,
	getMilkTypeText,
} from "src/app/utils/milk-label.util";
import { capitalize } from "../../app.util";

@Component({
	selector: "app-barcode-scanner-multiple",
	templateUrl: "./barcode-scanner-multiple.component.html",
	styleUrls: ["./barcode-scanner-multiple.component.scss"],
})
export class BarcodeScannerMultipleComponent
	implements OnInit, AfterViewInit, OnDestroy
{
	@ViewChild("square")
	public squareElement: ElementRef<HTMLDivElement> | undefined;

	@Input()
	feedObjects: FeedObject[] = [];

	isTorchAvailable = false;
	isTorchOn = false;

	get scannedItemDisplayDetails(): string[] {
		return this.feedObjects
			.map((feedObject): string => {
				const bottleNumber = getBottleNumberText(
					feedObject.bottleNumber
				);
				if (feedObject instanceof MilkBottleModel) {
					const milkText = getMilkTypeText(feedObject); // #123 Fresh EBM
					return `#${bottleNumber} ${milkText}`;
				} else if (feedObject instanceof MilkBankProductModel) {
					return `#${bottleNumber} ${capitalize(feedObject.productState)} ${feedObject.name}`; // #123 Frozen Donor Milk
				}
			})
			.reverse();
	}

	constructor(
		private readonly ngZone: NgZone,
		private base: BaseService
	) {}

	ngOnInit(): void {
		BarcodeScanner.isTorchAvailable().then((result) => {
			this.isTorchAvailable = result.available;
		});
	}

	// https://github.com/robingenz/capacitor-mlkit-plugin-demo/blob/199d8f616aa693b9c138d2abec6a3ad3903f2f04/src/app/modules/barcode-scanning/barcode-scanning-modal.component.ts
	// It's not clear why this settimeout is needed. It was included in the demo
	// from the package owner. Without it, the scanning does happen, but the scanner does
	// not close on successful scan. This causes a brief "white" screen.
	ngAfterViewInit(): void {
		setTimeout(() => {
			this.startScan();
		}, 300);
	}

	ngOnDestroy(): void {
		this.stopScan();
	}

	async closeModal(barcode?: Barcode): Promise<void> {
		document
			.querySelector("body")
			?.classList.remove("barcode-scanning-modal-active");

		if (barcode) {
			await this.base.modalService.dismissModalWithData({
				barcode: barcode,
			});
		} else {
			await this.base.modalService.dismissModal();
		}
	}

	async toggleTorch(): Promise<void> {
		await BarcodeScanner.toggleTorch();
		this.isTorchOn = !this.isTorchOn;
	}

	async startScan(): Promise<void> {
		// Hide everything behind the modal (see src/theme/modals.scss)
		document
			.querySelector("body")
			?.classList.add("barcode-scanning-modal-active");

		const options: StartScanOptions = {
			lensFacing: LensFacing.Back,
		};

		// Retrieves the bounding client rectangle of the square element or null if the element is not found.
		const squareElementBoundingClientRect =
			this.squareElement?.nativeElement.getBoundingClientRect();

		// Calculates the scaled rectangle based on the provided square element's bounding client rect.
		// The scaling is done using the window's device pixel ratio.
		// returns The scaled rectangle object or undefined if the square element's bounding client rect is not provided.
		const scaledRect = squareElementBoundingClientRect
			? {
					left:
						squareElementBoundingClientRect.left *
						window.devicePixelRatio,
					right:
						squareElementBoundingClientRect.right *
						window.devicePixelRatio,
					top:
						squareElementBoundingClientRect.top *
						window.devicePixelRatio,
					bottom:
						squareElementBoundingClientRect.bottom *
						window.devicePixelRatio,
					width:
						squareElementBoundingClientRect.width *
						window.devicePixelRatio,
					height:
						squareElementBoundingClientRect.height *
						window.devicePixelRatio,
				}
			: undefined;

		// Calculates the detection corner points based on the scaled rectangle.
		const detectionCornerPoints = scaledRect
			? [
					[scaledRect.left, scaledRect.top],
					[scaledRect.left + scaledRect.width, scaledRect.top],
					[
						scaledRect.left + scaledRect.width,
						scaledRect.top + scaledRect.height,
					],
					[scaledRect.left, scaledRect.top + scaledRect.height],
				]
			: undefined;

		//Listens for barcode scanned events and handles the event callback.
		const listener = await BarcodeScanner.addListener(
			"barcodeScanned",
			async (event) => {
				this.ngZone.run(async () => {
					const cornerPoints = event.barcode.cornerPoints;
					if (detectionCornerPoints && cornerPoints) {
						if (
							// this will only scan a barcode inside the square
							detectionCornerPoints[0][0] > cornerPoints[0][0] ||
							detectionCornerPoints[0][1] > cornerPoints[0][1] ||
							detectionCornerPoints[1][0] < cornerPoints[1][0] ||
							detectionCornerPoints[1][1] > cornerPoints[1][1] ||
							detectionCornerPoints[2][0] < cornerPoints[2][0] ||
							detectionCornerPoints[2][1] < cornerPoints[2][1] ||
							detectionCornerPoints[3][0] > cornerPoints[3][0] ||
							detectionCornerPoints[3][1] < cornerPoints[3][1]
						) {
							return;
						}
					}
					listener.remove();
					await this.closeModal(event.barcode);
				});
			}
		);
		await BarcodeScanner.startScan(options);
	}

	private async stopScan(): Promise<void> {
		// Show everything behind the modal again
		document
			.querySelector("body")
			?.classList.remove("barcode-scanning-modal-active");

		try {
			await BarcodeScanner.stopScan();
		} catch (error) {
			//
		}
	}
}

import { Component, OnInit, ViewChild } from "@angular/core";
import {
	IonSearchbar,
	LoadingController,
	ModalController,
} from "@ionic/angular";
import { displayDateOfBirth } from "src/app/app.util";
import { PatientService } from "src/app/services/patient.service";
import { PatientModel } from "src/app/models/patient.model";
import { IWristband } from "../../services/scanning.service";

@Component({
	selector: "app-select-patient",
	templateUrl: "./select-patient.modal.html",
	styleUrls: ["./select-patient.modal.scss"],
})
export class SelectPatientModal implements OnInit {
	@ViewChild("selectPatientModalSearchInput") searchInput: IonSearchbar;

	patientList: PatientModel[];
	filteredList: PatientModel[];
	isItemAvailable = false;
	searchValue: string;

	displayDateOfBirth = displayDateOfBirth;

	constructor(
		private patientService: PatientService,
		private modalCtrl: ModalController,
		private loadingCtrl: LoadingController
	) {}

	async ngOnInit() {
		const loading = await this.loadingCtrl.create({
			message: "Loading",
		});

		try {
			await loading.present();

			const patients = await this.patientService.getPatients();
			this.patientList = patients.filter(
				(p) => p.active && !p.discharged
			);
		} finally {
			await loading.dismiss();
			await this.searchInput.setFocus();
		}
	}

	initializeItems() {
		this.filteredList = this.patientList;
	}

	getItems(ev: any) {
		// Reset items back to all of the items
		this.initializeItems();

		// set val to the value of the searchbar
		const val = ev.target.value;

		// if the value is an empty string don't filter the items
		if (val && val.trim() !== "") {
			this.isItemAvailable = true;
			this.filteredList = this.filteredList.filter(
				(item) =>
					item.firstName.toLowerCase().indexOf(val.toLowerCase()) >
						-1 ||
					item.lastName.toLowerCase().indexOf(val.toLowerCase()) >
						-1 ||
					item.mrn.indexOf(val) > -1
			);
		} else {
			this.isItemAvailable = false;
		}
	}

	async selectPatient(mrn: string) {
		await this.modalCtrl.dismiss({
			type: "mrn",
			value: mrn,
		} as IWristband);
	}

	async cancel() {
		await this.modalCtrl.dismiss();
	}
}

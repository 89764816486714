<ng-container *ngIf="hasPermissionMessage">
	<p class="required-message">Manual Entry is required because you do not have permission to select a patient in this workflow.</p>
</ng-container>

<ng-template #isLoading> Loading... </ng-template>

<ng-container *ngIf="isLoaded; else isLoading">
	<div class="scanned-item-actions">
		<button
			(click)="manualEntryForm.action = 'feed all'; emit()"
			[ngClass]="{ 'selected': manualEntryForm.action === 'feed all' }"
		>
			Feed All
		</button>
		<button
			(click)="manualEntryForm.action = 'has remaining'; emit()"
			[ngClass]="{ 'selected': manualEntryForm.action === 'has remaining' }"
		>
			Has Remaining
		</button>
	</div>

	<ion-item lines="none" class="input-field" *ngIf="manualEntryForm.type === 'product'">
		<ion-label>
			<p>rtf type</p>
			<ion-input
				aria-label="rtf type"
				[(ngModel)]="manualEntryForm.productListItem.product.name"
				[disabled]="true"
			>
			</ion-input>
		</ion-label>
	</ion-item>

	<ion-item lines="none" class="input-field" *ngIf="manualEntryForm.type === 'milkbottle'">
		<ion-label>
			<p>Bottle Number</p>
			<ion-input
				[(ngModel)]="manualEntryForm.bottleNumber"
				(ionInput)="emit()"
				(ionFocus)="handleOnFocus()"
				(ionBlur)="handleOnBlur()"
				aria-label="bottle number"
				inputmode="numeric"
				placeholder="Enter bottle number"
				[disabled]="manualEntryForm.wasScanned"
			>
			</ion-input>
		</ion-label>
	</ion-item>

	<ion-item lines="none" class="input-field" *ngIf="manualEntryForm.type === 'milkbottle'">
		<ion-label>
			<p>mrn</p>
			<ion-input
				[(ngModel)]="manualEntryForm.mrn"
				(ionInput)="emit()"
				(ionFocus)="handleOnFocus()"
				(ionBlur)="handleOnBlur()"
				aria-label="mrn"
				placeholder="Enter mrn"
				[disabled]="manualEntryForm.wasScanned"
			></ion-input>
		</ion-label>
	</ion-item>

	<ion-item class="select-item" lines="none">
		<ion-label>
			<p class="select-item__title">reason</p>
			<ion-grid>
				<ion-row>
					<ion-col>
						<ion-select [interfaceOptions]="defaultSelectOptions"
						            [(ngModel)]="manualEntryForm.reason"
						            (ionChange)="emit()"
						            (ionFocus)="handleOnFocus()"
						            (ionBlur)="handleOnBlur()"
						            aria-label="reason"
						            fill="outline"
						            labelPlacement="stacked"
						            interface="popover"
						            placeholder="Select a reason">
							<ion-select-option *ngFor="let reason of reasons" [value]="reason">
								{{ reason }}
							</ion-select-option>
						</ion-select>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-label>
	</ion-item>

	<ion-item *ngIf="showOtherReason" lines="none" class="input-field">
		<ion-label>
			<p>other reason</p>
			<ion-input
				[(ngModel)]="manualEntryForm.otherReason"
				(ionInput)="emit()"
				(ionFocus)="handleOnFocus()"
				(ionBlur)="handleOnBlur()"
				aria-label="other reason"
				placeholder="Enter other reason"
			>
			</ion-input>
		</ion-label>
	</ion-item>

	<ion-item *ngIf="manualEntryForm.secondNurse" lines="none" class="input-field">
		<ion-label>
			<p>second nurse id</p>
			<span>{{manualEntryForm.secondNurse.id}}</span>
		</ion-label>
	</ion-item>
</ng-container>

<div *ngIf="milkBankProducts?.length > 0">
	<div class="section-header">
		Donor Milk Inventory
	</div>

	<ng-container *ngFor="let chip of chips">
		<ion-chip [disabled]="true" [ngClass]="chip.cssClass">
			<ion-label>{{ chip.value }} {{ chip.label }}</ion-label>
		</ion-chip>
	</ng-container>
</div>


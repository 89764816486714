import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { PatientModel } from "src/app/models/patient.model";
import { IPermissions } from "../../models/user-profile.model";

@Component({
	selector: "app-patient-info",
	templateUrl: "./patient-info.component.html",
	styleUrls: ["./patient-info.component.scss"],
})
export class PatientInfoComponent implements OnInit {
	@Input() isToolbar = false;
	@Input() isHeaderVisible = true;
	@Input() isPatientDisplayed = true;
	@Input() patients: PatientModel[] = [];
	@Input() title = "Patient Info";
	@Input() instructions = "Step 1: Scan a patient";
	@Input() isSelectPatientButtonDisabled: boolean;
	@Input() hasSelectPatientButton: boolean;
	@Input() hasMultiplePatients: boolean;
	@Output() selectPatientPressed = new EventEmitter();

	style: string;
	isDebug: boolean;
	isWeb: boolean;
	permissions: IPermissions;

	constructor() {}

	ngOnInit() {
		this.style = `patient-info2${this.isToolbar ? "--dark" : ""}`;
	}

	async presentPatientListModal2() {
		this.selectPatientPressed.emit();
	}

	trackByPatient(index, patient) {
		return (patient as PatientModel).mrn;
	}
}

<ng-container>
	<ion-item lines="none" class="input-field">
		<ion-label>
			<p>Milk Bottle Number</p>
			<div class="inline-button">
				<ion-input [(ngModel)]="bottleNumber" aria-label="Bottle Number" placeholder="Bottle Number">
				</ion-input>
				<ion-button [disabled]="disabled" slot="end" (click)="validateBottleNumber(bottleNumber)">
					Add
					<fa-icon [icon]="faPlus" [fixedWidth]="true" size="sm" class="is-dark-mode"></fa-icon>
				</ion-button>
			</div>
		</ion-label>
	</ion-item>
</ng-container>

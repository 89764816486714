<ion-grid *ngIf="feed">
	<ion-row>
		<!-- Status Color -->
		<ion-col [ngClass]="getFeedObjectColor(feed)" size="auto">
			<div></div>
		</ion-col>
		<div></div>

		<ion-col>
			<ion-chip size="small" color="success" *ngIf="displayNewChip(feed)">New</ion-chip>
			<ion-row>
				<ion-col class="milk-info__header__detail" size="auto">
					<p class="milk-info__header__detail__label">
						bottle #
					</p>
					<p class="milk-info__header__detail__value">
						<span><b>{{ getBottleNumberText(feed.bottleNumber) }}</b></span>
<!--						<span><b>lot number</b></span>-->
					</p>
				</ion-col>

				<ion-col size="auto">
					<div class="vertical-divider"></div>
				</ion-col>

				<ion-col class="milk-info__header__detail" size="auto">
					<p class="milk-info__header__detail__label">
						type
					</p>
					<p class="milk-info__header__detail__value">
						<span>{{ getFeedTypeText(feed) }}</span>
					</p>
				</ion-col>

				<ion-col class="milk-info__header__chip">
					<ion-chip color="{{ isExpired(feed.expirationDate) ? 'danger' : '' }}">
						<span *ngIf="!isDischarging">
							{{getRelativeExpirationTime(feed.expirationDate)}}
						</span>
						<span *ngIf="isDischarging">
							{{getRelativeReceived(feed.receivedDate)}}
						</span>
					</ion-chip>
				</ion-col>
			</ion-row>

			<ng-content></ng-content>

			<ng-container *ngIf="isContentVisible">
				<ion-row class="milk-info__secondary">
					<ion-col class="milk-info__secondary__contents">
						Contents:&nbsp;
						TODO
					</ion-col>
				</ion-row>
			</ng-container>

		</ion-col>
	</ion-row>
</ion-grid>


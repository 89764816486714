import { MilkLocation, MilkState, ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import { BottleNotReceivedError, ScanMilkBankProductValidationError } from "./scan-validation-error";
import { IScanValidation } from "./scan-validation.model";

export function CheckReceived(milkState?: MilkState) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				// if the milk bottle is at home then it hasn't been received
				if (obj.milkBottle && obj.milkBottle.location === MilkLocation.Home) {
					throw new BottleNotReceivedError(milkState);
				}
			}

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBottle && !obj.milkBottle.receivedDate) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Not_Received,
						modalMessage.product_not_received()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

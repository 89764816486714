import { MaskitoMask, MaskitoOptions } from "@maskito/core";
import dayjs from "dayjs";
import { DATE_FORMATS, ERROR_MESSAGE, PRECISION_MINUTE } from "./date-time-text.util";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)


export const isCompleteDate = (value: string, mask: MaskitoMask): boolean => {
	const isValid =
		value.length > 0 && value.length == (mask as Array<string>).length;

	if (!isValid) {
		throw new Error(ERROR_MESSAGE.INCOMPLETE_DATE);
	}

	return isValid;
};

export const isValidDate = (date: dayjs.Dayjs) => {
	if (!date.isValid()) {
		throw new Error(ERROR_MESSAGE.INVALID_DATE);
	}

	return true;
};

export const isBetweenMinMaxDate = (
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs,
	date: dayjs.Dayjs
): boolean => {

	if (!minDate || !maxDate) {
		return;
	}

	const isValid = date.isBetween(minDate, maxDate, PRECISION_MINUTE, "[]");

	if (!isValid) {
		throw new Error(ERROR_MESSAGE.INVALID_DATE);
	}

	return isValid;
};

export const isAfterMinDate = (
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs,
	date: dayjs.Dayjs
): boolean => {

	if (!minDate && maxDate) {
		return;
	}

	const isValid = date.isSameOrAfter(minDate, PRECISION_MINUTE);

	if (!isValid) {
		throw new Error(ERROR_MESSAGE.INVALID_DATE);
	}

	return isValid;
};

export const isBeforeMaxDate = (
	minDate: dayjs.Dayjs,
	maxDate: dayjs.Dayjs,
	date: dayjs.Dayjs
): boolean => {
	if (minDate && !maxDate) {
		return;
	}

	const isValid = date.isSameOrBefore(maxDate, PRECISION_MINUTE);

	if (!isValid) {
		throw new Error(ERROR_MESSAGE.INVALID_DATE);
	}

	return isValid;
};

export const transformCharToDate = (character: string) => {
	if (character.toLowerCase() === "t") {
		return dayjs().format(DATE_FORMATS.DATE);
	}

	return character;
};

export const datePreprocessor = ({ elementState, data }) => {
	let { value, selection } = elementState;

	// Check for 't' input and replace with the current date
	if (data.toLowerCase() === "t") {
		value = transformCharToDate(data);
		selection = [value.length, value.length];
	}

	return {
		elementState: {
			selection,
			value: value,
		},
		data,
	};
};

/**
 * Expiration Date:
 *   - Do not allow users to type in "T" to autocomplete to today's date
 *
 * Otherwise:
 *   - Users can type "T" to autocomplete to today's date
 */
export const setDateProcessors = () => ({
		mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
		preprocessors: [datePreprocessor],
	} as MaskitoOptions);


/**
 * Expiration Date:
 *   - Do not allow users to type in "T" to autocomplete to today's date
 *
 * Otherwise:
 *   - Users can type "T" to autocomplete to today's date
 */
export const setExpiredDateProcessors = () => ({
		mask: [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/],
	} as MaskitoOptions);

import { AbstractControl, Validators } from "@angular/forms";
import { FeedState, MilkState } from "../app.enums";

/**
 * Validates:
 *   - Fresh / Frozen: Pump date is required
 *   - Thawed: Thawed Date is required
 */
export function ValidateDatesByMilkState() {
	return (control: AbstractControl) => {
		if (!control || !control.parent) {
			return null;
		}
		const pumpDate = control.parent.get("pumpDate");
		const thawedDate = control.parent.get("thawedDate");
		const milkState = control.parent.get("milkState").value as FeedState;

		switch (milkState) {
			case MilkState.Fresh:
			case MilkState.Frozen:
				pumpDate.addValidators(Validators.required);
				thawedDate.removeValidators(Validators.required);
				break;
			case MilkState.Thawed:
				thawedDate.addValidators(Validators.required);
				pumpDate.removeValidators(Validators.required);
				break;
			default:
				console.log("Unsupported milk state.");
		}

		pumpDate.updateValueAndValidity();
		thawedDate.updateValueAndValidity();

		return null;
	};
}

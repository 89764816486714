<div class="BottleNumberInput">
	<form [formGroup]="unreceivedProductGroupForm" (ngSubmit)="onSubmit()">
		<ion-list>
			<ion-item lines="none" class="input-field">
				<ion-label>
					<p>Bottle Number</p>
					<ion-input aria-label="Bottle Number" formControlName="bottleNumber" type="number"
						(ionInput)="handleInput($event)" #bottleNumberInput>
					</ion-input>
				</ion-label>
				<ion-note *ngIf="invalidBottleNumber" slot="helper" color="danger">
					Bottle number must be unique
				</ion-note>
			</ion-item>
		</ion-list>

		<div class="BottleNumberInput-footer">
			<ion-button type="submit" [disabled]="!unreceivedProductGroupForm.valid">Submit</ion-button>
		</div>
	</form>
</div>

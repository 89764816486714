import { Injectable } from "@angular/core";
import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from "@angular/common/http";
import { NavController } from "@ionic/angular";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { AuthService } from "src/app/services/auth.service";
import { StorageService } from "src/app/services/storage.service";
import { IdleTimerService } from "./idle-timer.service";

@Injectable({
	providedIn: "root",
})
export class TokenInterceptorService implements HttpInterceptor {
	constructor(
		public authService: AuthService,
		private navCtrl: NavController,
		public idleTimerService: IdleTimerService
	) {}

	/*
		https://codinglatte.com/posts/angular/refreshing-authorization-tokens-angular-6/
		https://github.com/MainaWycliffe/refreshing-authorization-token-angular-6
		https://gist.github.com/Toilal/8849bd63d53bd2df2dd4df92d3b12f26
		https://medium.com/nuculabs/angular-http-interceptors-what-are-they-and-how-to-use-them-52e060321088
		https://github.com/IntertechInc/http-interceptor-refresh-token
	 */
	intercept(
		req: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		// Pass-through request because its login and doesn't require checking for a Bearer Token.
		if (req.url.includes("/auth/token") || req.url.includes("/pstprnt")) {
			return next.handle(req);
		}

		this.idleTimerService.initIdleTimer();

		// Clone and modify because requests are immutable. Also change header to authenticate on auth token.
		const request: HttpRequest<any> = req.clone({
			setHeaders: {
				Authorization: `Bearer ${StorageService.authToken}`, // Bearer for OAuth 2.0
			},
		});

		return next.handle(request).pipe(
			catchError((error) => this.handleError(error)) // end catchError
		);
	}

	/**
	 * The purpose of error handling in the interceptor is to check for a 401
	 * Unauthorized response.
	 *
	 * @param error
	 */
	handleError(error: any): Observable<never> {
		if (error instanceof HttpErrorResponse) {
			console.log(`TokenInterceptorService.intercept: ${error.message}`);

			// If we get a 401, the token is no longer valid (or was never valid) so logout.
			if (error && error.status === 401) {
				return this.logout(error);
			}
		}
		return throwError(() => error);
	}

	logout(error: Error): Observable<never> {
		console.log("TokenInterceptorService.logout()");
		this.authService.logout().then(() => {
			this.navCtrl.navigateRoot("/");
		});
		return throwError(() => error);
	}
}

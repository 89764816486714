import { MilkType } from "../app.enums";
import { MilkBottleModel } from "../models/milk.model";

export const FORTIFIED_ICON = [
    "^GFA,137,108,4,:Z64:eJw9jLEJgDAUBS8mqEj4p",
    "EyRzlYkZTpdxB0cwVEcRTcRXMIuphDvdcfj4GPaUf",
    "lA7w8NHikLGLwVnGlIonFWEfO9xXwSx754RbIV3tQ",
    "MnZR3QLaEnleqfMFSui0/L2QdDuw=:191F",
].join("");

export const isMilkBottleFortified = ( milkBottle: MilkBottleModel ): boolean =>
    milkBottle.milkType !== MilkType.EBM_EMB &&
    milkBottle.milkType !== MilkType.DM_DM &&
    milkBottle.isFortified

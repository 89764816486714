<ion-list *ngIf="milkBottle">
  <ion-item lines="none" class="milkBottle">
    <ion-label>
      <div class="milkBottle__label">Bottle #</div>
      {{ getBottleNumberText(milkBottle.bottleNumber) }}
    </ion-label>
  </ion-item>

  <app-feed-state-select title="milk state"
                         [feed]="milkBottle"
                         [presetState]="milkBottle.milkState"
                         [(state)]="milkBottle.feedState"
                         data-testid="milk_state_container"
                         [overrideState]="true">
  </app-feed-state-select>

  <app-inline-date
    *ngIf="hasEditablePumpDate"
    [label]="'Pump Date'"
    [defaultDate]="milkBottle.pumpDate"
    [maxDate]="maxDate"
    (inputFocus)="handleInputFocus($event)"
    [disabled]="milkBottle.isCombined"
    (dateTimeChange)="milkBottle.pumpDate = $event; emit()"
    data-testid="pump_date_container">
  </app-inline-date>

  <app-inline-date
    *ngIf="milkBottle.milkState === 'frozen'"
    [label]="'Frozen Date'"
    [defaultDate]="milkBottle.frozenDate"
    [maxDate]="maxDate"
    (inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBottle.frozenDate = $event; emit()">
  </app-inline-date>

  <app-inline-date
    *ngIf="milkBottle.milkState === 'thawed'"
    [label]="'Thaw Date'"
    [defaultDate]="milkBottle.thawedDate"
    [maxDate]="maxDate"
    (inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBottle.thawedDate = $event; emit()">
  </app-inline-date>

  <app-inline-date
    *ngIf="milkBottle.milkState === 'opened'"
    [label]="'Opened Date'"
    [defaultDate]="milkBottle.openedDate"
    [maxDate]="maxDate"
    (inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBottle.openedDate = $event; emit()">
  </app-inline-date>
  
  <app-inline-date
    [label]="'Expended Date'"
    [defaultDate]="milkBottle.expendedDate"
    [maxDate]="maxDate"
    (inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBottle.expendedDate = $event">
  </app-inline-date>

  <!-- Volume -->
  <ion-item *ngIf="hasEditableVolume" class="input-field" lines="none">
    <ion-label>
      <p>Volume (mL)</p>
      <ion-input inputmode="numeric"
                 minlength="0"
                 placeholder="Enter volume"
                 [(ngModel)]="milkBottle.volume"
				 aria-label="Volume">
      </ion-input>
    </ion-label>
  </ion-item>
</ion-list>

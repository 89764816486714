import { Injectable } from "@angular/core";
import { ToastController, ToastOptions } from "@ionic/angular";

@Injectable({
	providedIn: "root",
})
export class ToastService {
	private _toast: HTMLIonToastElement;

	constructor(private toastCtrl: ToastController) {}

	/**
	 * Dismiss previously presented toasts on page navigation.
	 * dismissOnPageChange will be unset for newly created toasts, and true for presented toasts.
	 */
	cleanToast() {
		if (this._toast) {
			if (this._toast.getAttribute("dismissOnPageChange") === "true") {
				this._toast.dismiss();
				this._toast = null;
				console.log("BaseService: Clear _toast");
			}
		}
	}

	async presentToast(
		msg: string,
		duration = 3000,
		buttons: ToastOptions["buttons"] = [],
		dismissOnPageChange = true
	) {
		this._toast = await this.toastCtrl.create({
			header: null,
			message: msg,
			animated: true,
			color: "primary",
			duration,
			position: "bottom",
			buttons,
		});

		// We want to dismiss toasts on page navigation, but *not* for newly created toasts.
		// Delay the toast dismissal by setting the dismissOnPageChange property after presenting the toast.
		// dismissOnPageChange is checked on page navigation in the clean function.
		if (dismissOnPageChange) {
			this._toast.addEventListener("ionToastDidPresent", () => {
				this._toast.setAttribute("dismissOnPageChange", "true");
			});
		}
		await this._toast.present();
	}

	async presentSuccessToast(
		msg: string,
		duration = 3000,
		buttons: ToastOptions["buttons"] = [],
		dismissOnPageChange = true
	) {
		this._toast = await this.toastCtrl.create({
			header: null,
			message: msg,
			animated: true,
			color: "success",
			cssClass: "full-width",
			duration,
			position: "top",
			buttons,
		});

		// We want to dismiss toasts on page navigation, but *not* for newly created toasts.
		// Delay the toast dismissal by setting the dismissOnPageChange property after presenting the toast.
		// dismissOnPageChange is checked on page navigation in the clean function.
		if (dismissOnPageChange) {
			this._toast.addEventListener("ionToastDidPresent", () => {
				this._toast.setAttribute("dismissOnPageChange", "true");
			});
		}
		await this._toast.present();
	}

	async presentFailToast(msg: string) {
		this._toast = await this.toastCtrl.create({
			header: null,
			message: msg,
			animated: true,
			color: "danger",
			cssClass: "full-width",
			duration: 3000,
			position: "top",
			buttons: [],
		});
		await this._toast.present();
	}

	async presentWarningToast(msg: string, duration = 3000) {
		this._toast = await this.toastCtrl.create({
			header: null,
			message: msg,
			animated: true,
			color: "warning",
			cssClass: "full-width",
			duration,
			position: "top",
			buttons: [],
		});
		await this._toast.present();
	}
}

import { FeedLabelStatus } from "../../app.enums";
import { FeedObjectModel } from "../../models/feed-object.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { CustomPrintLabelInput } from "./print-label-with-retry.component";

const getCreatedMilkBankProducts = (milkBankProducts: MilkBankProductModel[]) =>
	milkBankProducts.filter(
		(milkBankProduct) => milkBankProduct.labelStatus === FeedLabelStatus.New
	);

const getCreatedMilkBottles = (milkBottles: MilkBottleModel[]) =>
	milkBottles.filter(
		(milkBottle) => milkBottle.labelStatus === FeedLabelStatus.New
	);

/**
 * Milk Bottles that have only been updated can be skipped.
 */
export const isSkippable = (feedObjectModel: FeedObjectModel[]) =>
	feedObjectModel.every((x) => x.labelStatus === FeedLabelStatus.Updated);

/**
 * Only New Labels, hide button (Print All will be the only option)
 * Mix of New and updated, it says “Print new”
 */
export const isPrintable = (feedObjectModel: FeedObjectModel[]): boolean => {
	const labels = feedObjectModel
		.map((x) => x.labelStatus)
		.filter((id, index, arr) => arr.indexOf(id) === index); // dedupe

	return (
		labels.includes(FeedLabelStatus.New) &&
		labels.includes(FeedLabelStatus.Updated)
	);
};

export const getPrintDataByMode = (params: {
	mode: string;
	milkBottles: MilkBottleModel[];
	milkBankProducts: MilkBankProductModel[];
	customLabels: CustomPrintLabelInput[];
}): {
	milkBankProducts: MilkBankProductModel[];
	milkBottles: MilkBottleModel[];
	customLabels: CustomPrintLabelInput[];
} => {
	switch (params.mode) {
		case "all":
			return {
				milkBankProducts: params.milkBankProducts,
				milkBottles: params.milkBottles,
				customLabels: params.customLabels,
			};
		case "created":
			return {
				milkBankProducts: getCreatedMilkBankProducts(
					params.milkBankProducts
				),
				milkBottles: getCreatedMilkBottles(params.milkBottles),
				customLabels: params.customLabels,
			};
		default:
			console.error(`Print mode ${params.mode} was unrecognized.`);
			return undefined;
	}
};

/**
 * Some products (HMF Concentrated Liquid) will not have a bottle number.
 * In that case, we still want to show the user that printing was successful.
 */
export const getPrintedToastMessage = (params: {
	milkBottles: MilkBottleModel[];
	milkBankProducts: MilkBankProductModel[];
}): string => {
	const { milkBottles, milkBankProducts } = params;

	const bottleNumbers = [
		...milkBottles.map((b) => b.bottleNumber),
		...milkBankProducts.map((p) => p.bottleNumber || 0),
	];

	const bottleNumberText = bottleNumbers
		.map((x) => getBottleNumberText(x))
		.join(", ");

	return `Printed bottle #: ${bottleNumberText}`;
};

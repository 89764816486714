import { FeedState, MilkType, ProductType, ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import {
	ScanMilkBankProductValidationError,
	ScanMilkBottleValidationError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";
import { getFeedTypeText } from "../../utils/milk-label.util";
import { FeedObject } from "../../models/feed-object.model";
import { getFeedTypeText2 } from "../../utils/feed-info.util";

export function RejectType2(type: MilkType | ProductType) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;
			// destructure the object
			const { scannedObject, feedObject } = obj;

			// determine feed object type and check for duplicates
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					if (type === feedObject.milkType) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Milk_Bottle_Incompatible_Type,
							modalMessage.type_mismatch(
								getFeedTypeText(feedObject)
							)
						);
					}
				} else if (feedObject instanceof MilkBankProductModel) {
					if (type === feedObject.productType) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Milk_Bottle_Incompatible_Type,
							modalMessage.type_mismatch(
								getFeedTypeText(feedObject)
							)
						);
					}
				} else {
					console.error(
						`RejectType2: feedObject instance type is not supported.`
					);
				}
			} else if (scannedObject) {
				if (type === scannedObject.product.type) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Milk_Bottle_Incompatible_Type,
						modalMessage.type_mismatch(
							getFeedTypeText2(
								scannedObject.product as FeedObject
							)
						)
					);
				}
			} else {
				console.error(`RejectType2: feedObject is undefined`);
			}

			return originalMethod.apply(this, args);
		};
	};
}

/**
 * Check if the state of the product is invalid.
 */
export function RejectType(type: MilkType | ProductType, state?: FeedState) {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (
					state === obj.milkBottle.milkState &&
					type === obj.milkBottle.milkType
				) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Incompatible_Type,
						modalMessage.type_mismatch(
							getFeedTypeText(obj.milkBottle)
						)
					);
				}
			}

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (
					state === obj.milkBankProduct.productState &&
					type === obj.milkBankProduct.productType
				) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Milk_Bottle_Incompatible_Type,
						modalMessage.type_mismatch(
							getFeedTypeText(obj.milkBankProduct)
						)
					);
				}

				if (type === obj.milkBankProduct.productType) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Milk_Bottle_Incompatible_Type,
						modalMessage.product_additive_not_assignable(
							getFeedTypeText(obj.milkBankProduct)
						)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { Injectable } from "@angular/core";

import { ProductFeedPatientListItem } from "../administer-feed/feed-patient/list-item";
import { PatientModel, PatientEhrOrderModel } from "../models/patient.model";
import dayjs, { Dayjs } from "dayjs";
import {
	ContentType,
	MilkBottleUseType,
	MilkLocation,
	MilkState,
	ProductState,
	assertExhaustive,
} from "../app.enums";
import {
	createMilkBottlePayload,
	getCreatedMilkBottleState,
} from "../utils/feeding.util";
import { getCombinedMilkStateFromStates } from "../utils/milk-label.util";
import {
	CreateMilkBottle2Payload,
	InventoryService,
} from "./inventory.service";
import {
	MilkBankProductModel,
	IMilkBankProduct,
} from "../models/milk-bank-product.model";
import { IContent } from "../models/milk.model";
import { EditScannedProductModal } from "../modals/edit-scanned-product/edit-scanned-product.modal";
import { Product } from "../models/product.model";
import { ModalController } from "@ionic/angular";
import { PrintPrompts } from "../app-print-label-messages";
import {
	FeedPatientCommandResult,
	MarkMilkBottleAsAdministeredParams,
} from "./feed.service";
import { getEarliestDayjs } from "../utils/expiration.util";

@Injectable({
	providedIn: "root",
})
export class FeedProductService {
	constructor(
		public inventoryService: InventoryService,
		public modalCtrl: ModalController
	) {}

	async processProducts(
		products: ProductFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		if (products.length === 1) {
			return await this.processSingleProduct(
				products[0],
				patient,
				selectedOrder
			);
		} else {
			return await this.processMultipleProducts(
				products,
				patient,
				selectedOrder
			);
		}
	}

	private async processMultipleProducts(
		productListItems: ProductFeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		// receive the products and mark them as expended
		const milkBankProducts = await this.receiveProducts(productListItems, {
			expendedDate: dayjs(),
		});

		// unsure how to calculate the createdMilkBottle.milkState here, winging it TODO
		const milkStates = productListItems.map((listItem) =>
			getCreatedMilkBottleState(
				listItem.action,
				listItem.product.defaultState
			)
		);

		// create a bottle with the products as contents and mark it as fed
		const createdMilkBottle = await this.inventoryService.createMilkBottle2(
			this.createMilkBottlePayloadForRTFs({
				patient,
				milkBankProducts,
				milkState: getCombinedMilkStateFromStates(milkStates),
			})
		);

		const allFeedAllAction = productListItems.every(
			(m) => m.action === "feed all"
		);

		if (allFeedAllAction) {
			await this.markMilkBottleAsAdministered({
				milkBottle: createdMilkBottle,
				patient,
				selectedOrder,
				isOverride: false,
				reason: MilkBottleUseType.Feeding,
			});

			return {
				milkBottleLabels: [],
				optionalMilkBottleLabels: {
					prompt: PrintPrompts.created,
					milkBottle: createdMilkBottle,
				},
			};
		} else {
			// ML-674 single RTF / Has Remaining
			// Create another bottle and mark that one as fed
			const fedBottle = await this.inventoryService.createMilkBottle2(
				createMilkBottlePayload(patient, [createdMilkBottle])
			);
			const administeredBottle = await this.markMilkBottleAsAdministered({
				milkBottle: fedBottle,
				patient,
				selectedOrder,
				isOverride: false,
				reason: MilkBottleUseType.Feeding,
			});

			// If the RTF has a default state of Stable, then createdMilkBottle will have state Opened.
			// Print createdMilkBottle if the bottle was created in the Opened state. Only print for this case.

			const remainingMilk = [
				createdMilkBottle.milkState === MilkState.Opened
					? createdMilkBottle
					: null,
			].filter(Boolean);

			// Offer to create a label irrespective of if the product is emptied or has remaining.
			// https://angeleyehealth.atlassian.net/browse/ML-1640

			return {
				milkBottleLabels: remainingMilk,
				optionalMilkBottleLabels: {
					prompt: PrintPrompts.created,
					milkBottle: administeredBottle,
				},
			};
		}
	}

	/**
	 * TODO: What does this do?
	 *
	 * AFAIK, this theoretically receives unreceived products like RTFs and then marks them as expended.
	 *
	 * @param productListItem
	 * @param patient
	 * @param selectedOrder
	 * @private
	 */
	private async processSingleProduct(
		productListItem: ProductFeedPatientListItem,
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		// receive the product and mark it as expended
		const [milkBankProduct]: MilkBankProductModel[] = await this.receiveProducts(
			[productListItem],
			{
				expendedDate: dayjs(),
			}
		);
		const action = productListItem.action;
		switch (action) {
			case "feed all": {
				// create a bottle with the product as contents and mark it as fed
				const createdMilkBottle =
					await this.inventoryService.createMilkBottle2(
						this.createMilkBottlePayloadForRTFs({
							patient,
							milkBankProducts: [milkBankProduct],
							milkState: getCreatedMilkBottleState(
								productListItem.action,
								productListItem.product.defaultState
							),
						})
					);
				await this.markMilkBottleAsAdministered({
					milkBottle: createdMilkBottle,
					patient,
					selectedOrder,
					isOverride: productListItem.isOverride,
					overrideVerifiedBy: productListItem.secondNurse?.email,
					overrideReason: productListItem.resultMessage,
					reason: MilkBottleUseType.Feeding,
				});

				// Offer to create a label irrespective of if the product is emptied or has remaining.
				// https://angeleyehealth.atlassian.net/browse/ML-1640
				return {
					milkBottleLabels: [],
					optionalMilkBottleLabels: {
						prompt: PrintPrompts.created,
						milkBottle: createdMilkBottle,
					},
				};
			}
			case "has remaining":
				return await this.processSingleProductHasRemaining({
					milkBankProduct,
					patient,
					productListItem,
					selectedOrder,
				});
			case "oral-care":
				throw new Error("Not implemented.");
			default:
				assertExhaustive(action);
		}
	}

	/**
	 *
	 * @param params
	 * @private
	 */
	private async processSingleProductHasRemaining(params: {
		milkBankProduct: MilkBankProductModel;
		patient: PatientModel;
		productListItem: ProductFeedPatientListItem;
		selectedOrder: PatientEhrOrderModel;
	}): Promise<FeedPatientCommandResult> {
		const { milkBankProduct, patient, productListItem } = params;
		const createdMilkBottle = await this.inventoryService.createMilkBottle2(
			this.createMilkBottlePayloadForRTFs({
				patient,
				milkBankProducts: [milkBankProduct],
				milkState: getCreatedMilkBottleState(
					productListItem.action,
					productListItem.product.defaultState
				),
			})
		);
		// ML-674 single RTF / Has Remaining
		// Create another bottle and mark that one as fed
		const fedBottle = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, [createdMilkBottle])
		);

		const administeredBottle = await this.markMilkBottleAsAdministered({
			milkBottle: fedBottle,
			patient: params.patient,
			selectedOrder: params.selectedOrder,
			isOverride: productListItem.isOverride,
			overrideVerifiedBy: productListItem.secondNurse?.email,
			overrideReason: productListItem.resultMessage,
			reason: MilkBottleUseType.Feeding,
		});

		// If the RTF has a default state of Stable, then createdMilkBottle will have state Opened.

		// https://angeleyehealth.atlassian.net/browse/ML-1159
		// Print a label if the createdMilkBottle.milkState is Opened or Thawed
		const remainingMilk = [
			[MilkState.Opened, MilkState.Thawed].includes(
				createdMilkBottle.milkState
			)
				? createdMilkBottle
				: null,
		].filter(Boolean);

		// Offer to create a label irrespective of if the product is emptied or has remaining.
		// https://angeleyehealth.atlassian.net/browse/ML-1640

		return {
			milkBottleLabels: remainingMilk,
			optionalMilkBottleLabels: {
				prompt: PrintPrompts.created,
				milkBottle: administeredBottle,
			},
		};
	}

	private async receiveProducts(
		productListItems: ProductFeedPatientListItem[],
		props?: {
			expendedDate: MilkBankProductModel["expendedDate"];
		}
	) {
		const milkBankProducts: MilkBankProductModel[] = [];

		for (const productListItem of productListItems) {
			const milkBankProduct = new MilkBankProductModel({
				barcode: productListItem.barcodeText,
				name: productListItem.product.name,
				defective: false,
				bottleNumber: productListItem.bottleNumber,
				defectiveReason: null,
				productId: productListItem.product.id,
				manufacturerExpirationDate:
					productListItem.manufacturerExpirationDateString,
				lotNumber: productListItem.lotNumber,
				productManufacturer: productListItem.product.manufacturerName,
				manufacturerCreatedDate: null,
				expendedDate: props?.expendedDate?.toISOString() ?? null,
				thawedDate: productListItem.thawedDate?.toISOString() ?? null,
				openedDate: productListItem.openedDate?.toISOString() ?? null,
				productState: productListItem.productState,
			} as IMilkBankProduct);

			const received = await this.inventoryService.createMilkBankProduct(
				milkBankProduct
			);

			// retain the action
			received.action = productListItem.action;

			milkBankProducts.push(received);
		}
		return milkBankProducts;
	}

	private createMilkBottlePayloadForRTFs(params: {
		patient: PatientModel;
		milkBankProducts: MilkBankProductModel[];
		milkState: MilkState;
	}): CreateMilkBottle2Payload {
		return {
			mothersId: params.patient.motherId,
			milkState: params.milkState,
			location: MilkLocation.Hospital,
			volume: 0,
			calorie: params.milkBankProducts[0].calorieDensity,
			expirationDate: null,
			receivedDate: getEarliestDayjs(
				params.milkBankProducts,
				(m) => m.receivedDate
			)?.toISOString(),
			dischargeDate: null,
			frozenDate: null,
			pumpDate: null,
			thawedDate: null,
			openedDate: null,
			contents: params.milkBankProducts.map<IContent>(
				(milkBankProduct) =>
					({
						milkBankProductId: milkBankProduct.id,
						productId: milkBankProduct.productId,
						contentType: ContentType.Product,
					}) as IContent
			),
			sourceMilkbottleIds: [],
			patientIds: [params.patient.id],
		};
	}

	/**
	 * TODO: ML-1361
	 *
	 * @deprecated Once the product has been received, use EditMilkModal instead.
	 */
	async changeProductState(product: Product): Promise<{
		productState: ProductState;
		thawedDate?: dayjs.Dayjs;
		openedDate?: dayjs.Dayjs;
	}> {
		switch (product.defaultState) {
			case ProductState.Frozen:
				return {
					productState: ProductState.Thawed,
					thawedDate: await this.presentThawDateModal(product),
				};
			case ProductState.Stable:
				return {
					productState: ProductState.Opened,
					openedDate: dayjs(),
				};
			default:
				throw new Error("Not implemented.");
		}
	}

	/**
	 * TODO: ML-1361
	 *
	 * @deprecated Once the product has been received, use EditMilkModal instead.
	 */
	async presentThawDateModal(product: Product): Promise<Dayjs> {
		const thawDateModal = await this.modalCtrl.create({
			component: EditScannedProductModal,
			componentProps: {
				milkBankProduct: product,
				title: "Edit Milk Data",
				header: "Thaw Product",
				message:
					"Please enter the date and time this product was thawed to continue.",
				doneText: "Update",
			},
			cssClass: "fullscreen-modal",
		});

		thawDateModal.present();

		const { data } = await thawDateModal.onWillDismiss();

		return data.thawedDate;
	}

	// TODO: Also shared with milk bottles.
	private async markMilkBottleAsAdministered(
		params: MarkMilkBottleAsAdministeredParams
	) {
		return this.inventoryService.administerMilkBottle({
			milkBottleId: params.milkBottle.id,
			patientId: params.patient.id,
			reason: params.reason,
			orderId: params.selectedOrder?.id ?? null,
			isOverride: params.isOverride,
			overrideReason: params.overrideReason,
			overrideVerifiedBy: params.overrideVerifiedBy,
		});
	}
}

/* eslint-disable @typescript-eslint/adjacent-overload-signatures */
/* eslint-disable @typescript-eslint/member-ordering */
import {
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges,
} from "@angular/core";
import { PatientEhrOrderModel, PatientModel } from "../../models/patient.model";
import * as _ from "lodash";
import * as dayjs from "dayjs";
import { defaultSelectOptions } from "src/app/components/default-options";
import { RecipeBase } from "src/app/models/recipe.model";
import { PatientService } from "src/app/services/patient.service";
import { FeedPatientListItem } from "src/app/administer-feed/feed-patient/list-item";
import { assertExhaustive } from "src/app/app.enums";
import { IAdditive } from "../../models/additive.model";
import { capitalize } from "../../app.util";
import { Product } from "src/app/models/product.model";
import { MilkBottleModel } from "src/app/models/milk.model";

interface OrderMatchItems {
	startDate: string;
	standingStatus: string;
	details: string[];
}

@Component({
	selector: "app-order-match",
	templateUrl: "./order-match.component.html",
	styleUrls: ["./order-match.component.scss"],
})
export class OrderMatchComponent implements OnChanges {
	_patient: PatientModel;
	_ehrOrders: PatientEhrOrderModel[];

	@Input() allowOrderValidation = false;
	@Output() selectedOrderChanged = new EventEmitter<PatientEhrOrderModel>();
	@Output() validationResults2Change = new EventEmitter<
		IOrderValidationResult2[]
	>();

	_selectedOrder: PatientEhrOrderModel;
	_validationResults: IOrderValidationResult[];
	_validationResults2: IOrderValidationResult2[];

	defaultSelectOptions = defaultSelectOptions;
	selectedItem = null;
	selectedOrderInstructions: string;

	constructor(public patientService: PatientService) {}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		// handling async with getters and setters is weird, so I'm using ngOnChanges instead to detect a change to
		// the selected patient
		if (changes.patient) {
			await this.handlePatientChanged(changes.patient.currentValue);
		}
	}

	get isValidOrderMatch() {
		return this.validationResults2?.every((v) => v.isValid);
	}

	get anyOrderFulfillmentIsValid() {
		return this.validationResults2.some((v) => v.anyOrderFulfillmentValid);
	}

	get caloricDensityDoesNotMatchTheOrder() {
		return !this.validationResults2?.every((v) => v.caloriesMatch);
	}

	get selectedOrder() {
		return this._selectedOrder;
	}

	set selectedOrder(value: PatientEhrOrderModel) {
		this._selectedOrder = value;
		this._validationResults = null;
		this.selectedOrderChanged.emit(this._selectedOrder);
	}

	get validationResults() {
		return this._validationResults;
	}

	get patient() {
		return this._patient;
	}

	@Input()
	set patient(value: PatientModel) {
		this._patient = value;
	}

	get ehrOrders() {
		return this._ehrOrders;
	}

	set ehrOrders(value: PatientEhrOrderModel[]) {
		this._ehrOrders = value;
	}

	get ehrOrdersItems(): OrderMatchItems[] {
		return this._ehrOrders.map((x) => ({
			startDate: dayjs(x.startDate).isValid()
				? dayjs(x.startDate).format("MM/DD/YY")
				: "None",
			standingStatus: this.getStandingStatus(x),
			details: [
				this.formatContents(x),
				this.formatFeedingDuration(x),
				x.interval,
			],
		}));
	}

	async handlePatientChanged(patient: PatientModel) {
		this.ehrOrders = [];

		if (patient) {
			this.ehrOrders = await this.patientService.getPatientEhrOrders(
				patient.id
			);
			this.setDefaultOrder();
		}
	}

	get validationResults2() {
		return this._validationResults2;
	}

	set validationResults2(value: IOrderValidationResult2[]) {
		this._validationResults2 = value;
		this.validationResults2Change.emit(this.validationResults2);
	}

	/** preselect the first (latest) order, sorted by the backend
	 */
	setDefaultOrder() {
		if (this.ehrOrders?.length > 0) {
			this.selectedItem = 0;
			this.selectedOrder = this.ehrOrders[0];
			this.selectedOrderInstructions =
				this.ehrOrders[0].instructions || "None";
			this.selectedOrderChanged.emit(this.selectedOrder);
		}
	}

	selectOrder(index: number) {
		this.selectedItem = index;
		this.selectedOrder = this.ehrOrders[index];
		this.selectedOrderInstructions =
			this.ehrOrders[index].instructions || "None";
		this.selectedOrderChanged.emit(this.selectedOrder);
	}

	validateOrderMatchesFeedPatientListItems(
		feedPatientListItems: FeedPatientListItem[]
	) {
		this.validationResults2 =
			this.validateSelectedOrderMatchesFeedPatientListItems(
				this.selectedOrder,
				feedPatientListItems
			);
	}

	validateOrderMatchesRecipeCalculation(params: {
		calorieDensity: number;
		additives: IAdditive[];
		base: RecipeBase;
	}) {
		this.validationResults2 = this._validateOrderMatchesRecipeCalculation(
			this.selectedOrder,
			params.calorieDensity,
			params.additives,
			params.base
		);
	}

	validateSelectedOrderMatchesManualPrep(params: {
		calorieDensity: number;
		products: { productId: Product["id"]; productName: Product["name"] }[];
		recipeBase: RecipeBase;
	}) {
		if (!params.calorieDensity || params.products.length === 0) {
			console.warn(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				"validateSelectedOrderMatchesManualPrep: params are null"
			);
			return;
		}

		const selectedOrder = this.selectedOrder;

		// include the recipe base in products so that's it's included in the validation
		const products = params.products
			.filter((a) => a.productId)
			.map((a) => ({
				productId: a.productId,
				productName: a.productName,
			}))
			.concat({
				productId: params.recipeBase.baseProductId,
				productName: params.recipeBase.baseName,
			});

		const resultsByOrderFulfillment =
			this.validateSelectedOrderMatchesProducts(
				this.selectedOrder,
				products
			);
		const anyOrderFulfillmentValid = resultsByOrderFulfillment.some(
			(orderFulfillment) => orderFulfillment.isValid
		);
		const caloriesMatch = selectedOrder.calorie === params.calorieDensity;

		this.validationResults2 = [
			{
				milkBottleId: null,
				isValid: anyOrderFulfillmentValid && caloriesMatch,
				anyOrderFulfillmentValid,
				caloriesMatch,
				validationResultsByOrderFulfillment: resultsByOrderFulfillment,
			},
		];
	}

	validateSelectedOrderMatchesFeedPatientListItems(
		selectedPatientEhrOrder: PatientEhrOrderModel,
		feedPatientListItems: FeedPatientListItem[]
	): IOrderValidationResult2[] {
		/*
			If validation happens when the patient doesn't have any active orders,
			should return all warnings.
		 */
		if (!selectedPatientEhrOrder) {
			return feedPatientListItems.map((item) => ({
				milkBottleId:
					item.type === "milkbottle" ? item.milkBottle.id : null,
				key: item.key,
				isValid: false,
				anyOrderFulfillmentValid: false,
				caloriesMatch: false,
				validationResultsByOrderFulfillment: [],
			}));
		}

		const resultsByFeedPatientListItem: IOrderValidationResult2[] =
			feedPatientListItems.map((item) => {
				// pull products and calories out of the FeedPatientListItem
				let products: Array<{
					productId: Product["id"];
					productName: Product["name"];
				}>;
				let calorie: number;

				switch (item.type) {
					case "milkbottle":
						products = item.milkBottle.contents.map((c) => ({
							productId: c.contentProductId,
							productName: c.contentProductName,
						}));
						calorie = item.milkBottle.calorieDensity;
						break;
					case "product":
						products = [
							{
								productId: item.product.id,
								productName: item.product.name,
							},
						];
						calorie = item.product.calorieDensity;
						break;
					default:
						assertExhaustive(item);
				}

				const resultsByOrderFulfillment =
					this.validateSelectedOrderMatchesProducts(
						selectedPatientEhrOrder,
						products
					);
				const anyOrderFulfillmentValid = resultsByOrderFulfillment.some(
					(orderFulfillment) => orderFulfillment.isValid
				);
				const caloriesMatch =
					calorie === selectedPatientEhrOrder.calorie;

				return {
					milkBottleId:
						item.type === "milkbottle" ? item.milkBottle.id : null,
					key: item.key,
					isValid: anyOrderFulfillmentValid && caloriesMatch,
					anyOrderFulfillmentValid,
					caloriesMatch,
					validationResultsByOrderFulfillment:
						resultsByOrderFulfillment,
				};
			});

		return resultsByFeedPatientListItem;
	}

	/**
	 * Generic validation routine. Validates against products: Array<{productId: string; productName: string}>
	 */
	private validateSelectedOrderMatchesProducts(
		selectedEhrOrder: PatientEhrOrderModel,
		products: Array<{
			productId?: Product["id"];
			productName: Product["name"];
		}>
	) {
		const resultsByOrderFulfillment = selectedEhrOrder.fulfillments.map(
			(ehrOrderFulfillment) => {
				const contents = ehrOrderFulfillment.contents.filter(
					(o) => o.productId !== null
				);
				const bases = contents.filter((o) => o.isBase);
				const fortifiers = contents
					.filter((o) => o.isBase === false)
					.map((c) => ({
						productId: c.productId,
						productName: c.productName,
					}));

				// Check if all bottle contents (by productId) occur at least once in the selected order.
				// "For each bottle contents (all types): check if it is in the order’s contents"
				const milkBottleContentItemsNotInOrderFulfillment = _.chain(
					products
				)
					.filter(
						(milkBottleContentItem) =>
							milkBottleContentItem.productId != null
					)
					.filter(
						(milkBottleContentItem) =>
							false ===
							contents.some(
								(orderContentItem) =>
									milkBottleContentItem.productId ===
									orderContentItem.productId
							)
					)
					.uniqBy((contentItem) => contentItem.productId)
					.map((contentItem) => ({
						productId: contentItem.productId,
						productName: contentItem.productName,
					}))
					.value();

				// From the order, is there at least one of the bases in the bottle? (use new property isBase = true to check this)
				const milkBottleContainsAnyOrderFulfillmentBase = products.some(
					(contentItem) =>
						bases.some(
							(orderBase) =>
								orderBase.productId === contentItem.productId
						)
				);
				const orderFulfillmentBaseIsPresentInMilkBottle =
					bases.length === 0 ||
					milkBottleContainsAnyOrderFulfillmentBase;

				// are all of the order's non-base products (isBase = false) in the bottle?
				const orderFulfillmentNonBaseProductsNotInBottle =
					fortifiers.filter(
						(orderProduct) =>
							false ===
							products.some(
								(milkBottleProduct) =>
									milkBottleProduct.productId ===
									orderProduct.productId
							)
					);

				return {
					ehrOrderFulfillmentId: ehrOrderFulfillment.id,
					milkBottleContentItemsNotInOrderFulfillment,
					baseOrderedIsPresent:
						orderFulfillmentBaseIsPresentInMilkBottle,
					orderFulfillmentNonBaseProductsNotInBottle,
					isValid:
						milkBottleContentItemsNotInOrderFulfillment.length ===
							0 &&
						orderFulfillmentBaseIsPresentInMilkBottle &&
						orderFulfillmentNonBaseProductsNotInBottle.length === 0,
				};
			}
		);

		return resultsByOrderFulfillment;
	}

	private _validateOrderMatchesRecipeCalculation(
		selectedOrder: PatientEhrOrderModel,
		calorieDensity: number,
		additives: IAdditive[],
		recipeBase: RecipeBase
	): IOrderValidationResult2[] {
		if (!selectedOrder || !calorieDensity || !additives.length) {
			console.warn("validateRecipe: params are null");
			console.table({
				selectedOrder,
				calorieDensity,
				additives,
			});
			return;
		}

		// include the recipe base in products so that's it's included in the validation
		const products = additives
			.filter((a) => a.productId)
			.map((a) => ({
				productId: a.productId,
				productName: a.productName,
			}))
			.concat({
				productId: recipeBase.baseProductId,
				productName: recipeBase.baseName,
			});

		const resultsByOrderFulfillment =
			this.validateSelectedOrderMatchesProducts(
				this.selectedOrder,
				products
			);
		const anyOrderFulfillmentValid = resultsByOrderFulfillment.some(
			(orderFulfillment) => orderFulfillment.isValid
		);
		const caloriesMatch = selectedOrder.calorie === calorieDensity;

		return [
			{
				milkBottleId: null,
				isValid: anyOrderFulfillmentValid && caloriesMatch,
				anyOrderFulfillmentValid,
				caloriesMatch,
				validationResultsByOrderFulfillment: resultsByOrderFulfillment,
			},
		];
	}

	formatContents(ehrOrder: PatientEhrOrderModel): string {
		if (ehrOrder.contents) {
			return `${ehrOrder.base} ${ehrOrder.calorie}kcal/oz: ${ehrOrder.contents}`;
		}

		return `${ehrOrder.base} ${ehrOrder.calorie}kcal/oz`;
	}

	formatFeedingDuration(ehrOrder: PatientEhrOrderModel): string {
		const route = capitalize(ehrOrder.route);
		const volumeWithUnit = ehrOrder.amount
			? `${ehrOrder.amount}${ehrOrder.measuredUnit}`
			: "";

		// If route and volumeWithUnit have a value put a colon between them
		// if not, just display the one that has a value with no colon
		let routeVolumeDisplay = "";
		if (route && volumeWithUnit) {
			routeVolumeDisplay = `${route}: ${volumeWithUnit}`;
		} else {
			routeVolumeDisplay = route || volumeWithUnit;
		}

		if (ehrOrder.duration) {
			return `${routeVolumeDisplay}, over ${ehrOrder.duration}`;
		}

		return routeVolumeDisplay;
	}

	getStandingStatus(ehrOrder: PatientEhrOrderModel): string {
		if (ehrOrder.standingStatus) {
			return ehrOrder.standingStatus;
		}

		if (!ehrOrder.endDate) {
			return "Standing";
		}

		return "";
	}

	/**
	 * Method signature must match https://angular.io/api/core/TrackByFunction
	 *
	 * Without trackBy, Angular will re-render the entire list,
	 * rather than only refreshing and updating as needed. This causes
	 * jumping/snapping issues.
	 */
	trackByOrder(index, ehrOrder) {
		return (ehrOrder as PatientEhrOrderModel).id;
	}
}

export const isMilkBottleOrderMatch = (
	milkBottleId: string,
	results: IOrderValidationResult2[]
): boolean => {
	if (!results) {
		return;
	}

	const result = results.find((v) => v.milkBottleId === milkBottleId);
	if (!result) {
		console.warn(
			`order-match.component: milkBottle not found: ${milkBottleId}`
		);
	}
	return result.isValid;
};

/**
 * @deprecated use {@link isScannedItemOrderMatch} in {@link orders.utils.ts} instead
 */
export const isScannedItemOrderMatch = (
	scannedItemKey: string,
	validationResults: IOrderValidationResult2[]
): boolean => {
	if (!validationResults) {
		return;
	}

	const result = validationResults.find((v) => v.key === scannedItemKey);
	if (!result) {
		console.warn(
			`order-match.component: scannedItem not found: scannedItemKey = ${scannedItemKey}`
		);
	}
	return result.isValid;
};

export interface IOrderValidationResult {
	milkBottleId?: string;
	contentItemsNotInOrder: Array<{
		productId: Product["id"];
		productName: Product["name"];
	}>;
	baseOrderedIsPresent: boolean;
	orderNonBaseProductsNotInBottleOrRecipeCalculation: Array<{
		productId: Product["id"];
		productName: Product["name"];
	}>;
	caloriesMatched: boolean;
	isValid: boolean;
}

export interface IOrderValidationResult2 {
	milkBottleId?: MilkBottleModel["id"];
	key?: string; // unique id using nano id
	isValid: boolean;
	caloriesMatch: boolean;
	anyOrderFulfillmentValid: boolean;
	validationResultsByOrderFulfillment: Array<{
		ehrOrderFulfillmentId: string;
		milkBottleContentItemsNotInOrderFulfillment: {
			productId: Product["id"];
			productName: Product["name"];
		}[];
		baseOrderedIsPresent: boolean;
		orderFulfillmentNonBaseProductsNotInBottle: {
			productId: Product["id"];
			productName: Product["name"];
		}[];
		isValid: boolean;
	}>;
}

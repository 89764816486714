import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { PrepFeedQueryParams } from "./app.enums";
import { AuthGuardService, FeatureGuardService, PermissionsGuardService } from "./services/guards";
import { Features } from "./features.enums";

const routes: Routes = [
	{
		path: "",
		redirectTo: "login",
		pathMatch: "full",
	},
	{
		path: "login",
		loadChildren: () =>
			import("./login/login.module").then((m) => m.LoginPageModule),
	},
	{
		path: "app-auth/:authKey",
		loadChildren: () =>
			import("./login-crosswalk/login-crosswalk.module").then(
				(m) => m.LoginCrosswalkPageModule
			),
	},
	{
		path: "app-auth",
		redirectTo: `/login`,
	},
	{
		path: "home",
		loadChildren: () =>
			import("./home/home.module").then((m) => m.HomePageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "advanced-settings",
		loadChildren: () =>
			import("./advanced-settings/advanced-settings.module").then(
				(m) => m.AdvancedSettingsPageModule
			),
	},

	/* Administer Feed Routes */
	{
		path: "administer-feed",
		loadChildren: () =>
			import("./administer-feed/administer-feed-list.module").then(
				(m) => m.AdministerFeedListModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "feed-patient",
		loadChildren: () =>
			import("./administer-feed/feed-patient/feed-patient.module").then(
				(m) => m.FeedPatientPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "direct-breastfeed",
		loadChildren: () =>
			import(
				"./administer-feed/direct-breastfeed/direct-breastfeed.module"
			).then((m) => m.DirectBreastFeedPageModule),
		canActivate: [AuthGuardService, FeatureGuardService],
		data: {
			feature: Features.Direct_Breastfeed
		}
	},

	/* Prep Feed Routes */
	{
		path: "prep-feed",
		loadChildren: () =>
			import("./prep-feed/prepare-feed-list.module").then(
				(m) => m.PrepareFeedListPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "thaw-patients-milk",
		loadChildren: () =>
			import(
				"./prep-feed/thaw-patients-milk/thaw-patients-milk.module"
			).then((m) => m.ThawPatientsMilkPageModule),
		canActivate: [AuthGuardService, FeatureGuardService],
		data: {
			feature: Features.Thaw_Patients_Milk
		}
	},
	{ path: "thaw-parents-milk",   redirectTo: "/thaw-patients-milk", pathMatch: "full" },
	{
		path: "prep-milk",
		loadChildren: () =>
			import("./prep-feed/prep-milk/prep-milk.module").then(
				(m) => m.PrepMilkPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "divide-feed",
		redirectTo: `/prep-milk?mode=${PrepFeedQueryParams.Divide_Feed}`,
	},
	{
		path: "prep-formula",
		loadChildren: () =>
			import("./prep-feed/prep-formula/prep-formula.module").then(
				(m) => m.PrepFormulaPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "thaw-product",
		loadChildren: () =>
			import("./prep-feed/thaw-product/thaw-product.module").then(
				(m) => m.ThawProductPageModule
			),
		canActivate: [AuthGuardService, FeatureGuardService],
		data: {
			feature: Features.Thaw_Product
		}
	},
	{
		path: "assign-product",
		loadChildren: () =>
			import("./prep-feed/assign-product/assign-product.module").then(
				(m) => m.AssignProductPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "milk-room-prep",
		loadChildren: () =>
			import("./prep-feed/milk-room-prep/milk-room-prep.module").then(
				(m) => m.MilkRoomPrepPageModule
			),
		canActivate: [AuthGuardService],
	},

	/* Manage Labels Routes */
	{
		path: "manage-labels",
		loadChildren: () =>
			import("./manage-labels/manage-labels-list.module").then(
				(m) => m.ManageLabelsListPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "print-parent-labels",
		loadChildren: () =>
			import(
				"./manage-labels/print-parent-labels/print-parent-label.module"
			).then((m) => m.PrintParentLabelPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "print-fresh-milk",
		loadChildren: () =>
			import(
				"./manage-labels/print-fresh-milk/print-fresh-milk.module"
			).then((m) => m.PrintFreshMilkPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "link-siblings",
		loadChildren: () =>
			import("./manage-labels/link-siblings/link-siblings.module").then(
				(m) => m.LinkSiblingsPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "freeze-parents-milk",
		loadChildren: () =>
			import(
				"./manage-labels/freeze-parents-milk/freeze-parents-milk.module"
			).then((m) => m.FreezeParentsMilkPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "verify-milk-label",
		loadChildren: () =>
			import(
				"./manage-labels/verify-milk-label/verify-milk-label.module"
			).then((m) => m.VerifyMilkLabelPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "print-custom-label",
		loadChildren: () =>
			import(
				"./manage-labels/print-custom-label/print-custom-label.module"
			).then((m) => m.PrintCustomLabelPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "edit-reprint-label",
		loadChildren: () =>
			import(
				"./manage-labels/edit-reprint-label/edit-reprint-label.module"
			).then((m) => m.EditReprintLabelPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "edit-reprint-unassigned",
		loadChildren: () =>
			import(
				"./manage-labels/edit-reprint-unassigned/edit-reprint-unassigned.module"
			).then((m) => m.EditReprintUnassignedPageModule),
		canActivate: [AuthGuardService],
	},

	/* Reports & Inventory Routes */
	{
		path: "reports-and-inventory",
		loadChildren: () =>
			import("./reports-and-inventory/reports-and-inventory.module").then(
				(m) => m.ReportsAndInventoryPageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "patient-inventory",
		loadChildren: () =>
			import(
				"./reports-and-inventory/patient-inventory/patient-inventory.module"
			).then((m) => m.PatientInventoryPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "feeding-report",
		loadChildren: () =>
			import(
				"./reports-and-inventory/feeding-report/feeding-report.module"
			).then((m) => m.FeedingReportPageModule),
		canActivate: [AuthGuardService],
	},

	/* Receive & Discharge Routes */
	{
		path: "receive-and-discharge",
		loadChildren: () =>
			import("./receive-and-discharge/receive-and-discharge.module").then(
				(m) => m.ReceiveAndDischargePageModule
			),
		canActivate: [AuthGuardService],
	},
	{
		path: "receive-parents-milk",
		loadChildren: () =>
			import(
				"./receive-and-discharge/receive-parents-milk/receive-parents-milk.module"
			).then((m) => m.ReceiveParentsMilkPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "receive-product",
		loadChildren: () =>
			import(
				"./receive-and-discharge/receive-product/receive-product.module"
			).then((m) => m.ReceiveProductPageModule),
		canActivate: [AuthGuardService],
	},
	{
		path: "discharge-parents-milk",
		loadChildren: () =>
			import(
				"./receive-and-discharge/discharge-parents-milk/discharge-parents-milk.module"
			).then((m) => m.DischargeParentsMilkPageModule),
		canActivate: [AuthGuardService],
	},
	/* Internal Tools */
	{
		path: "internal-tools",
		loadChildren: () =>
			import("./internal-tools/internal-tools.module").then(
				(m) => m.InternalToolsPageModule
			),
		canActivate: [AuthGuardService, PermissionsGuardService],
	},
	{
		path: "internal-tools/alert-previewer",
		loadChildren: () =>
			import(
				"./internal-tools/alert-previewer/alert-previewer.module"
			).then((m) => m.AlertPreviewerPageModule),
		canActivate: [AuthGuardService, PermissionsGuardService],
	},
	{
		path: "internal-tools/design-prototypes",
		loadChildren: () =>
			import(
				"./internal-tools/design-prototypes/design-prototypes.module"
			).then((m) => m.DesignPrototypesPageModule),
		canActivate: [AuthGuardService, PermissionsGuardService],
	},
	{
		path: "internal-tools/feature-flags",
		loadChildren: () =>
			import(
				"./internal-tools/feature-flags/feature-flags.module"
			).then((m) => m.FeatureFlagsPagePageModule),
		canActivate: [AuthGuardService, PermissionsGuardService],
	},
	{
		path: "internal-tools/qr-code-previewer",
		loadChildren: () =>
			import(
				"./internal-tools/qr-code-preview/qr-code-preview.module"
			).then((m) => m.QRCodePreviewPageModule),
		canActivate: [AuthGuardService, PermissionsGuardService],
	},
	{
		path: "print-preview",
		loadChildren: () =>
			import("./internal-tools/print-preview/print-preview.module").then(
				(m) => m.PrintPreviewPageModule
			),
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
	],
	exports: [RouterModule],
})
export class AppRoutingModule {}

/**
	// key:    32  bytes
	// cipher: 658 bytes
	// tag:    16  bytes
	// nonce:  12  bytes
	* @param encryptedBytesBase64
	*
	* @param keyBase64
	* @returns string: decrypted text
*/
export const decryptAesGcmFromBytes = async (encryptedBytesBase64, keyBase64) => {

	try {
		const encryptedBytes = Uint8Array.from(atob(encryptedBytesBase64), c => c.charCodeAt(0));
		const encoder = new TextEncoder();
		const key = encoder.encode(keyBase64);

		if (encryptedBytes.length < 28) {
			throw new Error("Invalid encrypted data length.");
		}

		const nonce = encryptedBytes.slice(0, 12);
		const tag = encryptedBytes.slice(encryptedBytes.length - 16);
		const ciphertext = encryptedBytes.slice(12, encryptedBytes.length - 16);

		const algorithm = {
			name: "AES-GCM",
			iv: nonce,
			tagLength: 128,
		};

		const cryptoKey = await crypto.subtle.importKey(
			"raw",
			key,
			{ name: "AES-GCM",length: 128 },
			false,
			["decrypt"]
		);

		const decryptedBuffer = await crypto.subtle.decrypt(
			algorithm,
			cryptoKey,
			concatArrays(ciphertext, tag)
		);

		const decryptedText = new TextDecoder().decode(decryptedBuffer);
		return decryptedText;
	} catch (error) {
		console.error("Decryption error:", error);
		return null;
	}
}

export const concatArrays = (arr1, arr2) => {
	const result = new Uint8Array(arr1.length + arr2.length);
	result.set(arr1, 0);
	result.set(arr2, arr1.length);
	return result;
}

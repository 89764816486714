import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBottleModel } from "../../models/milk.model";
import { PatientModel } from "../../models/patient.model";
import { ScanMilkBottleValidationError } from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";

function isPatientMatch(
	patient: PatientModel,
	milkBottle: MilkBottleModel
): boolean {
	if (!patient) {
		console.log("isPatientMatch: patient is null");
		return false;
	}
	return !!milkBottle.patients.find((p) => p.mrn === patient.mrn);
}

export function CheckPatientMatch2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject, patient } = obj;

			// determine feed object type and check if patient matches feed object
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					if (!isPatientMatch(patient, feedObject)) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Patient_MRN_Mismatch,
							modalMessage.scan_mrn_mismatch(patient, feedObject)
						);
					}
				} else {
					console.error(`feedObject is not a MilkBottleModel`);
				}
			} else {
				console.error(`feedObject is undefined`);
			}
			return originalMethod.apply(this, args);
		};
	};
}

/**
 * Verify the the patient assigned to the milk bottle is the current patient.
 */
export function CheckPatientMatch() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (!isPatientMatch(obj.patient, obj.milkBottle)) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Patient_MRN_Mismatch,
						modalMessage.scan_mrn_mismatch(
							obj.patient,
							obj.milkBottle
						)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

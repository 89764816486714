import {
	AfterViewInit,
	Component,
	Input,
	OnInit,
	ViewChild,
} from "@angular/core";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ValidateBottleNumber } from "../../validations/unique-bottle-number.validator";
import { IonSearchbar, ModalController } from "@ionic/angular";
import { UnreceivedProduct } from "../../models";

@Component({
	selector: "app-bottle-number-input",
	templateUrl: "./bottle-number-input.component.html",
	styleUrls: ["./bottle-number-input.component.scss"],
})
export class BottleNumberInputComponent implements OnInit, AfterViewInit {
	@ViewChild("bottleNumberInput", { static: false })
	bottleNumberInput: IonSearchbar;
	@Input() unreceivedProduct: UnreceivedProduct;
	@Input() queue: MilkBankProductModel[];

	get invalidBottleNumber() {
		if (!this.unreceivedProductGroupForm) {
			return false;
		}

		const bottleNumber = this.unreceivedProductGroupForm.get("bottleNumber");
		return bottleNumber.errors?.invalidBottleNumber;
	}

	unreceivedProductGroupForm: FormGroup;

	constructor(
		private formBuilder: FormBuilder,
		public modalCtrl: ModalController
	) {}

	ngOnInit() {
		this.buildForm();
	}

	async ngAfterViewInit() {
		await new Promise((resolve) => setTimeout(resolve, 500));

		// Ensure that the modal has finished loading otherwise
		// searchInput will be undefined.
		if (this.bottleNumberInput) {
			await this.bottleNumberInput.setFocus();
		}
	}

	buildForm() {
		this.unreceivedProductGroupForm = this.formBuilder.group({
			bottleNumber: [
				"",
				Validators.compose([
					ValidateBottleNumber(
						this.queue,
						this.unreceivedProduct?.lotNumber
					),
					Validators.required,
				]),
			],
		});
	}

	onSubmit() {
		const updated = this.unreceivedProductGroupForm.value;
		this.modalCtrl.dismiss({ ...this.unreceivedProduct, ...updated });
	}

	/**
	 *
	 * Prevent users from entering negative bottle numbers.
	 */
	handleInput($event: Event) {
		const event = ($event.target! as HTMLInputElement).value.trim();
		if (!event) {
			return;
		}

		let value = parseInt(event);
		const minimumLabelQuantity = 0;

		if (value < minimumLabelQuantity) {
			value = minimumLabelQuantity;
		}

		this.unreceivedProductGroupForm.patchValue({
			bottleNumber: value,
		});
	}
}

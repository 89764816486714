import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import { ScanMilkBankProductValidationError } from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";

export function RejectDiscarded2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject } = obj;

			// determine feed object type and check for discarded date
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					console.error(
						`RejectDiscarded2: discarded MilkBottleModel is handled differently.`
					);
				} else if (feedObject instanceof MilkBankProductModel) {
					if (
						feedObject &&
						(feedObject as MilkBankProductModel).discardedDate
					) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Discarded,
							modalMessage.product_discarded(feedObject)
						);
					}
				} else {
					console.error(
						`RejectDiscarded2: feedObject is not a MilkBankProductModel.`
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectDiscarded() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBankProduct && obj.milkBankProduct.discardedDate) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Discarded,
						modalMessage.product_discarded(obj.milkBankProduct)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

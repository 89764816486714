<ion-list-header>
	<ion-label>
		Select Order
	</ion-label>
</ion-list-header>

<ion-item *ngIf="!hasActiveOrders(orders)">
	<ion-text class="validation-text">
		This patient has no active orders OR has an active NPO order
	</ion-text>
</ion-item>

<ng-container *ngIf="hasActiveOrders(orders)">
	<ion-grid class="ion-margin-bottom">
		<ion-row>
			<ion-col *ngFor="let order of orders; let i = index" size="12" size-sm="4">
				<div class="order"
				     [ngClass]="{ 'selected': selectedOrder === order }"
				     (click)="selectOrder($event, order)">
					<div class="order__title">
						<div>{{ displayDate(order.startDate) }}</div>
						<ion-chip *ngIf="order.standingStatus" size="small">
							{{ getStandingStatus(order) }}
						</ion-chip>
					</div>

					<ul class="order__details">
						<li *ngFor="let detail of getOrderDetails(order)">{{ detail }}</li>
					</ul>
				</div>
			</ion-col>
		</ion-row>
	</ion-grid>
</ng-container>

<ion-list-header heading="secondary">
	<ion-label>
		Instructions
	</ion-label>
</ion-list-header>

<p>{{ selectedOrder?.instructions || "None" }}</p>

<ng-container *ngIf="_validationResults2 && !isValidOrderMatch(_validationResults2)">
	<ion-text *ngIf="!hasValidOrderFulfillment(_validationResults2)" class="validation-text validation-text--warning">
		<img src="assets/imgs/icon-exclamation-point.png" class="icon" alt="">
		The contents of the bottle do not match the selected order.
	</ion-text>
</ng-container>

<ng-container *ngIf="!isCalorieMatch()">
	<ion-text  class="validation-text validation-text--warning">
		<img src="assets/imgs/icon-exclamation-point.png" class="icon" alt="">
		The caloric density does not match the order.
	</ion-text>
</ng-container>

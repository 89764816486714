<ion-grid>
	<div [formGroup]="labelQuantityFormGroup">
		<div formArrayName="items">
			<ng-container *ngFor="let row of items; let i=index">
				<ion-row [formGroupName]="i" class="border">
					<ion-col>
						{{ getPatient(i) }}
					</ion-col>
					<ion-col class="label-quantity">
						<ion-input type="number" formControlName="labelQuantity" class="form-control"
							(ionInput)="handleInput($event, i)" />
					</ion-col>
				</ion-row>
			</ng-container>
		</div>
	</div>
</ion-grid>

import { displayDate, getCaloricDensityText } from "../app.util";
import { CustomPrintLabelInput } from "../components/print-label-with-retry/print-label-with-retry.component";
import { getBottleNumberText, getMilkTypeText } from "../utils/milk-label.util";
import { MilkBankProductModel } from "./milk-bank-product.model";
import { MilkBottleModel } from "./milk.model";
import { PatientModel } from "./patient.model";
import { environment } from "../../environments/environment";
import { DATA_MATRIX_URL_PATH } from "../app.constants";

export class MilkBottlePrintModel {
	bottleNumber: string;
	caloricDensity: string;
	contents: string;
	expirationDate: string;
	milkType: string;
	patients: IMilkBottlePatientsPrintModel[];
	qrCode: string;
	qrCodeData: string;
	fedDate: string;
	lotNumber?: string;

	constructor(initial: MilkBottleModel) {
		if (!initial) {
			return;
		}

		const qrCodeData = `M|${initial.unifiedId}|B`;

		this.bottleNumber = getBottleNumberText(initial.bottleNumber);
		this.caloricDensity = getCaloricDensityText(initial.calorieDensity);
		this.contents = this.getContents(initial);
		this.expirationDate = displayDate(initial.expirationDate);
		this.milkType = getMilkTypeText(initial);
		this.patients = displayPatient(initial.patients);
		this.qrCode = generateDataMatrix({ data: qrCodeData });
		this.qrCodeData = qrCodeData;
		this.fedDate = displayDate(initial.usedDate);
		this.lotNumber = this.getLotNumbers(initial);
	}

	getContents(milk: MilkBottleModel): string {
		return milk.contents
			.map((x) => x.contentProductCode)
			.filter((id, index, arr) => arr.indexOf(id) === index) // dedupe
			.join(", ");
	}

	getLotNumbers(milk: MilkBottleModel): string {
		const lotNumbers = milk.contents
			.map((x) => x.mfgLotNumber)
			.filter((id) => Boolean(id)) // Filter out null, undefined, or empty strings
			.filter((id, index, arr) => arr.indexOf(id) === index); // Remove duplicates

		if (lotNumbers.length === 0) {
			return "None";
		}

		return lotNumbers.join(", ");
	}
}

export class MilkBankProductPrintModel {
	bottleNumber: string;
	caloricDensity: string;
	expirationDate: string;
	name: string;
	lotNumber: string;
	productState: string;
	productType: string;
	qrCode: string;
	qrCodeData: string;

	constructor(initial: MilkBankProductModel) {
		if (!initial) {
			return;
		}

		const qrCodeData = `M|${initial.unifiedId}|P`;

		this.bottleNumber = getBottleNumberText(initial.bottleNumber);
		this.caloricDensity = getCaloricDensityText(initial.calorieDensity);
		this.expirationDate = displayDate(initial.expirationDate);
		this.name = initial.name;
		this.lotNumber = initial.lotNumber;
		this.productState = initial.productState;
		this.productType = initial.productType;
		this.qrCode = generateDataMatrix({ data: qrCodeData });
		this.qrCodeData = qrCodeData;
	}
}

export class CustomPrintModel {
	line1: string;
	line2: string;
	patients: IMilkBottlePatientsPrintModel[];
	qrCode: string;
	qrCodeData: string;

	constructor(initial: CustomPrintLabelInput) {
		if (!initial) {
			return;
		}

		const qrCodeData = `C|${initial.patient.mrn}|L`;

		this.qrCode = generateDataMatrix({ data: qrCodeData });
		this.qrCodeData = qrCodeData;

		this.line1 = initial.line1;
		this.line2 = initial.line2;
	}
}

/**
 * {text to encode}&size150x150
 *
 * @param params
 */
export const generateDataMatrix = (params: { data: string }): string => {
	const width = 150;
	const height = 150;
	const dimensions = `&size${width}x${height}`;
	return `${environment.apiUrl}${DATA_MATRIX_URL_PATH}${params.data}${dimensions}`;
};

export const displayPatient = (
	patients: PatientModel[]
): IMilkBottlePatientsPrintModel[] =>
	patients.map((x: PatientModel) => ({
		name: `${x.lastName}, ${x.firstName}`,
		mrn: x.mrn,
		dateOfBirth: x.dateOfBirth.format("MM/DD/YY"),
	}));

interface IMilkBottlePatientsPrintModel {
	dateOfBirth: string;
	mrn: string;
	name: string;
}

export interface IMilkBottlePrintModel {
	bottleNumber: string;
	caloricDensity: string;
	contents: string;
	expirationDate: string;
	milkType: string;
	patients: IMilkBottlePatientsPrintModel[];
	qrCode: string;
	qrCodeData: string;
	fedDate: string;
	lotNumber?: string;
}

export interface IMilkBankProductPrintModel {
	bottleNumber: string;
	caloricDensity: string;
	expirationDate: string;
	lotNumber: string;
	name: string;
	productState: string;
	productType: string;
	qrCode: string;
	qrCodeData: string;
}

export interface ICustomPrintModel {
	line1: string;
	line2: string;
	qrCode: string;
	qrCodeData: string;
}

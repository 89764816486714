import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { OverlayModal } from "./overlay.modal";


@NgModule({
	imports: [
		CommonModule,
		IonicModule,
	],
	declarations: [OverlayModal]
})
export class OverlayModalModule {
}

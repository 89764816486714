import { Injectable } from "@angular/core";

import { MilkState, ScanLogType } from "src/app/app.enums";

import { MilkBottleModel } from "src/app/models/milk.model";
import { PatientModel } from "src/app/models/patient.model";
import { isExpired } from "../utils/expiration.util";

@Injectable({
	providedIn: "root"
})
export class VerificationService {

	constructor() {}

	isPatientMatch(patient: PatientModel, milkBottle: MilkBottleModel): boolean {
		return !!milkBottle.patients.find(p => p.mrn === patient.mrn);
	}

	/**
	 * Verify feeding needs to match the baby mrn and not have been used/fed.
	 * Oral Care doesn't count as being used.
	 *
	 * Milk label must also be a consumable type (e.g. can't be frozen).
	 */
	verifyFeeding(isOralCare: boolean, patient: PatientModel, milkBottle: MilkBottleModel): Promise<ScanLogType> {
		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			try {
				await this.isConsumable(milkBottle);
				if (this.isPatientMatch(patient, milkBottle)) {
					if (isExpired(milkBottle.expirationDate)) {
						resolve(ScanLogType.Milk_Bottle_Expired);
					}
					else if (!isOralCare && milkBottle.usedDate) {
						resolve(ScanLogType.Patient_Previously_Fed);
					}
					else {
						resolve(ScanLogType.Milk_Bottle_Match);
					}
				}
				else {
					resolve(ScanLogType.Milk_Bottle_Mismatch);
				}
			}
			catch (error) {
				console.error(`${this.constructor.name}.verifyFeeding: ${error}`);
				reject(error);
			}
		});
	}

	/**
	 * Not consumable:
	 *   - Frozen
	 */
	isConsumable(milkBottle: MilkBottleModel): Promise<string> {
		if (milkBottle.milkState === MilkState.Frozen) {
			const errorMessage = "New/frozen milk label detected. Please thaw and print a new label.";
			return Promise.reject(errorMessage);
		}
		return Promise.resolve(null);
	}
}

import {
	FeedPatientListItem,
	MilkBottleFeedPatientListItem,
	ProductFeedPatientListItem,
} from "../../administer-feed/feed-patient/list-item";
import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import {
	isInMilkBankProductArray,
	isInScannedProductsArray,
} from "../../utils/milk-bank-product.util";
import {
	ScanMilkBankProductValidationError,
	ScanMilkBottleValidationError,
	ScannedObjectError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";
import { Product } from "../../models/product.model";

/**
 * When a user has scanned a bottle/product twice.
 */
export function RejectDuplicate2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { scannedObject, feedObject, feedObjects } = obj;

			// determine feed object type and check for duplicates
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					const isDuplicate = !!feedObjects.find(
						(x: MilkBottleModel) => x.id === feedObject.id
					);

					if (isDuplicate) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Milk_Bottle_Duplicate,
							modalMessage.milk_duplicate(feedObject)
						);
					}
				} else if (feedObject instanceof MilkBankProductModel) {
					const isDuplicate = isInMilkBankProductArray(
						{
							milkBankProduct:
								obj.feedObject as MilkBankProductModel,
						},
						feedObjects.filter(
							(f) => f instanceof MilkBankProductModel
						) as MilkBankProductModel[]
					);

					if (isDuplicate) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Duplicate,
							modalMessage.scan_duplicate()
						);
					}
				} else if (feedObject as Product) {
					const isDuplicate = !!feedObjects.find(
						(x: Product) => x.id === feedObject.id
					);

					if (isDuplicate) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Duplicate,
							modalMessage.scan_duplicate()
						);
					}
				}
			} else if (scannedObject) {
				const isDuplicate = !!feedObjects.find(
					(f) =>
						(f as Product).barcodeText ===
						scannedObject.barcode.text
				);
				if (isDuplicate) {
					throw new ScannedObjectError(
						ScanLogType.Scanned_Object_Duplicate,
						modalMessage.scan_duplicate()
					);
				}
			} else {
				throw new Error("Missing scanned object or feed object");
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectDuplicate() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			// Milk Bottle
			if (obj.milkBottle instanceof MilkBottleModel) {
				let duplicate: boolean;

				if (obj.milkBottles) {
					duplicate = !!obj.milkBottles.find(
						(m: MilkBottleModel) => m.id === obj.milkBottle.id
					);
				}

				// Feed patient looks at FeedPatientListItems for duplication.
				if (obj.listItems) {
					duplicate = !!obj.listItems.find(
						(x: FeedPatientListItem) => {
							if (x instanceof MilkBottleFeedPatientListItem) {
								return x.milkBottle.id === obj.milkBottle.id;
							}
						}
					);
				}

				if (duplicate) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Duplicate,
						modalMessage.milk_duplicate(obj.milkBottle)
					);
				}
			}

			// Milk Bank Product
			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				const duplicate = isInMilkBankProductArray(
					{ milkBankProduct: obj.milkBankProduct },
					obj.milkBankProducts
				);

				if (duplicate) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Duplicate,
						modalMessage.scan_duplicate()
					);
				}
			}

			// Scan Duplicate
			if (obj.scannedObject) {
				let duplicate = false;

				if (obj.milkBankProducts) {
					duplicate = isInMilkBankProductArray(
						{ scannedObject: obj.scannedObject },
						obj.milkBankProducts
					);
				}

				if (obj.scannedProducts) {
					duplicate = isInScannedProductsArray(
						{ scannedObject: obj.scannedObject },
						obj.scannedProducts
					);
				}

				if (duplicate) {
					throw new ScannedObjectError(
						ScanLogType.Scanned_Object_Duplicate,
						modalMessage.scan_duplicate()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectDuplicateExternalProduct() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: any[]) {
			const [obj] = args;

			// RTF
			if (obj.product instanceof Product) {
				let duplicate: boolean;

				// Feed patient looks at FeedPatientListItems for duplication.
				if (obj.listItems) {
					duplicate = !!obj.listItems.find(
						(x: FeedPatientListItem) => {
							if (x instanceof ProductFeedPatientListItem) {
								return (
									x.bottleNumber ===
										Number(
											obj.regExpMatch.groups.bottleNumber
										) &&
									x.lotNumber ===
										obj.regExpMatch.groups.lotCode
								);
							}
						}
					);
				}

				if (duplicate) {
					throw new ScannedObjectError(
						ScanLogType.Product_Duplicate,
						modalMessage.scan_duplicate()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

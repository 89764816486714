import {
	IAuthorizedTenant,
	IPermissions,
	UserProfileModel,
} from "../models/user-profile.model";
import { PrinterFactory } from "./printer.factory";

// biome-ignore lint/complexity/noStaticOnlyClass: factory
export class UserProfileFactory {
	static create(data?): UserProfileModel {
		const authorizedTenant = AuthorizedTenantFactory.create();

		const defaultModel: UserProfileModel = {
			loginName: "testuser@example.com",
			// uid: "testuser",
			nurseId: "testuser123",
			badgeId: "testuser123",
			name: "Test User",
			firstName: "Test",
			lastName: "User",
			authKey: "00000000-0000-0000-00000-00000000000000",
			email: "testuser@example.com",
			authorizedTenants: [authorizedTenant],
			currentPrinter: PrinterFactory.create(),
			preferredTenantId: authorizedTenant.tenantId,
		};
		return new UserProfileModel({ ...defaultModel, ...data });
	}
}

// biome-ignore lint/complexity/noStaticOnlyClass: factory
export class PermissionsFactory {
	static create(data?): IPermissions {
		return { ...defaultPermissions, ...data } as IPermissions;
	}
}

export const defaultPermissions: IPermissions = {
	administerFeed: true,
	administerFeed_DirectBreastfeed: true,
	administerFeed_DirectBreastfeed_SelectPatient: true,
	administerFeed_FeedPatient: true,
	administerFeed_FeedPatient_SelectPatient: true,
	administerFeed_OralCare: true,
	administerFeed_OralCare_SelectPatient: true,
	global_InternalTools: true,
	global_SelectMilk: true,
	global_SkipPrintingUpdatedLabels: true,
	global_VirtualPrinting: false,
	manageLabels: true,
	manageLabels_EditAndReprintLabel: true,
	manageLabels_EditAndReprintLabel_SelectPatient: true,
	manageLabels_LinkSiblings: true,
	manageLabels_LinkSiblings_SelectPatient: true,
	manageLabels_PrintCustomLabel: true,
	manageLabels_PrintCustomLabel_SelectPatient: true,
	manageLabels_PrintFreshMilkLabel: true,
	manageLabels_PrintFreshMilkLabel_DesiredVolume: true,
	manageLabels_PrintFreshMilkLabel_SelectPatient: true,
	manageLabels_PrintFreshMilkLabel_VolumeOnHand: true,
	manageLabels_PrintFreshMilkLabel_ManualPrep: true,
	manageLabels_PrintParentLabel: true,
	manageLabels_PrintParentLabel_SelectPatient: true,
	manageLabels_VerifyMilkLabel: true,
	manageLabels_VerifyMilkLabel_SelectPatient: true,
	prepFeed: true,
	prepFeed_AssignProduct: true,
	prepFeed_AssignProduct_DesiredVolume: true,
	prepFeed_AssignProduct_SelectPatient: true,
	prepFeed_AssignProduct_VolumeOnHand: true,
	prepFeed_AssignProduct_ManualPrep: true,
	prepFeed_MilkRoomPrep: true,
	prepFeed_MilkRoomPrep_DesiredVolume: true,
	prepFeed_MilkRoomPrep_ManualPrep: true,
	prepFeed_MilkRoomPrep_SelectPatient: true,
	prepFeed_MilkRoomPrep_VolumeOnHand: true,
	prepFeed_DivideFeed: true,
	prepFeed_DivideFeed_DesiredVolume: true,
	prepFeed_DivideFeed_SelectPatient: true,
	prepFeed_DivideFeed_VolumeOnHand: true,
	prepFeed_PrepMilk: true,
	prepFeed_PrepMilk_DesiredVolume: true,
	prepFeed_PrepMilk_SelectPatient: true,
	prepFeed_PrepMilk_VolumeOnHand: true,
	prepFeed_PrepMilk_ManualPrep: true,
	prepFeed_PrepFormula: true,
	prepFeed_PrepFormula_DesiredVolume: true,
	prepFeed_PrepFormula_ManualPrep: true,
	prepFeed_PrepFormula_SelectPatient: true,
	prepFeed_PrepFormula_VolumeOnHand: true,
	prepFeed_ThawPatientsMilk: true,
	prepFeed_ThawPatientsMilk_DesiredVolume: true,
	prepFeed_ThawPatientsMilk_SelectPatient: true,
	prepFeed_ThawPatientsMilk_VolumeOnHand: true,
	prepFeed_ThawPatientsMilk_ManualPrep: true,
	prepFeed_ThawProduct: true,
	receiveAndDischargeMilk: true,
	receiveAndDischargeMilk_DischargeParentsMilk: true,
	receiveAndDischargeMilk_DischargeParentsMilk_SelectPatient: true,
	manageLabels_FreezeParentsMilk: true,
	manageLabels_FreezeParentsMilk_SelectPatient: true,
	receiveAndDischargeMilk_ReceiveParentsMilk: true,
	receiveAndDischargeMilk_ReceiveParentsMilk_SelectPatient: true,
	receiveAndDischargeMilk_ReceiveProduct: true,
	reportsAndInventory: true,
	reportsAndInventory_FeedingReport: true,
	reportsAndInventory_FeedingReport_SelectPatient: true,
	reportsAndInventory_PatientInventory: true,
	reportsAndInventory_PatientInventory_SelectPatient: true,
	milkTracker_Admin_Portal: true,
};

export class AuthorizedTenantFactory {
	tenantId: string;
	tenantName: string;
	permissionsList: string[];
	permissions: IPermissions;

	static create(data?): IAuthorizedTenant {
		const defaultModel = {
			tenantId: 1,
			tenantName: "TEST",
			permissionsList: [],
			permissions: defaultPermissions,
		};

		return { ...defaultModel, ...data } as IAuthorizedTenant;
	}
}

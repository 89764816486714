import { FeatureStatus, Features, IFeatureFlag } from "../features.enums";

// biome-ignore lint/complexity/noStaticOnlyClass: factory
export class FeatureFlagFactory {
	static create(data?: IFeatureFlag): IFeatureFlag {
		const defaultFeatureFlags = getDefaultFeatureFlags();

		return { ...defaultFeatureFlags, ...data };
	}
}

/**
 * Takes each item in the Features enum and sets the value to Stable
 */
export const getDefaultFeatureFlags = (): IFeatureFlag => {
	const defaultFeatureFlags = {};

	Object.values(Features).map((value) => {
		defaultFeatureFlags[value] = FeatureStatus.Stable;
	});

	return defaultFeatureFlags;
};

<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="back()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{title}}</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div>
		<div *ngIf="header || message" class="header">
			<h1>{{ header }}</h1>
			<p>{{ message }}</p>
		</div>

		<!-- Bottle Number -->
		<ion-item class="input-field" lines="none" *ngIf="hasVisibleBottleNumber">
			<ion-label>
				<p>Bottle Number</p>
				<span>{{ getBottleNumberText(milkBottle.bottleNumber) }}</span>
			</ion-label>
		</ion-item>

		<!-- Milk State -->
		<app-feed-state-select title="milk state"
		                       [feed]="milkBottle"
		                       [presetState]="presetState"
		                       [state]="presetState"
							   [isDisabled]="true"
							   >
		</app-feed-state-select>

		<!-- Thawed State Date -->
		<app-inline-date
			*ngIf="isThawedMilkState"
			label="Thaw Date"
			[defaultDate]="getThawedStateDate(milkBottle)"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(isDateValid)="handleValidity($event)"
			(dateTimeChange)="setThawedDate(milkBottle, $event)"
			[isExpirationDate]="false"
			[disabled]="false"
			>
		</app-inline-date>

		<div class="buttons">
			<button (click)="submit()"
			        [disabled]="!isValid(isThawDateValid, milkBottle)"
			        class="nav-btn">
				{{ doneText }}
			</button>
		</div>
	</div>
</ion-content>

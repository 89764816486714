import { Injectable } from "@angular/core";

import {
	FeedPatientListItem,
	MilkBottleFeedPatientListItem,
	ProductFeedPatientListItem,
} from "../administer-feed/feed-patient/list-item";
import { PatientModel, PatientEhrOrderModel } from "../models/patient.model";
import dayjs from "dayjs";
import { ContentType, MilkBottleUseType, MilkLocation, MilkState } from "../app.enums";
import {
	createMilkBottlePayload,
	getCreatedMilkBottleState,
} from "../utils/feeding.util";
import { InventoryService } from "./inventory.service";
import {
	MilkBankProductModel,
	IMilkBankProduct,
} from "../models/milk-bank-product.model";
import { IContent, MilkBottleModel } from "../models/milk.model";
import { ModalController } from "@ionic/angular";
import { PrintPrompts } from "../app-print-label-messages";
import { MarkMilkBottleAsAdministeredParams } from "./feed.service";
import _ from "lodash";
import {
	getVolumeByRecipeOrEvenlyDivided,
	getVolumePerBottle,
} from "../utils/volume.util";

@Injectable({
	providedIn: "root",
})
export class FeedCombinedService {
	constructor(
		public inventoryService: InventoryService,
		public modalCtrl: ModalController
	) {}

	async processMultipleFeeds(
		listItems: FeedPatientListItem[],
		patient: PatientModel,
		selectedOrder: PatientEhrOrderModel
	) {
		try {
			// process RTFs into a single milk bottle
			const { hasRemainingRTFMilkBottles, combinedRTFMilkBottle } =
				await this.combineRTFsIntoSingleMilkBottle(listItems, patient);

			// process milk bottles into a single milk bottle
			const { combinedMilkBottle, hasRemainingMilkBottle } =
				await this.combineMilkBottlesIntoSingleMilkBottle(
					listItems,
					patient
				);

			// Combine the two & administer it.
			// Source milk bottle should be everything that's in the list.
			const combined = await this.processCombinedMilk(
				[...combinedMilkBottle, ...combinedRTFMilkBottle],
				patient
			);

			const administeredBottle = await this.markMilkBottleAsAdministered({
				milkBottle: combined[0],
				patient,
				selectedOrder,
				isOverride: true,
				overrideReason: listItems[0].resultMessage, // Same for all list items
				overrideVerifiedBy: listItems[0].secondNurse?.email, // Same for all list items
				reason: listItems[0].action === "oral-care" ? MilkBottleUseType.OralCare : MilkBottleUseType.Feeding,
			});

			return {
				milkBottleLabels: [
					...hasRemainingRTFMilkBottles,
					...hasRemainingMilkBottle,
				],
				optionalMilkBottleLabels: {
					prompt: PrintPrompts.created,
					milkBottle: administeredBottle,
				},
			};
		} catch (error) {
			console.log("error :>> ", error);
		}
	}

	async combineRTFsIntoSingleMilkBottle(
		listItems: FeedPatientListItem[],
		patient: PatientModel
	) {
		const createdMilkBottles = [];
		const hasRemainingRTFMilkBottles = [];
		const combinedRTFMilkBottle = [];

		const products: FeedPatientListItem[] = listItems.filter(
			(x) => x.type === "product"
		);

		if (products.length === 0) {
			return {
				hasRemainingRTFMilkBottles,
				combinedRTFMilkBottle,
			};
		}

		// receive the products and mark them as expended.
		const milkBankProducts = await this.receiveProducts(
			products as ProductFeedPatientListItem[],
			{
				expendedDate: dayjs(),
			}
		);

		// create a milk bottle for each RTF
		for (let index = 0; index < milkBankProducts.length; index++) {
			const milkBankProduct = milkBankProducts[index];

			const payload = {
				mothersId: patient.motherId,
				milkState: getCreatedMilkBottleState(
					milkBankProduct.action,
					milkBankProduct.productState
				),
				location: MilkLocation.Hospital,
				volume: 0,
				calorie: milkBankProduct.calorieDensity,
				expirationDate: null,
				receivedDate: milkBankProduct.receivedDate.toISOString(),
				dischargeDate: null,
				frozenDate: null,
				pumpDate: null,
				thawedDate: null,
				openedDate: null,
				contents: [
					{
						milkBankProductId: milkBankProduct.id,
						productId: milkBankProduct.productId,
						contentType: ContentType.Product,
					} as IContent,
				],
				sourceMilkbottleIds: [],
				patientIds: [patient.id],
			};

			const result =
				await this.inventoryService.createMilkBottle2(payload);
			result.action = milkBankProduct.action; // retain action

			createdMilkBottles.push(result);

			if (result.action === "has remaining") {
				hasRemainingRTFMilkBottles.push(result);
			}
		}

		// If there's only one spent RTF, don't combine.
		if (createdMilkBottles.length === 1 && hasRemainingRTFMilkBottles.length === 0) {
			return {
				hasRemainingRTFMilkBottles,
				combinedRTFMilkBottle: createdMilkBottles,
			};
		}

		const result = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, createdMilkBottles)
		);

		combinedRTFMilkBottle.push(result);

		return {
			hasRemainingRTFMilkBottles,
			combinedRTFMilkBottle,
		};
	}


	async combineMilkBottlesIntoSingleMilkBottle(
		listItems: FeedPatientListItem[],
		patient: PatientModel
	) {
		const milkBottleListItems = listItems.filter(
			(i) => i.type === "milkbottle"
		);

		if (milkBottleListItems.length === 0) {
			return {
				combinedMilkBottle: [],
				hasRemainingMilkBottle: [],
			};
		}

		const milkBottles: MilkBottleModel[] = (
			milkBottleListItems as MilkBottleFeedPatientListItem[]
		).map((x) => x.milkBottle);

		// Set stable to opened
		const { openedMilkBottles } = setStableMilkBottlesToOpened(milkBottles);

		// Feed All - Update each milk bottle to expended
		const fullyFedMilkBottles = openedMilkBottles.filter(
			(m) => m.modifierOption === "feed all"
		);
		for (const milkBottle of fullyFedMilkBottles) {
			milkBottle.expendedDate = dayjs();
		}

		// Update all of the bottles that have been modified
		await this.updateMilkBottles(
			_.uniqBy(
				[...fullyFedMilkBottles, ...openedMilkBottles],
				(b) => b.id
			)
		);

		/**
		 * For a single "Has Remaining" milk bottle
		 * create a new bottle with the source milk bottle as contents
		 *
		 * Original bottle should remain unchanged, not expended, and no label needed.
		 * A new bottle number and label is generated for the feed, with the original
		 * bottle scanned as it's contents.
		 */
		const hasRemainingMilkBottles = openedMilkBottles.filter(
			(m) => m.modifierOption === "has remaining"
		);

		if (hasRemainingMilkBottles.length === 1) {
			const created = await this.inventoryService.createMilkBottle2(
				createMilkBottlePayload(patient, [hasRemainingMilkBottles[0]])
			);

			return {
				combinedMilkBottle: [created],
				hasRemainingMilkBottle: [],
			};
		}

		/**
		 * For multiple milk bottles, combine the milks into a new bottle and feed
		 * the new bottle.
		 *
		 * If "Has Remaining" - it should be unchanged
		 * If "Feed All" - it should be expended.
		 *
		 * The new milk bottle that is fed should have a label.
		 */
		const combinedMilkBottle = await this.inventoryService.createMilkBottle2(
			createMilkBottlePayload(patient, openedMilkBottles)
		);

		return {
			combinedMilkBottle: [combinedMilkBottle],
			hasRemainingMilkBottle: [],
		};
	}

	/**
	 * update scanned milk
	 *  - if stable, set opened date
	 *  - if "has remaining", don't expend, set volume
	 *  - if "is empty", set expend date, set volume to 0
	 *  - if combined milk is fortified
	 */
	async processCombinedMilk(
		milkBottles: MilkBottleModel[],
		patient: PatientModel
	): Promise<MilkBottleModel[]> {
		// update milk
		const updated: MilkBottleModel[] = [];

		const volumeByRecipeOrEvenlyDivided = getVolumeByRecipeOrEvenlyDivided({
			recipeData: null,
			milkBottles: milkBottles,
			labelQuantity: 1,
		});

		// update existing milk bottles
		for (const m of milkBottles) {
			try {
				if (!(m.modifierOption == "has remaining")) {
					m.volume = getVolumePerBottle({
						milkBottle: m,
						volumeByRecipeOrEvenlyDivided,
					});
				}

				const u = await this.inventoryService.updateMilkBottle3(m);

				u.prepType = m.prepType; // preserve PrepType to keep track of new milk
				u.modifierOption = m.modifierOption; // preserve after update
				updated.push(u);
			} catch (e) {
				return Promise.reject(new Error(`processMilkError`));
			}
		}

		const updatedMilkBottles = updated;

		// If there's only one milk bottle, don't combine.
		if (updatedMilkBottles.length === 1) {
			return Promise.resolve(updatedMilkBottles);
		}

		// create combined milk
		const combinedMilks = await this.inventoryService.createCombinedMilk({
			patient: patient,
			assignedPatients: [], // We don't have to pass this along. The milk bottles already contain this.
			milkBottles: updatedMilkBottles.map((m) => {
				m.volume = getVolumePerBottle({
					milkBottle: m,
					volumeByRecipeOrEvenlyDivided,
				});
				return m;
			}),
			recipeData: null,
			labelQuantity: 1,
		});

		return Promise.resolve(combinedMilks);
	}

	private async updateMilkBottles(milkBottles: MilkBottleModel[]) {
		for (const milkBottle of milkBottles) {
			await this.inventoryService.updateMilkBottle3(milkBottle);
		}
	}

	private async receiveProducts(
		productListItems: ProductFeedPatientListItem[],
		props?: {
			expendedDate: MilkBankProductModel["expendedDate"];
		}
	) {
		const milkBankProducts: MilkBankProductModel[] = [];

		for (const productListItem of productListItems) {
			const milkBankProduct = new MilkBankProductModel({
				barcode: productListItem.barcodeText,
				name: productListItem.product.name,
				defective: false,
				bottleNumber: productListItem.bottleNumber,
				defectiveReason: null,
				productId: productListItem.product.id,
				manufacturerExpirationDate:
					productListItem.manufacturerExpirationDateString,
				lotNumber: productListItem.lotNumber,
				productManufacturer: productListItem.product.manufacturerName,
				manufacturerCreatedDate: null,
				expendedDate: props?.expendedDate?.toISOString() ?? null,
				thawedDate: productListItem.thawedDate?.toISOString() ?? null,
				openedDate: productListItem.openedDate?.toISOString() ?? null,
				productState: productListItem.productState,
			} as IMilkBankProduct);

			const received =
				await this.inventoryService.createMilkBankProduct(
					milkBankProduct
				);

			// retain the action
			received.action = productListItem.action;
			milkBankProducts.push(received);
		}

		return milkBankProducts;
	}

	private async markMilkBottleAsAdministered(
		params: MarkMilkBottleAsAdministeredParams
	): Promise<MilkBottleModel> {
		return this.inventoryService.administerMilkBottle({
			milkBottleId: params.milkBottle.id,
			patientId: params.patient.id,
			reason: params.reason,
			orderId: params.selectedOrder?.id ?? null,
			isOverride: params.isOverride,
			overrideReason: params.overrideReason,
			overrideVerifiedBy: params.overrideVerifiedBy,
		});
	}
}

export const setStableMilkBottlesToOpened = (
	milkBottles: MilkBottleModel[]
): {
	openedMilkBottles: MilkBottleModel[];
} => {
	const openedMilkBottles: MilkBottleModel[] = [];

	for (const milkBottle of milkBottles.filter(
		(b) => b.milkState === MilkState.Stable
	)) {
		milkBottle.milkState = MilkState.Opened;
		milkBottle.openedDate = dayjs();
		openedMilkBottles.push(milkBottle);
	}

	if (openedMilkBottles.length > 0) {
		return { openedMilkBottles };
	}

	console.log("Nothing to open...");
	return { openedMilkBottles: milkBottles }
};

import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { IonDatetime, IonAccordionGroup } from "@ionic/angular";
import { MaskitoElementPredicate, MaskitoOptions } from "@maskito/core";
import dayjs, { Dayjs } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { StorageService } from "../../services";
import {
	isAfterMinDate,
	isBeforeMaxDate,
	isBetweenMinMaxDate,
	isCompleteDate,
	isValidDate,
	setDateProcessors,
	setExpiredDateProcessors,
} from "./utils/date-helper.util";
import { environment } from "src/environments/environment";
import {
	ACCORDION,
	DATE_FORMATS,
	PLACEHOLDER_TEXT,
	PRECISION_MILLISECOND,
} from "./utils/date-time-text.util";
import {
	isCompleteTime,
	isValidTime,
	setTimeProcessors,
	setExpiredTimeProcessors,
	timeMillisFromTimeStr,
} from "./utils/time-helper.util";

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);

export type DateTypeLabel =
	| "Expiration Date"
	| "Thaw Date"
	| "Pump Date"
	| "Manufacturer Expiration Date"
	| "Expended Date"
	| "Opened Date"
	| "Frozen Date"
	| "";

@Component({
	selector: "app-inline-date",
	templateUrl: "./inline-date.component.html",
	styleUrls: ["./inline-date.component.scss"],
})
export class InlineDateComponent implements OnInit {
	@ViewChild("datetime") datetime: IonDatetime;
	@ViewChild("accordionGroup") accordionGroup: IonAccordionGroup;

	@Input() label: DateTypeLabel;
	@Input() defaultDate: dayjs.Dayjs;
	@Input() minDate: dayjs.Dayjs = null;
	@Input() maxDate: dayjs.Dayjs = null;
	@Input() isExpirationDate = false;
	@Input() presentation = "date-time";
	/**
	 * Focus the element on the page.
	 */
	@Input() autofocusState = false;
	/**
	 * Disable the input field.
	 */
	@Input() disabled = false;
	/**
	 * Emit events so we can turn the scanner off when a user
	 * is in an input field.
	 */
	@Output() inputFocus = new EventEmitter<"focus" | "blur">();
	/**
	 * Emit the validity of the date so we can disable the form
	 * if the date is invalid.
	 */
	@Output() isDateValid = new EventEmitter<boolean>();
	/**
	 * Emit the date entered or selected
	 */
	@Output() dateTimeChange = new EventEmitter<dayjs.Dayjs | null>();

	date: string;
	time: string;

	accordionDate: string;

	placeholder = null;

	dateMaskOptions: MaskitoOptions;
	timeMaskOptions: MaskitoOptions;

	dateErrorMessage = "";
	timeErrorMessage = "";

	StorageService = StorageService;
	environment = environment;

	readonly maskPredicate: MaskitoElementPredicate = async (el) =>
		(el as HTMLIonInputElement).getInputElement();

	/**
	 * Returns the label for the datetime accordion.
	 *   - Formatted date: "01/01/2020, 10:00"
	 *   - Placeholder: "MM/DD/YYYY, HH:mm"
	 */
	get accordionLabel() {
		if (this.accordionDate) {
			return dayjs(this.accordionDate).format(ACCORDION.dateFormat);
		}

		return ACCORDION.dateFormat;
	}

	ngOnInit() {
		this.initIonDatetime();
		this.formatDate();

		if (this.isExpirationDate) {
			this.dateMaskOptions = setExpiredDateProcessors();
			this.timeMaskOptions = setExpiredTimeProcessors();
			this.placeholder = PLACEHOLDER_TEXT.EXPIRATION;
		} else {
			this.dateMaskOptions = setDateProcessors();
			this.timeMaskOptions = setTimeProcessors();
			this.placeholder = PLACEHOLDER_TEXT.STANDARD;
		}

		// It's possible that users won't change the date/time at all.
		// Especially in workflows where a valid value is already filled out.
		this.emitValidDateTime(this.date, this.time);
	}

	/**
	 * Converts date and time into a string.
	 * Converts date into ISO for the accordion.
	 */
	formatDate() {
		this.date = this.defaultDate
			? dayjs(this.defaultDate).format(DATE_FORMATS.DATE)
			: null;
		this.time = this.defaultDate
			? dayjs(this.defaultDate).format(DATE_FORMATS.TIME)
			: null;

		this.accordionDate = this.defaultDate
			? this.defaultDate.toISOString()
			: null;
	}

	/**
	 * Date and Time are required to be valid before emitting the value.
	 */
	emitValidDateTime(date: string, time: string) {
		try {
			this.resetDateValidation();

			if (!date && !time) {
				this.isDateValid.emit(true);
				this.dateTimeChange.emit(null);
				return;
			}

			// Only check the time if it is not an expiration date
			if (!date || (!time && !this.isExpirationDate)) {
				this.isDateValid.emit(false);
				this.dateTimeChange.emit(null);
				return;
			}

			let dateTime = dayjs(`${date} ${time}`, DATE_FORMATS.FULL, true);

			if (this.isExpirationDate) {
				dateTime = dayjs(date).endOf("day");
			}

			this.validateDate(dateTime);

			this.isDateValid.emit(true);
			this.dateTimeChange.emit(dateTime);
		} catch (error) {
			this.dateErrorMessage = error.message;
			this.isDateValid.emit(false);
			this.dateTimeChange.emit(null);
		}
	}

	resetDateValidation() {
		this.dateErrorMessage = "";
	}

	resetTimeValidation() {
		this.timeErrorMessage = "";
	}

	emitInvalidDate() {
		this.isDateValid.emit(false);
		this.dateTimeChange.emit(null);
	}

	/**
	 * Validates:
	 *   - Valid: Blank since some workflows don't require a date
	 *   - Invalid: When a date has not been fully typed (01/00/XXXX)
	 *   - Invalid: When a set of numbers is not a real date (99/99/9999)
	 *   - In Between: Dates must be between the min and max
	 *   - Before: Date must be before the max date
	 *   - After: Date must be after the min date
	 */
	handleDateChange($event: Event) {
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2866
		const value = ($event.target as any).value;
		const date = dayjs(value);
		this.date = value;
		this.resetDateValidation();

		if (!value) {
			this.date = null;
			this.emitValidDateTime(this.date, this.time);
			return;
		}

		try {
			isCompleteDate(value, this.dateMaskOptions.mask);
			isValidDate(date);

			const time = this.time ? timeMillisFromTimeStr(this.time) : 0;
			const compareDate = this.isExpirationDate
				? date.endOf("day")
				: date.startOf("day").add(time, PRECISION_MILLISECOND);

			this.validateDate(compareDate);

			this.date = date.format(DATE_FORMATS.DATE);
			this.emitValidDateTime(this.date, this.time);
		} catch (error) {
			this.dateErrorMessage = error.message;
			this.emitInvalidDate();
		}
	}

	validateDate(date: Dayjs) {
		isBetweenMinMaxDate(this.minDate, this.maxDate, date);
		isBeforeMaxDate(this.minDate, this.maxDate, date);
		isAfterMinDate(this.minDate, this.maxDate, date);
	}

	handleTimeChange($event: Event) {
		const value = ($event.target as HTMLInputElement).value;
		const time = dayjs(value, DATE_FORMATS.TIME, true);

		this.resetTimeValidation();

		if (!value) {
			this.time = null;
			this.emitValidDateTime(this.date, this.time);
			return;
		}

		try {
			isCompleteTime(value, this.timeMaskOptions.mask);
			isValidTime(time);

			this.time = time.format(DATE_FORMATS.TIME);
			this.emitValidDateTime(this.date, this.time);
		} catch (error) {
			this.timeErrorMessage = error.message;
			this.emitInvalidDate();
		}
	}

	/**
		Initialize IonDatetime min and max properties with the minDate and maxDate.
	*/
	async initIonDatetime() {
		if (
			environment.settings.showMobileDateTimePicker ||
			!StorageService.isWeb
		) {
			if (this.minDate && this.maxDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.min = this.minDate.format();
				this.datetime.max = this.maxDate.format();
				return;
			}

			if (this.minDate && !this.maxDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.min = this.minDate.format();
				return;
			}

			if (this.maxDate && !this.minDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.max = this.maxDate.format();
				return;
			}

			// console.warn("doesn't have minDate or maxDate");
		}
	}

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to emit the ionCancel event.
	 */
	async cancel() {
		await this.datetime.cancel();
		this.closeAccordionGroup();
	}

	closeAccordionGroup() {
		this.accordionGroup.value = null;
	}

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to resets the internal state of the datetime.
	 * We must manually reset the value since reset() does not update the value.
	 */
	async clear() {
		await this.datetime.reset();
		this.datetime.value = null;
		this.accordionDate = null;

		this.closeAccordionGroup();
	}

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to confirm the selected datetime value
	 * and update the value property.
	 *
	 * For pages that rely heavily on datetime (ex: Edit and Reprint),
	 * we call `done()` externally to save the fields since users
	 * may glance over the done button. ML-1896
	 */
	async done() {
		await this.datetime.confirm();
		this.accordionDate = this.datetime.value as string;
		const updatedDate = dayjs(this.accordionDate);
		this.dateTimeChange.emit(updatedDate);
		this.closeAccordionGroup();
	}
}

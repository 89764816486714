import { UIConfig } from "scanbot-web-sdk/@types";

export function applyPaletteConfig(
	config: UIConfig.BarcodeScannerScreenConfiguration
) {
	config.palette.sbColorPrimary = "#165d73";
	config.palette.sbColorPrimaryDisabled = "#F5F5F5";
	config.palette.sbColorNegative = "#830a0d";
	config.palette.sbColorPositive = "#16a54e";
	config.palette.sbColorWarning = "#ffc409";
	config.palette.sbColorSecondary = "#09567c";
	config.palette.sbColorSecondaryDisabled = "#F5F5F5";
	config.palette.sbColorOnPrimary = "#ffffff";
	config.palette.sbColorOnSecondary = "#ffffff";
	config.palette.sbColorSurface = "#ffffff";
	config.palette.sbColorOutline = "#EFEFEF";
	config.palette.sbColorOnSurfaceVariant = "#707070";
	config.palette.sbColorOnSurface = "#000000";
	config.palette.sbColorSurfaceLow = "#00000026";
	config.palette.sbColorSurfaceHigh = "#0000007A";
	config.palette.sbColorModalOverlay = "#000000A3";
}

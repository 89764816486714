import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
	selector: "app-nurse-info",
	templateUrl: "./nurse-info.component.html",
	styleUrls: ["./nurse-info.component.scss"],
})
export class NurseInfoComponent implements OnInit {
	@Input() isToolbar = false;
	@Input() isHeaderVisible = true;
	@Input() hasEditButton = true;
	@Input() nurses: Nurse[] = [];
	@Input() title = "Nurse Info";
	@Input() instructions = "Step 1: Gain second nurse authorization";
	@Output() editPress = new EventEmitter();

	style: string;

	constructor() {}

	ngOnInit() {
		this.style = `nurse-info2${this.isToolbar ? "--dark" : ""}`;
	}

	editNurses() {
		this.editPress.emit();
	}
}

export interface Nurse {
	id: string;
	email: string;
}

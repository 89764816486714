<app-navbar title="" (backButtonPress)="closeModal()">
</app-navbar>
<ion-content [fullscreen]="true">
	<div class="scanner">
		<div #square class="square"></div>

		<ng-container *ngIf="scannedItemDisplayDetails.length > 0">
			<ion-card class="scanner-card">
				<ion-card-header>
					<ion-card-title>Scans<ion-chip>{{ scannedItemDisplayDetails.length}}</ion-chip></ion-card-title>
				</ion-card-header>

				<ion-card-content>
					<ion-list>
						<ion-item *ngFor="let item of scannedItemDisplayDetails; let index = index">
							<ion-label><b>{{ item }}</b></ion-label>
						</ion-item>
					</ion-list>
				</ion-card-content>
				<div class="scanner-card-footer">
					<ion-button class="scanner-card-footer" expand="block" (click)="closeModal()">Done</ion-button>
				</div>
			</ion-card>
		</ng-container>

		<ng-container *ngIf="isTorchAvailable">
			<ion-button class="scanner-flashlight" (click)="toggleTorch()" expand="block"
						[fill]="isTorchOn ? 'solid' : 'outline'" color="light">
				<ion-icon slot="icon-only" name="flashlight"></ion-icon>
			</ion-button>
		</ng-container>
	</div>
</ion-content>

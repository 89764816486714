<ng-container *ngIf="patient && allowOrderValidation">
	<ion-list-header>
		<ion-label>
			Select Order
		</ion-label>
	</ion-list-header>

	<ion-item *ngIf="(!ehrOrders || ehrOrders.length === 0)">
		<ion-text class="validation-text">
			This patient has no active orders OR has an active NPO order
		</ion-text>
	</ion-item>

	<ng-container *ngIf="ehrOrders?.length > 0">
		<ion-grid class="ion-margin-bottom">
			<ion-row>
				<ion-col size="12" size-sm="4" *ngFor="let order of ehrOrdersItems; trackBy: trackByOrder; let i = index;">
					<div class="OrderMatch" [ngClass]="{ 'selected': i == selectedItem }" (click)="selectOrder(i)">
						<div class="OrderMatch-title">
							<div>{{ order.startDate }}</div>
							<ion-chip size="small" class="OrderMatch-chip" *ngIf="order.standingStatus">{{ order.standingStatus }}</ion-chip>
						</div>

						<ul class="OrderMatch-list">
							<li *ngFor="let detail of order.details">{{ detail }}</li>
						</ul>
					</div>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ng-container>

	<ion-list-header heading="secondary">
		<ion-label>
			Instructions
		</ion-label>
	</ion-list-header>

	<p>{{ selectedOrderInstructions }}</p>

	<ng-container *ngIf="validationResults2 && !isValidOrderMatch">
		<ion-text *ngIf="!anyOrderFulfillmentIsValid" class="validation-text validation-text--warning">
			<img src="assets/imgs/icon-exclamation-point.png" class="icon">
			The contents of the bottle do not match the selected order.
		</ion-text>
		<ion-text *ngIf="caloricDensityDoesNotMatchTheOrder" class="validation-text validation-text--warning">
			<img src="assets/imgs/icon-exclamation-point.png" class="icon">
			The caloric density does not match the order.
		</ion-text>
	</ng-container>

</ng-container>

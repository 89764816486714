import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { MilkBottleModel } from "src/app/models/milk.model";
import { defaultSelectOptions } from "src/app/components/default-options";
import * as dayjs from "dayjs";
import { capitalize } from "src/app/app.util";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { isPrepared } from "../../utils/inventory.util";
import { ScanningService } from "../../services/scanning.service";
import { InlineDateComponent } from "../inline-date/inline-date.component";

@Component({
	selector: "app-milkbottle-edit",
	templateUrl: "./milkbottle-edit.component.html",
	styleUrls: ["./milkbottle-edit.component.scss"],
})
export class MilkbottleEditComponent {
	@ViewChildren(InlineDateComponent) inlineDateComponents: QueryList<InlineDateComponent>

	@Input() milkBottle: MilkBottleModel;
	@Input() hasEditableVolume: boolean = false;
	@Input() hasEditablePumpDate: boolean = false;
	@Output() milkBottleChange = new EventEmitter<MilkBottleModel>();
	@Output() inputFocus = new EventEmitter<"focus" | "blur">();


	capitalize = capitalize;
	getBottleNumberText = getBottleNumberText;
	isPrepared = isPrepared;

	defaultSelectOptions = defaultSelectOptions;

	/**
	 * Returns end of today as a dayjs object
	 */
	get maxDate(): dayjs.Dayjs {
		return dayjs().endOf("day");
	}

	constructor(public scanningService: ScanningService) {}

	handleInputFocus(event: "focus" | "blur") {
		this.inputFocus.emit(event);
	}

	emit() {
		this.milkBottleChange.emit(this.milkBottle);
	}
}

import { assertExhaustive, RecipeMode } from "../app.enums";
import { MilkBottleModel } from "../models/milk.model";
import { RecipeData } from "../models/recipe.model";
import { FeedObject } from "../models/feed-object.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { Product } from "../models/product.model";

/**
 * Returns the total volume for a given set of milk bottles
 */
export const getTotalVolumeFromScannedBottles = (
	milkBottles: MilkBottleModel[]
): number => {
	const initialVolume = 0;

	const totalVolume = milkBottles.reduce(
		(accumulator, currentValue) => accumulator + currentValue.volume,
		initialVolume
	);

	return totalVolume;
};

/**
 * Returns the number of bottles that are 'Has Remaining'
 * (Bottles that have not been expended)
 */
export const getHasRemainingCount = (milkBottles: MilkBottleModel[]): number =>
	milkBottles.filter((x) => !x.expendedDate).length;

export const getHasRemainingCount2 = (feedObjects: FeedObject[]): number =>
	feedObjects.filter((x) => !x.expendedDate).length;

/**
 * @param totalVolume The calculated total volume (from the recipe or scanned bottles).
 * 		See: `getTotalVolumeFromScannedBottles()`, `getVolumeByRecipe()`.
 * @param milkBottles Used to check for bottles that 'Has Remaining'. For bottle creation, pass
 * 		an empty array.
 * @param labelQuantity The number of labels that the user has requested.
 */
export const getEvenlyDividedVolume = (params: {
	totalVolume: number;
	milkBottles: MilkBottleModel[];
	labelQuantity: number;
}) => {
	const hasRemainingCount = getHasRemainingCount(params.milkBottles);

	return params.totalVolume / (params.labelQuantity + hasRemainingCount);
};

export const getEvenlyDividedVolume2 = (params: {
	totalVolume: number;
	feedObjects: FeedObject[];
	labelQuantity: number;
}) => {
	// destructure the params
	const { totalVolume, feedObjects, labelQuantity } = params;
	const hasRemainingCount = getHasRemainingCount2(feedObjects);

	return totalVolume / (labelQuantity + hasRemainingCount);
};

export const getVolumeByRecipe = (recipe: RecipeData): number => {
	let volume = 0;

	if (!recipe) {
		return null;
	}

	switch (recipe.mode) {
		case RecipeMode.VOH:
		case RecipeMode.DV:
			volume = recipe.finalVolume;
			break;
		case RecipeMode.MP:
			volume = recipe.volume;
			break;
		default:
			console.error(
				`getVolumeByRecipe: unknown recipe mode ${recipe.mode}`
			);
			assertExhaustive(recipe.mode);
	}

	return volume;
};

export const getVolumeByRecipeOrEvenlyDivided = (params: {
	recipeData: RecipeData;
	milkBottles: MilkBottleModel[];
	labelQuantity: number;
}): number => {
	const recipeVolume = getVolumeByRecipe(params.recipeData);

	const dividedVolume = getEvenlyDividedVolume({
		totalVolume: getTotalVolumeFromScannedBottles(params.milkBottles),
		milkBottles: params.milkBottles,
		labelQuantity: params.labelQuantity,
	});

	return params.recipeData ? recipeVolume : dividedVolume;
};

export const getVolumePerBottle = (params: {
	milkBottle: MilkBottleModel;
	volumeByRecipeOrEvenlyDivided: number;
}): number => {
	const expendedVolume = 0;

	if (params.milkBottle.expendedDate) {
		return expendedVolume;
	}

	return params.volumeByRecipeOrEvenlyDivided;
};

export const getProductVolumeByRecipe = (params: {
	recipeData: RecipeData;
	labelQuantity: number;
}): number => {
	const recipeVolume = getVolumeByRecipe(params.recipeData);

	// TODO: ML-1546
	if (!recipeVolume) {
		return null;
	}

	return recipeVolume / params.labelQuantity;
};

/**
 * Returns calories of any feed object. If no feed object has any calories, returns 0.
 *
 * TODO: simplify and test
 */
export const getCaloriesFromFeedObjects = (
	feedObjects: FeedObject[]
): number => {
	for (const f of feedObjects) {
		if (f instanceof MilkBottleModel) {
			return f.calorieDensity;
		} else if (f instanceof MilkBankProductModel) {
			return f.calorieDensity;
		} else if (f instanceof Product) {
			return f.calorieDensity;
		}
	}
};

export const getFreshMilkVolume = (params: {
	recipeData: RecipeData;
	labelQuantity: number;
	volume: number;
}): number => {
	const { recipeData, labelQuantity, volume } = params;

	if (recipeData) {
		return getEvenlyDividedVolume({
			milkBottles: [],
			totalVolume: getVolumeByRecipe(recipeData),
			labelQuantity: labelQuantity,
		});
	}

	return volume;
};

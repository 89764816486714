import { Injectable } from "@angular/core";

import { InventoryService } from "./inventory.service";
import { FeedPatientListItem } from "../administer-feed/feed-patient/list-item";
import { PatientModel, PatientEhrOrderModel } from "../models/patient.model";
import {
	getMilkBottlesByListItem,
	getProductsByListItem,
	validate,
} from "../utils/feeding.util";
import { FeedMilkBottleService } from "./feed-milkbottle.service";
import { FeedProductService } from "./feed-product.service";
import { MilkBottleModel } from "../models/milk.model";
import { RestApiService } from "./rest-api.service";
import { lastValueFrom } from "rxjs";
import { FeedCombinedService } from "./feed-combined.service";

@Injectable({
	providedIn: "root",
})
export class FeedService {
	constructor(
		public http: RestApiService,
		public feedMilkBottleService: FeedMilkBottleService,
		public feedProductService: FeedProductService,
		public feedCombinedService: FeedCombinedService
	) {}

	/**
	 * Determines if we are processing milk bottles or products.
	 *
	 * @param listItems
	 * @param patient
	 * @param selectedOrder
	 */
	async feedPatient(
		listItems: FeedPatientListItem[],
		patient: PatientModel,
		selectedOrder?: PatientEhrOrderModel
	): Promise<FeedPatientCommandResult> {
		validate(listItems);

		const milkBottles = getMilkBottlesByListItem(listItems);
		const products = getProductsByListItem(listItems);

		let milkBottleFeedPromise = Promise.resolve(null);
		let productFeedPromise = Promise.resolve(null);
		let combinedFeedPromise = Promise.resolve(null);

		const isOverride = listItems.every((x) => x.isOverride);
		if (isOverride) {
			combinedFeedPromise = this.feedCombinedService.processMultipleFeeds(
				listItems,
				patient,
				selectedOrder
			);

			return combinedFeedPromise;
		}

		if (milkBottles.length > 0) {
			milkBottleFeedPromise =
				this.feedMilkBottleService.processMilkBottles(
					milkBottles,
					patient,
					selectedOrder
				);
		}

		if (products.length > 0) {
			productFeedPromise = this.feedProductService.processProducts(
				products,
				patient,
				selectedOrder
			);
		}

		const [milkBottleFeedResult, productFeedResult] = await Promise.all([
			milkBottleFeedPromise,
			productFeedPromise,
		]);

		const result: FeedPatientCommandResult = {
			...milkBottleFeedResult,
			...productFeedResult,
		};

		return result;
	}

	/**
	 * /api/v1/{tenantId}/breastfeed/administer
	 */
	logDirectBreastfeeding(payload: {
		patientId: string;
		reason: string;
		startTimestamp: string;
		leftDuration: number;
		rightDuration: number;
		latchQualityScore: number;
		// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2896
	}): Promise<any> {
		const url = "/breastfeed/administer";
		return lastValueFrom(this.http.post(url, payload));
	}
}

export interface FeedPatientCommandResult {
	milkBottleLabels: MilkBottleModel[];
	optionalMilkBottleLabels: {
		prompt: { header: string; message: string };
		milkBottle: MilkBottleModel;
	} | null;
	undo?: IUndo;
}

export type MarkMilkBottleAsAdministeredParams = {
	milkBottle: MilkBottleModel;
	patient: PatientModel;
	selectedOrder?: PatientEhrOrderModel;
} & Pick<
	Parameters<InventoryService["administerMilkBottle"]>[0],
	"isOverride" | "overrideReason" | "overrideVerifiedBy" | "reason"
>;

type IUndo = () => Promise<void>;

import { Component, Input, OnChanges } from "@angular/core";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { ProductState, ProductType } from "../../app.enums";
import { InventoryService } from "../../services/inventory.service";
import { MilkBankProductCategoryRecord, isRecalled } from "../../utils/inventory.util";
import { MilkBankProductInventoryChips, getFrozenMilkBankProduct, getPreparedMilkBankProduct, getStableMilkBankProduct, getThawedMilkBankProduct } from "./product-items";

@Component({
	selector: "app-product-inventory",
	templateUrl: "./product-inventory.component.html",
	styleUrls: ["./product-inventory.component.scss"],
})
export class ProductInventoryComponent implements OnChanges  {
	@Input() milkBankProducts: MilkBankProductModel[];

	chips: MilkBankProductInventoryChips[];

	constructor(
		public inventoryService: InventoryService
	) {}

	ngOnChanges() {
		this.chips = this.getChips(this.milkBankProducts);
	}

	getChips(milkBankProducts: MilkBankProductModel[]): MilkBankProductInventoryChips[] {
		const inventory = this.filterInventory(milkBankProducts);

		// Order here matters - This is the order displayed in the view.
		const chips = [
			...getPreparedMilkBankProduct(inventory.prepared),
			...getThawedMilkBankProduct(inventory.thawed),
			...getFrozenMilkBankProduct(inventory.frozen),
			...getStableMilkBankProduct(inventory.stable),
		];


		return chips;
	}

	filterInventory(milkBankProducts: MilkBankProductModel[]) {
		const categories = {
			frozen: [],
			stable: [],
			thawed: [],
			prepared: []
		} as MilkBankProductCategoryRecord;

		if (!milkBankProducts) {
			return categories;
		}

		// eslint-disable-next-line @typescript-eslint/prefer-for-of
		for (let i = 0; i < milkBankProducts.length; i++) {
			const milkBankProduct = milkBankProducts[i];

			if (milkBankProduct.feedType !== ProductType.Donor) {
				continue;
			}

			// Reject Recalled
			if (isRecalled(milkBankProduct)) {
				continue;
			}

			if (milkBankProduct.productState === ProductState.Frozen) {
				categories.frozen.push(milkBankProduct);
				continue;
			}

			if (milkBankProduct.productState === ProductState.Thawed) {
				categories.thawed.push(milkBankProduct);
				continue;
			}

			if (milkBankProduct.productState === ProductState.Opened) {
				categories.prepared.push(milkBankProduct);
				continue;
			}

			if (milkBankProduct.productState === ProductState.Stable) {
				categories.stable.push(milkBankProduct);
				continue;
			}
		}

		return categories;
	}
}

import {
	RecipeMode,
	RecipePageType,
	BaseType,
	ProductType,
} from "src/app/app.enums";
import { ScannedProduct } from "../services/scanning.service";
import { AdditiveCandidate } from "../modals/recipe-calculator/recipe-calculator.modal";
import { SelectedProduct } from "../modals/select-product/select-product.modal";
import { IAdditive } from "./additive.model";
import { IOrderFulfillment } from "./patient.model";

export class Recipe {
	id: string;
	calorieDensity: number;
	baseMeasuredUnit: string;
	baseName: string;
	baseProductId: string;
	baseType: BaseType;
	hasEnforcedScanning: boolean;
	hasHumanMilk: boolean;
	additives: IAdditive[];

	constructor(initial: IRecipe = {} as IRecipe) {
		Object.keys(initial).forEach((key) => {
			if (key === "recipeId") {
				this.id = initial[key];
			} else if (key === "finalCalorie") {
				this.calorieDensity = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "baseProductEnforceScanning") {
				this.hasEnforcedScanning = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "baseProductContainsHumanMilk") {
				this.hasHumanMilk = initial[key];
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface IRecipe {
	recipeId: string;
	finalCalorie: number; // calorie density
	additives: IAdditive[];
	baseInitialCalories: number;
	baseMeasuredUnit: string; // "mL"
	baseName: string;
	baseProductId: string;
	baseType: BaseType;
	baseProductEnforceScanning: boolean;
	baseProductContainsHumanMilk: boolean;
}

export interface ScannedRecipe {
	recipe: Recipe;
	additives: ScannedAdditive[];
}

export interface SelectedRecipe extends ScannedRecipe {}

export interface ScannedAdditive {
	additive: IAdditive;
	volume: number;
	volumeUnit: string;
	isScanned: boolean;
}

export interface ScannedRecipeData {
	selectedRecipes: ScannedRecipe[];
	selectedProducts: SelectedProduct[];
	scannedProducts: ScannedProduct[];
}

export interface RecipeData {
	pageType: RecipePageType;
	mode: RecipeMode;
	base: RecipeBase;
	calorieDensity: number;
	volume: number;
	finalVolume: number;
	baseAmount: number;
	scanData: ScannedRecipeData;
	additiveCandidates: AdditiveCandidate[];
	selectedFulfillment?: IOrderFulfillment;
}

export interface RecipeBase {
	baseName: string /** baseProductId uniquely identifies the base */;
	baseProductId: string;
	baseType: BaseType | ProductType;
}

export interface RecipePermissions {
	volumeOnHand: boolean;
	desiredVolume: boolean;
	manualPrep: boolean;
}

import { IAuthorizedTenant } from "../models/user-profile.model";

export const getUniqueFacilityIds = (tenants: IAuthorizedTenant[]): IAuthorizedTenant["tenantId"][] =>
	 tenants
		.map((x) =>  x.tenantId)
		.filter((id, index, arr) => arr.indexOf(id) === index); // dedupe

export const getTenantLabel = (
	tenant: IAuthorizedTenant,
	authorizedTenants: IAuthorizedTenant[]
): string => {
	const facilityIds = getUniqueFacilityIds(authorizedTenants);

	if (facilityIds && facilityIds.length > 1) {
		return `${tenant.facilityName} \u2013 ${tenant.tenantName}`;
	}

	return tenant.tenantName;
};

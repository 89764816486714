import { MilkBottleFeedPatientListItem } from "../../administer-feed/feed-patient/list-item";
import { ScannedPatientError } from "./scan-validation-error";
import { IPatientValidation, IWristbandValidation } from "./scan-validation.model";
import { MilkBottleModel } from "../../models/milk.model";

/**
 * TODO: Move this to patient-validation folder?
 *
 * This is designed to check if the patient can be changed.
 * In many (if not all) workflows, the patient cannot be changed once a feed
 * object has been scanned and added to the queue.
 *
 * In the old validator {@link RejectPatientChange}, the selected patient is
 * checked against the previously scanned milk bottles.
 *
 * According to ML-1809, once any feed object is scanned and added to the queue,
 * then the patient cannot be changed.
 */
export function RejectPatientChange2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IPatientValidation[]) {
			const [obj] = args;

			// destructure the object
			const { patient, feedObjects } = obj;

			if (feedObjects?.length > 0) {
				const mismatchedPatient = feedObjects
					.map((m) => {
						if (m instanceof MilkBottleModel) {
							return (m as MilkBottleModel).patients;
						}
					})
					.reduce((prev, curr) => prev.concat(curr), [])
					.find((x) => x.mrn !== patient.mrn);

				if (mismatchedPatient) {
					throw new ScannedPatientError(
						patient.mrn,
						mismatchedPatient
					);
				}
			}
			return originalMethod.apply(this, args);
		};
	};
}

export function RejectPatientChange() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IWristbandValidation[]) {
			const [obj] = args;
			const { wristband, listItems } = obj;

			// Scanned patient MRN
			const mrn = wristband.type === "mrn" ? wristband.value : null;

			if (listItems) {
				const mismatchedPatient = obj.listItems
					.filter((x) => x.type === "milkbottle") // we don't have patient info on products (ex: RTFs)
					.map((x: MilkBottleFeedPatientListItem) => x.milkBottle)
					.map((m) => m.patients)
					.reduce((prev, curr) => prev.concat(curr), [])
					.find((x) => x.mrn !== mrn);

				if (mismatchedPatient) {
					throw new ScannedPatientError(mrn, mismatchedPatient);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { Component, Input, OnChanges } from "@angular/core";
import {
	MilkBottleInventoryItems,
	getMilkBottleInventoryItem,
} from "./milkbottle-items";
import { InventoryCategory } from "../../app.enums";
import { IPatientInventoryCount, PatientModel } from "src/app/models/patient.model";

@Component({
	selector: "app-milkbottle-inventory",
	templateUrl: "./milkbottle-inventory.component.html",
	styleUrls: ["./milkbottle-inventory.component.scss"],
})
export class MilkBottleInventoryComponent implements OnChanges {
	@Input() patient: PatientModel;
	@Input() showHeader = true;

	chips: MilkBottleInventoryItems[];

	constructor() {}

	async ngOnChanges() {
		this.chips = this.getChips(this.patient.inventory);
	}

	getChips(inventory: IPatientInventoryCount): MilkBottleInventoryItems[] {
		const chips = [
			...getMilkBottleInventoryItem(inventory.prepared, InventoryCategory.Prepared),
			...getMilkBottleInventoryItem(inventory.fresh, InventoryCategory.Fresh),
			...getMilkBottleInventoryItem(inventory.thawed, InventoryCategory.Thawed),
			...getMilkBottleInventoryItem(inventory.frozen, InventoryCategory.Frozen),
		];

		return chips;
	}
}

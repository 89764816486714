import { Product } from "src/app/models/product.model";
import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import {
	ScanMilkBankProductValidationError,
} from "./scan-validation-error";
import { IScanProductValidation } from "./scan-validation.model";
import { StorageService } from "src/app/services/storage.service";

/**
 * Reject the product from being received if the product has a lot number that has been flagged as recalled.
 */


export function RejectRecalledProductOnReceive() {
	return (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) => {
		const originalMethod = descriptor.value;


		descriptor.value = function (...args: IScanProductValidation[]) {
			const [obj] = args;
			// destructure the object
			const { scannedObject, product, lotNumber } = obj;

			let productId: string;
			let productLotNumber: string;
			let productName: string;

			// determine if it is a scanned object or a product and lot number and get the product id, lot number, and product name
			if (scannedObject && scannedObject.product instanceof Product) {
				productId = scannedObject.product.id;
				productLotNumber = scannedObject.match.groups.lotCode;
				productName = scannedObject.product.name;
			} else if (product instanceof Product && lotNumber) {
				productId = product.id;
				productLotNumber = lotNumber;
				productName = product.name;
			}

			const recalledProducts = StorageService.configs.products.reduce((acc, product) => {
				if (product.recalledLotNumbers?.length) {
					// Create an object for each recalled lot number that includes the product name
					const recalledWithNames = product.recalledLotNumbers.map((lotNumber: any) => ({
						productId: product.id,
						productName: product.name,
						lotNumber: lotNumber
					}));
					acc.push(...recalledWithNames);
				}
				return acc;
			}, []);

			// check if the product is recalled by comparing the product id and lot number to the recalled products
			const isProductRecalled = recalledProducts.some(recalledProduct =>
				recalledProduct.productId === productId && recalledProduct.lotNumber === productLotNumber
			);

			// if the product is recalled pass the recalled product info to the error message
			if (isProductRecalled) {
				const recalledProductInfo = {
					lotNumber: productLotNumber,
					productName: productName
				};
				throw new ScanMilkBankProductValidationError(
					ScanLogType.Product_Recalled,
					modalMessage.product_recalled_not_receivable(recalledProductInfo)
				);
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { Dayjs } from "dayjs";
import { FeedLabelStatus, ProductState } from "../app.enums";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { ScannedObject, ScannedProduct } from "../services/scanning.service";
import { handleExpirationDateFromScanGroups } from "./expiration.util";
import { RecipeData } from "../models/recipe.model";

/**
 * Creates a QR code data string that represents a milk tracker milk bank product.
 * Milk Tracker QR codes start with `M|`, and
 * tracked milk bank products end with `|P`.
 *
 * @param milkBankProductId MilkBankProductModel.id
 * @returns A QR code data string that can be parsed as a milk tracker milk bank product
 */
export const createMilkBankProductQRCode = (milkBankProductId: number) =>
	`M|${milkBankProductId}|P`;

/**
 * Formatted milk type text and status.
 * See ML-361 for more discussion.
 */
export const getProductTypeText = (
	milkBankProduct: MilkBankProductModel
): string => milkBankProduct.name;

export function isInMilkBankProductArray(
	item: {
		scannedObject?: ScannedObject;
		milkBankProduct?: MilkBankProductModel;
	},
	arr: MilkBankProductModel[]
): boolean {
	let productId;
	let lotCode;
	let bottleNumber;
	let barcode;
	if (item.scannedObject) {
		productId = item.scannedObject.product.id;
		lotCode = item.scannedObject.match.groups.lotCode;
		bottleNumber = parseInt(item.scannedObject.match.groups.bottleNumber);
		barcode = item.scannedObject.match.groups.barcode;
	} else if (item.milkBankProduct) {
		productId = item.milkBankProduct.productId;
		lotCode = item.milkBankProduct.lotNumber;
		bottleNumber = item.milkBankProduct.bottleNumber;
		barcode = item.milkBankProduct.barcode;
	} else {
		throw new Error("missing item");
	}
	return !!arr.find(
		(m) =>
			m.productId === productId &&
			m.lotNumber === lotCode &&
			m.bottleNumber === bottleNumber &&
			m.barcode === barcode
	);
}

export const hasSameProductIdInArray = (
	item: {
		scannedObject?: ScannedObject;
		milkBankProduct?: MilkBankProductModel;
	},
	arr: MilkBankProductModel[]
): boolean => {
	let productId;
	if (item.scannedObject) {
		productId = item.scannedObject.product.id;
	} else if (item.milkBankProduct) {
		productId = item.milkBankProduct.productId;
	} else {
		throw Error("missing item");
	}
	console.log(`${!!arr.find((m) => m.productId === productId)}`);
	return !!arr.find((m) => m.productId === productId);
};

export const isExpended = (milkBankProduct: MilkBankProductModel): boolean =>
	!!milkBankProduct.expendedDate;

//
/**
 * In Receive Product, we want to check that the item scanned isn't duplicated.
 * manufacturer exp, lot #, and bottle # all the same.
 */
export const isInScannedProductsArray = (
	item: {
		scannedObject: ScannedObject;
	},
	arr: MilkBankProductModel[]
): boolean => {
	const lotCode = item.scannedObject.match.groups.lotCode;
	const bottleNumber = parseInt(item.scannedObject.match.groups.bottleNumber);

	const expirationDate = handleExpirationDateFromScanGroups(item.scannedObject?.match?.groups);

	return !!arr.find(
		(m) =>
			m.bottleNumber === bottleNumber &&
			m.lotNumber === lotCode &&
			m.manufacturerExpirationDate.isSame(expirationDate, "day") // Passing in day will check day, month, and year.
	);
};

/**
 * Given a ScannedProduct, return the product name or milk bank product name.
 */
export const getProductName = (scannedProduct: ScannedProduct): string => {
	if (scannedProduct.milkBankProduct) {
		return scannedProduct.milkBankProduct.name;
	}
	return scannedProduct.product.name;
};

export const getBottleNumber = (scannedProduct: ScannedProduct): string => {
	if (scannedProduct.milkBankProduct) {
		return scannedProduct.milkBankProduct.bottleNumber?.toString();
	}
	return scannedProduct.match.groups.bottleNumber;
};

export const getLotNumber = (scannedProduct: ScannedProduct): string => {
	if (scannedProduct.milkBankProduct) {
		return scannedProduct.milkBankProduct.lotNumber;
	}
	return scannedProduct.match.groups.lotCode;
};

export const isNewlyCreated = (milkBankProduct: MilkBankProductModel): boolean =>
	milkBankProduct.labelStatus === FeedLabelStatus.New;

export const isUpdated = (milkBankProduct: MilkBankProductModel): boolean =>
	milkBankProduct.labelStatus === FeedLabelStatus.Updated;

export const isPartiallyUsed = (
	milkBankProduct: MilkBankProductModel
): boolean => milkBankProduct.modifierOption === "partial use";

/**
 * Print a label for any milk bank product that is partially used AND is newly created / updated / inline thawed
 * @param recipe milk bank recipe data
 * @returns milk bank product that is partially used AND is newly created / updated / inline thawed
 */
export const getPrintableMilkBankProductLabels = (
	recipe: RecipeData
): MilkBankProductModel[] => {
	if (!recipe) {
		return [];
	}

	const milkBankProducts = getMilkBankProductFromRecipe(recipe);
	
	return milkBankProducts.filter((x) => isPartiallyUsed(x) && (isNewlyCreated(x) || isUpdated(x) || x.isInlineThawed));
}


/**
 * Thaw frozen milk bank products by setting the thawedDate and product state.
 */
export const thawMilkBankProducts = (params: {
	thawedDate: Dayjs;
	milkBankProducts: MilkBankProductModel[];
}): MilkBankProductModel[] =>
	params.milkBankProducts.map((milkBankProduct) => {
		if (milkBankProduct.productState === ProductState.Frozen) {
			milkBankProduct.thawedDate = params.thawedDate;
			milkBankProduct.productState = ProductState.Thawed;
			milkBankProduct.isInlineThawed = true;
		}

		return milkBankProduct;
	});

export const getMilkBankProductFromRecipe = (recipeData: RecipeData): MilkBankProductModel[] =>
	{
		if (!recipeData) {
			return [];
		}

		return recipeData.scanData.scannedProducts
			.map((s) => s.milkBankProduct)
			.filter((p) => p !== null);
	}

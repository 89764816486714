import { AbstractControl } from "@angular/forms";
import { FeedPatientListItem, MilkBottleFeedPatientListItem, ProductFeedPatientListItem } from "../administer-feed/feed-patient/list-item";

export function ValidateModifierSelected(isOralCare: boolean) {
	return (control: AbstractControl) => {
		const action = control.value.every((x: FeedPatientListItem) => {
			if ((x as MilkBottleFeedPatientListItem).milkBottle) {
				return (x as MilkBottleFeedPatientListItem).action;
			}

			if ((x as ProductFeedPatientListItem).product) {
				return (x as ProductFeedPatientListItem).action;
			}

			console.log("Unidentified List Item");
		});

		if (!action && !isOralCare) {
			return { invalidModifier: true };
		}

		return null;
	};
}

import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBottleModel } from "../../models/milk.model";
import { ScanMilkBottleValidationError } from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { FeedObject } from "../../models/feed-object.model";

/**
 * Return true if the calorie density of the assigned feed or milk bank product
 * matches the calorie density of the queue. If there is no object in the queue,
 * return true.
 *
 * @param feedObject
 * @param queue
 */
export const isSameCalorieDensity2 = (
	feedObject: FeedObject,
	queue: FeedObject[]
): boolean => {
	if (!queue.length) {
		return true;
	}
	return queue.every((f) => f.calorieDensity === feedObject.calorieDensity);
};

/**
 * Check if the calorie density of the milk bottle matches the calorie density
 * of the queue.
 */
export function CheckCalorieMatch2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject, feedObjects } = obj;

			// determine feed object type and check if calorie density matches the queue
			if (feedObject) {
				if (
					feedObject instanceof MilkBottleModel ||
					feedObject instanceof MilkBankProductModel
				) {
					if (!isSameCalorieDensity2(feedObject, feedObjects)) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Milk_Bottle_Density_Mismatch,
							modalMessage.milk_calorie_mismatch()
						);
					}
				} else {
					console.error(
						`CheckCalorieMatch2: feedObject is not supported.`
					);
				}
			} else {
				console.error(`CheckCalorieMatch2: feedObject is undefined`);
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function CheckCalorieMatch() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (!isSameCalorieDensity2(obj.milkBottle, obj.milkBottles)) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Density_Mismatch,
						modalMessage.milk_calorie_mismatch()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { InventoryCategory } from "../../app.enums";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";

export interface MilkBankProductInventoryChips {
	label: string;
	value: number;
	cssClass: InventoryCategory;
}

export const  getFrozenMilkBankProduct =  (
	milkBankProduct: MilkBankProductModel[]
): MilkBankProductInventoryChips[] => {
	const label = "Frozen";

	return [
		{
			label,
			value: milkBankProduct.length,
			cssClass: InventoryCategory.Frozen
		},
	];
};

export const getStableMilkBankProduct =  (
	milkBankProduct: MilkBankProductModel[]
): MilkBankProductInventoryChips[] => {
	const label = "Stable";

	return [
		{
			label,
			value: milkBankProduct.length,
			cssClass: InventoryCategory.Stable
		},
	];
};

export const getThawedMilkBankProduct = (
	milkBankProduct: MilkBankProductModel[]
): MilkBankProductInventoryChips[] => {
	const label = "Thawed";

	return [
		{
			label,
			value: milkBankProduct.length,
			cssClass: InventoryCategory.Thawed
		},
	];
};

export const getPreparedMilkBankProduct = (
	milkBankProduct: MilkBankProductModel[]
): MilkBankProductInventoryChips[] => {
	const label = "Prepared";

	return [
		{
			label,
			value: milkBankProduct.length,
			cssClass: InventoryCategory.Prepared
		},
	];
};

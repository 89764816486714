import { Component, EventEmitter, Input, Output } from "@angular/core";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { capitalize, displayDate } from "src/app/app.util";
import {
	expirationDateChipColor,
	getRelativeExpirationTime,
	isExpired,
} from "../../utils/expiration.util";
import { MilkDetailItem } from "../milk-details/milk-details.model";
import { getBottleNumberText } from "../../utils/milk-label.util";

@Component({
	selector: "app-edit-reprint-unassigned-list",
	templateUrl: "./edit-reprint-unassigned-list.component.html",
	styleUrls: ["./edit-reprint-unassigned-list.component.scss"],
})
export class EditReprintUnassignedListComponent {
	@Input() milkBankProducts?: MilkBankProductModel[];
	@Input() isExpirationVisible = true;
	@Input() hasButtons = true;

	@Output() reprint: EventEmitter<MilkBankProductModel> = new EventEmitter();
	@Output() edit: EventEmitter<MilkBankProductModel> = new EventEmitter();


	capitalize = capitalize;
	displayDate = displayDate;
	expirationDateChipColor = expirationDateChipColor;
	getBottleNumberText = getBottleNumberText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	isExpired = isExpired;

	MilkDetailItem = MilkDetailItem;

	constructor() {}


	handleClickEdit(milkBankProduct: MilkBankProductModel) {
		this.edit.emit(milkBankProduct);
	}

	handleClickReprint(milkBankProduct: MilkBankProductModel) {
		this.reprint.emit(milkBankProduct);
	}

	getStatusColor = (product: MilkBankProductModel): string => {
		const element = "milk-info__header__state";

		return `${element}--${product.productState}`;
	};
}

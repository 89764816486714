import {
	BarcodeScanResult,
	IWristband,
	MilkTrackerMilkBankProductQRCode,
	MilkTrackerMilkBottleQRCode,
} from "../services/scanning.service";

/**
 * Removes additional characters that may have been picked up from
 * the keyboard or scanner.
 */
export const formatScanData = (s: string): string => {
	/**
	 * Removes special characters from a string except for:
	 *   pipe (|)
	 *   space ( )
	 *   underscore (_)
	 *   exclamation (!)
	 */
	// eslint-disable-next-line no-useless-escape
	let result = s.replace(/[^a-zA-Z0-9|\-\s\!\_]/g, "");

	// Remove instances of "Control" followed by any character
	result = result.replace(/Control./g, "");

	// Remove instances of "NumLock"
	result = result.replace(/NumLock/g, "");

	return result;
};

/**
 * Formats scan data as BarcodeScanResult for consistency with Ionic Native
 * barcode scanner.
 */
export const createBarcodeScanResult = (
	dataString: string
): BarcodeScanResult =>
	({
		format: "QR_CODE",
		cancelled: false,
		text: formatScanData(dataString),
	}) as BarcodeScanResult;

/**
 * unified ids always have 13 characters: 4B7CG2R87VF83
 */
export const getMilkBottleQRCodeIdType = (
	data: string
):
	| MilkTrackerMilkBottleQRCode["id"]["type"]
	| MilkTrackerMilkBankProductQRCode["id"]["type"] =>
	data.length === 13 ? "unifiedId" : "guid";

/**
 * Returns a IWristband if the scan buffer matches the regex string.
 * A wristband either has an ECD or MRN on it.
 *
 * TODO: there is no real stacktrace for null values or if the regex doesn't have a group for ecd nor mrn.
 */
export const getWristband = (
	regexString: string,
	scanBufferString: string
): IWristband => {
	if (regexString == null) {
		return null;
	}

	const groups = RegExp(regexString).exec(scanBufferString)?.groups;
	const hasEcd = groups?.ecd;
	const hasMrn = groups?.mrn;

	let result: IWristband = null;
	if (hasEcd) {
		result = {
			type: "ecd",
			value: hasEcd,
		};
	} else if (hasMrn) {
		result = {
			type: "mrn",
			value: hasMrn,
		};
	}
	return result;
};

/**
* Returns a wristband id if the buffer string matches the format of a custom label.
* A custom label begins with a C| and ends with an |L.
*/
export const getCustomLabel = (regexString: string, scanBufferString: string): IWristband  => {

	if (regexString == null || !(scanBufferString.startsWith("C|") && scanBufferString.endsWith("|L"))) {
		return null;
	}
	const wristbandID = scanBufferString.substring(2, scanBufferString.length - 2);
	const groups = RegExp(regexString).exec(wristbandID)?.groups;
	const hasEcd = groups?.ecd;
	const hasMrn = groups?.mrn;

	let result: IWristband = null;
	if (hasEcd) {
		result = {
			type: "ecd",
			value: hasEcd,
		};
	} else if (hasMrn) {
		result = {
			type: "mrn",
			value: hasMrn,
		};
	}
	return result;
};

import { Injectable } from "@angular/core";
import * as _ from "lodash";

import { ContentProductType, MilkLocation } from "src/app/app.enums";
import {
	createMilkBankProductQRCodeFromUnifiedId,
	createMilkBottleQRCodeFromUnifiedId,
	getMilkTypeText,
} from "src/app/utils/milk-label.util";

import { MilkBottleModel } from "src/app/models/milk.model";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { PatientModel } from "../models/patient.model";
import { PrinterModel } from "../models/printer.model";
import { FORTIFIED_ICON, isMilkBottleFortified } from "../utils/label.util";

const LEFT_MARGIN = 20;
const RIGHT_MARGIN = 27;
const BOTTLE_NUMBER_PADDING_RIGHT = RIGHT_MARGIN - 13;

@Injectable({
	providedIn: "root",
})
export class LabelService {
	createMilkBottleLabels(params: {
		milkBottles: MilkBottleModel[];
		printer: PrinterModel;
	}) {
		return createMilkBottleLabels(params);
	}

	createMilkBankProductLabels(params: {
		milkBankProducts: MilkBankProductModel[];
		printer: PrinterModel;
	}) {
		return createMilkBankProductLabels(params);
	}

	createCustomLabel(params: {
		customLabels: CustomLabel[];
		printer: PrinterModel;
	}) {
		return createCustomLabels(params);
	}
}

export type CustomLabel = {
	patient: PatientModel;
	line1?: string;
	line2?: string;
};

enum PrintMode {
	TearOff = "T",
}

enum FieldAlignment {
	Left = "0",
	Right = "1",
	Automatic = "2",
}

/**
 * 	First number = height
 * 	Second number = width
 */
export enum FontSize {
	Small = "23,23",
	Medium = "28,28",
	Large = "32,40",
}

export enum DataMatrixSize {
	ExtraSmall = "3",
	Small = "4",
	Medium = "5",
	Large = "6",
}

export const FONT_HEIGHT = {
	SMALL: 23,
	MEDIUM: 28,
	LARGE: 32,
};

export const DATA_MATRIX_HEIGHT = {
	MEDIUM: 90, // FIXME: currently estimated
};

export interface PrintTextParams {
	fontSize: FontSize;
	position: string;
	text: string;
}

export interface PrintDataMatrixParams {
	size: DataMatrixSize;
	position: string;
	data: string;
}

interface LabelZplWriterOptions {
	printMode: PrintMode;
	printWidth: number;
	labelLength: number;
	labelShift: number;
	labelTop: number;
	orientation: "N" | "I"; // I (invert label content) and N (do not invert label content).
}

const DefaultLabelZplWriterOptions: LabelZplWriterOptions = {
	printMode: PrintMode.TearOff,
	printWidth: 406,
	labelLength: 264,
	labelShift: 0,
	labelTop: 0,
	orientation: "I",
};

export class LabelZplWriter {
	private readonly strings: string[] = [];
	public readonly options: LabelZplWriterOptions;
	private previous = {
		fontHeight: 0,
		fontWidth: 0,
	};

	constructor(params?: Partial<LabelZplWriterOptions>) {
		this.options = { ...DefaultLabelZplWriterOptions, ...params };
	}

	write(value: string) {
		this.strings.push(value);
	}

	private writeStart() {
		this.write("^XA");
	}

	private writeEnd() {
		this.write("^XZ");
	}

	private writeConfig() {
		this.write(`
			^PQ1,0,1,Y
			^MM${this.options.printMode}
			^PO${this.options.orientation}
			^PW${this.options.printWidth}
			^LL${this.options.labelLength}
			^LS${this.options.labelShift}
			^LT${this.options.labelTop}
		`);
	}

	start(): LabelZplWriter {
		this.writeStart();
		this.writeConfig();
		return this;
	}

	end(): LabelZplWriter {
		this.writeEnd();
		return this;
	}

	text(
		value: string,
		x: number,
		y: number,
		options: {
			alignment?: FieldAlignment;
			height?: number;
			width?: number;
			textBox?: TextBlock;
		}
	): LabelZplWriter {
		const alignment = options?.alignment ?? FieldAlignment.Left;
		const height = options?.height ?? this.previous.fontHeight;
		let textBox = "";

		if (options?.height) {
			this.previous.fontHeight = options.height;
		}
		const width = options?.width ?? this.previous.fontWidth;
		if (options?.width) {
			this.previous.fontWidth = options.width;
		}
		if (options?.textBox) {
			textBox = `^TB${options.textBox.rotation},${options.textBox.width},${options.textBox.height}`;
		}

		this.write(`
			^FO${x},${y},${alignment}
			^A0N,${height},${width}
			${textBox}
			^FH\\^FD${value}^FS
		`);
		return this;
	}

	box(
		x: number,
		y: number,
		options: { width: number; height: number; thickness: number }
	) {
		this.write(`
			^FO${x},${y}^GB${options.width},${options.height},${options.thickness}^FS
		`);
		return this;
	}

	dataMatrix(
		data: string,
		x: number,
		y: number,
		params: { alignment?: FieldAlignment; size: number }
	) {
		const alignment = params?.alignment ?? FieldAlignment.Left;

		this.write(`
			^FO${x},${y},${alignment}^BXN,${params.size},200,0,0,1,_,1
			^FH^FD${data}^FS
		`);
	}

	isFortifiedIcon(
		x: number,
		y: number,
		params: { alignment?: FieldAlignment }
	) {
		const alignment = params?.alignment ?? FieldAlignment.Left;
		this.write(`
			^FO${x},${y},${alignment}
			${FORTIFIED_ICON}
		`);
	}

	hasFedIcon(x: number, y: number, params: { alignment?: FieldAlignment }) {
		const alignment = params?.alignment ?? FieldAlignment.Left;
		const image = [
			"^GFA,109,128,4,:Z64:eJxjYEACjEDM3IDA7AcYGJiAmMWBgYFDgYFBpoCBwYCDgfEBPwN3A/MCeQbGD3wNTA+AfAcGAwmIPEgdSD1IH0g/2CwozciAAQC6hw6l:28C3",
		].join("");

		this.write(`
			^FO${x},${y},${alignment}
			${image}
		`);
	}

	toString() {
		return this.strings.join("").replace(/\t/g, "");
	}
}

interface BaseLabelSlots {
	expiration: string;
	milkTypeText: string;
	caloriesAndBottleNumber: { left: string; right: string };
	patientLastName: string;
	dataMatrix: string;
	isFortified: boolean;
	hasFed: boolean;
}

interface BaseLabelParams {
	writer: LabelZplWriter;
	contentArea: ContentArea;
	slots: BaseLabelSlots;
}

class ContentArea {
	readonly beginY: number;
	readonly beginX: number;
	readonly endX: number;
	readonly endY: number;
	readonly width: number;

	constructor(
		public topMargin: number,
		public bottomMargin: number,
		public rightMargin: number,
		public leftMargin: number,
		printWidth: number,
		labelHeight: number
	) {
		this.beginY = topMargin;
		this.beginX = leftMargin;
		this.endX = printWidth - rightMargin;
		this.endY = labelHeight - bottomMargin;
		this.width = printWidth - rightMargin - leftMargin;
	}
}

function createMilkBottleLabel(
	milkBottle: MilkBottleModel,
	printer: PrinterModel
) {
	switch (milkBottle.location) {
		case MilkLocation.Hospital:
			return createMilkBottleHospitalLabel(milkBottle, printer);
		// If the bottle location is Home, it was created by the Print Parent Label workflow
		// and hasn't been received yet.
		case MilkLocation.Home:
			return createMilkBottleParentLabel(milkBottle, printer);
	}
}

// (ML-2947) Removes text after max pixel count is reached
// Maximizes the amount of characters visible on a line
function optimizeWordWrap( text: string, pixelLimit: number ) {
	const DEFAULT_CHAR_SIZE = PIXEL_CHAR_MAP.W //default to largest width "W"
	const buffer = DEFAULT_CHAR_SIZE / 2; //create a margin of error for pixel size estimation
	let currentPixelCount = 0;

	for(const [index, char] of Array.from(text).entries()) {
		const pixelCharSize = PIXEL_CHAR_MAP[char];
		currentPixelCount += pixelCharSize ?? DEFAULT_CHAR_SIZE;
		if(currentPixelCount >= pixelLimit - buffer) {
			return text.substring(0, index);
		}
	}

	return text;
}

// (ML-2947) calculates an optmized size of the name text box using the dob + mrn string
// To be used only when a line displays name, dob, and MRN
function calculateNamePixelLength(dobAndMrnText: string, pixelLengthOverride?: number) {
	const DEFAULT_TEXT_LENGTH = 16 // MM/DD/YY <7 char MRN as default>
	const NUMERIC_CHAR_SIZE = PIXEL_CHAR_MAP.ZERO //All numeric characters have shown to be equal pixel width
	const TEXT_SIZE_PIXELS = pixelLengthOverride ?? 190;
	return TEXT_SIZE_PIXELS - ((dobAndMrnText.length - DEFAULT_TEXT_LENGTH)*NUMERIC_CHAR_SIZE)
}

function createMilkBottleParentLabel(
	milkBottle: MilkBottleModel,
	printer: PrinterModel
) {
	const params: MilkBottleParentLabelParams = {
		bottleNumber: `#${milkBottle.bottleNumber}`,
		lastName: milkBottle.patients[0].lastName.toUpperCase(),
		dataMatrix: createMilkBottleQRCodeFromUnifiedId(milkBottle.unifiedId),
		patients: milkBottle.patients.map((p) => ({
			firstName: p.firstName,
			dobAndMrn: `${p.dateOfBirth.format("MM/DD/YY")} ${p.mrn}`,
		})),
	};

	return createMilkBottleParentLabelFromParams(params, printer);
}

interface MilkBottleParentLabelParams {
	bottleNumber: string;
	lastName: string;
	dataMatrix: string;
	patients: { firstName: string; dobAndMrn: string }[];
}

function createMilkBottleParentLabelFromParams(
	params: MilkBottleParentLabelParams,
	printer: PrinterModel
) {
	const writer = new LabelZplWriter({
		labelShift: printer.xOffset,
		labelTop: printer.yOffset,
	});
	const contentArea = new ContentArea(
		15,
		10,
		RIGHT_MARGIN,
		LEFT_MARGIN,
		writer.options.printWidth,
		writer.options.labelLength
	);

	writer.start();

	const align = new AlignmentState(contentArea.beginX, contentArea.beginY);

	// data matrix
	writer.dataMatrix(params.dataMatrix, contentArea.endX, contentArea.beginY, {
		size: 5,
		alignment: FieldAlignment.Right,
	});

	// instructions
	align.lineHeight = 28;
	writer.text("write pump date & time", align.x, align.y, {
		height: 23,
		width: 23,
	});

	// pump date blank
	align.nextLine();
	align.lineHeight = 28;
	writer.text("         /           /         ", align.x, align.y, {
		height: 28,
		width: 28,
	});
	align.nextLine();
	align.lineHeight = 5;
	writer.box(align.x, align.y, {
		width: contentArea.endX - DataMatrixSizeInfo[5].width - 22,
		height: 0,
		thickness: 2,
	});

	// pump time blank
	align.nextLine();
	align.lineHeight = 28;
	writer
		.text("         :          ", align.x, align.y, {
			height: 28,
			width: 28,
		})
		.text(
			params.bottleNumber,
			contentArea.endX - DataMatrixSizeInfo[5].width - 22,
			align.y + 4,
			{ height: 23, width: 23, alignment: FieldAlignment.Right }
		);
	align.nextLine();
	align.lineHeight = 8;
	writer.box(align.x, align.y, {
		width: contentArea.endX - DataMatrixSizeInfo[5].width - 22,
		height: 0,
		thickness: 2,
	});

	// patient last name
	align.nextLine();
	align.lineHeight = 31;
	writer.text(params.lastName, align.x, align.y, {
		height: 28,
		width: 28,
		textBox: {
			rotation: BlockRotation.Normal,
			width: 365,
			height: 28,
		},
	});

	// patients
	align.nextLine();
	align.lineHeight = 22;
	params.patients.forEach((patient) => {
		const namePixelSize = calculateNamePixelLength(patient.dobAndMrn)
		writer
			.text(optimizeWordWrap(patient.firstName, namePixelSize), align.x, align.y, {
				height: 23,
				width: 23,
				textBox: {
					rotation: BlockRotation.Normal,
					width: namePixelSize,
					height: 23,
				},
			})
			.text(patient.dobAndMrn, contentArea.endX, align.y, {
				alignment: FieldAlignment.Right,
			});
		align.nextLine();
	});

	writer.end();

	return writer.toString();
}

function createMilkBottleHospitalLabel(
	milkBottle: MilkBottleModel,
	printer: PrinterModel
) {
	if (!milkBottle.patients || milkBottle.patients.length === 0) {
		throw new Error("no patients");
	}

	const patients = milkBottle.patients.map((p) => ({
		firstName: p.firstName,
		dobAndMrn: `${p.dateOfBirth.format("MM/DD/YY")} ${p.mrn}`,
	}));

	const products = _.chain(milkBottle.contents)
		.filter(
			(c) =>
				c.contentProductType !== ContentProductType.EBM &&
				c.contentProductType !== ContentProductType.Water
		)
		.uniqWith(
			(left, right) =>
				left.contentProductCode === right.contentProductCode &&
				((left.mfgLotNumber == null && right.mfgLotNumber == null) ||
					left.mfgLotNumber === right.mfgLotNumber)
		)
		.map(
			(p) =>
				`${p.contentProductCode}${
					p.mfgLotNumber != null ? ` ${p.mfgLotNumber}` : ""
				}`
		)
		.value();

	const baseLabelSlots: BaseLabelSlots = {
		expiration: `EXP ${milkBottle.expirationDate.format("MM/DD/YY HH:mm")}`,
		milkTypeText: getMilkTypeText(milkBottle),
		caloriesAndBottleNumber: {
			left: milkBottle.calorieDensity
				? `${milkBottle.calorieDensity} kcal/oz`
				: "",
			right: `#${milkBottle.bottleNumber}`,
		},
		patientLastName: milkBottle.patients[0].lastName.toUpperCase(),
		dataMatrix: createMilkBottleQRCodeFromUnifiedId(milkBottle.unifiedId),
		isFortified: isMilkBottleFortified(milkBottle),
		hasFed: !!milkBottle.usedDate,
	};

	// decide which milk bottle label types to print and overflow to another label if neccesary
	const labels: string[] = []; // we might generate two labels if we don't have enough space to display everything
	const wordWrappedProducts = splitStringArrayAtMaxChars(products, 23);
	const hasProducts = products.length > 0;
	const firstLabelType: MilkBottleHospitalLabelType =
		patients.length < 3 ? "patientsandproducts" : "patientsonly";

	switch (firstLabelType) {
		case "patientsonly": {
			labels.push(
				createMilkBottleHospitalLabelFromParams({
					type: "patientsonly",
					baseLabelSlots,
					patients,
					products: [],
					printer,
				})
			);

			// print all of the products on the second label
			if (hasProducts) {
				labels.push(
					createMilkBottleHospitalLabelFromParams({
						type: "productsonly",
						baseLabelSlots,
						patients: [],
						products: wordWrappedProducts,
						printer,
					})
				);
			}
			break;
		}
		case "patientsandproducts": {
			labels.push(
				createMilkBottleHospitalLabelFromParams({
					type: "patientsandproducts",
					baseLabelSlots,
					patients,
					products:
						wordWrappedProducts.length > 0
							? wordWrappedProducts.slice(0, 2)
							: [], // display two rows of products
					printer,
				})
			);

			// print the rest of the products on the second label if necessary
			if (wordWrappedProducts.length > 2) {
				labels.push(
					createMilkBottleHospitalLabelFromParams({
						type: "productsonly",
						baseLabelSlots,
						patients: [],
						products: wordWrappedProducts.slice(2),
						printer,
					})
				);
			}
			break;
		}
	}

	return labels.join("");
}

/**
 * "word wraps" the source string array into multiple lines.
 * This is used to make sure the product information is split into multiple lines
 * that fit on the label without overflowing;
 *
 * @param source The string array to split
 * @param maxChars The maxiumum amount of characters in a line which should not be exceeded
 * @returns An array of strings with less than maxChars per array item
 */
function splitStringArrayAtMaxChars(
	source: string[],
	maxChars: number
): Array<Array<string>> {
	let currentArrayChars = 0;
	const lines: Array<Array<string>> = [];
	let currentArray: string[] = [];
	lines.push(currentArray);

	for (const value of source) {
		if (currentArrayChars + value.length <= maxChars) {
			// we can fit the current value in the current array, push it
			currentArray.push(value);
		} else {
			// we can't fit the current value in the current array
			currentArray = [value]; // create a new current array
			lines.push(currentArray);
			currentArrayChars = 0;
		}
		currentArrayChars += value.length;
	}

	return lines;
}

type MilkBottleHospitalLabelType =
	| "patientsonly"
	| "patientsandproducts"
	| "productsonly";

interface MilkBottleHospitalLabelParams {
	type: MilkBottleHospitalLabelType;
	baseLabelSlots: BaseLabelSlots;
	patients: { firstName: string; dobAndMrn: string }[];
	products: Array<Array<string>>;
	printer: PrinterModel;
}

function createMilkBottleHospitalLabelFromParams({
	baseLabelSlots,
	...params
}: MilkBottleHospitalLabelParams) {
	const writer = new LabelZplWriter({
		labelShift: params.printer.xOffset,
		labelTop: params.printer.yOffset,
	});
	const contentArea = new ContentArea(
		15,
		10,
		RIGHT_MARGIN,
		LEFT_MARGIN,
		writer.options.printWidth,
		writer.options.labelLength
	);

	writer.start();

	const { dataMatrixEndY } = writeBaseLabel({
		writer,
		contentArea,
		slots: baseLabelSlots,
	});
	const align = new AlignmentState(contentArea.beginX, dataMatrixEndY + 6);

	if (params.type === "patientsandproducts") {
		// patients
		align.lineHeight = 22;
		params.patients.forEach((patient) => {
			const namePixelSize = calculateNamePixelLength(patient.dobAndMrn)
			writer
				.text(optimizeWordWrap(patient.firstName, namePixelSize), align.x, align.y, {
					height: 23,
					width: 23,
					textBox: {
						rotation: BlockRotation.Normal,
						width: namePixelSize,
						height: 23,
					},
				})
				.text(patient.dobAndMrn, contentArea.endX, align.y, {
					alignment: FieldAlignment.Right,
				});
			align.nextLine();
		});

		// additive contents
		if (params.products.length > 2) {
			throw new Error(
				"this label type can only display two rows of products"
			);
		}

		if (params.products[0].length > 0) {
			const additiveContentsBeginY = contentArea.endY - 58;
			align.y = additiveContentsBeginY;

			// line separator
			align.lineHeight = 9;
			writer.box(align.x, align.y, {
				width: contentArea.width,
				height: 0,
				thickness: 2,
			});

			align.nextLine();
			align.lineHeight = 22;
			params.products.forEach((p) => {
				const joined = p.join(", ");
				writer.text(joined, align.x, align.y, {
					height: 23,
					width: 23,
				});
				align.nextLine();
			});
		}
	}

	if (params.type === "productsonly") {
		// line separator
		align.lineHeight = 9;
		writer.box(align.x, align.y, {
			width: contentArea.width,
			height: 0,
			thickness: 2,
		});

		align.nextLine();
		align.lineHeight = 22;
		params.products.forEach((p) => {
			const joined = p.join(", ");
			writer.text(joined, align.x, align.y, { height: 23, width: 23 });
			align.nextLine();
		});
	}

	if (params.type === "patientsonly") {
		align.lineHeight = 22;
		params.patients.forEach((patient) => {
			const namePixelSize = calculateNamePixelLength(patient.dobAndMrn)
			writer
				.text(optimizeWordWrap(patient.firstName, namePixelSize), align.x, align.y, {
					height: 23,
					width: 23,
				})
				.text(patient.dobAndMrn, contentArea.endX, align.y, {
					alignment: FieldAlignment.Right,
				});
			align.nextLine();
		});
	}

	writer.end();

	return writer.toString();
}

/**
 * ML-2689
 *
 * The printer truncate function was omitting the baby's
 * second first name. For example: Ava Grace LastName -> Ava LastName
 *
 * For safety reasons, we are removing the space(s) in first names so that
 * names are not omitted. AvaGrace LastName
 */
const removeSpaces = (firstName: string): string => firstName.split(" ").join("")

const DataMatrixSizeInfo = {
	5: {
		width: 122,
		height: 122,
	},
};

function writeBaseLabel({ writer, contentArea, slots }: BaseLabelParams) {
	const align = new AlignmentState(contentArea.beginX, contentArea.beginY);

	// data matrix
	writer.dataMatrix(slots.dataMatrix, contentArea.endX, contentArea.beginY, {
		size: 5,
		alignment: FieldAlignment.Right,
	});

	// header (expiration)
	align.lineHeight = 30;
	writer.text(slots.expiration, align.x, align.y, { height: 28, width: 30 });

	// sub header (milk type)
	align.nextLine();
	align.lineHeight = 29;
	writer.text(slots.milkTypeText, align.x, align.y, {
		height: 23,
		width: 23,
	});

	// sub header two (kcal/oz, bottle number)
	align.nextLine();
	align.lineHeight = 28;
	writer
		.text(slots.caloriesAndBottleNumber.left, align.x, align.y, {
			height: 23,
			width: 23,
		})
		.text(
			slots.caloriesAndBottleNumber.right,
			contentArea.endX -
				DataMatrixSizeInfo[5].width -
				BOTTLE_NUMBER_PADDING_RIGHT,
			align.y,
			{ alignment: FieldAlignment.Right }
		);

	// has products icon
	if (slots.isFortified) {
		const middleOfSubHeaderTwo =
			(contentArea.endX -
				DataMatrixSizeInfo[5].width -
				BOTTLE_NUMBER_PADDING_RIGHT) /
			2;
		writer.isFortifiedIcon(middleOfSubHeaderTwo + 4, align.y - 3, {
			alignment: FieldAlignment.Automatic,
		});
	}

	if (slots.hasFed) {
		const middleOfSubHeaderTwo =
			(contentArea.endX -
				DataMatrixSizeInfo[5].width -
				BOTTLE_NUMBER_PADDING_RIGHT) /
			2;

		writer.hasFedIcon(middleOfSubHeaderTwo + 55, align.y - 6, {
			alignment: FieldAlignment.Automatic,
		});
	}

	// header two
	align.nextLine();
	align.y += 7; // make sure long patient last names clear the qr code
	align.lineHeight = 28;
	writer.text(slots.patientLastName, align.x, align.y, {
		height: 28,
		width: 28,
		textBox: {
			rotation: BlockRotation.Normal,
			width: 365,
			height: 28,
		},
	});

	align.nextLine();

	return {
		lastNextLineY: align.y,
		dataMatrixEndY: contentArea.beginY + DataMatrixSizeInfo[5].height,
	};
}

class AlignmentState {
	constructor(
		public x: number,
		public y: number,
		public lineHeight?: number
	) {}

	nextLine() {
		this.y = this.y += this.lineHeight;
	}
}

function createMilkBottleLabels(params: {
	milkBottles: MilkBottleModel[];
	printer: PrinterModel;
}) {
	return params.milkBottles
		.map((m) => createMilkBottleLabel(m, params.printer))
		.join("");
}

function createMilkBankProductLabels(params: {
	milkBankProducts: MilkBankProductModel[];
	printer: PrinterModel;
}) {
	return params.milkBankProducts
		.map((p) => createMilkBankProductLabel(p, params.printer))
		.join("");
}

function createCustomLabels(params: {
	customLabels: CustomLabel[];
	printer: PrinterModel;
}) {
	return params.customLabels
		.map((customLabel) => createCustomLabel(customLabel, params.printer))
		.join("");
}

function createMilkBankProductLabel(
	milkBankProduct: MilkBankProductModel,
	printer: PrinterModel
): string {
	const params: MilkBankProductLabelParams = {
		expiration: `EXP ${milkBankProduct.expirationDate.format(
			"MM/DD/YY HH:mm"
		)}`,
		dataMatrix: createMilkBankProductQRCodeFromUnifiedId(
			milkBankProduct.unifiedId
		),
		stateAndTypeText: `${milkBankProduct.productState.toUpperCase()} ${milkBankProduct.productType?.toUpperCase()}`,
		bottleNumber: milkBankProduct.bottleNumber
			? `#${milkBankProduct.bottleNumber}`
			: "--",
		calories: "", // for future use, leave blank for now -- 8/25/2022 Jay Douglass
		manufacturer: milkBankProduct.productManufacturer,
		productName: `PRODUCT: ${milkBankProduct.name}`,
		lotNumber: `LOT: ${milkBankProduct.lotNumber}`,
	};

	return createMilkBankProductLabelFromParams(params, printer);
}

interface MilkBankProductLabelParams {
	expiration: string;
	dataMatrix: string;
	stateAndTypeText: string;
	bottleNumber: string;
	calories: string;
	manufacturer: string;
	productName: string;
	lotNumber: string;
}

function createMilkBankProductLabelFromParams(
	params: MilkBankProductLabelParams,
	printer: PrinterModel
) {
	const writer = new LabelZplWriter({
		labelShift: printer.xOffset,
		labelTop: printer.yOffset,
	});
	const contentArea = new ContentArea(
		15,
		10,
		RIGHT_MARGIN,
		LEFT_MARGIN,
		writer.options.printWidth,
		writer.options.labelLength
	);

	writer.start();

	const align = new AlignmentState(contentArea.beginX, contentArea.beginY);

	// data matrix
	writer.dataMatrix(params.dataMatrix, contentArea.endX, contentArea.beginY, {
		size: 5,
		alignment: FieldAlignment.Right,
	});

	// expiration
	align.lineHeight = 30;
	writer.text(params.expiration, align.x, align.y, { height: 28, width: 30 });

	// state + type
	align.nextLine();
	align.lineHeight = 29;
	writer.text(params.stateAndTypeText, align.x, align.y, {
		height: 23,
		width: 23,
	});

	// bottle number and kcal/oz
	align.nextLine();
	writer.text(
		`${params.bottleNumber}  ${params.calories}`,
		align.x,
		align.y,
		{ height: 23, width: 23 }
	);

	// begin product info on second half of label
	const dataMatrixEndY = contentArea.beginY + DataMatrixSizeInfo[5].height;
	align.y = dataMatrixEndY + 3;

	// manufacturer
	align.lineHeight = 28;
	writer.text(params.manufacturer, align.x, align.y, {
		height: 28,
		width: 28,
	});

	// line separator
	align.nextLine();
	align.lineHeight = 11;
	writer.box(align.x, align.y, {
		width: contentArea.width,
		height: 0,
		thickness: 2,
	});

	// product name
	align.nextLine();
	align.lineHeight = 31;
	writer.text(params.productName, align.x, align.y, {
		height: 28,
		width: 28,
	});

	// lot number
	align.nextLine();
	align.lineHeight = 31;
	writer.text(params.lotNumber, align.x, align.y, { height: 28, width: 28 });

	writer.end();

	return writer.toString();
}

function createCustomLabel(params: CustomLabel, printer: PrinterModel) {
	const writer = new LabelZplWriter({
		labelShift: printer.xOffset,
		labelTop: printer.yOffset,
	});
	const contentArea = new ContentArea(
		15,
		10,
		RIGHT_MARGIN,
		LEFT_MARGIN,
		writer.options.printWidth,
		writer.options.labelLength
	);

	writer.start();

	const align = new AlignmentState(contentArea.beginX, contentArea.beginY);

	// last name
	align.lineHeight = 35;
	writer.text(params.patient.lastName, align.x, align.y, {
		height: 35,
		width: 35,
	});

	// first name, dob, mrn
	align.nextLine();
	align.lineHeight = 22;
	const patientDobMRN = `${params.patient.dateOfBirth.format("MM/DD/YY")} ${params.patient.mrn}`;
	const namePixelSize = calculateNamePixelLength(patientDobMRN)
	writer
		.text(optimizeWordWrap(params.patient.firstName, namePixelSize), align.x, align.y, {
			height: 23,
			width: 23,
			textBox: {
				rotation: BlockRotation.Normal,
				width: namePixelSize,
				height: 23,
			},
		})
		.text(
			patientDobMRN,
			contentArea.endX,
			align.y,
			{
				alignment: FieldAlignment.Right,
			}
		);

	// data matrix
	writer.dataMatrix(
		`C|${params.patient.mrn}|L`,
		contentArea.beginX,
		contentArea.endY - DataMatrixSizeInfo[5].height,
		{
			size: 5,
		}
	);

	// line1 and line 2
	align.y = contentArea.beginY + 115;
	align.lineHeight = 35;
	writer.text(params.line1 ?? "", contentArea.endX, align.y, {
		height: 35,
		width: 35,
		alignment: FieldAlignment.Right,
	});
	align.nextLine();
	writer.text(`${params.line2}`, contentArea.endX, align.y, {
		alignment: FieldAlignment.Right,
	});

	writer.end();

	return writer.toString();
}

/**
 * https://support.zebra.com/cpws/docs/zpl/TB_Command.pdf
 */
interface TextBlock {
	rotation: BlockRotation;
	width: number; // Block width in dots
	height: number; // Block height in dots
}

enum BlockRotation {
	Normal = "N",
	Rotate = "R", // Rotate 90 degrees clockwise
	Invert = "I", // Invert 180 degrees
	Bottom = "B", // Read from bottom up-270 degrees
}

type PixelChar = Record<string, number>;

// Estimated pixel sizes for swiss-721 Bold font used in ZPL labels
const PIXEL_CHAR_MAP: PixelChar = {
	"ZERO": 11,
	"A": 13,
	"a": 10,
	"B": 13,
	"b": 11,
	"C": 12,
	"c": 10,
	"D": 13,
	"d": 11,
	"E": 11,
	"e": 11,
	"F": 11,
	"f": 6,
	"G": 13,
	"g": 11,
	"H": 14,
	"h": 11,
	"I": 6,
	"i": 6,
	"J": 10,
	"j": 6,
	"K": 13,
	"k": 10,
	"L": 11,
	"l": 6,
	"M": 17,
	"m": 17,
	"N": 14,
	"n": 11,
	"O": 13,
	"o": 11,
	"P": 13,
	"p": 11,
	"Q": 13,
	"q": 11,
	"R": 13,
	"r": 7,
	"S": 12,
	"s": 9,
	"T": 11,
	"t": 6,
	"U": 14,
	"u": 11,
	"V": 12,
	"v": 10,
	"W": 18,
	"w": 15,
	"X": 13,
	"x": 10,
	"Y": 13,
	"y": 10,
	"Z": 11,
	"z": 9,
	" ": 7,
	"-": 21,
	"_": 11,
	"/": 7,
};

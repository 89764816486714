import { capitalize, getCaloricDensityText } from "../../app.util";
import { PatientEhrOrderModel } from "../../models/patient.model";

/**
 * Standing means the order is active (no end date).
 */
export const getStandingStatus = (order: PatientEhrOrderModel): string => {
	if (order.standingStatus) {
		return order.standingStatus;
	}

	if (!order.endDate) {
		return "Standing";
	}

	return "";
};

export const formatContents = (ehrOrder: PatientEhrOrderModel): string => {
	if (ehrOrder.contents) {
		return `${ehrOrder.base} ${getCaloricDensityText(
			ehrOrder.calorie
		)}: ${ehrOrder.contents}`;
	}

	return `${ehrOrder.base} ${getCaloricDensityText(ehrOrder.calorie)}`;
};

export const formatFeedingDuration = (
	ehrOrder: PatientEhrOrderModel
): string => {
	const route = capitalize(ehrOrder.route);
	const volumeWithUnit = ehrOrder.amount
		? `${ehrOrder.amount}${ehrOrder.measuredUnit}`
		: "";

	// If route and volumeWithUnit have a value put a colon between them
	// if not, just display the one that has a value with no colon
	let routeVolumeDisplay = "";
	if (route && volumeWithUnit) {
		routeVolumeDisplay = `${route}: ${volumeWithUnit}`;
	} else {
		routeVolumeDisplay = route || volumeWithUnit;
	}

	if (ehrOrder.duration) {
		return `${routeVolumeDisplay}, over ${ehrOrder.duration}`;
	}

	return routeVolumeDisplay;
};

/**
 * Returns an array of strings that are used to display the order details.
 * This includes the contents, feeding duration, and feeding interval.
 */
export const getOrderDetails = (order: PatientEhrOrderModel): string[] => [
	formatContents(order),
	formatFeedingDuration(order),
	order.interval,
];

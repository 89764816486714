import {
	Component,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
} from "@angular/core";
import { StorageService } from "../../services";
import {
	FeedPatientListItem,
	FeedPatientListItemAction,
	ProductFeedPatientListItem,
} from "../../administer-feed/feed-patient/list-item";
import { defaultSelectOptions } from "../default-options";
import { Nurse } from "../nurse-info/nurse-info.component";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { PatientModel } from "../../models/patient.model";
import { MilkBottleModel } from "src/app/models/milk.model";
import { ValidateModifierSelected } from "../../validations/modifier-selected.validator";
import { ValidateOtherReason } from "src/app/validations";
import {
	faClock,
	faUtensils,
	faIndustry,
	faRectangleList,
} from "@fortawesome/free-solid-svg-icons";
import { getProductColor } from "../product-info-collapsed/product-info-collapsed.component";
import { ProductState } from "../../app.enums";
import { getCaloricDensityText, displayDate } from "../../app.util";
import { getFormattedExpirationDate } from "../../utils/expiration.util";

export const OTHER = "Other";

@Component({
	selector: "app-feed-manual-entry",
	templateUrl: "./feed-manual-entry.component.html",
	styleUrls: ["./feed-manual-entry.component.scss"],
})
export class FeedManualEntryComponent implements OnInit, OnChanges {
	@Input() patient: PatientModel;
	@Input() listItems: FeedPatientListItem[];
	@Input() hasPermissionMessage = false;
	@Input() secondNurse: Nurse = null;
	@Input() isOralCare: boolean;

	bottleNumberSearch: string;

	defaultSelectOptions = defaultSelectOptions;

	manualEntryForm: FormGroup;

	getBottleNumberText = getBottleNumberText;
	getProductColor = getProductColor;
	getCaloricDensityText = getCaloricDensityText;
	displayDate = displayDate;

	faClock = faClock;
	faUtensils = faUtensils;
	faIndustry = faIndustry;
	faRectangleList = faRectangleList;

	get reasons() {
		return [...(StorageService.configs?.overrideReasons || []), OTHER];
	}

	get hasSecondNurse(): boolean {
		return !!this.manualEntryForm.get("secondNurse").value;
	}

	get items(): FormArray {
		return this.manualEntryForm.get("items") as FormArray;
	}

	get reason(): string {
		return (this.manualEntryForm.get("reason")).value;
	}

	get otherReason(): string {
		return this.manualEntryForm.get("otherReason").value;
	}

	get isOtherReason() {
		return this.reason === OTHER;
	}

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit(): void {
		this.buildForm();
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.secondNurse) {
			this.setSecondNurse(changes.secondNurse.currentValue);
		}
	}

	/**
	 * We're keeping `secondNurse` in the form because it's
	 * required from this view to continue. This also
	 * appropriately nukes the second nurse value when
	 * a user leaves the form.
	 *
	 * Excluding:
	 *  - MRN: read only field, set directly from the `patient`
	 * 		   form validation for disabled fields has known issues:
	 *  	   https://github.com/angular/angular/issues/11447
	 */
	buildForm() {
		this.manualEntryForm = this.formBuilder.group({
			items: this.formBuilder.array(
				this.listItems,
				Validators.compose([
					ValidateModifierSelected(this.isOralCare),
					Validators.required,
				])
			),
			reason: this.formBuilder.control(null, Validators.required),
			otherReason: this.formBuilder.control(null, ValidateOtherReason()),
			secondNurse: this.formBuilder.control(null, Validators.required),
		});
		this.setOtherReasonValidation();
	}

	/**
	 * Disabling the field nukes all of the validation.
	 * Once we have a valid value, we can safely disable
	 * the field since it's been entered and users will not
	 * have a way to remove the second nurse id.
	 */
	setSecondNurse(secondNurse: Nurse) {
		const field = this.manualEntryForm.get("secondNurse");

		field.setValue(secondNurse.id);
	}

	/**
	 * When the reason is "Other", "Other Reason" is required.
	 */
	setOtherReasonValidation() {
		this.manualEntryForm.get("reason").valueChanges.subscribe(() => {
			this.manualEntryForm
				.get("otherReason")
				.setValidators(ValidateOtherReason());
			this.manualEntryForm.get("otherReason").updateValueAndValidity();
		});
	}

	processForm(): FeedPatientListItem[] {
		try {
			const listItems: FeedPatientListItem[] = this.items.value.map(
				(feed: FeedPatientListItem) => {
					const listItem = feed;
					listItem.action = this.isOralCare ? "oral-care" : feed.action;
					listItem.isOverride = true;
					listItem.resultMessage = this.isOtherReason
						? `Other: ${this.otherReason}`
						: this.reason;
					listItem.secondNurse = this.secondNurse;
					return listItem;
				}
			);

			return listItems;
		} catch (error) {
			console.log("Error processing manual entry form", error);
		}
	}

	/**
	 * Sets the action items for Products
	 *
	 * The user can only click “Has Remaining” on one item;
	 * if they do, any other bottles should be marked “Feed All"
	 *
	 * For Oral Care, the action is always "oral-care" and users
	 * do not see the option to set an action.
	 */
	setProductActionItem(
		e: Event,
		scannedItem: FeedPatientListItem,
		action: FeedPatientListItemAction
	) {
		e.stopPropagation();

		if (action === "has remaining") {
			for (const item of this.listItems.filter(
				(i) => i.key !== scannedItem.key
			)) {
				item.action = "feed all";
			}
		}

		scannedItem.action = action;

		this.items.updateValueAndValidity();
	}

	/**
	 * Update a milk bottle's "action" in FeedPatientListItem
	 */
	setMilkBottleActionItem(
		$event: { option: string; milk?: MilkBottleModel },
		index: number
	) {
		const item = this.items.at(index);
		let modifier = $event.option;

		if (this.isOralCare) {
			modifier = "oral-care";
		}

		item.patchValue({
			...item.value,
			action: modifier,
		});

		this.items.updateValueAndValidity();
	}

	public isValidForm() {
		return this.manualEntryForm?.valid;
	}

	public reset() {
		this.manualEntryForm?.reset();
	}

	handleRemoveProduct(index: number) {
		(this.manualEntryForm.controls.items as FormArray).removeAt(index);
	}

	handleRemoveMilkBottle(milkBottle: MilkBottleModel, index: number) {
		(this.manualEntryForm.controls.items as FormArray).removeAt(index);
	}

	/**
	 * Because we're working with products that haven't been received yet,
	 * we need to manually look up the expiration dates so that
	 * it displays appropriate in the accordion.
	 */
	getExpirationDate(item: ProductFeedPatientListItem): string {
		if (!item?.productState) {
			console.warn("product state does not exist.");
			return;
		}

		switch (item.productState) {
			case ProductState.Thawed:
				return getFormattedExpirationDate(
					item.thawedDate,
					StorageService.expirationPolicy.thawedDbm
				);
			case ProductState.Opened:
				return getFormattedExpirationDate(
					item.openedDate,
					StorageService.expirationPolicy.formula
				);
			default:
				return null;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	trackByIndex(index: number, _: any) {
		return index;
	}

	logForm() {
		console.log("this.manualEntryForm :>> ", this.manualEntryForm);
	}
}

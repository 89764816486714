<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="cancel()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">select milk</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
	<ion-toolbar color="primary">
		<ion-searchbar type="text"
					   debounce="1000"
					   [(ngModel)]="searchInput"
					   showClearButton="true"
					   placeholder="Search by bottle number, product lot number or product name"
					   (ionClear)="handleClearSearch()"
					   (ionInput)="handleMilkBottleSearch()"
					   >
		</ion-searchbar>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<ion-list *ngIf="isItemAvailable">
		<ion-item *ngFor="let item of filteredList"
				  button (click)="select(item)"
				  lines="full">
			<ion-label class="ion-text-wrap" slot="start">
				#{{ getBottleNumberText(item.bottleNumber) }} {{getFeedTypeText(item)}}
				<p>{{ item.id }}</p>
			</ion-label>
			<ion-text class="ion-text-wrap" slot="end">{{getRelativeExpirationTime(item.expirationDate)}}</ion-text>
		</ion-item>
	</ion-list>
	<ion-list *ngIf="!isItemAvailable">
		<ion-item *ngFor="let item of list"
				  button (click)="select(item)"
				  lines="full">
			<ion-label class="ion-text-wrap" slot="start">
				#{{ getBottleNumberText(item.bottleNumber) }} {{getFeedTypeText(item)}}
				<p>{{ item.id }}</p>
			</ion-label>
			<ion-text class="ion-text-wrap" slot="end">{{getRelativeExpirationTime(item.expirationDate)}}</ion-text>
		</ion-item>
	</ion-list>
	<ion-button expand="block" (click)="handleViewMoreClicked()" *ngIf="hasMoreBottles" class="action-btn">
		<span>View More</span>
	</ion-button>
</ion-content>

import { AbstractControl } from "@angular/forms";

/**
 * Validates:
 *   - Contain only numbers
 *   - Number must be greater than 0
 */
export function ValidateVolume() {
	return (control: AbstractControl) => {
		if (!control || !control.value) {
			return null;
		}

		const volume = control.value;

		const isValid = /^\d+$/.test(volume);
		if (!isValid) {
			return { invalidVolume: true };
		}

		const minimumVolume = 0;
		if (parseInt(volume) < minimumVolume) {
			return { invalidVolume: true };
		}

		return null;
	};
}

import dayjs from "dayjs";
import { MilkBottleModel } from "src/app/models/milk.model";
import { Product } from "src/app/models/product.model";
import { nanoid } from "nanoid";
import { Nurse } from "src/app/components/nurse-info/nurse-info.component";
import { ProductState, ProductType } from "../../app.enums";

/** Feed Patient allows scanning of MT milk bottles and RTF products */
export type FeedPatientListItemAction =
	| "feed all"
	| "has remaining"
	| "oral-care"
	| null;
export type FeedPatientListItem =
	| MilkBottleFeedPatientListItem
	| ProductFeedPatientListItem;

interface IFeedPatientListItem {
	/** ui list key */
	key: string;
	action: FeedPatientListItemAction | null;
	isOverride: boolean;
	resultMessage: string;
	secondNurse: Nurse;
}

export class MilkBottleFeedPatientListItem implements IFeedPatientListItem {
	type = "milkbottle" as const;
	action: FeedPatientListItemAction | null;
	milkBottle: MilkBottleModel;
	isOverride: boolean;
	resultMessage: string;
	secondNurse: Nurse;

	get isRtf() {
		return this.milkBottle.contents.every((c) => c.contentProductType === ProductType.RTF);
	}

	get key() {
		return `milkbottle/${this.milkBottle.id}`;
	}

	constructor(milkBottle: MilkBottleModel) {
		this.milkBottle = milkBottle;
	}
}

export class ProductFeedPatientListItem implements IFeedPatientListItem {
	type = "product" as const;
	/** The user might scan the same product multiple times, so we generate a random id (nanoid) instead of
	 * using the product id.
	 */
	id: string; // nanoid
	action: FeedPatientListItemAction | null;
	product: Product;
	upc?: string;
	lotNumber: string | null;
	bottleNumber: number | null;
	barcodeText: string | null;
	manufacturerExpirationDate: dayjs.Dayjs;
	isOverride: boolean;
	resultMessage: string;
	secondNurse: Nurse;
	productState: ProductState;
	thawedDate: dayjs.Dayjs | null;
	openedDate: dayjs.Dayjs | null;

	get isRtf() {
		return this.product.type === ProductType.RTF;
	}

	get key() {
		return `product/${this.id}`;
	}

	get manufacturerExpirationDateString() {
		return this.manufacturerExpirationDate?.format();
	}

	constructor(params: {
		product: Product;
		lotNumber?: string;
		manufacturerExpirationDate: dayjs.Dayjs;
		bottleNumber?: number;
		barcodeText?: string;
		productState?: ProductState;
		thawedDate?: dayjs.Dayjs;
		openedDate?: dayjs.Dayjs;
		action?: FeedPatientListItemAction;
	}) {
		this.id = nanoid(5);
		this.product = params.product;
		this.lotNumber = params.lotNumber;
		this.bottleNumber = params.bottleNumber;
		this.barcodeText = params.barcodeText;
		this.manufacturerExpirationDate = params.manufacturerExpirationDate;
		this.productState = params.productState;
		this.thawedDate = params.thawedDate || null;
		this.openedDate = params.openedDate || null;
		this.action = params.action || null;
	}
}

import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import {
	AlertInput,
	IonModal,
	ModalController,
	Platform,
} from "@ionic/angular";

import {
	faDungeon,
	faHospital,
	faHospitalUser,
	faPrint,
	faRightFromBracket,
	faScrewdriverWrench,
	faUser,
} from "@fortawesome/free-solid-svg-icons";
import { datadogLogs, StatusType } from "@datadog/browser-logs";

import { environment } from "src/environments/environment";
import buildNumber from "src/environments/build-number.json";
import datadogConfig from "src/environments/datadog.json";

import { AuthService } from "src/app/services/auth.service";
import { StorageService } from "src/app/services/storage.service";

import { BaseService } from "./services/base.service";
import {
	IAuthorizedTenant,
	UserProfileModel,
} from "./models/user-profile.model";
import { SelectItemModal } from "./modals/select-item/select-item.modal";
import { PrinterModel } from "./models/printer.model";
import { getTenantLabel } from "./utils/tenant.util";

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"],
})
export class AppComponent {
	@ViewChild(IonModal) modal: IonModal;

	isDebug: boolean;
	isMenuDisabled: boolean;

	get user() {
		return StorageService?.user;
	}

	get tenant() {
		return StorageService?.activeTenant;
	}

	get preferredTenant() {
		return StorageService?.preferredTenant;
	}

	get authorizedTenants() {
		return StorageService?.user?.authorizedTenants;
	}

	/**
	 * If the user has no last saved/used printer, update the current printer to
	 * the one saved in local storage. If there is printer stored in local
	 * storage and also no last saved/used printer, display “None” instead of a
	 * printer name.
	 */
	get printer() {
		return StorageService?.lastUsedCachedPrinter;
	}

	StorageService = StorageService;
	faHospital = faHospital;
	faHospitalUser = faHospitalUser;
	faPrint = faPrint;
	faRightFromBracket = faRightFromBracket;
	faUser = faUser;
	faScrewdriverWrench = faScrewdriverWrench;
	faDungeon = faDungeon;

	getTenantLabel = getTenantLabel;

	constructor(
		private platform: Platform,
		private authService: AuthService,
		public modalCtrl: ModalController,
		public base: BaseService,
		private storage: StorageService, // instantiate/inject
		public router: Router
	) {
		this.isDebug = !environment.production;

		this.initializeApp();
	}

	async initializeApp() {
		console.log("Initializing app");
		this.platform.ready().then(async () => {
			StorageService.isWeb =
				!this.platform.is("ios") && !this.platform.is("android");

			// store app configs
			StorageService.appConfigs =
				await this.authService.getAppConfigWithFallback();

			this.initializeDatadog();
			this.initializeDevTools();

			// if the route is "print-preview", then we don't want to load the configs
			if (this.router.url == "/print-preview") {
				return;
			}

			// If already logged in, set necessary data (set user profile, set tenant, set configs)
			// This only really applies to dev builds if you are navigating within the app
			if (StorageService.authToken) {
				try {
					await this.authService.setUserProfile(); // retrieve user profile and store it
					await this.checkAuthorizedTenants(StorageService.user);
					await StorageService.setTenant();
				} catch (e) {
					/*
						There's a chance that the user's authKey expired. The user should be logged out
						by the token interceptor, so we don't need to do anything here.
					*/
					console.error(e);
				}
			}
		});
	}

	initializeDatadog() {
		if (datadogConfig.enabled) {
			datadogLogs.init({
				clientToken: datadogConfig.clientToken,
				forwardErrorsToLogs: true,
				sessionSampleRate: 100,
				service: datadogConfig.service,
			});

			datadogLogs.logger.setLevel(
				datadogConfig.minimumLogLevel as StatusType
			);

			datadogLogs.setGlobalContext({
				env: environment.type,
				isProduction: environment.production,
				site: datadogConfig.site,
				version: buildNumber.GIT_SHA.substring(0, 7),
				platforms: this.platform.platforms(),
				baseUrl: environment.baseUrl,
			});

			datadogLogs.logger.debug("client initialized");
		}
	}

	/** Internal developer tools */
	initializeDevTools() {
		// simulate a scan
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		(window as any).scan = (value) => {
			setTimeout(() => {
				[...value].forEach((key) =>
					document.dispatchEvent(
						new KeyboardEvent("keydown", { key })
					)
				);
			}, 1500);
		};

		(window as any).scanmb = (value) => {
			setTimeout(() => {
				[...`M|${value}|B`].forEach((key) =>
					document.dispatchEvent(
						new KeyboardEvent("keydown", { key })
					)
				);
			}, 1500);
		};

		(window as any).scanmbp = (value) => {
			setTimeout(() => {
				[...`M|${value}|P`].forEach((key) =>
					document.dispatchEvent(
						new KeyboardEvent("keydown", { key })
					)
				);
			}, 1500);
		};
	}

	logout() {
		this.base.logout();
	}

	/**
	 * Show select printer modal when the user clicks on the current printer
	 * item in the menu.
	 */
	async handleSelectPrinterPressed() {
		const modal = await this.base.modalCtrl.create({
			component: SelectItemModal,
			componentProps: {
				title: "Select Printer",
				items: StorageService.printers,
				searchProperties: {
					title: "name",
					subtitle: "",
					endText: "",
				},
			},
			cssClass: "fullscreen-modal",
		});

		modal.onDidDismiss().then((data) => {
			const selectedPrinter: PrinterModel = data?.data;
			if (selectedPrinter) {
				this.base.authService.updateUserProfile({
					currentPrinterId: selectedPrinter.id,
				});
				StorageService.lastUsedCachedPrinter = selectedPrinter;

				console.log(
					`PrintLabelComponent.handleSelectPrinterPress onDidDismiss: ${JSON.stringify(
						selectedPrinter,
						null,
						2
					)}`
				);
			} else {
				console.log(
					`PrintLabelComponent.handleSelectPrinterPress onDidDismiss: no data passed`
				);
			}
		});

		return await modal.present();
	}

	getPreferredTenantLabel(preferredTenant: IAuthorizedTenant, authorizedTenants: IAuthorizedTenant[]) {
		return preferredTenant ? getTenantLabel(preferredTenant, authorizedTenants) : "None"
	}

	/**
	 * Show an alert where the user can select a tenant from a radio list of
	 * authorized tenants. The selected tenant is then set as the default tenant.
	 */
	async selectTenant() {
		const options: AlertInput[] = [];

		for (const [i, tenant] of this.authorizedTenants.entries()) {
			const label = getTenantLabel(tenant, this.authorizedTenants);

			const option: AlertInput = {
				name: `radio${i + 1}`, // i starts at 0, and selected tenant is index radio1
				type: "radio",
				label,
				value: `${JSON.stringify(tenant)}`,
				checked:
					tenant.tenantId === StorageService.activeTenant?.tenantId,
			};
			options.push(option);
		}

		const alert = await this.base.alerts.createAlert({
			header: "Select Tenant",
			message: "Select the tenant you would like to use",
			inputs: options,
			backdropDismiss: false,
			buttons: [
				{
					text: "Select",
					handler: async (value: string) => {
						const tenant: IAuthorizedTenant = JSON.parse(value);
						StorageService.saveTenant(tenant);
						StorageService.configs =
							await this.authService.getTenantConfigs(
								tenant.tenantId
							);
					},
				},
			],
		});

		await alert.present();
	}

	/**
	 * Show an alert where the user can select a tenant from a radio list of
	 * authorized tenants. The selected tenant is then set as the default tenant.
	 */
	async selectPreferredTenant() {
		const options: AlertInput[] = [];
		for (const [i, tenant] of this.authorizedTenants.entries()) {
			const label = getTenantLabel(tenant, this.authorizedTenants);

			const option: AlertInput = {
				name: `radio${i + 1}`,
				type: "radio",
				label,
				value: tenant.tenantId,
				checked: tenant.isPreferredTenant,
			};
			options.push(option);
		}

		const alert = await this.base.alerts.createAlert({
			header: "Set your home unit",
			message: "This setting will be remembered every time you login.",
			inputs: options,
			backdropDismiss: false,
			buttons: [
				{
					text: "Select",
					handler: async (tenantId: IAuthorizedTenant["tenantId"]) => {
						StorageService.user =
							await this.authService.updateUserProfile({
								preferredTenantId: tenantId,
							});

						StorageService.preferredTenant =
							StorageService.user.authorizedTenants.find(
								(x) =>
								x.tenantId ===
									StorageService.user.preferredTenantId
							);
					},
				},
			],
		});

		await alert.present();
	}

	/**
	 * If a user has an invalid authKey
	 * 	  the user is redirected to the login page
	 * If the user does not have an authKey
	 * 	  take the user to /portal since that route will handle the redirect.
	 */
	goToAdminPortal() {
		const userProfile = StorageService.user;

		const authenticatedRoute =
			environment.baseUrl +
			"/portal/account/app-auth/" +
			userProfile.authKey;
		const portalRoute = environment.baseUrl + "/portal";

		const route = userProfile.authKey ? authenticatedRoute : portalRoute;
		window.open(route);
	}

	/**
	 * If the user has no authorized tenants, display a message and cancel the login
	 *
	 * TODO: This is duplicated in {@link LoginPage} and {@link LoginCrosswalkPage}
	 */
	async checkAuthorizedTenants(user: UserProfileModel) {
		if (user.authorizedTenants && user.authorizedTenants.length === 0) {
			datadogLogs.logger.info(
				`user has no authorized tenants: ${user.email}`
			);
			await this.base.alerts.presentAlert({
				header: "Login Failed",
				message: [
					"You do not have access to any units in MilkTracker. </br></br>",
					"Please verify with hospital IT support that you have been added to the appropriate access group and try log in again.",
				].join(""),
			});
			throw Error("User has no authorized tenants");
		}
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBottleModel } from "../../models/milk.model";
import { getRelativeReceived } from "../../app.util";
import {
	getBottleNumberText,
	getMilkTypeText,
	getStatusColor,
} from "../../utils/milk-label.util";
import {
	FeedModifierEvent,
	ModifierOption,
} from "../milk-modifiers/milk-modifiers.component";
import { ScannedObject } from "../../services/scanning.service";
import { isExpired } from "../../utils/expiration.util";
import { FeedObject } from "../../models/feed-object.model";
import { Product } from "../../models/product.model";
import { getFeedObjectColor, isWater } from "../../utils/feed-info.util";

@Component({
	selector: "app-feed-info",
	templateUrl: "./feed-info.component.html",
	styleUrls: ["./feed-info.component.scss"],
})
export class FeedInfoComponent implements OnInit {
	@Input() feedObjects: FeedObject[];

	@Input() isPartiallyUsable = false;
	@Input() isWaterAvailable = true;
	@Input() hasButtons = true;
	@Input() instructions = "Step 2: Scan a base (EBM, DM, or RTF)";
	@Input() options: ModifierOption[];

	@Output() modifierPress = new EventEmitter<FeedModifierEvent>();
	@Output() selectWaterPress = new EventEmitter<boolean>();
	@Output() selectMilkPress = new EventEmitter<ScannedObject>();
	@Output() removeButtonPressed = new EventEmitter<FeedObject>();

	getMilkTypeText = getMilkTypeText;
	getRelativeReceived = getRelativeReceived;
	getStatusColor = getStatusColor;
	isExpired = isExpired;
	getBottleNumberText = getBottleNumberText;
	getFeedObjectColor = getFeedObjectColor;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	isProduct(object): boolean {
		return object instanceof Product;
	}

	getProduct(feed): Product {
		return feed as Product;
	}

	async handleRemoveButtonPressed(feed: FeedObject) {
		this.removeButtonPressed.emit(feed);
	}

	selectFeedModifier(option: ModifierOption, feedObject: FeedObject) {
		this.modifierPress.emit({
			option,
			feedObject,
		});
	}

	removeFeed(feedObject: FeedObject) {
		this.removeButtonPressed.emit(feedObject);
	}

	selectMilk() {
		this.selectMilkPress.emit();
	}

	/**
	 * TODO: implement
	 * Allow for water to be selected as a feed (base).
	 * Propagate the event to the parent component.
	 */
	handleWaterSelected() {
		this.selectWaterPress.emit(true);
	}

	// TODO: rename to getContentCodes
	getContents = (milk: MilkBottleModel): string =>
		milk.contents
			.map((x) => x.contentProductCode)
			.filter((id, index, arr) => arr.indexOf(id) === index) // dedupe
			.join(", ");

	/**
	 * Feed modifiers are only available if the flag {@link isPartiallyUsable}
	 * is set to true and the feed is not water.
	 *
	 * @param feed
	 */
	hasFeedModifiers(feed: FeedObject): boolean {
		return this.isPartiallyUsable && !isWater(feed);
	}
}

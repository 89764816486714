import { AbstractControl } from "@angular/forms";

export function ValidateOtherReason() {
	return (control: AbstractControl) => {
		if (!(control?.root.get("reason")?.value === "Other")) {
			return null;
		}

		return control?.value?.length > 0
				? null
				: { invalidModifierOption: true };
	};
}

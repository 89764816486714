<div class="LogoutWarning">
	<div class="LogoutWarning-body">
		<div class="LogoutWarning-icon">
			<fa-icon [icon]="faClock" [fixedWidth]="true" slot="start"></fa-icon>
		</div>
		<div class="LogoutWarning-heading">
			<h2>You Will Be Logged Out Soon</h2>
		</div>
		<div class="LogoutWarning-body">
			<p>For your security, you will be logged out when you have not been active for a certain amount of time.</p>
		</div>
		<div class="LogoutWarning-timer">
			{{ countdownClock }}
		</div>
	</div>

	<div class="LogoutWarning-footer">
		<ion-button fill="outline" (click)="logout()">Logout</ion-button>
		<ion-button fill="solid" (click)="confirm()">Continue Session</ion-button>
	</div>
</div>

<app-navbar title="" (backButtonPress)="closeModal()">
</app-navbar>
<ion-content [fullscreen]="true">
	<div class="scanner">
		<div #square class="square"></div>

		<ng-container *ngIf="isTorchAvailable">
			<ion-button class="scanner-flashlight" (click)="toggleTorch()" expand="block"
						[fill]="isTorchOn ? 'solid' : 'outline'" color="light">
				<ion-icon slot="icon-only" name="flashlight"></ion-icon>
			</ion-button>
		</ng-container>
	</div>
</ion-content>

import * as dayjs from "dayjs";

import { ModalType } from "./app.constants";
import { displayDate } from "./app.util";
import { PatientModel } from "./models/patient.model";
import { MilkBottleModel } from "./models/milk.model";
import { MilkBankProductModel } from "./models/milk-bank-product.model";
import { ScannedProduct } from "./services/scanning.service";
import {
	IAuthorizedTenant,
	UserProfileModel,
} from "./models/user-profile.model";
import { FeedObjectModel } from "./models/feed-object.model";

export interface IModalMessage {
	header: string;
	message: string;
	modalType: ModalType;
}

export const modalMessage = {
	//* CUSTOM LABEL
	custom_label_mismatch: () => ({
		header: "",
		message:
			"The item scanned is not a Patient ID Band. Please scan an ID band to continue.",
		modalType: ModalType.Error,
	}),
	//* FORM VALIDATIONS
	form_validation_milk_not_found: (bottleNumber: string): IModalMessage => ({
		header: "Error",
		message: `Milk bottle not found based on bottle number ${bottleNumber}.`,
		modalType: ModalType.Error,
	}),
	form_validation_mrn_mismatch: (params: {
		mrn: string;
		patient: PatientModel;
	}): IModalMessage => ({
		header: "Error",
		message: `Entered MRN ${params.mrn} does not match patient mrn ${params.patient.mrn}.`,
		modalType: ModalType.Error,
	}),
	// MILK
	assigned_milk_scanned: (): IModalMessage => ({
		header: "Assigned Bottle Scanned",
		message:
			"Thaw Product can only be used to thaw products that have not yet been assigned to a patient. Please use the Thaw Patient's Milk workflow.",
		modalType: ModalType.Info,
	}),
	milk_already_received: (): IModalMessage => ({
		header: "Already Received",
		message:
			"The scanned item has already been received into hospital inventory.",
		modalType: ModalType.Info,
	}),
	milk_calorie_mismatch: (): IModalMessage => ({
		header: "Mismatched Density",
		message:
			"The milk scanned has a different caloric density than the previously scanned milk and cannot be combined or fortified together.",
		modalType: ModalType.Warning,
	}),
	milk_cannot_be_thawed: (milkBottle: MilkBottleModel): IModalMessage => ({
		header: "Not Frozen",
		message: `This bottle contains milk that is in a ${milkBottle.milkState} state and cannot be thawed. To edit milk state, use the Edit & Reprint labels workflow.`,
		modalType: ModalType.Info,
	}),
	milk_combine: (milkBottle: MilkBottleModel): IModalMessage => ({
		header: "Cannot Combine",
		message: `This bottle's contents do not allow it to be combined with the previously scanned bottle #(${milkBottle.bottleNumber}).`,
		modalType: ModalType.Warning,
	}),
	milk_discharged: (milkBottle: MilkBottleModel): IModalMessage => ({
		header: "Discharged",
		message: `This bottle was discharged from hospital inventory on ${displayDate(
			milkBottle.dischargeDate
		)}. Use the Receive Parents Milk workflow to re-receive it into hospital inventory.`,
		modalType: ModalType.Info,
	}),
	milk_duplicate: (feed: FeedObjectModel): IModalMessage => {
		let message = "This label has already been scanned.";

		if (feed?.bottleNumber) {
			message = `This label has already been scanned (#${feed.bottleNumber}).`;
		}

		return {
			header: "Duplicate",
			message,
			modalType: ModalType.Info,
		};
	},
	milk_expended: (milkBottle: MilkBottleModel): IModalMessage => ({
		header: "Expended",
		message: `This bottle was expended as of (${displayDate(
			milkBottle.expendedDate
		)}). If it was marked expended in error, please use the edit and reprint workflow.`,
		modalType: ModalType.Info,
	}),
	milk_expired: (expirationDate: dayjs.Dayjs): IModalMessage => ({
		header: "Expired",
		message: `This milk expired on ${displayDate(
			expirationDate
		)} and cannot be used.`,
		modalType: ModalType.Error,
	}),
	milk_fed: (): IModalMessage => ({
		header: "Already Fed",
		message: "This milk bottle has already been fed.",
		modalType: ModalType.Info,
	}),

	milk_fortified_cannot_be_frozen: (): IModalMessage => ({
		header: "Fortified Milk",
		message: "Milk that is fortified cannot be frozen.",
		modalType: ModalType.Warning,
	}),
	//* NURSE
	nurse_duplicate: (user: UserProfileModel): IModalMessage => ({
		header: "Duplicate Nurse ID",
		message: `Scanned ID is the same as the current user: ${user.email}. Please scan another second Nurse ID for confirmation.`,
		modalType: ModalType.Warning,
	}),
	nurse_invalid: (): IModalMessage => ({
		header: "Error",
		message: "Invalid second nurse ID.",
		modalType: ModalType.Info,
	}),
	//* PATIENT
	patient_duplicate: (): IModalMessage => ({
		header: "Duplicate Patient",
		message:
			"The patient scanned has already been entered. Verify patients listed and scan/select additional patients if needed.",
		modalType: ModalType.Error,
	}),
	patient_not_found_with_mrn: (
		mrn: string,
		tenant: IAuthorizedTenant
	): IModalMessage => ({
		header: "Patient Not Found",
		message: `The patient scanned (${mrn}) cannot be found in the ${tenant.tenantName}. Please contact your administrator.`,
		modalType: ModalType.Warning,
	}),
	patient_required: (): IModalMessage => ({
		header: "",
		message:
			"The item scanned is not a Patient ID Band. Please scan an ID Band to continue.",
		modalType: ModalType.Info,
	}),
	patient_change_disabled: (): IModalMessage => ({
		header: "Cannot Change Patient",
		message: "Cannot change patient after feeds have been scanned.",
		modalType: ModalType.Warning,
	}),
	//* PRODUCT
	product_defective: (
		milkBankProduct: MilkBankProductModel
	): IModalMessage => ({
		header: "Defective",
		message: `Current hospital policy does not allow use of this product because it was received with the following defect: (${milkBankProduct.defectiveReason}). Please discard this product and contact your administrator.`,
		modalType: ModalType.Error,
	}),
	water_already_selected: (): IModalMessage => ({
		header: "Water Already Selected",
		message:
			"Cannot add any other base because water has already been selected as the base.",
		modalType: ModalType.Info,
	}),
	product_discarded: (
		milkBankProduct: MilkBankProductModel
	): IModalMessage => ({
		header: "Discarded",
		message: `This product is defective and cannot be used. Records show it was discarded on (${milkBankProduct.discardedDate}).`,
		modalType: ModalType.Error,
	}),
	product_expended: (): IModalMessage => ({
		header: "Expended",
		message:
			"This product was expended. If it was marked expended in error, please use the edit and reprint workflow.",
		modalType: ModalType.Info,
	}),
	product_expended_with_date: (
		milkBankProduct: MilkBankProductModel
	): IModalMessage => ({
		header: "Expended",
		message: `This product was expended as of ${displayDate(
			milkBankProduct.expendedDate
		)}. If it was marked expended in error, please use the edit and reprint workflow.`,
		modalType: ModalType.Info,
	}),
	product_expired: (expirationDate: dayjs.Dayjs): IModalMessage => ({
		header: "Expired",
		message: `This product expired on ${displayDate(
			expirationDate
		)} and can no longer be used.`,
		modalType: ModalType.Info,
	}),
	product_mismatch_scan: (): IModalMessage => ({
		header: "Mismatched Product",
		message:
			"The product scanned is a different product from the selected product.",
		modalType: ModalType.Warning,
	}),
	product_frozen_required: () => ({
		header: "",
		message:
			"Once a frozen product is scanned, other products in a non-frozen state can no longer be scanned for assignment at the same time.",
		modalType: ModalType.Warning,
	}),
	product_not_received: (): IModalMessage => ({
		header: "Not Received",
		message:
			"This product has not been received yet. Please use the receive product workflow.",
		modalType: ModalType.Info,
	}),
	product_not_received_error: (): IModalMessage => ({
		header: "Not Received",
		message:
			"This product has not been received yet. Please use the receive product workflow.",
		modalType: ModalType.Error,
	}),
	product_receive_required: (): IModalMessage => ({
		header: "Unreceived",
		message:
			"This product cannot be used until it is received via the receive product workflow. If you do not have access to receive products, please contact your administrator.",
		modalType: ModalType.Warning,
	}),
	product_thaw_required: (
		milkBankProduct: MilkBankProductModel
	): IModalMessage => ({
		header: "",
		message: `This product is in a ${milkBankProduct.productState} state and cannot be thawed. To edit state, use the Edit & Reprint Unassigned workflow.`,
		modalType: ModalType.Info,
	}),
	product_unassigned: (): IModalMessage => ({
		header: "Unassigned",
		message:
			"The product scanned has not been assigned. To use this product, it must be assigned via the Assign Product workflow.",
		modalType: ModalType.Warning,
	}),
	product_mismatch: (): IModalMessage => ({
		header: "Mismatched Product",
		message:
			"The milk scanned is a different product from the previously scanned milk(s) and cannot be combined or fortified together.",
		modalType: ModalType.Warning,
	}),
	product_frozen: (): IModalMessage => ({
		header: "Frozen",
		message:
			"This product is in a frozen state. Use the thaw product workflow before assigning.",
		modalType: ModalType.Warning,
	}),
	product_recalled: ({ lotNumber, productName }): IModalMessage => ({
		header: "Product Recalled",
		message: `The item you scanned cannot be used because it contains a recalled lot number ${lotNumber} of ${productName}.`,
		modalType: ModalType.Error,
	}),
	product_recalled_not_receivable: ({
		lotNumber,
		productName,
	}): IModalMessage => ({
		header: "Product Recalled",
		message: `The item cannot be received because it contains a recalled lot number ${lotNumber} of ${productName}.`,
		modalType: ModalType.Error,
	}),
	product_additive_not_assignable: (productName: string): IModalMessage => ({
		header: "",
		message: `${productName} is used for fortification and cannot be assigned to a patient. Please scan this product directly during fortification in the Recipe Calculator.`,
		modalType: ModalType.Info,
	}),
	//* SCANNING
	scan_duplicate: (): IModalMessage => ({
		header: "Duplicate",
		message: "This barcode has already been scanned.",
		modalType: ModalType.Warning,
	}),
	scan_mrn_mismatch: (
		patient: PatientModel,
		milkBottle: MilkBottleModel
	): IModalMessage => ({
		header: "Mismatch",
		message: `The patient (MRN: ${patient.mrn} | ${patient.lastName}, ${patient.firstName} | ${patient.dateOfBirth}) does not match the MRN on the milk label scanned (MRN: ${milkBottle.patients[0].mrn}). Verify correct patient and correct milk.`,
		modalType: ModalType.Error,
	}),
	scan_unknown_milk: (): IModalMessage => ({
		header: "Unidentified",
		message:
			"MilkTracker is unable to identify the scanned object as an assigned bottle. If you continue to receive this message, please contact your administrator.",
		modalType: ModalType.Info,
	}),
	scan_unknown_product: (): IModalMessage => ({
		header: "Unidentified",
		message:
			"MilkTracker is unable to identify the scanned object as a product. If you continue to receive this message, please contact your administrator.",
		modalType: ModalType.Info,
	}),
	scan_product_invalid: (scannedProduct: ScannedProduct): IModalMessage => ({
		header: "Invalid Product",
		message: `${scannedProduct.product.name} is not a selected product.`,
		modalType: ModalType.Info,
	}),
	scan_unknown: (): IModalMessage => ({
		header: "Unknown Barcode",
		message:
			"MilkTracker is unable to identify the scanned object. If you continue to receive this message, please contact your administrator.",
		modalType: ModalType.Warning,
	}),
	scan_patient_mismatch: (
		mismatchedPatient: PatientModel,
		patient: PatientModel
	): IModalMessage => ({
		header: "",
		message: `The patient (MRN: ${mismatchedPatient.mrn} | ${
			mismatchedPatient.lastName
		}, ${mismatchedPatient.firstName} | ${displayDate(
			mismatchedPatient.dateOfBirth
		)}) does not match the MRN on the milk label scanned (MRN: ${patient.mrn}). Use the back button to leave the workflow and start over to ensure correct patient and correct milk.`,
		modalType: ModalType.Error,
	}),
	//* PAGE SPECIFIC
	receive_product_expired: (expirationDate: dayjs.Dayjs): IModalMessage => ({
		header: "Expired",
		message: `This product expired on ${displayDate(
			expirationDate
		)} and will not be usable in MilkTracker. Please select the appropriate defective reason.`,
		modalType: ModalType.Error,
	}),
	product_already_received: (
		milkBankProduct: MilkBankProductModel
	): IModalMessage => ({
		header: "Already Received",
		message: `The product scanned was already received ${displayDate(milkBankProduct.receivedDate)} by ${milkBankProduct.receivedBy}.`,
		modalType: ModalType.Info,
	}),
	product_already_received_no_data: (): IModalMessage => ({
		header: "Already Received",
		message: "This product has already been received ",
		modalType: ModalType.Info,
	}),
	//* GENERIC
	state_mismatch: (state: string): IModalMessage => ({
		header: "Milk State Error",
		message: `The scanned item is in a ${state} state. To manually modify the state, use Manage Labels > Edit & Reprint.`,
		modalType: ModalType.Info,
	}),
	type_mismatch: (type: string): IModalMessage => ({
		header: "Milk Type Error",
		message: `The scanned item is a ${type} and cannot be added to the queue.`,
		modalType: ModalType.Warning,
	}),
};

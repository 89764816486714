import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root",
})
export class SoundService {
	constructor() {}

	/**
	 * Use js Audio object to play a sound
	 */
	async playSuccess() {
		try {
			const audio = new Audio();
			audio.src = "assets/sounds/success.mp3";
			await audio.play();
		} catch (e) {
			console.warn(e);
		}
	}

	/**
	 * Use js Audio object to play an error sound
	 */
	async playError() {
		try {
			const audio = new Audio();
			audio.src = "assets/sounds/error.mp3";
			await audio.play();
		} catch (e) {
			console.warn(e);
		}
	}

	/**
	 * Use js Audio object to play an error sound
	 */
	async playWarning() {
		try {
			const audio = new Audio();
			audio.src = "assets/sounds/warning.m4a";
			await audio.play();
		} catch (e) {
			console.warn(e);
		}
	}
}

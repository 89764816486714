<ion-list-header *ngIf="isHeaderVisible && isPatientDisplayed">
	<ion-label>{{title}}</ion-label>
	<ion-button *ngIf="hasSelectPatientButton"
				[disabled]="isSelectPatientButtonDisabled"
				(click)="presentPatientListModal2()"
				class="select-patient-btn">
		{{ patients.length ? "Change Patient" : "Select Patient" }}
	</ion-button>
</ion-list-header>

<ion-accordion-group *ngIf="patients.length && isPatientDisplayed" [multiple]="true" [ngClass]="style">
	<ion-accordion *ngFor="let p of patients; trackBy: trackByPatient; let i = index">
		<ion-item slot="header" class="PatientInfoList">
			<ion-grid>
				<ion-row>
					<ion-col class="ion-no-padding">
						<div class="PatientInfo">
							<div class="PatientInfo-dob">
								<div class="PatientInfo-dob-label">DOB</div>
								<div class="PatientInfo-dob-date">{{p.dateOfBirth.format("DD MMM")}}</div>
								<div class="PatientInfo-dob-year">{{p.dateOfBirth.format("YYYY")}}</div>
							</div>
							<div class="PatientInfo-content">
								<div>
									<div class="PatientInfo-content-label">Last, First</div>
									<div class="PatientInfo-content-value" [attr.data-testid]="'patient_info__last_name_first_name_' + i">
										<span class="text-uppercase">{{ p.lastName }}</span>, {{ p.firstName }}
									</div>
								</div>
								<div>
									<div class="PatientInfo-content-label">MRN</div>
									<div class="PatientInfo-content-value" [attr.data-testid]="'mrn_' + i">{{ p.mrn }}</div>
								</div>
							</div>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-item>
		<ion-list class="patient-info2__content" slot="content">
			<app-patient-info-content [patient]="p"></app-patient-info-content>
		</ion-list>
	</ion-accordion>
</ion-accordion-group>

<ion-grid *ngIf="!patients.length" class="{{style}}--empty">
	<ion-row>
		<span>{{instructions}}</span>
	</ion-row>
</ion-grid>

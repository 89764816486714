<ion-header class="ion-no-border" color="primary">
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button (click)="prev()">cancel</ion-button>
        </ion-buttons>
        <ion-title>
            <div *ngIf="state === 1" class="ion-text-wrap">select product(s)</div>
            <div *ngIf="state === 2" class="ion-text-wrap">scan product(s)</div>
        </ion-title>
        <ion-buttons slot="end">
<!--              <ion-button *ngIf="state === 1 && hasChecked()" (click)="goToScanProduct()">next</ion-button>-->
        </ion-buttons>
    </ion-toolbar>
    <ion-toolbar *ngIf="state === 1" color="primary" class="secondary-toolbar">

        <div class="secondary-toolbar__patient-info">
			<app-patient-info [patients]="patients"
							  [isHeaderVisible]="false"
							  [isToolbar]="true">
			</app-patient-info>
        </div>

        <div *ngIf="allowOrderValidation" class="secondary-toolbar__order-info">
			<ion-item *ngIf="selectedOrder" lines="none">
				<ion-input [disabled]="true" [value]="displaySelectedOrder(selectedOrder)" readonly></ion-input>
			</ion-item>
			<ion-item *ngIf="!selectedOrder" lines="none">
				<ion-text class="validation-text">
					This patient has no active orders OR has an active NPO order
				</ion-text>
			</ion-item>
        </div>

        <ion-searchbar
			#selectProductModalSearchInput
			type="text"
			debounce="500"
			showClearButton="true"
			(ionInput)="getItems($event)">
		</ion-searchbar>
    </ion-toolbar>
</ion-header>

<!-- Select Product (Multiple) -->
<ion-content *ngIf="state === 1">
    <ion-list *ngIf="isItemAvailable">
        <ion-item *ngFor="let o of filteredSelectableProducts" lines="full">
            <div (click)="onProductSelected(o)" class="selectable-product">
                <ion-label class="ion-text-wrap">
                    {{o.product.name}}
                    <p>{{o.product.manufacturerName}} ({{o.product.productCode}})</p>
                </ion-label>
				<fa-icon *ngIf="o.isChecked" [icon]="faCircleCheck" [fixedWidth]="true" size="lg"></fa-icon>
            </div>
        </ion-item>
    </ion-list>
    <ion-list *ngIf="!isItemAvailable">
        <ion-item *ngFor="let o of selectableProducts" lines="full">
            <div (click)="onProductSelected(o)" class="selectable-product">
                <ion-label class="ion-text-wrap" slot="start">
                    {{o.product.name}}
                    <p>{{o.product.manufacturerName}} ({{o.product.productCode}})</p>
                </ion-label>
				<fa-icon *ngIf="o.isChecked" [icon]="faCircleCheck" [fixedWidth]="true" size="lg"></fa-icon>
            </div>
        </ion-item>
    </ion-list>

    <div class="buttons">
        <button [disabled]="!hasChecked()" (click)="next()" class="nav-btn">Next</button>
    </div>
</ion-content>

<!-- Scan Product -->
<ion-content fullscreen *ngIf="state === 2">
    <ion-list>
        <ion-item *ngFor="let o of getUnscannedProducts()" class="scan-additive">
            <ion-label>{{o.product.name}}</ion-label>
            <ion-chip *ngIf="o.product.enforceScanning" color="warning">Required</ion-chip>
            <ion-chip *ngIf="!o.product.enforceScanning">Optional</ion-chip>
        </ion-item>
		<ion-item *ngFor="let o of scannedProducts" class="scanned-additive">
			<!-- Products -->
			<ion-label *ngIf="o.scanType !== 'milk-label'">
				{{o.product.name}}
				<div *ngIf="!o.match.groups.upc">
					<p>Lot #: {{o.match.groups.lotCode}}</p>
					<p>Bottle #: {{ getBottleNumberText(parseInt(o.match.groups.bottleNumber)) }}</p>
					<p>Expiration: {{ handleExpirationDateFromScanGroups(o.match?.groups)?.format("MM/DD/YY") }}</p>
				</div>
				<div *ngIf="o.match.groups.upc">
					<p>UPC: {{o.match.groups.upc}}</p>
				</div>
			</ion-label>
			<ion-chip *ngIf="o.scanType !== 'milk-label'" color="success">
				{{ o.milkBankProduct ? "Received" : "Scanned" }}
			</ion-chip>

			<!-- Milk Bank Products -->
			<ion-label *ngIf="o.scanType === 'milk-label'">
				{{o.milkBankProduct.name}}
				<div>
					<p>Lot #: {{o.milkBankProduct.lotNumber}}</p>
					<p>Bottle #: {{ getBottleNumberText(o.milkBankProduct.bottleNumber) }}</p>
					<p>Expiration: {{ o.milkBankProduct.expirationDate.format("MM/DD/YY") }}</p>
				</div>
			</ion-label>
			<ion-chip *ngIf="o.scanType === 'milk-label'" color="success">
				Scanned
			</ion-chip>
		</ion-item>
    </ion-list>

  <!--	<p>{{selectedAdditives | json}}</p>-->
  <!--	<p>{{scannedAdditives | json}}</p>-->
    <div class="buttons">
        <button (click)="done()" [disabled]="hasUnscanned()" class="nav-btn">Continue</button>
    </div>
</ion-content>

<app-scan-footer (handleScan)="handleScannedObject($event)" [scannedObjects]="scannedObjects" [option]="getScanOptions()" #scanner></app-scan-footer>

import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "src/app/services/auth.service";
import { IdleTimerService } from "../idle-timer.service";
import { FeatureFlagService } from "../feature-flag.service";

@Injectable({
	providedIn: "root",
})
export class FeatureGuardService {
	static appFocusGuard = true;

	constructor(
		public authService: AuthService,
		private router: Router,
		public idleTimerService: IdleTimerService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		const feature = route?.data.feature;

		if (!feature) {
			console.warn(`${state}: no feature detected`);
			return;
		}

		if (FeatureFlagService.isOff(feature)) {
			console.warn(`FeatureGuardService: feature not enabled`);
			this.router.navigate(["home"]);
			return false;
		}

		this.idleTimerService.initIdleTimer();

		return true;
	}
}

import * as dayjs from "dayjs";
import {
	ContentType,
	MilkState,
	MilkType,
	ScanLogWorkflow,
} from "src/app/app.enums";

export class FeedingEntry {
	id: string;
	created: dayjs.Dayjs;
	createdBy: string;
	patientId: string;
	reason: ScanLogWorkflow;
	feedType: FeedingEntryFeedType;
	breastfeedDetails: BreastfeedDetails;
	bottleFeedDetails: BottleFeedDetails;

	get isMilkBottle(): boolean {
		return this.feedType === FeedingEntryFeedType.Bottle;
	}

	get isBreastfeed(): boolean {
		return this.feedType === FeedingEntryFeedType.Breastfeed;
	}

	constructor(initial: IFeedingEntry = {} as IFeedingEntry) {
		Object.keys(initial).forEach((key) => {
			if (key === "created") {
				this.created = initial[key] ? dayjs(initial[key]) : null;
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface BottleFeedDetails {
	isOverride: boolean;
	// biome-ignore lint/suspicious/noExplicitAny: https://angeleyehealth.atlassian.net/browse/ML-2894
	useType: any;
	overrideVerifiedBy: string;
	overrideReason: string;
	bottleId: string;
	bottleNumber: number;
	milkType: MilkType;
	milkState: MilkState;
	volume: number;
	calorie: number;
	contents: {
		milkBottleId: string;
		contentType: ContentType;
		milkBankProductId: string;
		productId: string;
		contentProductType: string;
		contentProductName: string;
		copiedFromMilkBottleId: string; //guid
		copiedFromMilkBottleNumber: number;
		recalled: boolean;
	}[];
}

export interface BreastfeedDetails {
	breastfeedStartTime: dayjs.Dayjs;
	leftDuration: number;
	rightDuration: number;
	latchQualityScore: number;
}

export interface IFeedingEntry {
	id: string;
	created: string;
	createdBy: string;
	patientId: string;
	reason: ScanLogWorkflow;
	feedType: FeedingEntryFeedType;
	breastfeedDetails: BreastfeedDetails;
	bottleFeedDetails: BottleFeedDetails;
}

export enum FeedingEntryFeedType {
	Bottle = "Bottle Feed",
	Breastfeed = "Breastfeed",
}

export enum FeedListItemType {
	MilkBottle = "milkbottle",
	Product = "product",
}

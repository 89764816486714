import { AbstractControl } from "@angular/forms";
import { MilkBankProductModel } from "../models/milk-bank-product.model";

/**
 * Validates:
 *   - User enters a unique bottle number for a given lot.
 */
export function ValidateBottleNumber(
	milkBankProducts: MilkBankProductModel[],
	lotNumber: string
) {
	return (control: AbstractControl) => {
		const bottleNumber = control.value;
		if (!bottleNumber) {
			return null;
		}

		const match = milkBankProducts
			.filter((x) => x.lotNumber === lotNumber?.trim())
			.some(
				(x) => x.bottleNumber?.toString() === bottleNumber?.toString()
			);

		if (match) {
			return { invalidBottleNumber: true };
		}

		return null;
	};
}

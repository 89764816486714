<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="back()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{title}}</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div>
		<div *ngIf="header || message" class="header">
			<h1>{{ header }}</h1>
			<p>{{ message }}</p>
		</div>

		<!-- Bottle Number -->
		<ion-item class="input-field" lines="none" *ngIf="hasVisibleBottleNumber">
			<ion-label>
				<p>Bottle Number</p>
				<span>{{ getBottleNumberText(feed.bottleNumber) }}</span>
			</ion-label>
		</ion-item>

		<!-- Milk State -->
		<app-feed-state-select title="milk state"
		                       [feed]="feed"
		                       [presetState]="presetState"
		                       [(state)]="feedState"
							   [isDisabled]="hasDisabledPresetState"
							   data-testid="milk_state_container"
							   >
		</app-feed-state-select>

		<!--State Date for Assigned Milk [ New Inline Date ] -->
		<app-inline-date *ngIf="feedState === 'fresh' || feedState === 'frozen'"
			[label]="getFeedStateDateText()"
			[defaultDate]="getFeedStateDate(feed)"
			[maxDate]="maxDate"
			(dateTimeChange)="setDates2(feed, $event)">
		</app-inline-date>

		<app-inline-date *ngIf="hasEditableStateDate(feed) && feedState === 'thawed'"
		    [label]="getFeedStateDateText()"
			[defaultDate]="getFeedStateDate(feed)"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(dateTimeChange)="setDates2(feed, $event)">
		</app-inline-date>

		<!-- Expended Date  [ New Inline Date ] -->
		<app-inline-date *ngIf="hasEditableExpendDate"
			title="Expended Date"
			[defaultDate]="feed.expendedDate"
			[maxDate]="maxDate"
			(dateTimeChange)="feed.expendedDate = $event">
		</app-inline-date>

		<!-- Volume -->
		<ion-item *ngIf="milkBottle && hasEditableVolume"
		          class="input-field"
		          lines="none">
			<ion-label>
				<p>Volume
					<span class="measurement">(mL)</span>
				</p>
				<ion-input inputmode="numeric"
				           minlength="0"
				           placeholder="Enter volume"
				           [(ngModel)]="milkBottle.volume"
				           (ionInput)="setVolume(milkBottle, $any($event.target).value)"
						   aria-label="Volume">
				</ion-input>
			</ion-label>
		</ion-item>


		<div class="buttons">
			<button (click)="submit()"
			        [disabled]="!isValid({ milkBottle, milkBankProduct })"
			        class="nav-btn">
				{{ doneText }}
			</button>
		</div>
	</div>

	<!--	<div *ngIf="milkBottle">-->
	<!--		<p>state = {{milkBottle.milkState | json}}</p>-->
	<!--		<p>pump date = {{milkBottle.pumpDate | json}}</p>-->
	<!--		<p>thawed date = {{milkBottle.thawedDate | json}}</p>-->
	<!--		<p>frozen date = {{milkBottle.frozenDate | json}}</p>-->
	<!--		<p>opened date = {{milkBottle.openedDate | json}}</p>-->
	<!--		<p>expended date = {{milkBottle.expendedDate | json}}</p>-->
	<!--		<p>volume = {{milkBottle.volume | json}}</p>-->
	<!--	</div>-->
	<!--	<p>{{milkBankProduct | json}}</p>-->
</ion-content>

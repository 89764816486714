import { MilkBottleModel } from "src/app/models/milk.model";
import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import {
	ScanMilkBankProductValidationError, ScanMilkBottleValidationError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";

/**
 * Reject the scan if the product has a lot number that has been flagged as recalled.
 */

export function RejectRecalledProduct2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject } = obj;

			// determine the feed object type and check for recalled lot number
			if (feedObject) {
				if (feedObject instanceof MilkBankProductModel) {
					if (feedObject.recalled) {
						const recalledProductInfo = {
							lotNumber: feedObject.lotNumber,
							productName: feedObject.name,
						};
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Recalled,
							modalMessage.product_recalled(recalledProductInfo)
						);
					}
				} else if (feedObject instanceof MilkBottleModel) {
					if (feedObject.contents) {
						const isRecalled = feedObject.contents.some(content => content.recalled);

						if (isRecalled) {
							const recalledContent = feedObject.contents.find(content => content.recalled);
							const recalledProductInfo = {
								lotNumber: recalledContent.mfgLotNumber,
								productName: recalledContent.contentProductName,
							};

							throw new ScanMilkBottleValidationError(
								ScanLogType.Product_Recalled,
								modalMessage.product_recalled(recalledProductInfo)
							);
						}

					} else {
						console.error(
							"RejectRecalledProduct2: feedObject is not a MilkBankProductModel or MilkBottleModel."
						);
					}
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectRecalledProduct() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			// Milk Bank Product
			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBankProduct.recalled) {
					const recalledProductInfo = {
						lotNumber: obj.milkBankProduct.lotNumber,
						productName: obj.milkBankProduct.name,
					};

					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Recalled,
						modalMessage.product_recalled(recalledProductInfo)
					);
				}
			}

			// Milk Bottle
			if (obj.milkBottle instanceof MilkBottleModel) {

				if (obj.milkBottle.contents) {
					const isRecalled = obj.milkBottle.contents.some(content => content.recalled);

					if (isRecalled) {
						const recalledContent = obj.milkBottle.contents.find(content => content.recalled);
						const recalledProductInfo = {
							lotNumber: recalledContent.mfgLotNumber,
							productName: recalledContent.contentProductName,
						};

						throw new ScanMilkBottleValidationError(
							ScanLogType.Product_Recalled,
							modalMessage.product_recalled(recalledProductInfo)
						);
					}
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { StorageService } from "../storage.service";

@Injectable({
	providedIn: "root",
})
export class PermissionsGuardService {
	constructor(public router: Router) {}

	canActivate(): boolean {
		return StorageService.permissions.global_InternalTools;
	}
}

@Injectable({
	providedIn: "root",
})
export class PrintPreviewGuardService {
	constructor(public router: Router) {}

	canActivate(): boolean {
		if (StorageService.permissions.global_VirtualPrinting) {
			return true;
		}
		this.router.navigate(["/home"]);
		alert("You do not have permission to view virtual labels.");
		return false;
	}
}

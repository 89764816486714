<ion-list-header *ngIf="isHeaderVisible" class="milk-header">
	<ion-label>
		{{ title }}
		<ion-chip color="primary" *ngIf="showPaginatedChip">
			<ion-label *ngIf="!isPaginated">{{ milks?.length }}</ion-label>
			<ion-label *ngIf="isPaginated">{{ milks?.length }} of {{ totalBottleCount }}</ion-label>
		</ion-chip>
	</ion-label>
	<ion-button *ngIf="StorageService.permissions?.global_SelectMilk && hasSelectMilkButton"
	            [disabled]="!patient"
	            (click)="selectMilk()"
	            class="select-milk-btn">
		Select Milk
	</ion-button>
</ion-list-header>
<ion-accordion-group *ngIf="milks" [multiple]="true" class="milk-info">
	<ion-accordion *ngFor="let m of milks; let i = index">
		<ion-item class="milk-info__header" slot="header" [attr.data-testid]="'bottle_info__item_' + i">
			<ion-grid>
				<ion-row>
					<!-- Status Color -->
					<ion-col [ngClass]="getStatusColor(m)" size="auto">
						<div></div>
					</ion-col>

					<ion-col>
						<ion-chip size="small" color="success" *ngIf="displayNewChip(m)">New</ion-chip>

						<ion-row>
							<ion-col class="milk-info__header__detail" size="auto">
								<p class="milk-info__header__detail__label">
									bottle #
								</p>
								<p class="milk-info__header__detail__value" [attr.data-testid]="'bottle_info__number_' + i">
									<span><b>{{ getBottleNumberText(m.bottleNumber) }}</b></span>
								</p>
							</ion-col>

							<ion-col size="auto">
								<div class="vertical-divider"></div>
							</ion-col>

							<ion-col class="milk-info__header__detail" size="auto">
								<p class="milk-info__header__detail__label">
									milk type
								</p>
								<p class="milk-info__header__detail__value" [attr.data-testid]="'bottle_info__milk_type_' + i">
									<span>{{ getMilkTypeText(m) }}</span>
								</p>
							</ion-col>

							<ion-col class="milk-info__header__chip">
								<ion-chip
									color="{{
										isExpired(m.expirationDate)
											? 'danger'
											: ''
									}}"
								>
									<span *ngIf="!isDischarging">
										{{getRelativeExpirationTime(m.expirationDate)}}
									</span>
									<span *ngIf="isDischarging">
										{{getRelativeReceived(m.receivedDate)}}
									</span>
								</ion-chip>
							</ion-col>
						</ion-row>

						<ng-container *ngIf="isContentVisible">
							<ion-row class="milk-info__secondary">
								<ion-col class="milk-info__secondary__contents" [attr.data-testid]="'bottle_info__secondary_contents_' + i">
									Contents:&nbsp;
									{{ getContents(m) }}
								</ion-col>
							</ion-row>
						</ng-container>

						<app-milk-modifiers *ngIf="isPartiallyUsable"
											[options]="options"
											[(option)]="m.modifierOption"
											(optionChange)="selectMilkModifier($event, m)">
						</app-milk-modifiers>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-item>
		<ion-list slot="content">
			<ion-grid class="milk-info__content">
				<app-milk-details [milk]="m"></app-milk-details>
				<ion-row *ngIf="hasButtons">
					<ion-col>
						<ion-button (click)="remove(m)" color="danger" data-testid="remove_milk_button"
							>Remove milk</ion-button
						>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-list>
	</ion-accordion>
</ion-accordion-group>

<ion-grid *ngIf="isInstructionsVisible && !milks?.length" class="milk-info--empty">
	<ion-row>
		<span>{{ instructions }}</span>
	</ion-row>
</ion-grid>

import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

import {
	ContentProductType,
	FeedLabelStatus,
	ProductState,
	ProductType,
} from "src/app/app.enums";
import { IContent } from "src/app/models/milk.model";
import { IDefectiveReason } from "./config.model";
import { FeedObjectModel } from "./feed-object.model";
import { FeedPatientListItemAction } from "../administer-feed/feed-patient/list-item";
import { Product } from "./product.model";

export class MilkBankProductModel extends FeedObjectModel {
	name: string;
	barcode: string;
	defective: boolean;
	defectiveReason: string;
	discardedDate: dayjs.Dayjs;
	discardedReason: string;
	createdBy: string;
	lotNumber: string;
	modifiedBy: string;
	productId: Product["id"];
	productState: ProductState;
	defaultState: ProductState;
	receivedBy: string;
	receivedDate: dayjs.Dayjs;
	thawedDate: dayjs.Dayjs;
	openedDate: dayjs.Dayjs;
	manufacturerCreatedDate: dayjs.Dayjs;
	manufacturerExpirationDate: dayjs.Dayjs;
	productType: ProductType;
	productManufacturer: string;
	isAssigned: boolean;
	lastAssignedPatient?: IAssignedPatient;
	recalled: boolean;
	receivedByTenantId: string;

	// front-end attributes
	defectiveInfo: IDefectiveReason;
	isInlineThawed: boolean;
	hasGeneratedBarcode: boolean;
	hasExpirationDate: boolean;
	action: FeedPatientListItemAction;

	constructor(initial: IMilkBankProduct = {} as IMilkBankProduct) {
		super();
		Object.keys(initial).forEach((key) => {
			// biome-ignore lint/nursery/noSecrets: <explanation>
			if (key === "productCalorieDensity") {
				this.calorieDensity = initial[key];
			} else if (key === "created") {
				this.created = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "modified") {
				this.modified = initial[key] ? dayjs(initial[key]) : null;
			}
			if (key === "discardedDate") {
				this.discardedDate = initial[key] ? dayjs(initial[key]) : null;
			}
			if (key === "expirationDate") {
				this.expirationDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "receivedDate") {
				this.receivedDate = initial[key] ? dayjs(initial[key]) : null;
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "manufacturerCreatedDate") {
				this.manufacturerCreatedDate = initial[key]
					? dayjs(initial[key])
					: null;
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "manufacturerExpirationDate") {
				this.manufacturerExpirationDate = initial[key]
					? dayjs(initial[key])
					: null;
				this.hasExpirationDate = !!this.manufacturerExpirationDate;
			} else if (key === "expendedDate") {
				this.expendedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "thawedDate") {
				this.thawedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "openedDate") {
				this.openedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "productType") {
				this.feedType = initial[key];
				this[key] = initial[key];
			} else if (key === "productState") {
				this.feedState = initial[key];
				this[key] = initial[key];
			} else {
				this.labelStatus = this.hasBeenCreated(this)
					? FeedLabelStatus.New
					: FeedLabelStatus.Updated;

				this[key] = initial[key];
			}
		});
	}

	isFortified(): boolean {
		return !!this.contents?.find(
			(c) => c.contentProductType === ContentProductType.Additive
		);
	}

	toPayload() {
		return {
			barcode: this.barcode,
			bottleNumber: this.bottleNumber ? this.bottleNumber : null,
			defective: this.defective,
			defectiveReason: this.defectiveReason,
			lotNumber: this.lotNumber,
			manufacturerCreatedDate:
				this.manufacturerCreatedDate?.toISOString() ?? null,
			manufacturerExpirationDate:
				this.manufacturerExpirationDate?.toISOString() ?? null,
			productId: this.productId,
			productState: this.productState,
			expendedDate: this.expendedDate?.toISOString() ?? null,
			openedDate: this.openedDate?.toISOString() ?? null,
			thawedDate: this.thawedDate?.toISOString() ?? null,
		};
	}
}

export interface IAssignedPatient {
	firstName: string;
	lastName: string;
	mrn: string;
}

export interface IMilkBankProduct {
	id: string;
	unifiedId: string;
	barcode: string;
	bottleNumber: number;
	created: string;
	createdBy: string;
	defective: boolean;
	defectiveReason: string;
	discardedDate: string;
	discardedReason: string; // TODO: why is this necessary if we have defective reason? (ML-1250)
	expirationDate: string;
	isAssigned: boolean;
	lotNumber: string;
	modified: string;
	modifiedBy: string;
	name: string;
	productId: string;
	productState: ProductState;
	defaultState: ProductState;
	receivedBy: string;
	receivedDate: string;
	manufacturerCreatedDate: string;
	manufacturerExpirationDate: string;
	productType: ProductType;
	productManufacturer: string;
	contents: IContent[];
	expendedDate?: string;
	thawedDate?: string;
	openedDate?: string;
	lastAssignedPatient?: IAssignedPatient;
	recalled: boolean;
	receivedByTenantId: string;
}

export interface PaginatedMilkBankProductResponse {
	items: MilkBankProductModel[];
	totalItemCount: number;
	pageNumber: number;
	pageSize: number;
	hasPrevious: boolean;
	hasNext: boolean;
}

export class UnreceivedProduct extends MilkBankProductModel {
	constructor(initial: IMilkBankProduct = {} as IMilkBankProduct) {
		super(initial);
	}
}

/**
 * ML-701 Handle non-frozen product states
 * A product can be "opened" upon partial assign (on assign-product), any feeding, full or partial (on feed-patient),
 * fortification (on assign & prep-milk)
 */
export function transitionStableMilkBankProductsToOpened(
	milkBankProducts: MilkBankProductModel[]
) {
	for (const milkBankProduct of milkBankProducts.filter(
		(product) => product.productState === ProductState.Stable
	)) {
		milkBankProduct.productState = ProductState.Opened;
		milkBankProduct.openedDate = dayjs();
	}
}

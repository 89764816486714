<!-- Single Scanner -->
<ion-footer class="scan-btn">
	<ion-toolbar *ngIf="isVisible && hasVisibleItems">
		<ion-title>
			<ng-container *ngFor="let item of option.items">
				<button *ngIf="item.isVisible" (click)="scanBarcode()">Scan {{ item.title }}</button>
			</ng-container>
		</ion-title>
	</ion-toolbar>
</ion-footer>

<!-- Multiple Scanner -->
<!-- <ion-footer *ngIf="isVisible && FeatureFlagService.isLatest(Features.Mobile_Scanner)" class="scan-btn">
	<ion-toolbar>
		<ion-title>
			<ng-container *ngFor="let option of option.items">
				<button *ngIf="option.isVisible" (click)="startScan()">Scan {{ option.title }}</button>
			</ng-container>
		</ion-title>
	</ion-toolbar>
</ion-footer> -->

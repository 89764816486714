import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import {
	ScanMilkBankProductValidationError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";

export function RejectDefective2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject } = obj;

			// determine feed object type and check if defective
			if (feedObject) {
				if (feedObject instanceof MilkBankProductModel) {
					if (
						feedObject &&
						(feedObject as MilkBankProductModel).defective
					) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Defective,
							modalMessage.product_defective(feedObject)
						);
					}
				} else {
					console.error(
						`RejectDefective2: feedObject is not a MilkBankProductModel`
					);
				}
			} else {
				console.error(`RejectDefective2: feedObject is undefined`);
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectDefective() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBankProduct && obj.milkBankProduct.defective) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Defective,
						modalMessage.product_defective(obj.milkBankProduct)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { hasSameProductIdInArray } from "../../utils/milk-bank-product.util";
import {
	ScanMilkBankProductValidationError,
} from "./scan-validation-error";
import { IScanValidation } from "./scan-validation.model";

export function RejectProductMismatch() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			// Product
			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				const mismatch = obj.milkBankProducts.length && !hasSameProductIdInArray({ scannedObject: obj.scannedObject, milkBankProduct: obj.milkBankProduct }, obj.milkBankProducts)

				if (mismatch) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Mismatch,
						modalMessage.product_mismatch()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

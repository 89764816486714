import { Product } from "./product.model";

export class Additive {
	productId: string;
	productDisplacementPerUnit: number;
	productEnforceScanning: boolean;
	productName: string;
	productType: string;
	productMeasuredUnit: string;
	productAmountPerMl: number;
	productContainsHumanMilk: boolean;
	increment: number;
	product: Product;

	constructor(initial: IAdditive = {} as IAdditive) {
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}
}

export interface IAdditive {
	productId: string;
	productDisplacementPerUnit: number;
	productEnforceScanning: boolean;
	productName: string;
	productType: string;
	productMeasuredUnit: string;
	productAmountPerMl: number;
	productContainsHumanMilk: boolean;
}

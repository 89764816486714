import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { StorageService } from "src/app/services/storage.service";
import { ModalType, SECONDS_UNTIL_MODAL_CLOSES } from "src/app/app.constants";

@Component({
	selector: "app-overlay",
	templateUrl: "./overlay.modal.html",
	styleUrls: ["./overlay.modal.scss"],
})
export class OverlayModal implements OnInit {
	// Data passed in by componentProps
	@Input() type: ModalType;
	@Input() iconPath: string;
	@Input() title: string;
	@Input() messages: string[];
	@Input() buttonText: string;
	@Input() hasActionButton = false;
	@Input() hasDismissButton = true;
	@Input() actionButtonText = "action";

	isWeb = false;
	showDismissMessage = false;
	secondsUntilDismissed = SECONDS_UNTIL_MODAL_CLOSES;
	dismissTimeout: any;

	constructor(private modalCtrl: ModalController) {
		this.isWeb = StorageService.isWeb;
	}

	ngOnInit() {
		if (this.type === ModalType.Verified) {
			this.showDismissMessage = true;
			this.dismissTimeout = setInterval(async () => {
				if (this.secondsUntilDismissed <= 1) {
					await this.modalCtrl.dismiss();
					clearInterval(this.dismissTimeout);
				}
				this.secondsUntilDismissed--;
			}, 1000);
		}
	}

	async action() {
		await this.modalCtrl.dismiss("handleActionButtonPress");
	}

	async dismiss() {
		clearInterval(this.dismissTimeout);
		await this.modalCtrl.dismiss();
	}
}

import { defineEnvironment } from "./common";
import { PermissionsFactory } from "../app/factories/user-profile.factory";
import { FeatureFlagFactory } from "../app/factories/feature-flag.factory";
import { FeatureStatus, Features } from "../app/features.enums";

export const environment = defineEnvironment({
	production: false,
	type: 'dev',
	baseUrl: 'https://development.milktracker.angeleyehealth.com',
	settings: {
		authGuard: false,
		sessionLengthMinutes: 20,
		timeoutDuration: 15000,
		enableBadgeScanning: true,
		enableSelectMilk: true,
		bypassExpiration: true,
		showMobileDateTimePicker: false,
		bypassOrderMatching: false, // Set to true for testing manual entry
		apiDelay: 0,
		removeAllOrders: false,
		clerkSignInURL: 'https://stable-fish-31.accounts.dev/sign-in',
		clerkSignOutURL: 'https://stable-fish-31.accounts.dev/sign-out',
		// You can comment this whole thing out if you want to use the default permissions
		overridePermissions: PermissionsFactory.create({
			global_VirtualPrinting: true,
		}),

		overrideFeatureFlags: FeatureFlagFactory.create({
			[Features.Virtual_Printer]: FeatureStatus.Latest,
			[Features.Mobile_Scanner]: FeatureStatus.Latest,
		}),
	},
});

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="back()">back</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{mode || "recipe calculator"}}</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content>
	<div *ngIf="!mode" class="selector">
		<div *ngIf="recipePermissions.volumeOnHand"
			 (click)="handleModeSelected(RecipeMode.VOH)"
			 class="card"
			 data-testid="card_volume_on_hand">
			<div class="icon icon-volume-on-hand"></div>
			<p>Volume On Hand</p>
		</div>
		<div *ngIf="recipePermissions.desiredVolume"
			 (click)="handleModeSelected(RecipeMode.DV)"
			 class="card"
			 data-testid="card_desired_volume">
			<div class="icon icon-desired-volume"></div>
			<p>Desired Volume</p>
		</div>
		<div *ngIf="recipePermissions.manualPrep"
			 (click)="handleModeSelected(RecipeMode.MP)"
			 class="card"
			 data-testid="card_manual_prep">
			<div class="icon icon-manual-prep"></div>
			<p>Manual Prep</p>
		</div>
	</div>

	<div *ngIf="mode">
		<app-patient-info [patients]="patients"></app-patient-info>

		<app-order-select *ngIf="patients.length && StorageService.isOrderValidationEnforced"
		                  [orders]="orders"
		                  [(selectedOrder)]="selectedOrder"
		                  (selectedOrderChange)="handleSelectOrderChanged($event)"
                          [selectedCalorie]="form?.value.calorieDensity"
		                  #orderSelectRef>
		</app-order-select>

		<ion-list-header>
			<ion-label>
				{{ mode === RecipeMode.MP ? "Recipe Details" : "Recipe Calculator" }}
			</ion-label>
		</ion-list-header>

		<form [formGroup]="form" (ngSubmit)="onSubmit()">

			<!-- Base -->
			<ion-item  [disabled]="!hasSelectableBase()" class="select-item" lines="none">
				<ion-label>
					<p class="select-item__title">Base</p>
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-select [interfaceOptions]="defaultSelectOptions"
								            [attr.disabled]="!hasSelectableBase()"
								            (ionChange)="baseOptionChanged(mode, $any($event.target).value)"
								            aria-label="base"
								            fill="outline"
								            labelPlacement="stacked"
								            interface="popover"
								            formControlName="recipeBase"
								            placeholder="Select desired base"
											data-testid="recipe_base">
									<ion-select-option *ngFor="let baseOption of baseOptions"
									                   [value]="baseOption">
										{{baseOption.baseName}}
									</ion-select-option>
								</ion-select>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-label>
			</ion-item>

			<!-- Volume -->
			<ion-item class="select-item" lines="none">
				<ion-label>
					<p *ngIf="mode === RecipeMode.VOH">
						<span class="select-item__title">Base Volume </span>
						<span class="select-item__title_measurement">(mL)</span>
					</p>
					<p *ngIf="mode === RecipeMode.DV">
						<span class="select-item__title">Desired Volume </span>
						<span class="select-item__title_measurement">(mL)</span>
					</p>
					<p *ngIf="mode === RecipeMode.MP">
						<span class="select-item__title">Final Volume </span>
						<span class="select-item__title_measurement">(mL)</span>
					</p>
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-input [attr.disabled]="!hasSelectableBase()"
								           (ionInput)="volumeChanged()"
								           aria-label="volume"
								           fill="outline"
								           formControlName="volume"
								           type="number"
								           inputmode="numeric"
								           labelPlacement="stacked"
								           placeholder="Enter volume"
								           debounce="500"
										   data-testid="recipe_volume">
								</ion-input>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-label>
			</ion-item>

			<!-- Calorie Density -->
			<ion-item  [disabled]="!hasRecipeBase()" class="select-item" lines="none">
				<ion-label>
					<p class="select-item__title">Calorie Density</p>
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-select [interfaceOptions]="defaultSelectOptions"
								            (ionChange)="calorieDensityChanged($event)"
								            aria-label="calorie density"
								            fill="outline"
								            formControlName="calorieDensity"
								            interface="popover"
								            labelPlacement="stacked"
								            placeholder="Select calorie density"
											data-testid="recipe_calorie_density">
									<ion-select-option *ngFor="let calorieDensity of calorieDensityOptions"
									                   [value]="calorieDensity">
										{{calorieDensity}} kcal/oz
									</ion-select-option>
								</ion-select>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-label>
			</ion-item>

			<!-- Additives Form Array -->
			<ion-item *ngIf="mode !== RecipeMode.MP"
					  [disabled]="!hasRecipeBase() || !hasCalorieDensity()"
					  formArrayName="recipes"
					  class="select-item"
					  lines="none">
				<div style="position: relative; width: 100%;">
					<p class="select-item__title">Additive(s)</p>
					<ion-grid>
						<ion-row>
							<ion-col>
								<div *ngFor="let r of formRecipes().controls; let i=index"
									 [formGroupName]="i"
									 [ngClass]="{multiple: formRecipes().controls.length > 1}"
									 class="select-item__selector">
									<ion-input formControlName="additive"
									           aria-label="additive"
									           fill="outline"
											   placeholder="Select additive"
											   readonly>
									</ion-input>
									<fa-icon [icon]="faChevronRight" [fixedWidth]="true" size="1x"></fa-icon>
								</div>

								<!-- Overlay clickable area -->
								<div data-testid="recipe_additive" style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 10;" (click)="handleSelectAdditivePressed()"></div>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>
			</ion-item>

			<!-- Products Form Array -->
			<ion-item *ngIf="mode === RecipeMode.MP"
					  [disabled]="!hasRecipeBase() || !hasCalorieDensity()"
					  formArrayName="products"
					  class="select-item additives"
					  lines="none">
				<div style="position: relative; width: 100%;">
					<ion-label>
						<p class="select-item__title">Product(s)</p>
						<ion-grid>
							<ion-row>
								<ion-col>
									<div *ngFor="let r of formProducts().controls; let i=index"
										 [formGroupName]="i"
										 [ngClass]="{multiple: formProducts().controls.length > 1}"
										 class="additives__selector">
										<ion-input formControlName="product"
										           aria-label="product"
												   placeholder="Select product"
												   readonly>
										</ion-input>
										<fa-icon [icon]="faChevronRight" [fixedWidth]="true" size="1x"></fa-icon>
									</div>

									<!-- Overlay clickable area -->
									<div style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 10;" (click)="handleSelectAdditivePressed()"></div>
								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-label>
				</div>
			</ion-item>

			<!-- Calculations -->
			<ion-list *ngIf="mode !== RecipeMode.MP">
				<ion-item>
					<ion-label>Final Volume</ion-label>
					<ion-text data-testid="recipe__final_volume" slot="end">{{finalVolume ? finalVolume + " mL" : "--"}}</ion-text>
				</ion-item>
				<ion-item>
					<ion-label>Base Needed</ion-label>
					<ion-text slot="end" class="second-item-label" data-testid="recipe__base_needed"
						>{{baseNeeded ? baseNeeded + " mL" : "--"}}</ion-text
					>
				</ion-item>
				<ion-item-group>
					<ion-item-divider *ngIf="additiveCandidates?.length">
						<ion-label>Additive Needed</ion-label>
					</ion-item-divider>
					<ion-item *ngFor="let a of additiveCandidates; let i = index">
						<ion-label>{{a.additive.productName}}</ion-label>
						<ion-text slot="end" [attr.data-testid]="'recipe__additive_amount_' + i"
							>{{a.additiveAmount}}
							{{a.additive.productMeasuredUnit}}</ion-text
						>
					</ion-item>
				</ion-item-group>
			</ion-list>

			<!--			<p>{{ form.value | json}}</p>-->

			<div class="buttons">
				<button
					type="submit"
					[disabled]="!canSubmit(mode, form)"
					class="nav-btn"
					data-testid="recipe_calculator__submit_button"
				>
					Submit
				</button>
			</div>
		</form>
	</div>
</ion-content>

import { IPrinter, PrinterModel } from "./printer.model";

export class UserProfileModel {
	loginName: string;
	nurseId: string;
	badgeId: string;
	name: string;
	firstName: string;
	lastName: string;
	authKey: string;
	email: string;
	authorizedTenants: IAuthorizedTenant[];
	currentPrinter: PrinterModel;
	preferredTenantId: string;

	constructor(initial: IUserProfile = {} as IUserProfile) {
		if (!initial) {
			return;
		}
		Object.keys(initial).forEach((key) => {
			if (key === "uid") {
				this.nurseId = initial[key];
			} else if (key === "currentPrinter") {
				this.currentPrinter = new PrinterModel(initial[key]);
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface IUserProfile {
	loginName: string;
	uid: string;
	badgeId: string;
	name: string;
	firstName: string;
	lastName: string;
	authKey: string;
	email: string;
	authorizedTenants: IAuthorizedTenant[];
	currentPrinter: IPrinter;
}

export interface IAuthorizedTenant {
	tenantId: string;
	tenantName: string;
	permissionsList: string[];
	permissions: IPermissions;
	isPreferredTenant: boolean;
	facilityId: string;
	facilityName: string;
}

export interface IPermissions {
	administerFeed: boolean;
	administerFeed_DirectBreastfeed: boolean;
	administerFeed_DirectBreastfeed_SelectPatient: boolean;
	administerFeed_FeedPatient: boolean;
	administerFeed_FeedPatient_SelectPatient: boolean;
	administerFeed_OralCare: boolean;
	administerFeed_OralCare_SelectPatient: boolean;
	global_InternalTools: boolean;
	global_SelectMilk: boolean;
	global_SkipPrintingUpdatedLabels: boolean;
	global_VirtualPrinting: boolean;
	manageLabels: boolean;
	manageLabels_EditAndReprintLabel: boolean;
	manageLabels_EditAndReprintLabel_SelectPatient: boolean;
	manageLabels_FreezeParentsMilk: boolean;
	manageLabels_FreezeParentsMilk_SelectPatient: boolean;
	manageLabels_LinkSiblings: boolean;
	manageLabels_LinkSiblings_SelectPatient: boolean;
	manageLabels_PrintCustomLabel: boolean;
	manageLabels_PrintCustomLabel_SelectPatient: boolean;
	manageLabels_PrintFreshMilkLabel: boolean;
	manageLabels_PrintFreshMilkLabel_DesiredVolume: boolean;
	manageLabels_PrintFreshMilkLabel_SelectPatient: boolean;
	manageLabels_PrintFreshMilkLabel_VolumeOnHand: boolean;
	manageLabels_PrintFreshMilkLabel_ManualPrep: boolean;
	manageLabels_PrintParentLabel: boolean;
	manageLabels_PrintParentLabel_SelectPatient: boolean;
	manageLabels_VerifyMilkLabel: boolean;
	manageLabels_VerifyMilkLabel_SelectPatient: boolean;
	prepFeed: boolean;
	prepFeed_AssignProduct: boolean;
	prepFeed_AssignProduct_DesiredVolume: boolean;
	prepFeed_AssignProduct_SelectPatient: boolean;
	prepFeed_AssignProduct_VolumeOnHand: boolean;
	prepFeed_AssignProduct_ManualPrep: boolean;
	prepFeed_MilkRoomPrep: boolean;
	prepFeed_MilkRoomPrep_DesiredVolume: boolean;
	prepFeed_MilkRoomPrep_ManualPrep: boolean;
	prepFeed_MilkRoomPrep_SelectPatient: boolean;
	prepFeed_MilkRoomPrep_VolumeOnHand: boolean;
	prepFeed_DivideFeed: boolean;
	prepFeed_DivideFeed_DesiredVolume: boolean;
	prepFeed_DivideFeed_SelectPatient: boolean;
	prepFeed_DivideFeed_VolumeOnHand: boolean;
	prepFeed_PrepMilk: boolean;
	prepFeed_PrepMilk_DesiredVolume: boolean;
	prepFeed_PrepMilk_SelectPatient: boolean;
	prepFeed_PrepMilk_VolumeOnHand: boolean;
	prepFeed_PrepMilk_ManualPrep: boolean;
	prepFeed_PrepFormula: boolean;
	prepFeed_PrepFormula_DesiredVolume: boolean;
	prepFeed_PrepFormula_ManualPrep: boolean;
	prepFeed_PrepFormula_SelectPatient: boolean;
	prepFeed_PrepFormula_VolumeOnHand: boolean;
	prepFeed_ThawPatientsMilk: boolean;
	prepFeed_ThawPatientsMilk_DesiredVolume: boolean;
	prepFeed_ThawPatientsMilk_SelectPatient: boolean;
	prepFeed_ThawPatientsMilk_VolumeOnHand: boolean;
	prepFeed_ThawPatientsMilk_ManualPrep: boolean;
	prepFeed_ThawProduct: boolean;
	receiveAndDischargeMilk: boolean;
	receiveAndDischargeMilk_DischargeParentsMilk: boolean;
	receiveAndDischargeMilk_DischargeParentsMilk_SelectPatient: boolean;
	receiveAndDischargeMilk_ReceiveParentsMilk: boolean;
	receiveAndDischargeMilk_ReceiveParentsMilk_SelectPatient: boolean;
	receiveAndDischargeMilk_ReceiveProduct: boolean;
	reportsAndInventory: boolean;
	reportsAndInventory_FeedingReport: boolean;
	reportsAndInventory_FeedingReport_SelectPatient: boolean;
	reportsAndInventory_PatientInventory: boolean;
	reportsAndInventory_PatientInventory_SelectPatient: boolean;
	milkTracker_Admin_Portal: boolean;
}

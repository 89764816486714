/**
 * This file is used for any utility functions that are used for EHR orders,
 * order fulfillments, and order validation.
 */

import { RecipeBase } from "src/app/models/recipe.model";
import {
	IOrderFulfillment,
	IOrderFulfillmentContent,
	PatientEhrOrderModel,
} from "../models/patient.model";
import {
	FulfillmentValidationResult,
	IContentProduct,
	IOrderValidationResult2,
} from "../components/order-select/order-select.component";
import { IAdditive } from "../models/additive.model";

/**
 * Returns an array of all the fortifiers that are missing from the order
 * fulfillment.
 *
 * @param contents is a list of the selected/desired contents to check against
 * the selected fulfillment option.
 *
 * @param fulfillmentContents is a list of all the contents that the selected
 * contents are being checked against.
 */
export const findMissingFortifiers = (
	contents: IContentProduct[],
	fulfillmentContents: IOrderFulfillmentContent[]
): IContentProduct[] => {
	// Format the fulfillment contents to match the selected contents and remove duplicate product ids
	const fulfillmentFortifiers = fulfillmentContents
		.filter((o) => o.isBase === false)
		.map(
			(c) =>
				({
					productId: c.productId,
					productName: c.productName,
				}) as IContentProduct
		);

	// Return only the fortifiers that are not in the selected contents and remove duplicates
	return fulfillmentFortifiers
		.filter((f) => !contents.some((c) => c.productId === f.productId))
		.filter((f, index, arr) => arr.indexOf(f) === index);
};

/**
 * Gets the base products from the order fulfillment and checks if any of them are in the milk bottle.
 *
 * @param products
 * @param fulfillmentBases
 */
export const hasFulfillmentBase = (
	products: IContentProduct[],
	fulfillmentContents: IOrderFulfillmentContent[]
): boolean => {
	const bases = fulfillmentContents.filter((content) => content.isBase);
	return products.some((p) =>
		bases.some((base) => base.productId === p.productId)
	);
};

/**
 * This returns true if there are no bases in the order fulfillment or if there is at least one base in the milk bottle.
 *
 * @param products
 * @param fulfillmentContents
 */
export const hasOptionalFulfillmentBase = (
	products: IContentProduct[],
	fulfillmentContents: IOrderFulfillmentContent[]
): boolean => {
	const bases = fulfillmentContents.filter((content) => content.isBase);
	return (
		bases.length === 0 || hasFulfillmentBase(products, fulfillmentContents)
	);
};

/**
 * Returns true if base is in the order fulfillment.
 */
export const isBaseInOrderFulfillment = (
	recipeBase: RecipeBase,
	selectedFulfillment: IOrderFulfillment
): boolean => {
	const bases = selectedFulfillment.contents.filter(
		(content) => content.isBase
	);
	return bases.some((base) => base.productId === recipeBase.baseProductId);
};

/**
 * Package up various validation results into a single object.
 *
 * @param products are the list of products that are in the assigned feed.
 * @param fulfillment is the selected order fulfillment.
 */
export const validateContentProducts = (
	products: IContentProduct[],
	fulfillment: IOrderFulfillment
): FulfillmentValidationResult => {
	const contents: IOrderFulfillmentContent[] = fulfillment.contents.filter(
		(o) => o.productId !== null
	);

	const fulfillmentId = fulfillment.id;
	const extraProducts = findExtraProducts(products, contents);
	const hasBase = hasOptionalFulfillmentBase(products, contents);
	const missingFortifiers = findMissingFortifiers(products, contents);

	// In order to be valid, need to have a base, no missing fortifiers, and no extra products.
	const hasAllFortifiers = !missingFortifiers.length;
	const hasExtraProducts = !!extraProducts.length;
	const isValid = hasValidContentProducts(
		hasBase,
		hasAllFortifiers,
		hasExtraProducts
	);

	return {
		ehrOrderFulfillmentId: fulfillmentId,
		milkBottleContentItemsNotInOrderFulfillment: extraProducts,
		baseOrderedIsPresent: hasBase,
		orderFulfillmentNonBaseProductsNotInBottle: missingFortifiers,
		isValid,
	};
};

/**
 * Returns true if the order fulfillment has a base and all fortifiers as
 * well as no extra products.
 *
 * @param hasBase
 * @param hasAllFortifiers
 * @param hasExtraProducts
 */
export const hasValidContentProducts = (
	hasBase: boolean,
	hasAllFortifiers: boolean,
	hasExtraProducts: boolean
): boolean => hasBase && hasAllFortifiers && !hasExtraProducts;

/**
 * Returns an array of all unique products (both bases and fortifiers) that are
 * not in the order fulfillment.
 *
 * This is used to determine if there are any extra products in the feed that
 * are not supposed to be in the order fulfillment.
 *
 * Example:
 * - Order fulfillment has water and gentlease.
 * - Selected products include water (base) and Prolacta.
 * - This function will return Prolacta because it is not supposed to be in the
 * 	 order fulfillment.
 *
 * @param contents
 * @param fulfillmentContents
 */
export const findExtraProducts = (
	contents: IContentProduct[],
	fulfillmentContents: IOrderFulfillmentContent[]
): IContentProduct[] => {
	const fulfillmentContentProducts: IContentProduct[] =
		fulfillmentContents.map((c) => ({
			productId: c.productId,
			productName: c.productName,
		}));

	// Return only the product contents that are not in the selected contents and remove duplicates
	return contents.filter(
		(f) =>
			!fulfillmentContentProducts.some((c) => c.productId === f.productId)
	);
};

export const hasMissingValue = (
	selectedOrder: PatientEhrOrderModel,
	calorieDensity: number,
	additives: IAdditive[]
): boolean => {
	if (!selectedOrder || !calorieDensity || !additives.length) {
		console.warn(`One or more params are null`);
		console.table({
			selectedOrder,
			calorieDensity,
			additives,
		});
		return false;
	}
	return true;
};

export const createContentProductsList = (
	additives: IAdditive[],
	recipeBase: RecipeBase
): IContentProduct[] => {
	const products = additives
		.filter((a) => a.productId)
		.map((a) => ({
			productId: a.productId,
			productName: a.productName,
		}))
		.concat({
			productId: recipeBase.baseProductId,
			productName: recipeBase.baseName,
		});

	return products;
};

export const isMilkBottleOrderMatch = (
	milkBottleId: string,
	results: IOrderValidationResult2[]
): boolean => {
	if (!results) {
		return;
	}

	const result = results.find((v) => v.milkBottleId === milkBottleId);
	if (!result) {
		console.warn(
			`order-select.component: milkBottle not found: ${milkBottleId}`
		);
	}
	return result.isValid;
};

/**
 * Returns the validation status if the scanned item matches the order validation results
 *
 * TODO: if the scanned item is not found, should we throw an error?
 *
 * @param scannedItemKey
 * @param validationResults
 */
export const isScannedItemOrderMatch = (
	scannedItemKey: string,
	validationResults: IOrderValidationResult2[]
): boolean => {
	if (!validationResults) {
		return;
	}

	const result = validationResults.find((v) => v.key === scannedItemKey);
	if (!result) {
		console.warn(
			`order-match.component: scannedItem not found: scannedItemKey = ${scannedItemKey}`
		);
	}
	return result.isValid;
};

import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { ScanMilkBottleValidationError } from "./scan-validation-error";
import { IScanValidation } from "./scan-validation.model";

export function RejectFortified() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle && obj.milkBottle.isFortified) {
				throw new ScanMilkBottleValidationError(
					ScanLogType.Milk_Bottle_Fortified_Cannot_Be_Frozen,
					modalMessage.milk_fortified_cannot_be_frozen()
				);
			}

			return originalMethod.apply(this, args);
		};
	};
}

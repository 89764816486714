import { Injectable } from "@angular/core";
import { RestApiService } from "src/app/services/rest-api.service";
import { LogObjectModel } from "../models/log-scan.model";
import { lastValueFrom } from "rxjs";

@Injectable({
	providedIn: "root",
})
export class LoggingService {
	constructor(private http: RestApiService) {}

	/**
	 * TODO: Currently the HTTP error is caught and printed, which may or may not be ideal in terms of error
	 *  handling and error detection.
	 */
	logScan(logModel: LogObjectModel) {
		const url = "/scans";
		const payload = logModel.toPayload();
		return lastValueFrom(this.http.post(url, payload)).catch((error) => {
			console.error("LoggingService.logScan", error);
			return error;
		});
	}
}

<ion-accordion-group *ngIf="feedObjects?.length" [multiple]="true" class="milk-info">
	<ion-accordion *ngFor="let feed of feedObjects">
		<ng-container *ngIf="!isProduct(feed)">
			<ion-item class="milk-info__header" slot="header">
				<app-feed-info-header [feed]="feed"
				                      [isContentVisible]="false">
					<app-milk-modifiers *ngIf="hasFeedModifiers(feed)"
					                    [option]="feed.modifierOption"
					                    [options]="options"
					                    (optionChange)="selectFeedModifier($event, feed)">
					</app-milk-modifiers>
				</app-feed-info-header>
			</ion-item>
			<ion-list class="milk-info__content" slot="content">
				<app-feed-info-content [feed]="feed"></app-feed-info-content>
					<ion-row *ngIf="hasButtons">
						<ion-col>
							<ion-button (click)="removeFeed(feed)" color="danger">
								Remove
							</ion-button>
						</ion-col>
					</ion-row>
			</ion-list>
		</ng-container>
		<ng-container *ngIf="isProduct(feed)">
			<ion-item class="milk-info__header" slot="header">
				<ion-grid>
					<ion-row>
						<ion-col [ngClass]="getFeedObjectColor(feed)" size="auto">
							<div></div>
						</ion-col>
						<div></div>

						<ion-col>
							<ion-row>
								<ion-col class="milk-info__header__detail" size="auto">
									<p class="milk-info__header__detail__label">
										Bottle #
									</p>
									<p class="milk-info__header__detail__value">
										{{ getBottleNumberText(null) }}
									</p>
								</ion-col>

								<ion-col size="auto">
									<div class="vertical-divider"></div>
								</ion-col>

								<ion-col class="milk-info__header__detail" size="auto">
									<p class="milk-info__header__detail__label">
										{{ getProduct(feed).defaultState }}
									</p>
									<p class="milk-info__header__detail__value">
										<span>{{ getProduct(feed).name }}</span>
									</p>
								</ion-col>
							</ion-row>

							<app-milk-modifiers *ngIf="hasFeedModifiers(feed)"
							                    [options]="options"
							                    (optionChange)="selectFeedModifier($event, feed)">
							</app-milk-modifiers>
						</ion-col>
					</ion-row>

				</ion-grid>
			</ion-item>
			<ion-list class="milk-info__content" slot="content">
					<app-feed-info-content [feed]="feed"></app-feed-info-content>
					<ion-row *ngIf="hasButtons">
						<ion-col>
							<ion-button (click)="removeFeed(feed)" color="danger">
								Remove
							</ion-button>
						</ion-col>
					</ion-row>
			</ion-list>
		</ng-container>
	</ion-accordion>
</ion-accordion-group>

<ion-grid *ngIf="!feedObjects?.length" class="milk-info--empty">
	<ion-row class="milk-info__instructions">
		<span>{{ instructions }}&nbsp;</span>
		<a *ngIf="isWaterAvailable" (click)="handleWaterSelected()"> or select water as a base.</a>
	</ion-row>
</ion-grid>

<ion-header class="ion-no-border">
    <ion-toolbar color="primary">
        <ion-buttons slot="start">
            <ion-button *ngIf="!isRetry && hasBackButton" (click)="goBack()">
				<fa-icon *ngIf="!isWeb" [icon]="faChevronLeft" [fixedWidth]="true" size="1x" class="is-dark-mode"></fa-icon>
                {{backButtonText}}
            </ion-button>
        </ion-buttons>
        <ion-title>
            <div class="ion-text-wrap">{{title}}</div>
        </ion-title>

		<ion-buttons *ngIf="!isWeb" slot="end">
			<ion-menu-button></ion-menu-button>
		</ion-buttons>

		<ion-buttons *ngIf="isWeb" slot="end">
			<fa-icon
				*ngIf="isMenuOpen"
				(click)="toggleMenu(false)"
				[icon]="faBars"
				[fixedWidth]="true"
				size="lg"
				class="is-dark-mode"
				data-testid="navbar__menu_icon">
			</fa-icon>

			<fa-icon
				*ngIf="!isMenuOpen"
				(click)="toggleMenu(true)"
				[icon]="faBars"
				[fixedWidth]="true"
				size="lg"
				class="is-dark-mode"
				data-testid="navbar__menu_icon">
			</fa-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

import dayjs from "dayjs";
import { MilkLocation, MilkState, ProductState } from "../app.enums";
import { MilkBottleModel, getUniquePatientIds } from "../models/milk.model";
import {
	FeedPatientListItem,
	FeedPatientListItemAction,
	MilkBottleFeedPatientListItem,
	ProductFeedPatientListItem,
} from "../administer-feed/feed-patient/list-item";
import { PatientModel } from "../models/patient.model";
import { CreateMilkBottle2Payload } from "../services/inventory.service";
import { getEarliestDayjs } from "./expiration.util";
import { getCombinedMilkState } from "./milk-label.util";
import { FeedListItemType } from "../models/feeding-entry.model";

export const setStableMilkBottlesToOpened = (
	milkBottles: MilkBottleModel[]
): {
	openedMilkBottles: MilkBottleModel[];
} => {
	const openedMilkBottles: MilkBottleModel[] = [];

	for (const milkBottle of milkBottles.filter(
		(b) => b.milkState === MilkState.Stable
	)) {
		milkBottle.milkState = MilkState.Opened;
		milkBottle.openedDate = dayjs();
		openedMilkBottles.push(milkBottle);
	}

	return { openedMilkBottles };
};

/**
 * The list should contain items with actions selected.
 *
 * When feeding a list of feeds, the user is only allowed to feed it milk
 * bottles or products, not both.
 *
 * TODO: I feel like this should return a success state and not just throw errors.
 *
 * @param listItems
 */
export const validate = (listItems: FeedPatientListItem[]) => {
	const isOverride = listItems.every((x) => x.isOverride);

	// When using manually entry, allow the user to continue
	// without extra validation. The form is already valid.
	if (isOverride) {
		return;
	}

	if (
		false ===
		(listItems.length > 0 && listItems.every((item) => item.action))
	) {
		throw new Error(
			"there should be list items, each with an action selected"
		);
	}

	const milkBottles = getMilkBottlesByListItem(listItems);
	const products = getProductsByListItem(listItems);

	const allItemsAreRtf = listItems.every((i) => i.isRtf);

	if (milkBottles.length > 0 && products.length > 0) {
		if (allItemsAreRtf) {
			// When a MT label is created for a RTF product it is technically a milk bottle.
			// The only time we allow processing of milk bottles and products is when
			// all the items are RTF.
			return;
		}
		throw new Error("process milk bottles or products, not both");
	}

	if (milkBottles.length === 0 && products.length === 0) {
		throw new Error("must pass milk bottles or products");
	}
};

export const getMilkBottlesByListItem = (
	listItems: FeedPatientListItem[]
): MilkBottleFeedPatientListItem[] =>
	listItems.filter(
		(i): i is MilkBottleFeedPatientListItem =>
			i.type === FeedListItemType.MilkBottle
	);

export const getProductsByListItem = (
	listItems: FeedPatientListItem[]
): ProductFeedPatientListItem[] =>
	listItems.filter(
		(i): i is ProductFeedPatientListItem =>
			i.type === FeedListItemType.Product
	);

export const getCreatedMilkBottleState = (
	action: FeedPatientListItemAction,
	productState: ProductState
): MilkState => {
	switch (productState) {
		case ProductState.Stable: {
			if (action === "has remaining") {
				return MilkState.Opened;
			} else if (action === "feed all") {
				return MilkState.Stable;
			}
			break;
		}
		case ProductState.Frozen:
			return MilkState.Thawed;
		case ProductState.Thawed:
			return MilkState.Thawed;
		case ProductState.Opened:
			return MilkState.Opened;
		default:
			return MilkState.Opened;
	}
};

export const createMilkBottlePayload = (
	patient: PatientModel,
	sourceMilkBottles: MilkBottleModel[]
): CreateMilkBottle2Payload => ({
	mothersId: patient.motherId,
	milkState: getCombinedMilkState(sourceMilkBottles),
	location: MilkLocation.Hospital,
	volume: 0,
	calorie: sourceMilkBottles[0].calorieDensity,
	expirationDate: null,
	receivedDate: getEarliestDayjs(
		sourceMilkBottles,
		(m) => m.receivedDate
	)?.toISOString(),
	dischargeDate: null,
	frozenDate: getEarliestDayjs(
		sourceMilkBottles,
		(m) => m.frozenDate
	)?.toISOString(),
	pumpDate: getEarliestDayjs(
		sourceMilkBottles,
		(m) => m.pumpDate
	)?.toISOString(),
	thawedDate: getEarliestDayjs(
		sourceMilkBottles,
		(m) => m.thawedDate
	)?.toISOString(),
	openedDate: getEarliestDayjs(
		sourceMilkBottles,
		(m) => m.openedDate
	)?.toISOString(),
	contents: [],
	sourceMilkbottleIds: sourceMilkBottles.map((b) => b.id),
	patientIds: getUniquePatientIds(sourceMilkBottles),
});

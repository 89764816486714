<ion-input
	[attr.aria-label]="ariaLabel"
	[id]="id"
	[value]="input"
	[disabled]="disabled"
	(ionInput)="handleInput($event)"
	type="number"
	inputmode="decimal"
	[step]="step"
	[min]="minimumValue"
	[max]="maximumValue"
	#ionInputEl
	>
</ion-input>

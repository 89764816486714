import { Component, Input } from "@angular/core";
import { PatientModel } from "../../models/patient.model";
import {
	PATIENT_PROPERTIES,
	PatientProperty,
	PatientPropertyItem,
} from "../../utils/patient-info-content.util";

@Component({
	selector: "app-patient-info-content",
	templateUrl: "./patient-info-content.component.html",
	styleUrls: ["./patient-info-content.component.scss"],
})
export class PatientInfoContentComponent {
	@Input() patient: PatientModel;
	@Input() excludes: PatientProperty[] = [];

	constructor() {}

	/**
	 * Return the appropriate default properties.
	 * Displays in order.
	 */
	getDisplayedProperties(
		patient: PatientModel,
		excludes: PatientProperty[]
	): PatientPropertyItem[] {
		let props: PatientProperty[] = [
			PatientProperty.AdmittedDate,
			PatientProperty.BedId,
			PatientProperty.Gender,
			PatientProperty.Siblings,
			PatientProperty.BottlesRemaining,
		];
		props = props.filter((p) => p); // clear null values
		props = this.applyExclusion(props, excludes);
		return this.getPatientPropertyItems(props);
	}

	applyExclusion(props: PatientProperty[], excludes: PatientProperty[]) {
		return props.filter((p) => !excludes.includes(p));
	}

	/**
	 * Matches the FeedProperty to the FeedPropertyItem.
	 */
	getPatientPropertyItems = (
		props: PatientProperty[]
	): PatientPropertyItem[] =>
		props.map((prop) => PATIENT_PROPERTIES.find((x) => x.label === prop));
}

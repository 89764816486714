import * as dayjs from "dayjs";
import {
	ContentProductType,
	ContentType,
	MilkState,
	MilkType,
	RecipeMode,
} from "src/app/app.enums";

import { IContent, MilkBottleModel } from "src/app/models/milk.model";
import { RecipeData } from "../models/recipe.model";
import { capitalize } from "../app.util";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { PatientModel } from "../models/patient.model";
import { ModifierOption } from "../components/milk-modifiers/milk-modifiers.component";
import { MilkBottleFeedPatientListItem } from "../administer-feed/feed-patient/list-item";
import { Product } from "../models/product.model";
import { getProductTypeText } from "./milk-bank-product.util";
import { FeedObject, FeedObjectModel } from "../models/feed-object.model";
import { ScannedProduct } from "../services/scanning.service";
import { SelectedProduct } from "../modals/select-product/select-product.modal";

/**
 * This converts format MMMM D, YYYY with `EXP: MM/DD/YY HH:mm` if it has an expiration date.
 * Otherwise it formats with `PMP: __/__/__ __:__ __`.
 *
 * @param expirationDate -
 */
export function formatLabelExpirationDate(
	createdOnDate: dayjs.Dayjs,
	expirationDate: dayjs.Dayjs
): string {
	let result;
	if (createdOnDate && !expirationDate) {
		result = "PMP: " + createdOnDate.format("MM/DD/YY HH:mm").toString();
	} else if (expirationDate) {
		result = "EXP: " + expirationDate.format("MM/DD/YY HH:mm").toString();
	} else {
		result = "PMP: __/__/__ __:__ __";
	}
	return result;
}

export const createFeedObjectBarcode = (feed: FeedObjectModel): string => {
	if (feed instanceof MilkBottleModel) {
		return createMilkBottleQRCode(feed.id);
	} else if (feed instanceof MilkBankProductModel) {
		return createMilkBankProductQRCode(feed.id);
	} else {
		throw Error("Invalid feed type");
	}
};

/**
 * Creates a QR code data string that represents a milk tracker milk bottle.
 * Milk Tracker QR codes start with `M|`, and
 * tracked milk bottles end with `|B`.
 *
 * @param milkBottleId MilkBottleModel.id
 * @returns A QR code data string that can be parsed as a milk tracker milk bottle
 */
export const createMilkBottleQRCode = (milkBottleId: string) =>
	`M|${milkBottleId}|B`;

/**
 * Creates a QR code data string that represents a milk tracker milk bottle using
 * the MilkBottleModel.unifiedId.
 * Milk Tracker QR codes start with `M|`, and
 * tracked milk bottles end with `|B`.
 *
 * @param milkBottleUnifiedId MilkBottleModel.unifiedId
 * @returns A QR code data string that can be parsed as a milk tracker milk bottle
 */
export const createMilkBottleQRCodeFromUnifiedId = (
	milkBottleUnifiedId: MilkBottleModel["unifiedId"]
) => `M|${milkBottleUnifiedId}|B`;

/**
 * Creates a QR code data string that represents a milk tracker milk bank product.
 * Milk Tracker QR codes start with `M|`, and
 * tracked milk bank products end with `|P`.
 *
 * @param milkBankProductId MilkBankProductModel.id
 * @returns A QR code data string that can be parsed as a milk tracker milk bank product
 */
export const createMilkBankProductQRCode = (milkBankProductId: string) =>
	`M|${milkBankProductId}|P`;

/**
 * Creates a QR code data string that represents a milk tracker milk bank product.
 * Milk Tracker QR codes start with `M|`, and
 * tracked milk bank products end with `|P`.
 *
 * @param milkBankProductUnifiedId MilkBankProductModel.unifiedId
 * @returns A QR code data string that can be parsed as a milk tracker milk bank product
 */
export const createMilkBankProductQRCodeFromUnifiedId = (
	milkBankProductUnifiedId: MilkBankProductModel["unifiedId"]
) => `M|${milkBankProductUnifiedId}|P`;

export function getMilkLabelWithSoonestExpirationDate(
	milkBottles: MilkBottleModel[]
): MilkBottleModel {
	return milkBottles.reduce((pre, cur) =>
		pre.expirationDate.isAfter(cur.expirationDate) ? cur : pre
	);
}

export const getCalorieDensityByRecipe = (recipeData: RecipeData) =>
	recipeData ? recipeData.calorieDensity : 0;

// TODO: Refactor candidate (write unit test and refactor)
export const isCombinable = (
	milkBottle: MilkBottleModel,
	milkBottles: MilkBottleModel[]
): Promise<any> => {
	let isCombinable: boolean;

	if (!atLeastOnePatientMatches(milkBottle, milkBottles)) {
		return Promise.reject({
			header: "Patient Mismatch",
			message: "Assigned patients do not match.",
		});
	}

	// If no milk bottle scanned, automatically add
	if (!milkBottles.length) {
		return Promise.resolve(true);
	}

	const hasEBM = !!milkBottles.find((m) => m.milkType === MilkType.EBM);
	const hasDM = !!milkBottles.find((m) => m.milkType === MilkType.DM);
	const hasFormula = !!milkBottles.find((m) =>
		m.contents.filter(
			(c) => c.contentProductType === ContentProductType.Formula
		)
	);
	const containsFortified = !!milkBottles.find((m) => m.isFortified);

	console.table({
		hasEBM,
		hasDM,
		hasFormula,
		containsFortified,
	});
	const has_ebm_ebm = !!milkBottles.find(
		(m) =>
			m.contents.filter(
				(c) => c.contentProductType === ContentProductType.EBM
			).length >= 2
	);
	// const has_ebm_formula = milkBottles.find(
	// 	(m) => m.milkType === "EBM + Formula"
	// );

	const has_dm_dm = !!milkBottles.find(
		(m) =>
			m.contents.filter(
				(c) => c.contentProductType === ContentProductType.DM
			).length >= 2
	);
	// const has_dm_formula = milkBottles.find(
	// 	(m) => m.milkType === "DM + Formula"
	// );
	//
	// const has_ebm_dm = milkBottles.find((m) => m.milkType === "EBM + DM");
	// const has_ebm_dm_formula = milkBottles.find(
	// 	(m) => m.milkType === "EBM + DM + Formula"
	// );

	/*
		If the scanned bottle contains an additive (fortified or formula), the rest have to have the same one as well.
		If the scanned bottle doesn't contain an additive, check if the queue does.
	 */
	const additive = milkBottle.contents.find(
		(c) => c.contentProductType === ContentProductType.Additive
	);
	if (additive) {
		const matchAdditive = !!milkBottles[0].contents.find(
			(c) => c.productId === additive.productId
		);
		if (matchAdditive) {
			return Promise.resolve(true);
		} else {
			return Promise.reject({
				header: "Milk State Error",
				message:
					"Can only combine fortified milk with other fortified milk and must have the same additives",
			});
		}
	}

	if (!additive && containsFortified) {
		return Promise.reject({
			header: "Milk State Error",
			message:
				"Can only combine fortified milk with other fortified milk and must have the same additives",
		});
	}

	if (
		milkBottle.milkState === MilkState.Fresh ||
		milkBottle.milkState === MilkState.Thawed
	) {
		const hasCombined = has_ebm_ebm || has_dm_dm;
		isCombinable =
			hasEBM || hasDM || !containsFortified || hasFormula || hasCombined;
	} else if (milkBottle.milkType === MilkType.DM) {
		const hasCombined = has_ebm_ebm || has_dm_dm;
		isCombinable =
			hasEBM || hasDM || !containsFortified || hasFormula || hasCombined;
	} else if (
		milkBottle.contents.filter(
			(c) => c.contentProductType === ContentProductType.Formula
		)
	) {
		const hasCombined = has_ebm_ebm || has_dm_dm;
		isCombinable =
			hasEBM || hasDM || !containsFortified || !hasFormula || hasCombined;
	} else if (milkBottle.isCombined) {
		isCombinable = hasEBM || hasDM || !containsFortified || hasFormula; // TODO
	} else {
		return Promise.reject({
			header: "Label Error",
			message:
				"The label scanned is not a Fresh or Thawed label. Please scan a Fresh or Thawed label to continue.",
		});
	}

	if (isCombinable) {
		return Promise.resolve(true);
	} else {
		return Promise.reject({
			header: "Milk State Error",
			message: "Cannot combine",
		});
	}
};

/**
 * Check if the assigned patients match 1 to 1
 * Only checking first milkBottle in the list is necessary because all of them should match
 */
export const assignedPatientsMatch = (
	milkBottle: MilkBottleModel,
	milkBottles: MilkBottleModel[]
): boolean => {
	if (!milkBottles.length) {
		return true;
	}
	return (
		milkBottles[0].patients.length === milkBottle.patients.length &&
		milkBottles[0].patients.every(
			(p) => !!milkBottle.patients.find((q) => p.mrn === q.mrn)
		)
	);
};

/**
 * Check if at least one patient matches
 * @param milkBottle
 * @param milkBottles
 * @see {@link https://angeleyehealth.atlassian.net/browse/ML-1612 ML-1612}
 */
export const atLeastOnePatientMatches = (
	milkBottle: MilkBottleModel,
	milkBottles: MilkBottleModel[]
): boolean => {
	if (!milkBottles.length) {
		return true;
	}

	return milkBottles
		.flatMap((m) => m.patients)
		.some((p2) => milkBottle.patients.some((p1) => p1.mrn == p2.mrn));
};

/**
 * If any milk is thawed, combined milk state is considered thawed.
 *
 * @deprecated Use {@link getCombinedFeedState} instead.
 */
export const getCombinedMilkState = (milkBottles: MilkBottleModel[]) => {
	// handle the case when all milk bottles are the same state
	if (milkBottles.every((m) => m.milkState === MilkState.Opened)) {
		return MilkState.Opened;
	} else if (milkBottles.every((m) => m.milkState === MilkState.Fresh)) {
		return MilkState.Fresh;
	} else if (milkBottles.every((m) => m.milkState === MilkState.Stable)) {
		return MilkState.Stable;
	} else if (milkBottles.every((m) => m.milkState === MilkState.Frozen)) {
		return MilkState.Frozen;
	} else if (milkBottles.every((m) => m.milkState === MilkState.Thawed)) {
		return MilkState.Thawed;
	}

	// handle the mixed case

	const anyFresh = milkBottles.some((m) => m.milkState === MilkState.Fresh);
	const anyThawed = milkBottles.some((m) => m.milkState === MilkState.Thawed);
	const anyStable = milkBottles.some((m) => m.milkState === MilkState.Stable);

	// Per Fiedler users will only feed a single Stable bottle,
	// and won't scan and feed other bottles in fresh/thawed states.
	// So we don't need to handle the logic for multiple scanned bottles.
	if (anyStable) {
		return MilkState.Stable;
	}

	return anyFresh && !anyThawed ? MilkState.Fresh : MilkState.Thawed;
};

/**
 * NOTE I needed getCombinedMilkState but without a milk bottle. TODO refactor
 * so there isn't duplicated code -- Jay Douglass
 */
export const getCombinedMilkStateFromStates = (milkStates: MilkState[]) => {
	// handle the case when all milk bottles are the same state
	if (milkStates.every((m) => m === MilkState.Opened)) {
		return MilkState.Opened;
	} else if (milkStates.every((m) => m === MilkState.Fresh)) {
		return MilkState.Fresh;
	} else if (milkStates.every((m) => m === MilkState.Stable)) {
		return MilkState.Stable;
	} else if (milkStates.every((m) => m === MilkState.Frozen)) {
		return MilkState.Frozen;
	} else if (milkStates.every((m) => m === MilkState.Thawed)) {
		return MilkState.Thawed;
	}

	// handle the mixed case

	const anyFresh = milkStates.some((m) => m === MilkState.Fresh);
	const anyThawed = milkStates.some((m) => m === MilkState.Thawed);
	const anyStable = milkStates.some((m) => m === MilkState.Stable);

	// Per Fiedler users will only feed a single Stable bottle,
	// and won't scan and feed other bottles in fresh/thawed states.
	// So we don't need to handle the logic for multiple scanned bottles.
	if (anyStable) {
		return MilkState.Stable;
	}

	return anyFresh && !anyThawed ? MilkState.Fresh : MilkState.Thawed;
};

/**
 * Fortified milk contains content with at least one additive
 *
 * TODO: can't we just check if isFortified?
 */
export const hasFortified = (milkBottles: MilkBottleModel[]): boolean =>
	!!milkBottles
		.map((m) => m.contents)
		.reduce((prev, curr) => prev.concat(curr), [])
		.find((c) => c.contentProductType === ContentProductType.Additive);

/**
 * Merge two contents and remove duplicates
 *
 * @param first
 * @param second
 */
export const mergeUniqueContents = (
	first: IContent[],
	second: IContent[]
): IContent[] => {
	const merged = [...first, ...second];
	return merged.filter((num, index) => merged.indexOf(num) === index); // remove dupes
};

/**
 * Returns the added contents (from being distributed to) of a milk model.
 */
export const getAddedContents = (milkBottle: MilkBottleModel): IContent[] =>
	milkBottle.milkAdded
		.map((m) => m.contents)
		.reduce((prev, curr) => prev.concat(curr), []); // flatten

export const getAddedContentsIds = (milkBottle: MilkBottleModel): string[] => [
	...milkBottle.milkAdded.map((m) => m.id),
	...milkBottle.sourceMilkBottleIds,
];

export const displayAddedContents = (
	milkBottle: MilkBottleModel
): { contentProductName: string; fromBottleNumber: number }[] => {
	const result = [];
	for (const m of milkBottle.milkAdded) {
		for (const c of m.contents) {
			// don't add duplicates
			const found = result.find(
				(o) =>
					o.contentProductName === c.contentProductName &&
					o.fromBottleNumber === m.bottleNumber
			);
			if (!found) {
				result.push({
					contentProductName: c.contentProductName,
					fromBottleNumber: m.bottleNumber,
				});
			}
		}
	}
	return result;
};

/**
 * Used to set the contents of the specified milk.
 *
 * Include the original contents of the milk.
 * The milk contents must include the additive(s) that was/were set in the recipe.
 * If milk was added to it (distributed into it), that content is set to `sourceMilkBottleIds`, not `contents`.
 */
export const createContents = (
	m: MilkBottleModel,
	recipeData: RecipeData
): IContent[] => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData); // Include fortifier(s)
	const result = [...m.contents, ...contentsFromRecipe];
	return result.filter((num, index) => result.indexOf(num) === index); // remove dupes
};

/**
 * This returns an ICotent[] that includes the fortifier(s) if the recipe was
 * set as well as the content of the milk bank product product in the form of
 * an IContent.
 *
 * TODO: are all assigned milk bank products Donor Milk?
 */
export const createContentsForMilkBankProduct = (
	milkBankProduct: MilkBankProductModel,
	recipeData: RecipeData
): IContent[] => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData);
	let result = [
		{
			id: null, // This is not set because the id is created on the server.
			milkBottleId: null,
			milkBankProductId: milkBankProduct.id,
			contentType: ContentType.Donor,
		} as IContent,
	];
	result = [...result, ...contentsFromRecipe];
	return result.filter((num, index) => result.indexOf(num) === index); // remove dupes
};

/**
 * This is technically a "combine" because it takes in a list of milk bank
 * products.
 *
 * @param milkBankProducts
 * @param recipeData
 */
export const createContentsForMilkBankProducts = (
	milkBankProducts: MilkBankProductModel[],
	recipeData: RecipeData
): IContent[] => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData); // Include fortifier(s)
	let result = [];
	for (const m of milkBankProducts) {
		result.push({
			milkBottleId: null,
			milkBankProductId: m.id,
			contentType: ContentType.Donor,
		} as IContent);
	}
	result = [...result, ...contentsFromRecipe];
	return result.filter((num, index) => result.indexOf(num) === index); // remove dupes
};

/**
 * If it is a UPC, then it wasn't received and doesn't have a milk bank product id
 */
const getMilkBankProductId = (
	scannedProduct: ScannedProduct
): FeedObjectModel["id"] =>
	scannedProduct.milkBankProduct ? scannedProduct.milkBankProduct.id : null;

/**
 * This gets the list of selected products that have not been scanned.
 *
 * @param scannedProducts
 * @param selectedProducts
 */
export const getUnscannedSelectedProducts = (
	selectedProducts: SelectedProduct[],
	scannedProducts: ScannedProduct[]
): SelectedProduct[] => {
	// Filter selected products that have not been scanned
	const unscannedSelectedProducts = selectedProducts.filter(
		(selectedProduct) => {
			const isScanned = scannedProducts.some(
				(scannedProduct) =>
					scannedProduct.product.id === selectedProduct.product.id
			);

			// Check if there is no corresponding scanned product with the same product ID
			return !isScanned;
		}
	);

	return unscannedSelectedProducts;
};

/**
 * When adding products (additives) pass the following format as the bottle contents when updating:
 * {
 *     contentType: "product"
 *     productId: number
 * }
 *
 * If it is already in the milk bank, only required to pass the milk bank product id:
 * {
 *     contentType: "milkbankproduct"
 *     milkBankProductId: number
 * }
 *
 * Create content from scanned products
 * Create content from selected products only if it scanned product is not in the list
 */
export const getContentsFromRecipe = (recipeData: RecipeData): IContent[] => {
	// console.log(
	// 	`getContentsFromRecipe recipeData ${JSON.stringify(
	// 		recipeData,
	// 		null,
	// 		2
	// 	)}`
	// );
	const result: IContent[] = [];
	if (recipeData) {
		const selectedProducts = recipeData.scanData.selectedProducts;
		const scannedProducts = recipeData.scanData.scannedProducts;

		for (const scannedProduct of scannedProducts) {
			result.push({
				milkBankProductId: getMilkBankProductId(scannedProduct),
				productId: scannedProduct.product.id,
				contentType: ContentType.Product,
			} as IContent);
		}

		/*
		 	Get the selected additives/products that have not been scanned
		 	(i.e. optional scan). Only get the ones that weren't scanned because
		 	we don't want to add the same product twice, and we want to make
		 	sure the additives/products are included in the contents.
		 */
		if (recipeData.mode === RecipeMode.MP) {
			const unscannedSelectedProducts = getUnscannedSelectedProducts(
				selectedProducts,
				scannedProducts
			);
			for (const selectedProduct of unscannedSelectedProducts) {
				result.push({
					milkBankProductId: null,
					productId: selectedProduct.product.id,
					contentType: ContentType.Product,
				} as IContent);
			}
		} else {
			const optionalAdditives = recipeData.scanData.selectedRecipes
				.map((selected) => selected.additives)
				.reduce((prev, curr) => prev.concat(curr), [])
				.filter((s) => !s.isScanned)
				.map((a) => a.additive);
			for (const optional of optionalAdditives) {
				const wasScanned = recipeData.scanData.scannedProducts.find(
					(o) => o.product.id === optional.productId
				);
				if (!wasScanned) {
					result.push({
						milkBankProductId: null,
						productId: optional.productId,
						contentType: ContentType.Product,
					} as IContent);
				}
			}
		}
	}

	return result;
};

/**
 * @deprecated duplicate of feed-info.util.ts, and {@link getFeedTypeText2}
 */
export const getFeedTypeText = (feed: FeedObjectModel): string => {
	if (feed instanceof MilkBottleModel) {
		return getMilkTypeText(feed);
	} else if (feed instanceof MilkBankProductModel) {
		return getProductTypeText(feed);
	} else {
		throw Error("Invalid feed type");
	}
};

/**
 * Formatted milk type text and status.
 * See ML-361, ML-418 for more discussion.
 */
export const getMilkTypeText = (milk: {
	isCombined: boolean;
	milkType: MilkType;
	milkState: MilkState;
}): string => {
	// Concatenated
	const isConcatenatedMilkType = milk.milkType.indexOf("+") !== -1;
	if (isConcatenatedMilkType) {
		return `${milk.milkType}`;
	}

	// Combined
	if (milk.isCombined) {
		return `${capitalize(milk.milkState)} Combined ${milk.milkType}`;
	}

	// Excluded
	const excludeMilkState = [MilkType.Formula];
	if (excludeMilkState.includes(milk.milkType)) {
		return `${milk.milkType}`;
	}

	return `${capitalize(milk.milkState)} ${milk.milkType}`;
};

/**
 * Formatted milk type text and status.
 * See ML-361 for more discussion.
 */
export const getPatientsFromMilks = (
	milks: MilkBottleModel[]
): PatientModel[] =>
	milks
		.map((m) => m.patients)
		.reduce((prev, curr) => prev.concat(curr), []) // flatten
		.filter(
			(patient, index, arr) =>
				arr
					.map((mappedPatient) => mappedPatient.mrn)
					.indexOf(patient.mrn) === index
		); // dedupe

export const hasModifiersSelected = (
	milks: { modifierOption: ModifierOption }[]
): boolean => milks.every((m) => m.modifierOption);

// TODO: handle UPC a different way.
export const hasProductModifiersSelected = (
	milks: { product: Product; modifierOption: ModifierOption }[]
): boolean => milks.every((m) => m.product?.hasUPC || m.modifierOption);

export const isInMilkBottleArray = (
	item: {
		milkBottle: MilkBottleModel;
	},
	arr: MilkBottleFeedPatientListItem[]
): boolean => {
	let id;

	if (item.milkBottle) {
		id = item.milkBottle.id;
	} else {
		throw Error("missing item");
	}

	return !!arr.find(
		(m: MilkBottleFeedPatientListItem) => m.milkBottle.id === id
	);
};

export const getVolumeText = (volume: number): string =>
	volume ? `${volume} mL` : "Unknown";

export const getBottleNumberText = (bottleNumber: number): string =>
	bottleNumber ? `${bottleNumber}` : "None";

export const getStatusColor = (milk: MilkBottleModel): string => {
	const element = "milk-info__header__state";

	if (milk.dischargeDate) {
		// This isn't added to MilkState because we iterate through
		// MilkState for the dropdown.
		return `${element}--discharged`;
	}

	return `${element}--${milk.milkState.toLowerCase()}`;
};

/**
 * Remove circular references from milkBottles. This also clones the array to
 * prevent mutating the original array.
 *
 * @param milks
 */
export const removeCircularDistribution = (
	milks: MilkBottleModel[]
): MilkBottleModel[] => {
	const clone = JSON.parse(JSON.stringify(milks)).map(
		(m) => new MilkBottleModel(m)
	);
	return clone.map((m) => {
		m.milkAdded = null;
		m.distributedTo = null;
		return m;
	});
};

/**
 * Used in Edit and Reprint
 *
 * The pump date can be edited when the milk bottle
 * is fresh or frozen and contains EBM.
 */
export const canEditPumpDate = (feedObject: FeedObject) => {
	if (feedObject instanceof MilkBankProductModel) {
		return false;
	}

	if (feedObject instanceof Product) {
		return false;
	}

	if (feedObject instanceof MilkBottleModel) {
		const containsEBM = feedObject.contents.some(
			(x) => x.contentProductType === ContentProductType.EBM
		);
		const isFresh = feedObject.milkState === MilkState.Fresh;
		const isFrozen = feedObject.milkState === MilkState.Frozen;

		return containsEBM && (isFresh || isFrozen);
	}
};

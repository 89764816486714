import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import type { IonInput } from "@ionic/angular";

@Component({
	selector: "app-input-positive-number",
	templateUrl: "./input-positive-numbers.component.html",
	styleUrls: ["./input-positive-numbers.component.scss"],
})
export class InputPositiveNumberComponent implements OnInit {
	@ViewChild("ionInputEl", { static: true }) ionInputEl!: IonInput;

	@Input() ariaLabel = "";
	@Input() minimumValue = 0;
	@Input() maximumValue;
	@Input() initialValue: number;
	@Input() disabled = false;
	@Input() step = "any";
	@Input() id = "";
	@Output() valueChange = new EventEmitter<number>();

	input: number;

	ngOnInit(): void {
		const isValidInitialValue = (this.initialValue && this.initialValue >= this.minimumValue) && this.initialValue <= this.maximumValue;
		this.input = isValidInitialValue ? this.initialValue : this.minimumValue;
	}

	handleInput(ev: Event) {
		const value = this.getValue((ev.target! as HTMLInputElement).value);

		this.ionInputEl.value = value;
		this.input = value;
		this.valueChange.emit(value);
	}

	getValue(value: string): number {
		return Number(value);
	}
}

import { environment } from "../../../environments/environment";
import { MilkLocation, ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import {
	handleExpirationDateFromScanGroups,
	isExpired,
} from "../../utils/expiration.util";
import {
	ScanMilkBankProductValidationError,
	ScanMilkBottleValidationError,
	ScannedObjectError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";
import { StorageService } from "../../services/storage.service";

export function RejectExpired2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			if (
				environment.settings.bypassExpiration ||
				StorageService.appConfigs.bypassExpiration
			) {
				console.warn(`BYPASSING EXPIRATION`);
				return originalMethod.apply(this, args);
			}

			// destructure the object
			const { feedObject } = obj;

			// determine feed object type and check if it was expired
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					if (
						feedObject.expirationDate &&
						isExpired(feedObject.expirationDate)
					) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Milk_Bottle_Expired,
							modalMessage.milk_expired(feedObject.expirationDate)
						);
					}
				} else if (feedObject instanceof MilkBankProductModel) {
					if (
						feedObject.expirationDate &&
						isExpired(feedObject.expirationDate)
					) {
						throw new ScanMilkBankProductValidationError(
							ScanLogType.Product_Expired,
							modalMessage.product_expired(
								feedObject.expirationDate
							)
						);
					}
				} else {
					console.error(
						`RejectExpired2: feedObject is not supported.`
					);
				}
			} else {
				console.error(`RejectExpired2: feedObject is undefined`);
			}

			// Receive Product, Thaw Product
			if (obj.scannedObject) {

				const expirationDate = handleExpirationDateFromScanGroups(obj.scannedObject?.match?.groups);

				if (isExpired(expirationDate)) {
					throw new ScannedObjectError(
						ScanLogType.Scanned_Object_Expired,
						modalMessage.receive_product_expired(expirationDate)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectExpired() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (StorageService.appConfigs.bypassExpiration) {
				console.warn(`BYPASSING EXPIRATION`);
			}

			// Milk Bottle
			if (obj.milkBottle && obj.milkBottle instanceof MilkBottleModel) {
				if (
					obj.milkBottle.location === MilkLocation.Home &&
					!obj.milkBottle.expirationDate
				) {
					console.warn(
						`Skipping expiration check because milk is at home and doesn't have an expiration date`
					);
				} else if (
					!StorageService.appConfigs.bypassExpiration &&
					isExpired(obj.milkBottle.expirationDate)
				) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Expired,
						modalMessage.milk_expired(obj.milkBottle.expirationDate)
					);
				}
			}

			// Milk Bank Product
			if (
				obj.milkBankProduct &&
				obj.milkBankProduct instanceof MilkBankProductModel
			) {
				if (
					!StorageService.appConfigs.bypassExpiration &&
					isExpired(obj.milkBankProduct.expirationDate)
				) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Expired,
						modalMessage.product_expired(
							obj.milkBankProduct.expirationDate
						)
					);
				}
			}

			// Receive Product, Thaw Product
			if (obj.scannedObject) {

				const expirationDate = handleExpirationDateFromScanGroups(obj.scannedObject?.match?.groups);

				if (isExpired(expirationDate)) {
					throw new ScannedObjectError(
						ScanLogType.Scanned_Object_Expired,
						modalMessage.receive_product_expired(expirationDate)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import { MilkBottleModel } from "../models/milk.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { FeedLabelStatus, ProductType } from "../app.enums";
import { getProductTypeText } from "./milk-bank-product.util";
import { getMilkTypeText } from "./milk-label.util";
import { FeedObject } from "../models/feed-object.model";
import { Product } from "../models/product.model";

// TODO: this is just an idea. I don't know if it will be useful.
// type FeedState = { productState } | { milkState } | { defaultState };

/*
 	TODO: adding support for Products (not just Milk Bottles and Milk Bank
 	 Products)? Probably best to use createMilkBankProductObject and create a
 	 child class of MilkBankProductModel like ReceivableProductModel.
 */
//
export const getFeedObjectColor = (feed: FeedObject): string => {
	const element = "milk-info__header__state";

	if (!feed) {
		return element;
		// throw Error("getFeedObjectColor: feed is required.");
	}

	if (feed instanceof MilkBottleModel) {
		/*
		 	This isn't added to MilkState because we iterate through MilkState for the dropdown.
		 	At one time, we iterated through the Milk State enums for a dropdown somewhere. (I can't remember where.)
		 	By adding "Discharge" to the dropdown, it would give users the option to select it which we didn't want thus
		 	the extra check.
		 */
		if (feed.dischargeDate) {
			return `${element}--discharged`;
		} else {
			return `${element}--${feed.milkState.toLowerCase()}`;
		}
	} else if (feed instanceof MilkBankProductModel) {
		return `${element}--${feed.productState.toLowerCase()}`;
	} else if (feed instanceof Product) {
		const product = feed as Product;
		return `${element}--${product.defaultState.toLowerCase()}`;
		// return product.name === "Water"
		// 	? `${element}--${product.defaultState.toLowerCase()}`
		// 	: `${element}--water`;
	} else {
		throw Error("ngOnInit: milkBottle xor milkBankProduct not found.");
	}
};

export const displayNewChip = (feed: FeedObject): boolean =>
	feed?.labelStatus === FeedLabelStatus.New;

export const getFeedTypeText = (feed: FeedObject): string => {
	if (feed instanceof MilkBottleModel) {
		return getMilkTypeText(feed);
	} else if (feed instanceof MilkBankProductModel) {
		return getProductTypeText(feed);
	} else {
		throw Error("ngOnInit: milkBottle xor milkBankProduct not found.");
	}
};

export const getFeedTypeText2 = (feed: FeedObject): string => {
	if (feed instanceof MilkBottleModel) {
		return getMilkTypeText(feed);
	} else if (feed instanceof MilkBankProductModel) {
		return getProductTypeText(feed);
	} else if (feed instanceof Product) {
		return (feed as Product).name;
	} else {
		throw Error("Invalid feed type");
	}
};

export const isWater = (feedObject: FeedObject): boolean => {
	if (feedObject instanceof Product) {
		return (feedObject as Product).type === ProductType.Water;
	} else {
		return false;
	}
};

/**
 * Returns true if a feed object has a modifier option selected.
 * Always ignore water.
 * If empty, then return false by default.
 */
export const hasFeedModifierSelected = (feedObjects: FeedObject[]): boolean => {
	if (!feedObjects || feedObjects.length === 0) {
		return false;
	}
	return feedObjects.every((f) => isWater(f) || f.modifierOption);
};

import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import {
	BehaviorSubject,
	fromEvent,
	Observable,
	pipe,
	Subject,
	Subscription,
} from "rxjs";
import { buffer, filter, map, tap } from "rxjs/operators";

import { ScanType } from "src/app/app.enums";

import { BaseService } from "src/app/services/base.service";

import { Product } from "src/app/models/product.model";

import { StorageService } from "src/app/services/storage.service";
import { ModifierOption } from "src/app/components/milk-modifiers/milk-modifiers.component";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { MilkBottleModel } from "src/app/models/milk.model";
import {
	createBarcodeScanResult,
	formatScanData,
	getMilkBottleQRCodeIdType,
	getWristband,
	getCustomLabel,
} from "../utils/scanning.util";
import { ModalType } from "../app.constants";
import { AuthGuardService } from "./guards";
import ScanbotSDK from "scanbot-web-sdk/ui";
import { applyActionBarConfig } from "../scanbot/config/action-bar.config";
import { applyPaletteConfig } from "../scanbot/config/palette.config";
import { applySingleScanningUseCase } from "../scanbot/config/single-scan.config";
import startScanner from "../scanbot/scanbot-scanner/launcher/start-scanner";
import { BarcodeScannerComponent } from "../components/barcode-scanner/barcode-scanner.component";

@Injectable({
	providedIn: "root",
})
export class ScanningService {
	private _scanStartListenerSub: Subscription;
	private _scanReady = new BehaviorSubject<boolean>(true);

	isScannerOpen: boolean;

	get scanReady$(): Observable<boolean> {
		if (StorageService.isWeb) {
			console.warn("Multi-scanner disabled for web...");
			return new BehaviorSubject<boolean>(false);
		}

		return this._scanReady;
	}

	constructor(
		public platform: Platform,
		private base: BaseService
	) {}

	setScanReady(isReady: boolean): void {
		this._scanReady.next(isReady);
	}

	async startScanbotScanner() {
		try {
			const config =
				new ScanbotSDK.UI.Config.BarcodeScannerScreenConfiguration();

			applySingleScanningUseCase(config);
			applyPaletteConfig(config);
			applyActionBarConfig(config);

			const result = await startScanner(config);
			return result;
		} catch (error) {
			console.error("Error scanning barcode:", error);
		}
	}

	/**
	 * @deprecated
	 * 	use startScanbotScanner instead
	 **/
	scan(): Promise<any> {
		if (this.platform.is("mobile")) {
			AuthGuardService.appFocusGuard = false;
			return this.scan2();
		}
	}

	public async scan2(): Promise<BarcodeScanResult> {
		const element = await this.base.modalService.presentModal({
			component: BarcodeScannerComponent,
			cssClass: ModalType.BarcodeScanner,
		});

		return new Promise<BarcodeScanResult>((resolve, reject) => {
			element.onDidDismiss().then((result) => {
				const barcode = result.data?.barcode;
				if (barcode) {
					resolve({
						format: barcode.format,
						cancelled: false,
						text: formatScanData(barcode.text), // using displayValue instead of rawValue because rawValue can include encoding we don't want
					});
				} else {
					reject("No barcode content");
				}
			});
			element.present();
		});
	}

	/**
	 *  Code Reader CR2600 relies on keyboard events when scanning.
	 *
	 *  returns Observable
	 */

	bufferActiveUntil(duration: number) {
		const closingNotifier = new Subject<void>();
		let timeout;

		return pipe(
			tap(() => {
				if (timeout == null) {
					timeout = setTimeout(() => {
						closingNotifier.next();
						timeout = null;
					}, duration);
				}
			}),
			buffer(closingNotifier)
		);
	}

	startKeyboardEventListener(): Observable<string> {
		return fromEvent(document, "keydown").pipe(
			filter((e: KeyboardEvent) => {
				console.warn(`${e.key} + ${e.code}`);
				return !INVALID_KEYS.find((key) => e.key === key);
			}),
			map((e: KeyboardEvent) => e.key),
			this.bufferActiveUntil(750),
			filter((v) => v.length > 4),
			map((buff) => formatScanData(buff.join("")))
		);
	}

	/**
	 * Parse and return scan value as string
	 */
	startRawScanListener(): Observable<string> {
		return new Observable((subscriber) => {
			if (this._scanStartListenerSub) {
				console.log("Unsubscribing from previous scan listener");
				this._scanStartListenerSub.unsubscribe();
			}
			console.log("Starting raw scan listener");
			const sub = this.startKeyboardEventListener().subscribe(
				(val) => {
					subscriber.next(val);
				},
				(error) => {
					subscriber.error(error);
				}
			);

			this._scanStartListenerSub = sub;
			StorageService.addSubscription(sub);
		});
	}

	/**
	 * Utilize getScannedObject to auto-detect EBM, DM, and wristbands
	 * rather and returning a ScannedObject based on a ScanType.
	 */
	startScanListener4(): Observable<ScannedObject> {
		return new Observable((subscriber) => {
			if (this._scanStartListenerSub) {
				console.log("Unsubscribing from previous scan listener...");
				this._scanStartListenerSub.unsubscribe();
			}
			console.log("Starting scan listener...");
			const sub = this.startKeyboardEventListener().subscribe({
				next: (val) => {
					const o = this.getScannedObject(val);
					console.log(
						`getScannedObject: ${JSON.stringify(o, null, 2)}`
					);

					subscriber.next(o);
				},
				error: (error) => {
					subscriber.error(error);
				},
				complete: () => {
					subscriber.complete();
				},
			});

			this._scanStartListenerSub = sub;
			StorageService.addSubscription(sub);
		});
	}

	stopScanListener() {
		if (this._scanStartListenerSub) {
			this._scanStartListenerSub.unsubscribe();
			console.log(
				`Stop scan listener... ${this._scanStartListenerSub.closed}`
			);
			this._scanStartListenerSub = null;
		} else {
			console.log("Stop scan listener doesn't exist or already stopped");
		}
	}

	/**
	 * getScannedObject is designed to check all available milk bottle (EBM and DM) regexes
	 * as well as patient wristband regexes and returns a ScannedObject
	 * containing information based on a match.
	 *
	 * By checking all possible potential matches, this allows for detection
	 * of invalid barcode scans (barcodes that aren't EBM, DM, or wristbands).
	 */
	getScannedObject(bufferString: string): ScannedObject {
		const milkTrackerQRCode = tryParseMilkTrackerQRCode(bufferString);
		if (milkTrackerQRCode) {
			return {
				isValid: true,
				scanType: ScanType.MilkLabel,
				milkTrackerQRCode,
			} as ScannedObject;
		}

		for (const label of StorageService.configs.getProductRegexes()) {
			const match: RegExpMatchArray = bufferString.match(
				new RegExp(label.pattern)
			);
			if (match) {
				console.log(
					`Barcode matched on ${label.manufacturerName} ${label.product.name}`
				);
				return {
					isValid: true,
					scanType: ScanType.Unreceived_Product,
					manufacturerName: label.manufacturerName,
					product: label.product,
					barcode: createBarcodeScanResult(match[0]),
					match,
				} as ScannedObject;
			}
		}
		const customLabel = getCustomLabel(
			StorageService.tenantConfigs?.wristbandRegex,
			bufferString
		);
		if (customLabel) {
			console.log(`Custom Label scan: Complete Barcode: ${bufferString}`);
			return {
				isValid: true,
				scanType: ScanType.Custom_Label,
				barcode: null,
				match: null,
				wristband: customLabel,
			} as ScannedObject;
		}

		const wristband = getWristband(
			StorageService.tenantConfigs?.wristbandRegex,
			bufferString
		);
		if (wristband) {
			console.log(`Wristband scan: Complete Barcode: ${bufferString}`);
			return {
				isValid: true,
				scanType: ScanType.Wristband,
				barcode: null, // why is this needed?
				match: null,
				wristband: wristband,
			} as ScannedObject;
		}

		/**
		 * If we get here, the barcode was not identifiable.
		 */
		return {
			isValid: false,
			barcode: createBarcodeScanResult(bufferString),
		} as ScannedObject;
	}
}

/**
 * Milk tracker QR code format for milk bottles and milk bank products.
 * Format: `M|{data}|{typeId}`, where typeId is B (milk bottle), or P (milk bank product).
 * data is either a guid (for milk bottles), or a milk bank product id.
 *
 * Allow case insensitivity incase the user has capslocks turned on
 */
const MILK_TRACKER_QR_CODE_REGEX = /M\|(?<data>.+)\|(?<typeId>[BP])/i;

/**
 * Use this function to parse all QR codes that are generated by milk tracker.
 *
 * @param value The QR code data
 * @returns MilkTrackerQRCode union type, or null if the QR code did not match a milk tracker QR code.
 * Use the type property on MilkTrackerQRCode to differentiate between milk bottles and milk bank products.
 */
function tryParseMilkTrackerQRCode(value: string): MilkTrackerQRCode | null {
	if (value == null) {
		return null;
	}
	const match = MILK_TRACKER_QR_CODE_REGEX.exec(value);
	const data = match?.groups?.data;
	const typeId =
		match?.groups?.typeId.toUpperCase() as MilkTrackerQRCode["typeId"];

	if (!data || !typeId) {
		return null;
	}

	switch (typeId) {
		case "B": {
			const milkBottleQRCodeIdType = getMilkBottleQRCodeIdType(data);

			return {
				type: "milkbottle",
				typeId,
				id:
					milkBottleQRCodeIdType === "guid"
						? { type: "guid", milkBottleId: data }
						: { type: "unifiedId", milkBottleUnifiedId: data },
			};
		}
		case "P": {
			const milkBankProductQRCodeIdType = getMilkBottleQRCodeIdType(data);

			return {
				type: "milkbankproduct",
				typeId,
				id:
					milkBankProductQRCodeIdType === "guid"
						? { type: "guid", milkBankProductId: data }
						: { type: "unifiedId", milkBankProductUnifiedId: data },
			};
		}
		default:
			throw new Error(
				"ScanningService.tryParseMilkTrackerQRCode: unknown MilkTrackerQRCode.typeId"
			);
	}
}

export type MilkTrackerQRCode =
	| MilkTrackerMilkBottleQRCode
	| MilkTrackerMilkBankProductQRCode;

/** We support both guid and unified ids (13 character HEX32) */
export interface MilkTrackerMilkBottleQRCode {
	type: "milkbottle";
	typeId: "B" | "b";
	id:
		| { type: "guid"; milkBottleId: MilkBottleModel["id"] }
		| {
				type: "unifiedId";
				milkBottleUnifiedId: MilkBottleModel["unifiedId"];
		  };
}

/** We support both guid and unified ids (13 character HEX32) */
export interface MilkTrackerMilkBankProductQRCode {
	type: "milkbankproduct";
	typeId: "P" | "p";
	id:
		| { type: "guid"; milkBankProductId: MilkBankProductModel["id"] }
		| {
				type: "unifiedId";
				milkBankProductUnifiedId: MilkBankProductModel["unifiedId"];
		  };
}

export interface BarcodeScanResult {
	format: string;
	cancelled: boolean;
	text: string;
}

export interface ScannedObject {
	isValid: boolean;
	message?: string;
	scanType: ScanType;
	manufacturerName: string;
	product: Product; // TODO: move to ScannedProduct?
	barcode: BarcodeScanResult;
	match: RegExpMatchArray;
	wristband?: IWristband;
	milkTrackerQRCode:
		| MilkTrackerQRCode
		| MilkTrackerMilkBankProductQRCode
		| null;
}

export interface ScannedProduct extends ScannedObject {
	modifierOption: ModifierOption;
	originalMilkBankProduct: string;
	milkBankProduct: MilkBankProductModel;
	milkTrackerQRCode: any;
	hasUPC: boolean;
}

export interface IWristband {
	type: PatientIdType;
	value: string;
}

export type PatientIdType = "mrn" | "ecd";

export const INVALID_KEYS = [
	"Alt",
	"AltLeft",
	"AltRight",
	"CapsLock",
	"CapsLock",
	"ContextMenu",
	"Delete",
	"End",
	"Enter",
	"Home",
	"Insert",
	"Meta",
	"Numpad0",
	"Numpad1",
	"Numpad2",
	"Numpad3",
	"Numpad4",
	"Numpad5",
	"Numpad6",
	"Numpad7",
	"Numpad8",
	"Numpad9",
	"PageDown",
	"PageUp",
	"Shift",
	"ShiftLeft",
	"ShiftRight",
	"Tab",
];

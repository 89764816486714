import { ProductState, ProductType, Units } from "src/app/app.enums";

export class Product {
	calorieDensity: number;
	caloriesPerUnit: number;
	displacementPerUnit: number;
	enforceScanning: boolean;
	containsHumanMilk: boolean;
	id: string;
	manufacturerName: string;
	manufacturerProductId: number;
	measuredUnit: Units;
	name: string;
	productCode: string;
	regexes: RegExp[];
	type: ProductType;
	additiveIncrement: number;
	defaultState: ProductState;

	// front end attributes
	hasUPC: boolean;

	// biome-ignore lint/suspicious/noExplicitAny:  allow additional fields on the model
	[propName: string]: any; // extra properties

	// biome-ignore lint/suspicious/noExplicitAny: allow additional fields on the model
	constructor(initial: any) {
		Object.keys(initial).forEach((key) => {
			if (key === "regexes") {
				// this.regex = new RegExp(initial.regexes[0]);
				this.hasUPC = !!initial.regexes.find(
					(r) => r.indexOf("upc") > -1
				);
				this.regexes = initial.regexes.map((r) => new RegExp(r));
			}
			if (key === "code") {
				this.productCode = initial.code;
			}
			// biome-ignore lint/nursery/noSecrets: <explanation>
			if (key === "volumeIncrementalConstraint") {
				this.additiveIncrement = initial.volumeIncrementalConstraint;
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface IProduct {
	calorieDensity: number;
	caloriesPerUnit: number;
	code: string;
	containsHumanMilk: boolean;
	displacementPerUnit: number;
	enforceScanning: boolean;
	id: number;
	manufacturerName: string;
	manufacturerProductId: number;
	measuredUnit: Units;
	name: string;
	regexes: string[];
	type: ProductType;
	volumeIncrementalConstraint: number;
	defaultState: ProductState;
	// biome-ignore lint/suspicious/noExplicitAny: allow extra properties
	[propName: string]: any; // extra properties
}

/** The allowed product states, based on the Product.DefaultState.
 *
 * i.e., only products with a default state of frozen can be thawed
 */
export const ValidProductStates: Map<ProductState, ProductState[]> = new Map([
	[ProductState.Frozen, [ProductState.Frozen, ProductState.Thawed]],
	[ProductState.Stable, [ProductState.Stable, ProductState.Opened]],
]);

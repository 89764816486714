import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { ScanMilkBankProductValidationError } from "./scan-validation-error";
import { IScanFeedValidation } from "./scan-validation.model";
import { isFrozen2 } from "../../utils/inventory.util";

/**
 * ML-1874: Specific to Assign Product.
 * Given the user scans a frozen DM to start,
 * don’t let them scan anything stable, opened, or thawed.
 */
export function RejectFrozenMismatch() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { milkBankProduct, milkBankProducts } = obj;

			if (isFrozen2(milkBankProducts[0]) && !isFrozen2(milkBankProduct)) {
				throw new ScanMilkBankProductValidationError(
					ScanLogType.Product_Mismatch,
					modalMessage.product_frozen_required(),
				);
			}

			return originalMethod.apply(this, args);
		};
	};
}

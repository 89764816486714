import dayjs from "dayjs";
import { MilkState } from "../app.enums";
import { MilkBottleModel } from "./milk.model";

export class ReceiveParentsMilkBottleModel {
	id: string;
	milkState: MilkState;
	volume?: number;
	pumpDate?: dayjs.Dayjs;
	thawedDate?: dayjs.Dayjs;

	constructor(
		initial: Partial<MilkBottleModel> = {} as Partial<MilkBottleModel>
	) {
		if (!initial) {
			return;
		}

		this.id = initial.id;
		this.milkState = initial.milkState;
		this.volume = initial.volume ?? null;
		this.pumpDate = initial.pumpDate ?? null;
		this.thawedDate = initial.thawedDate ?? null;
	}
}

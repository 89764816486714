<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="cancel()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{title}}</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<div class="container">
		<div *ngIf="header || message" class="header">
			<h1>{{ header }}</h1>
			<p>{{ message }}</p>
		</div>

		<!-- Thawed Date -->
		<app-inline-date
			label="Thaw Date"
			[defaultDate]="thawedDate"
			[minDate]="minDate"
			[maxDate]="maxDate"
			(dateTimeChange)="thawedDate = $event"
			[isExpirationDate]="false"
			[disabled]="false"
			#datePicker
			>
		</app-inline-date>

		<div class="buttons">
			<button (click)="confirm()" [disabled]="!isValid()" class="nav-btn">
				{{ doneText }}
			</button>
		</div>
	</div>
</ion-content>

<div class="container">
	<img [src]="iconPath" class="icon">
	<h1 class="title">
		{{title}}
	</h1>
	<ul class="messages">
		<li *ngFor="let message of messages">{{message}}</li>
	</ul>

	<div class="button-group">
		<button *ngIf="hasActionButton" (click)="action()" class="action-btn">
			{{actionButtonText}}
		</button>
		<button *ngIf="hasDismissButton" (click)="dismiss()" class="dismiss-btn">
			{{buttonText}}
		</button>
	</div>
	<p *ngIf="showDismissMessage">Closing in {{secondsUntilDismissed}} seconds...</p>
</div>

<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="cancel()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">select patient</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
    <ion-toolbar color="primary">
	    <ion-searchbar
			#selectPatientModalSearchInput
			type="text"
			debounce="500"
			showClearButton="true"
			(ionInput)="getItems($event)">
		</ion-searchbar>
    </ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<ion-list *ngIf="isItemAvailable">
		<ion-item *ngFor="let patient of filteredList"
				  button (click)="selectPatient(patient.mrn)"
				  lines="full">
			<ion-label class="ion-text-wrap" slot="start">
				{{patient.lastName}}, {{patient.firstName}}
				<p>Date of Birth: {{displayDateOfBirth(patient.dateOfBirth)}}</p>
			</ion-label>
			<ion-text class="ion-text-wrap" slot="end">{{patient.mrn}}</ion-text>
		</ion-item>
	</ion-list>
	<ion-list *ngIf="!isItemAvailable">
		<ion-item *ngFor="let patient of patientList"
				  button (click)="selectPatient(patient.mrn)"
				  lines="full">
			<ion-label class="ion-text-wrap" slot="start">
				{{patient.lastName}}, {{patient.firstName}}
				<p>Date of Birth: {{displayDateOfBirth(patient.dateOfBirth)}}</p>
			</ion-label>
			<ion-text class="ion-text-wrap" slot="end">{{patient.mrn}}</ion-text>
		</ion-item>
	</ion-list>
</ion-content>

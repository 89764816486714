import { Component, Input } from "@angular/core";
import { StorageService } from "../../services/storage.service";
import { BaseService } from "../../services/base.service";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
	@Input() isInSideMenu = false;
	protected readonly StorageService = StorageService;

	constructor(public base: BaseService) {}

	/**
	 * On click of the commit hash, copy it to the clipboard
	 */
	async copyCommitHash() {
		const copied = await StorageService.copyCommitHash();
		if (copied) {
			await this.base.toastService.presentToast("Copied to clipboard");
		}
	}
}

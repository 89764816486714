<ng-container *ngFor="let m of milks">
	<div class="milk-details">
		<div class="milk-details__icon">
			<fa-icon [icon]="m.icon" [fixedWidth]="true" size="sm"></fa-icon>
		</div>
		<div class="milk-details__label">{{ m.label }}</div>
		<div>
			<ng-container *ngFor="let value of m.values">
				<div class="milk-details__value">{{ value }}</div>
			</ng-container>
		</div>
	</div>
</ng-container>

<ng-container *ngFor="let p of products">
	<div class="milk-details">
		<div class="milk-details__icon">
			<fa-icon [icon]="p.icon" [fixedWidth]="true" size="sm"></fa-icon>
		</div>
		<div class="milk-details__label">{{ p.label }}</div>
		<div>
			<ng-container *ngFor="let value of p.values">
				<div class="milk-details__value">{{ value }}</div>
			</ng-container>
		</div>
	</div>
</ng-container>

<!-- Feeding Report -->
<ng-container *ngFor="let f of feedings">
	<div class="milk-details">
		<div class="milk-details__icon">
			<fa-icon [icon]="f.icon" [fixedWidth]="true" size="sm"></fa-icon>
		</div>
		<div class="milk-details__label">{{ f.label }}</div>
		<div>
			<ng-container *ngFor="let value of f.values">
				<div class="milk-details__value">{{ value }}</div>
			</ng-container>
		</div>
	</div>
</ng-container>

import { MilkState, ScanLogType } from "../../app.enums";
import { IModalMessage } from "../../app.modal-messages";
import { IPatient, PatientModel } from "../../models/patient.model";

export class ScanMilkBottleValidationError extends Error {
	modal: IModalMessage;
	scanLogType: ScanLogType;

	constructor(scanLogType: ScanLogType, modal: IModalMessage) {
		// Elevate the scanLogType as the message for testing.
		super(scanLogType);

		this.scanLogType = scanLogType;
		this.modal = modal;
	}
}

export class ScanMilkBankProductValidationError extends Error {
	modal: IModalMessage;
	scanLogType: ScanLogType;

	constructor(scanLogType: ScanLogType, modal: IModalMessage) {
		// Elevate the scanLogType as the message for testing.
		super(scanLogType);

		this.scanLogType = scanLogType;
		this.modal = modal;
	}
}

export class BottleNotReceivedError extends Error {
	scanLogType: ScanLogType;
	presetMilkState: MilkState;

	constructor(presetMilkState = null) {
		// Elevate the scanLogType as the message for testing.
		super(ScanLogType.Milk_Bottle_Not_Received);

		this.scanLogType = ScanLogType.Milk_Bottle_Not_Received;
		this.presetMilkState = presetMilkState;
	}
}

export class ScannedObjectError extends Error {
	modal: IModalMessage;
	scanLogType: ScanLogType;

	constructor(scanLogType: ScanLogType, modal: IModalMessage) {
		// Elevate the scanLogType as the message for testing.
		super(scanLogType);

		this.scanLogType = scanLogType;
		this.modal = modal;
	}
}

export class ScannedWristbandError extends Error {
	scanLogType: ScanLogType;
	modal: IModalMessage;
	// biome-ignore lint/suspicious/noExplicitAny: <explanation>
	[propName: string]: any;
	constructor(initial: { scanLogType: ScanLogType; modal: IModalMessage }) {
		super(initial.scanLogType); // Elevate the scanLogType as the message for testing.
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}
}

export class ScannedPatientError extends Error {
	scanLogType: ScanLogType;
	mrn: IPatient["mrn"];
	patient: PatientModel;

	constructor(mrn: IPatient["mrn"], patient: PatientModel) {
		// Elevate the scanLogType as the message for testing.
		super(ScanLogType.Scanned_Patient_Change);

		this.scanLogType = ScanLogType.Scanned_Patient_Change;
		this.mrn = mrn;
		this.patient = patient;
	}
}

export class MilkBankProductAlreadyReceivedError extends Error {
	modal: IModalMessage;

	constructor(modal: IModalMessage) {
		super();
		this.modal = modal;
	}
}

export class FrozenProductValidationError extends Error {}

<ion-list *ngIf="milkBankProduct">
	<app-edit-reprint-unassigned-list
		[milkBankProducts]="[milkBankProduct]"
		[isExpirationVisible]="false"
		[hasButtons]="false">
	</app-edit-reprint-unassigned-list>
	<br />

	<app-feed-state-select title="product state"
	                       [feed]="milkBankProduct"
	                       [presetState]="milkBankProduct.productState"
	                       [(state)]="milkBankProduct.feedState">
	</app-feed-state-select>

  <app-inline-date
  	label="Manufacturer Expiration Date"
	[defaultDate]="milkBankProduct.manufacturerExpirationDate"
	[minDate]="minManufacturerExpirationDate"
	[maxDate]="maxManufacturerExpirationDate"
	(inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBankProduct.manufacturerExpirationDate = $event; emit()"
	[isExpirationDate]="true"
	>
  </app-inline-date>

  <app-inline-date
  	*ngIf="milkBankProduct.productState === ProductState.Thawed"
	label="Thaw Date"
	[defaultDate]="milkBankProduct.thawedDate"
	[maxDate]="maxDate"
	(inputFocus)="handleInputFocus($event)"
	(dateTimeChange)="milkBankProduct.thawedDate = $event; emit()">
  </app-inline-date>

  <app-inline-date
  	*ngIf="milkBankProduct.productState === ProductState.Opened"
	label="Opened Date"
	[defaultDate]="milkBankProduct.openedDate"
	[maxDate]="maxDate"
	(inputFocus)="handleInputFocus($event)"
    (dateTimeChange)="milkBankProduct.openedDate = $event; emit()">
  </app-inline-date>

	<app-inline-date
  		label="Expended Date"
		[defaultDate]="milkBankProduct.expendedDate"
		[maxDate]="maxDate"
		(inputFocus)="handleInputFocus($event)"
		(dateTimeChange)="milkBankProduct.expendedDate = $event; emit()">
	</app-inline-date>
</ion-list>

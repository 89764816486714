<ion-footer no-border>
	<div *ngIf="!isInSideMenu" class="footer-wrapper">
		<p class="app-info">version {{StorageService.appVersion}}
			&#64;
			<a (click)="copyCommitHash()">{{StorageService.commitHash}}</a>
		</p>
	</div>

	<div *ngIf="isInSideMenu" class="side-menu-wrapper">
		<img src="assets/imgs/logo_milktracker.png" class="logo" />
		<p class="app-info">version {{StorageService.appVersion}}
			&#64;
			<a (click)="copyCommitHash()">{{StorageService.commitHash}}</a>
		</p>
	</div>
</ion-footer>

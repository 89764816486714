import { Injectable } from "@angular/core";
import {
	ActivatedRouteSnapshot,
	Router,
	RouterStateSnapshot,
} from "@angular/router";

import { AuthService } from "src/app/services/auth.service";
import { StorageService } from "src/app/services/storage.service";
import { IdleTimerService } from "../idle-timer.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class AuthGuardService {
	static appFocusGuard = true;

	constructor(
		public authService: AuthService,
		public router: Router,
		public idleTimerService: IdleTimerService
	) { }

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (!StorageService.authToken) {
			console.warn(
				`AuthGuardService.canActivate: blocked route ${state.url}`
			);
			window.location.href = environment.settings.clerkSignInURL;
			return false;
		}

		this.idleTimerService.initIdleTimer();

		return true;
	}
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBottleModel } from "../../models/milk.model";
import { PatientModel } from "../../models/patient.model";
import { getRelativeReceived } from "../../app.util";
import {
	getBottleNumberText,
	getMilkTypeText,
	getStatusColor,
} from "../../utils/milk-label.util";
import {
	MilkModifierEvent,
	ModifierOption,
} from "../milk-modifiers/milk-modifiers.component";
import { ScannedObject } from "../../services/scanning.service";
import {
	getRelativeExpirationTime,
	isExpired,
} from "../../utils/expiration.util";
import { StorageService } from "../../services/storage.service";
import { FeedLabelStatus } from "../../app.enums";

@Component({
	selector: "app-bottle-info",
	templateUrl: "./bottle-info.component.html",
	styleUrls: ["./bottle-info.component.scss"],
})
export class BottleInfoComponent implements OnInit {
	@Input() isContentVisible = true;
	@Input() isHeaderVisible = true;
	@Input() isInstructionsVisible = true;
	@Input() isDischarging = false;
	@Input() isPartiallyUsable = false;
	@Input() hasButtons = true;
	@Input() hasSelectMilkButton = true;
	@Input() patient: PatientModel;
	@Input() title = "Milk Info";
	@Input() instructions = "Step 2: Scan milk";
	@Input() milks: MilkBottleModel[];
	@Input() options: ModifierOption[];
	@Input() totalBottleCount: number;
	@Input() showPaginatedChip = false;
	@Output() modifierPress = new EventEmitter<MilkModifierEvent>();
	@Output() selectMilkPress = new EventEmitter<ScannedObject>();
	@Output() removeButtonPressed = new EventEmitter<MilkBottleModel>();

	getBottleNumberText = getBottleNumberText;
	getMilkTypeText = getMilkTypeText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	getRelativeReceived = getRelativeReceived;
	getStatusColor = getStatusColor;
	isExpired = isExpired;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	get isPaginated(): boolean {
		return this.totalBottleCount !== null;
	}

	selectMilkModifier(option: ModifierOption, milk: MilkBottleModel) {
		this.modifierPress.emit({ option, milk });
	}

	remove(m: MilkBottleModel) {
		this.removeButtonPressed.emit(m);
	}

	selectMilk() {
		this.selectMilkPress.emit();
	}

	getContents = (milk: MilkBottleModel): string =>
		milk.contents
			.map((x) => x.contentProductCode)
			.filter((id, index, arr) => arr.indexOf(id) === index) // dedupe
			.join(", ");

	displayNewChip(milkBottle: MilkBottleModel): boolean {
		return milkBottle.labelStatus === FeedLabelStatus.New;
	}

	protected readonly StorageService = StorageService;
}

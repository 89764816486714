import {
	AfterViewInit,
	Component,
	ElementRef,
	NgZone,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import {
	Barcode,
	BarcodeScanner,
	LensFacing,
	StartScanOptions,
} from "@capacitor-mlkit/barcode-scanning";
import { BaseService } from "src/app/services/base.service";

@Component({
	selector: "app-barcode-scanner",
	templateUrl: "./barcode-scanner.component.html",
	styleUrls: ["./barcode-scanner.component.scss"]
})

export class BarcodeScannerComponent
	implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild("square")
	squareElement: ElementRef<HTMLDivElement> | undefined;

	isTorchAvailable: boolean = false;
	isTorchOn: boolean = false;

	constructor(
		private readonly ngZone: NgZone,
		private base: BaseService
	) { }

	ngOnInit(): void {
		BarcodeScanner.isTorchAvailable().then((result) => {
		  this.isTorchAvailable = result.available;
		});
	  }

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.startScan();
		}, 250);
	}

	ngOnDestroy(): void {
		this.stopScan();
	}

	async closeModal(barcode?: Barcode): Promise<void> {
		document.querySelector("body")?.classList.remove("barcode-scanning-modal-active");

		this.base.modalService.dismissModalWithData({
			barcode: barcode
		});
	}

	async toggleTorch(): Promise<void> {
		await BarcodeScanner.toggleTorch();
		this.isTorchOn = !this.isTorchOn;
	  }

	async startScan(): Promise<void> {
		// Hide everything behind the modal (see src/theme/modals.scss)
		document.querySelector("body")?.classList.add("barcode-scanning-modal-active");

		const options: StartScanOptions = {
			lensFacing: LensFacing.Back
		};

		// Retrieves the bounding client rectangle of the square element or null if the element is not found.
		const squareElementBoundingClientRect =
			this.squareElement?.nativeElement.getBoundingClientRect();

		// Calculates the scaled rectangle based on the provided square element's bounding client rect.
		// The scaling is done using the window's device pixel ratio.
		// returns The scaled rectangle object or undefined if the square element's bounding client rect is not provided.
		const scaledRect = squareElementBoundingClientRect
			? {
				left: squareElementBoundingClientRect.left * window.devicePixelRatio,
				right:
					squareElementBoundingClientRect.right * window.devicePixelRatio,
				top: squareElementBoundingClientRect.top * window.devicePixelRatio,
				bottom:
					squareElementBoundingClientRect.bottom * window.devicePixelRatio,
				width:
					squareElementBoundingClientRect.width * window.devicePixelRatio,
				height:
					squareElementBoundingClientRect.height * window.devicePixelRatio,
			}
			: undefined;

		// Calculates the detection corner points based on the scaled rectangle.
		const detectionCornerPoints = scaledRect
			? [
				[scaledRect.left, scaledRect.top],
				[scaledRect.left + scaledRect.width, scaledRect.top],
				[
					scaledRect.left + scaledRect.width,
					scaledRect.top + scaledRect.height,
				],
				[scaledRect.left, scaledRect.top + scaledRect.height],
			]
			: undefined;

		//Listens for barcode scanned events and handles the event callback.
		const listener = await BarcodeScanner.addListener(
			"barcodeScanned",
			async (event) => {
				this.ngZone.run(() => {
					const cornerPoints = event.barcode.cornerPoints;
					if (detectionCornerPoints && cornerPoints) {
						if (
							// this will only scan barcodes that are inside of the square
							detectionCornerPoints[0][0] > cornerPoints[0][0] ||
							detectionCornerPoints[0][1] > cornerPoints[0][1] ||
							detectionCornerPoints[1][0] < cornerPoints[1][0] ||
							detectionCornerPoints[1][1] > cornerPoints[1][1] ||
							detectionCornerPoints[2][0] < cornerPoints[2][0] ||
							detectionCornerPoints[2][1] < cornerPoints[2][1] ||
							detectionCornerPoints[3][0] > cornerPoints[3][0] ||
							detectionCornerPoints[3][1] < cornerPoints[3][1]
						) {
							return;
						}
					}
					listener.remove();
					this.closeModal(event.barcode);
				});
			},
		);
		await BarcodeScanner.startScan(options);
	}

	private async stopScan(): Promise<void> {
		// Show everything behind the modal again
		document.querySelector("body")?.classList.remove("barcode-scanning-modal-active");

		try {
			await BarcodeScanner.stopScan();
		} catch(error) {
			//
		}

	}
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { faBars, faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { StorageService } from "src/app/services/storage.service";

@Component({
	selector: "app-navbar",
	templateUrl: "./navbar.component.html",
	styleUrls: ["./navbar.component.scss"],
})
export class NavbarComponent implements OnInit {
	@Input() hasBackButton = true;
	@Input() isRetry = false;
	@Input() title: string;
	@Input() backButtonText = "back";
	@Output() backButtonPress = new EventEmitter();
	@Output() menuToggle = new EventEmitter<boolean>();

	get isWeb() {
		return StorageService.isWeb;
	}
	get isMenuOpen() {
		return StorageService.isMenuOpen;
	}

	faChevronLeft = faChevronLeft;
	faBars = faBars;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	goBack() {
		this.backButtonPress.emit();
	}

	toggleMenu(isOpen: boolean) {
		this.menuToggle.emit(isOpen);
	}
}

// FIXME: This overlaps with ProductType. Aren't all base types product types?
export enum BaseType {
	Water = "water",
	EBM = "ebm",
	RTF = "rtf",
	DM = "donor",
}

export enum ContentType {
	Parent = "mothers",
	Donor = "milkbankproduct",
	Product = "product",
}

export enum ContentProductType {
	EBM = "ebm",
	DM = "donor",
	RTF = "rtf",
	Additive = "additive",
	Formula = "formula",
	Water = "water",
}

export enum MilkLocation {
	Home = "home",
	Hospital = "hospital",
}

export type FeedState = MilkState | ProductState;

export enum MilkState {
	Fresh = "fresh",
	Frozen = "frozen",
	Thawed = "thawed",
	Stable = "stable",
	Opened = "opened",
}

export enum ProductState {
	Frozen = "frozen",
	Thawed = "thawed",
	Stable = "stable",
	Opened = "opened",
}

export type FeedType = MilkType | ProductType;

export enum MilkType {
	Parent = "mothers",
	EBM = "EBM",
	DM = "DM",
	Formula = "Formula",
	EBM_EMB = "EBM + EBM",
	EBM_Formula = "EBM + Formula",
	DM_DM = "DM + DM",
	DM_Formula = "DM + Formula",
	EBM_DM = "EBM + DM",
	EBM_DM_Formula = "EBM + DM + Formula",
}

export enum MilkBottleUseType {
	Feeding = "feeding",
	OralCare = "oral-care",
	SkinCare = "skin-care",
}

export enum ProductType {
	Additive = "additive",
	Formula = "formula",
	RTF = "rtf",
	Donor = "donor",
	Water = "water",
	EBM = "ebm",
}

export enum Sex {
	Female = "female",
	Male = "male",
}

export enum ScanLabelTitle {
	Patient = "Patient",
	Label = "Label",
	Product = "Product",
}

export enum ScanType {
	Wristband = "wristband",
	/** ScannedObject.milkTrackerQrCode will have a value if it's this ScanType */
	MilkLabel = "milk-label",
	Custom_Label = "custom-label",
	Unreceived_Product = "factory-donor-label",
}

export enum ScanLogWorkflow {
	Assign_Product = "assign-product",
	Milk_Room_Prep = "milk-room-prep",
	Discharge_Milk = "discharge-milk",
	Divide_Feed = "divide-feed",
	Edit_Reprint_Label = "edit-reprint-label",
	Feed_Override_Form = "feed-override-form",
	Feeding = "feeding",
	Feeding_Report = "feeding-report",
	Freeze_Parents_Milk = "freeze-milk",
	Link_Siblings = "link-siblings",
	Oral_Care = "oral-care",
	Patient_Inventory_Report = "patient-inventory-report",
	Prep_Formula = "prep-formula",
	Prep_Milk = "prep-milk",
	Print_Fresh = "print-fresh",
	Print_Parent = "print-parent",
	Receive_Milk = "receive-milk",
	Receive_Product = "receive-product",
	Select_Additive = "select-additive",
	Select_Product = "select-product",
	Thaw_Patients_Milk = "thaw-patients-milk",
	Thaw_Product = "thaw-product",
	Verify_Milk = "verify-label",
	Print_Custom_Label = "print-custom-label",
}

export enum ScanLogType {
	//* Milk Bottle
	Bottle_Already_Assigned = "bottle-already-assigned",
	Milk_Bottle_Density_Mismatch = "milk-bottle-density-mismatch",
	Milk_Bottle_Discharged = "milk-bottle-discharged",
	Milk_Bottle_Duplicate = "milk-bottle-duplicate",
	Milk_Bottle_Expended = "milk-bottle-expended",
	Milk_Bottle_Expired = "milk-bottle-expired",
	Milk_Bottle_Fortified_Cannot_Be_Frozen = "milk-bottle-fortified-cannot-be-frozen",
	Milk_Bottle_Incompatible_Contents = "milk-bottle-incompatible-contents",
	Milk_Bottle_Incompatible_State = "milk-bottle-incompatible-state",
	Milk_Bottle_Incompatible_Type = "milk-bottle-incompatible-type",
	Milk_Bottle_Match = "milk-bottle-match",
	Milk_Bottle_Mismatch = "milk-bottle-mismatch",
	Milk_Bottle_Not_Received = "milk-bottle-not-received",
	Milk_Bottle_Previously_Fed = "milk-bottle-previously-fed",
	Milk_Bottle_Previously_Discharged = "milk-bottle-previously-discharged",
	Milk_Bottle_Previously_Received = "milk-bottle-previously-received",
	Milk_Bottle_Unknown = "milk-bottle-unknown",
	//* Nurse
	Nurse_Duplicate = "nurse-duplicate",
	Nurse_Invalid = "nurse-invalid",
	//* Patient
	Patient_Duplicate = "patient-duplicate",
	Patient_MRN_Mismatch = "patient-mrn-mismatch",
	Patient_Previously_Fed = "patient-previously-fed",
	Patient_Required = "patient-required",
	//* Product
	Product_Defective = "product-defective",
	Product_Discarded = "product-discarded",
	Product_Duplicate = "product-duplicate",
	Product_Expended = "product-expended",
	Product_Expired = "product-expired",
	Product_Incompatible_State = "product-incompatible-state",
	Product_Invalid = "product-invalid",
	Product_Mismatch = "product-mismatch",
	Product_Not_Received = "product-not-received",
	Product_Success = "product-success",
	Product_Unassigned = "product_unassigned",
	Product_Unknown = "product-unknown",
	Product_Recalled = "product-recalled",
	Water_Already_Selected = "water-already-selected",
	//* Scanned Object
	Scanned_Object_Duplicate = "scanned-object-duplicate",
	Scanned_Object_Expired = "scanned-object-expired",
	Scanned_Object_Invalid = "scanned-object-invalid",
	Scanned_Patient_Change = "scanned-patient-change",
	//* Custom Label
	Custom_Label_Invalid = "custom-label-invalid",
}

export enum RecipeCalculatorType {
	EBM_Volume_On_Hand = "volume-on-hand",
	EBM_Desired_Volume = "desired-volume",
	EBM_Manual_Prep = "ebm-manual-prep",
	Formula_Desired_Volume = "formula",
	Formula_Manual_Prep = "formula-manual-prep",
	DM_Desired_Volume = "dm-desired-volume",
	DM_Volume_On_Hand = "dm-volume-on-hand",
	DM_Manual_Prep = "dm-manual-prep",
}

export enum RecipePageType {
	EBM = "ebm",
	DM = "dm",
	Formula = "formula",
	Milk_Room_Prep = "milk-room-prep",
}

export enum RecipeMode {
	VOH = "Volume On Hand",
	DV = "Desired Volume",
	MP = "Manual Prep",
}

export enum Units {
	Milliliters = "mL",
	Grams = "g",
}

export enum PrepFeedQueryParams {
	Divide_Feed = "divide-feed",
}

/** Use this in the default block of a switch statement to check for an "exhaustive switch" of the enum at compile time.
 * https://www.fullstory.com/blog/discriminated-unions-and-exhaustiveness-checking-in-typescript/ */
export const assertExhaustive = (
	value,
	message = "Reached unexpected case in exhaustive switch"
): never => {
	throw new Error(message);
};

export enum InventoryCategory {
	All = "all",
	Discharged = "discharged",
	Fresh = "fresh",
	Frozen = "frozen",
	Home = "home",
	Prepared = "prepared",
	Stable = "stable",
	Recalled = "recalled",
	Thawed = "thawed",
	Expended = "expended",
	Opened = "opened",
}

export enum PaginationAction {
	New = "new",
	Append = "append",
}

export enum FeedLabelStatus {
	New = "new",
	Updated = "updated",
}

import { Injectable } from "@angular/core";
import { BaseService } from "../../services/base.service";
import {
	ScannedObject,
	ScannedProduct,
	ScanningService,
} from "../../services/scanning.service";
import { ModalController } from "@ionic/angular";
import { StorageService } from "../../services/storage.service";
import { environment } from "../../../environments/environment";
import {
	assertExhaustive,
	ProductState,
	RecipeMode,
	ScanLabelTitle,
	ScanLogType,
	ScanLogWorkflow,
	ScanType,
} from "../../app.enums";
import { Subscription } from "rxjs";
import { displayDate, displaySelectedOrder } from "../../app.util";
import {
	IMilkBankProduct,
	MilkBankProductModel,
} from "../../models/milk-bank-product.model";
import { isExpired } from "../../utils/expiration.util";
import { displayAdditives } from "../../utils/recipe-calculator.util";
import { EditMilkModal } from "../edit-milk/edit-milk.modal";
import { ModalType } from "../../app.constants";
import { ScannedAdditive } from "../../models/recipe.model";
import { SelectedProduct } from "./select-product.modal";
import { ScanOptions } from "../../base.page";
import { getBottleNumberText } from "../../utils/milk-label.util";
import { faCircleCheck } from "@fortawesome/free-solid-svg-icons";
import {
	FrozenProductValidationError,
	ScanMilkBankProductValidationError,
	ScannedObjectError,
} from "../../decorators/scan-validation/scan-validation-error";
import { modalMessage } from "../../app.modal-messages";
import {
	LogMilkBankProductModel,
	LogScannedObjectModel,
} from "../../models/log-scan.model";
import dayjs from "dayjs";
import { RejectRecalledProductOnReceive } from "src/app/decorators/scan-validation";
import { Product } from "src/app/models/product.model";
import { AuthGuardService } from "../../services/guards";
import { handleExpirationDateFromScanGroups } from "../../utils/expiration.util";

@Injectable()
export class BaseSelectProduct {
	isDebug: boolean;
	isWeb: boolean;
	isItemAvailable = false;
	allowOrderValidation: boolean;
	scannedProducts: ScannedProduct[] = []; // data used to pass back

	state = 1;

	RecipeMode = {
		VOH: RecipeMode.VOH,
		DV: RecipeMode.DV,
		MP: RecipeMode.MP,
	};

	displayAdditives = displayAdditives;
	displayDate = displayDate;
	displaySelectedOrder = displaySelectedOrder;
	getBottleNumberText = getBottleNumberText;
	handleExpirationDateFromScanGroups = handleExpirationDateFromScanGroups;
	parseInt = Number.parseInt;

	faCircleCheck = faCircleCheck;
	workflow = ScanLogWorkflow.Select_Product;

	constructor(
		public base: BaseService,
		public scanningService: ScanningService,
		public modalCtrl: ModalController
	) {
		this.isDebug = !environment.production;
		this.isWeb = StorageService.isWeb;

		if (StorageService.configs.tenant) {
			this.allowOrderValidation = StorageService.appConfigs
				?.bypassOrderMatching
				? false
				: StorageService.configs.tenant.validateOrders || false;
		}
	}

	// View management

	cleanup() {
		this.scanningService.stopScanListener();
		StorageService.unsubscribeAll();
	}

	handleLeaving() {
		this.scannedProducts = [];
		this.state = 1;
		this.cleanup();
	}

	async presentLeavingAlert() {
		await this.base.alerts.presentAlert({
			header: "Unsaved Changes",
			message: "Scanned additives will not be saved",
			backdropDismiss: false,
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
				},
				{
					text: "Go Back",
					handler: () => {
						this.handleLeaving();
					},
				},
			],
		});
	}

	// Navigation

	async prev() {
		if (this.state === 2) {
			await this.presentLeavingAlert();
		} else {
			await this.modalCtrl.dismiss();
		}
	}

	next() {
		this.state++;
	}

	// Scan processing

	startScanListener(): Subscription {
		return StorageService.addSubscription(
			this.scanningService.startScanListener4().subscribe(async (o) => {
				await this.handleScannedObject(o);
			})
		);
	}

	stopScanListener() {
		this.scanningService.stopScanListener();
	}

	getScanOptions = (): ScanOptions => ({
		isWeb: this.isWeb,
		currentStep: this.state === 2,
		items: [{ title: ScanLabelTitle.Label, isVisible: true }],
	});

	/**
	 * Scan and search for matches on additives
	 */
	async scan2() {
		if (this.isDebug && StorageService.isWeb) {
			await this.showDebugScanAlert();
		} else {
			this.scanningService.scan().then(async (barcode) => {
				const o = this.scanningService.getScannedObject(barcode.text);
				// console.log(`getScannedObject: ${JSON.stringify(o, null, 2)}`);
				await this.handleScannedObject(o);
				AuthGuardService.appFocusGuard = true;
			});
		}
	}

	// Debug stuff

	async showDebugScanAlert() {
		await this.base.alerts.presentAlert({
			header: "MOBILE DEBUG SCAN",
			subHeader: "",
			message: "Scan barcode to simulate mobile scanning flow",
			backdropDismiss: false,
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					handler: () => {
						this.cleanup();
					},
				},
			],
		});

		const sub = this.scanningService
			.startScanListener4()
			.subscribe(async (o) => {
				await this.handleScannedObject(o);
				this.scanningService.stopScanListener();
				sub.unsubscribe();
				this.base.alerts.dismissAlert();
			});
	}

	async handleScannedObject(scannedObject: ScannedObject) {
		const scanType = scannedObject.scanType;
		if (!scannedObject.isValid) {
			await this.base.modalService.presentUnknownScanError({
				workflow: this.workflow,
			});
			return;
		}

		const scannedProduct = scannedObject as ScannedProduct;
		switch (scanType) {
			case ScanType.Custom_Label:
			case ScanType.Wristband:
			case ScanType.MilkLabel:
				await this.handleMilkBankProductScanned(scannedProduct);
				break;
			case ScanType.Unreceived_Product:
				await this.handleProductScanned(scannedProduct);
				break;
			default:
				console.error(
					`handleScannedObject: unknown scan type ${scanType}`
				);
				assertExhaustive(scanType);
		}
	}

	// biome-ignore lint/suspicious/noExplicitAny: overridden in subclass
	findSelectedProduct(productId: Product["id"]): any {
		// biome-ignore lint/nursery/noSecrets: <explanation>
		console.warn("findSelectedProduct not implemented.");
	}

	// TODO: I feel like this can be more DRY because of handleProductScanned
	async handleMilkBankProductScanned(scannedProduct: ScannedProduct) {
		// biome-ignore lint/nursery/noSecrets: <explanation>
		console.warn("handleMilkBankProductScanned not implemented.");
	}

	/**
	 * - Check already scanned
	 * - Check scanned product matches selected product(s)
	 * - Check manufacturer expiration date
	 * - If UPC, it's not receivable so mark it as scanned and add it
	 * - If scanned product is receivable (does not have UPC), check to see if it was received.
	 *   If it wasn't received, receive it.
	 * - Check if expended
	 * - Check milk bank product expiration
	 */
	async handleProductScanned(scannedProduct: ScannedProduct) {
		try {
			// Check already scanned
			const duplicate = this.scannedProducts.find(
				(p) => p.barcode.text === scannedProduct.barcode.text
			);
			if (duplicate) {
				throw new ScannedObjectError(
					ScanLogType.Scanned_Object_Duplicate,
					modalMessage.scan_duplicate()
				);
			}

			// Check scanned product matches selected product(s)
			const selectedProduct = this.findSelectedProduct(
				scannedProduct.product.id
			);
			if (!selectedProduct) {
				throw new ScannedObjectError(
					ScanLogType.Product_Invalid,
					modalMessage.scan_product_invalid(scannedProduct)
				);
			}

			// Check if recalled
			const product = scannedProduct.product;
			const lotNumber = scannedProduct.match.groups.lotCode;
			try {
				await this.checkIfRecalled({ product, lotNumber: lotNumber });
			} catch (error) {
				if (error instanceof ScanMilkBankProductValidationError) {
					const { header, message, modalType } = error.modal;
					await this.base.modalService.presentOverlayModal(
						modalType,
						header,
						[message]
					);

					await this.base.loggingService.logScan(
						new LogScannedObjectModel({
							type: error.scanLogType,
							workflow: this.workflow,
							productId: product.id,
						})
					);
					return;
				}
				console.error(error);
			}

			// Check manufacturer expiration date
			const expirationDate = handleExpirationDateFromScanGroups(
				scannedProduct?.match?.groups
			);
			if (
				!StorageService.appConfigs.bypassExpiration &&
				isExpired(expirationDate)
			) {
				throw new ScannedObjectError(
					ScanLogType.Product_Expired,
					modalMessage.product_expired(expirationDate)
				);
			}

			// Some products like Neosure can be a UPC or have a unique barcode pattern that matches via regex
			// If product is not parseable (is a UPC code)
			if (scannedProduct.match.groups.upc) {
				await this.handleValidationSuccess({
					scannedProduct,
					selectedProduct,
					milkBankProduct: null,
				});
				return;
			}

			// If scanned product is receivable (does not have UPC), check to see if it was received.
			let milkBankProduct =
				await this.base.inventoryService.getMilkBankProductByBarcode(
					scannedProduct.barcode.text
				);

			// If it wasn't received already, receive it
			if (!milkBankProduct) {
				// A barcode is unique when there is a bottle number in the barcode.
				// For barcodes without a bottle number, generate a unique barcode so
				// users can scan the same product later.
				if (!scannedProduct.match.groups?.bottleNumber) {
					scannedProduct.barcode.text = dayjs().unix().toString();
				}
				milkBankProduct = await this.receive(scannedProduct);
				if (!milkBankProduct) {
					throw new ScannedObjectError(
						ScanLogType.Product_Not_Received,
						modalMessage.product_not_received_error()
					);
					// biome-ignore lint/style/noUselessElse: <explanation>
				} else {
					console.log("Successfully Received Product");
				}
			}

			// Check if expended
			if (milkBankProduct.expendedDate) {
				throw new ScannedObjectError(
					ScanLogType.Product_Expended,
					modalMessage.product_expended_with_date(milkBankProduct)
				);
			}

			// Check milk bank product expiration
			if (
				!StorageService.appConfigs.bypassExpiration &&
				isExpired(milkBankProduct.expirationDate)
			) {
				throw new ScannedObjectError(
					ScanLogType.Product_Expired,
					modalMessage.milk_expired(milkBankProduct.expirationDate)
				);
			}

			// Check if frozen. Frozen milk must be thawed
			if (milkBankProduct.productState === ProductState.Frozen) {
				this.base.loggingService.logScan(
					new LogMilkBankProductModel({
						type: ScanLogType.Milk_Bottle_Expired,
						workflow: this.workflow,
						milkBankProductId: milkBankProduct.id,
						patientId: null,
					})
				);

				await this.presentThawProductModal({
					scannedProduct,
					selectedProduct,
					milkBankProduct,
				});
				return;
			}

			// Passed all validation checks
			await this.handleValidationSuccess({
				scannedProduct,
				selectedProduct,
				milkBankProduct,
			});

			console.log(JSON.stringify(scannedProduct, null, 2));
		} catch (error) {
			if (error instanceof ScannedObjectError) {
				// display the associated modal
				const { header, message, modalType } = error.modal;
				await this.base.modalService.presentOverlayModal(
					modalType,
					header,
					[message]
				);

				await this.base.loggingService.logScan(
					new LogScannedObjectModel({
						type: error.scanLogType,
						workflow: this.workflow,
						productId: scannedProduct.product.id,
					})
				);
			} else {
				console.error(error);
			}
		}
	}

	@RejectRecalledProductOnReceive()
	async checkIfRecalled(params: {
		product: Product;
		lotNumber: string;
	}): Promise<boolean> {
		if (params.product && params.lotNumber) {
			return;
		}
	}

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async handleValidationSuccess(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: ScannedAdditive | SelectedProduct;
		milkBankProduct: MilkBankProductModel;
	}) {
		// biome-ignore lint/nursery/noSecrets: <explanation>
		console.warn("handleValidationSuccess not implemented.");
	}

	/**
	 * Update milk bank product with thawed date
	 * Handle validation success
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	async handleThawProduct(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: ScannedAdditive | SelectedProduct;
		milkBankProduct: MilkBankProductModel;
	}) {
		console.warn("handleThawProduct not implemented.");
	}

	receive(o: ScannedObject): Promise<MilkBankProductModel> {
		const product = new MilkBankProductModel({
			barcode: o.barcode.text,
			defective: false,
			bottleNumber: Number.parseInt(o.match.groups.bottleNumber),
			defectiveReason: null,
			productId: o.product.id,
			manufacturerExpirationDate: handleExpirationDateFromScanGroups(
				o.match?.groups
			).toISOString(),
			lotNumber: o.match.groups.lotCode,
			productState: o.product.defaultState, // TODO make sure this works (ML-995)
			productManufacturer: o.manufacturerName,
			manufacturerCreatedDate: null,
		} as IMilkBankProduct);
		return this.base.inventoryService.createMilkBankProduct(product);
	}

	async presentThawProductModal(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: ScannedAdditive | SelectedProduct;
		milkBankProduct: MilkBankProductModel;
	}) {
		const modal = await this.base.modalService.presentModal({
			component: EditMilkModal,
			cssClass: ModalType.NotReceived,
			componentProps: {
				milkBankProduct: params.milkBankProduct,
				presetState: ProductState.Thawed,
				title: "Edit Milk Data",
				header: "Thaw Product",
				message:
					"Please enter the date and time this product was thawed to continue.",
				doneText: "Update",
			},
		});

		modal.onDidDismiss().then((data) => {
			console.log(JSON.stringify(data, null, 2));
			this.handleThawProduct({
				selectedProduct: params.selectedProduct,
				scannedProduct: params.scannedProduct,
				milkBankProduct: data.data.milkBankProduct,
			});

			this.startScanListener();
		});

		return await modal.present().then(() => {
			this.base.dismissLoading();
			this.stopScanListener();
		});
	}
}

/**
 * A milk bank product that has been expended will return null.
 */
export const isExpended = (milkBankProduct: MilkBankProductModel) => {
	if (!milkBankProduct || milkBankProduct.expendedDate) {
		throw new ScanMilkBankProductValidationError(
			ScanLogType.Product_Expended,
			modalMessage.product_expended()
		);
	}
};

export const isDuplicate = (
	scannedProducts: ScannedProduct[],
	milkBankProduct: MilkBankProductModel
) => {
	const duplicate = scannedProducts.find(
		(scannedProduct) =>
			scannedProduct.milkBankProduct.id === milkBankProduct.id
	);

	if (duplicate) {
		throw new ScanMilkBankProductValidationError(
			ScanLogType.Product_Duplicate,
			modalMessage.scan_duplicate()
		);
	}
};

export const isMilkBankProductExpired = (
	milkBankProduct: MilkBankProductModel
) => {
	if (isExpired(milkBankProduct.expirationDate)) {
		throw new ScanMilkBankProductValidationError(
			ScanLogType.Product_Expired,
			modalMessage.product_expired(milkBankProduct.expirationDate)
		);
	}
};

export const isRecalled = (milkBankProduct: MilkBankProductModel) => {
	// Check if recalled
	if (milkBankProduct.recalled) {
		const recalledProductInfo = {
			lotNumber: milkBankProduct.lotNumber,
			productName: milkBankProduct.name,
		};
		throw new ScanMilkBankProductValidationError(
			ScanLogType.Product_Recalled,
			modalMessage.product_recalled(recalledProductInfo)
		);
	}
};

export const getSelectedProduct = (params: {
	productId: Product["id"];
	selectedProducts: SelectedProduct[];
	scannedProduct: ScannedProduct;
}): SelectedProduct => {
	const { productId, selectedProducts, scannedProduct } = params;

	const product = selectedProducts.find((s) => productId === s.product.id);

	if (!product) {
		throw new ScanMilkBankProductValidationError(
			ScanLogType.Product_Mismatch,
			modalMessage.product_mismatch_scan()
		);
	}

	return product;
};

export const isFrozen = (milkBankProduct: MilkBankProductModel) => {
	if (milkBankProduct.productState === ProductState.Frozen) {
		throw new FrozenProductValidationError();
	}
};

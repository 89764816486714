import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PatientEhrOrderModel } from '../../models/patient.model';
import * as _ from 'lodash';
import { defaultSelectOptions } from 'src/app/components/default-options';
import { RecipeBase, RecipeData } from 'src/app/models/recipe.model';
import { PatientService } from 'src/app/services/patient.service';
import { FeedPatientListItem } from 'src/app/administer-feed/feed-patient/list-item';
import { assertExhaustive } from 'src/app/app.enums';
import { IAdditive } from '../../models/additive.model';
import { displayDate } from '../../app.util';
import {
	createContentProductsList,
	hasMissingValue,
	validateContentProducts,
} from '../../utils/orders.util';
import { getStandingStatus, getOrderDetails } from './order-select.util';
import { Product } from 'src/app/models/product.model';

@Component({
	selector: 'app-order-select',
	templateUrl: './order-select.component.html',
	styleUrls: ['./order-select.component.scss'],
})
export class OrderSelectComponent {
	@Input() selectedCalorie: number = null;
	@Input() orders: PatientEhrOrderModel[];
	@Input() selectedOrder: PatientEhrOrderModel;
	@Input() scannedBase: RecipeBase;
	@Input() recipeData: RecipeData;
	@Output() selectedOrderChange = new EventEmitter<PatientEhrOrderModel>();
	@Output() validationResults2Change = new EventEmitter<
		IOrderValidationResult2[]
	>();

	getStandingStatus = getStandingStatus;
	getOrderDetails = getOrderDetails;
	displayDate = displayDate;

	_validationResults2: IOrderValidationResult2[];

	/**
	 * @deprecated this doesn't really do anything
	 */
	get validationResults2() {
		return this._validationResults2;
	}

	/**
	 * @deprecated this doesn't really do anything because the parent component doesnt
	 * do anything with the validation results. Use `_validationResults2` instead.
	 */
	set validationResults2(value: IOrderValidationResult2[]) {
		this._validationResults2 = value;
		this.validationResults2Change.emit(this.validationResults2);
		console.log(
			`validationResults = ${JSON.stringify(this.validationResults2, null, 2)}`,
		);
	}

	defaultSelectOptions = defaultSelectOptions;

	constructor(public patientService: PatientService) {}

	selectOrder(ev, order: PatientEhrOrderModel) {
		ev.stopPropagation();
		this.selectedOrder = order;
		this.selectedOrderChange.emit(this.selectedOrder);
	}

	hasActiveOrders(orders: PatientEhrOrderModel[]) {
		return orders?.length > 0;
	}

	// Validation Result helpers
	isValidOrderMatch(results: IOrderValidationResult2[]) {
		return !!results?.every((v) => v.isValid);
	}

	hasValidOrderFulfillment(results: IOrderValidationResult2[]) {
		return !!results?.some((v) => v.anyOrderFulfillmentValid);
	}

	hasCalorieMismatch(results: IOrderValidationResult2[]) {
		return !!results?.some((v) => !v.caloriesMatch);
	}

	isCalorieMatch() {
		return (
			this.selectedOrder?.calorie === this.selectedCalorie ||
			this.orders.length === 0
		);
	}

	// Validation

	validateOrderMatchesFeedPatientListItems(
		feedPatientListItems: FeedPatientListItem[],
	) {
		this.validationResults2 =
			this.validateSelectedOrderMatchesFeedPatientListItems(
				this.selectedOrder,
				feedPatientListItems,
			);
	}

	/**
	 * This is called by the parent class. When calorie density, additives, and
	 * base are set, this will validate the values against the selected order.
	 *
	 * If any of the params are null, return an empty array meaning that
	 * validation doesn't happen.
	 */
	validateOrderMatchesRecipeCalculation(params: {
		calorieDensity: number;
		additives: IAdditive[];
		base: RecipeBase;
	}) {
		if (
			!hasMissingValue(
				this.selectedOrder,
				params.calorieDensity,
				params.additives,
			)
		) {
			this.validationResults2 = [];
		}

		this.validationResults2 = this._validateOrderMatchesRecipeCalculation(
			this.selectedOrder,
			params.calorieDensity,
			params.additives,
			params.base,
		);
	}

	// TODO: how is this different?
	validateSelectedOrderMatchesManualPrep(params: {
		calorieDensity: number;
		products: { productId: Product['id']; productName: Product['name'] }[];
		recipeBase: RecipeBase;
	}) {
		if (!params.calorieDensity || params.products.length === 0) {
			console.warn(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				'validateSelectedOrderMatchesManualPrep: params are null',
			);
			return;
		}

		// TODO: refactor this using the util functions (below)
		const selectedOrder = this.selectedOrder;

		// include the recipe base in products so that's it's included in the validation
		const products = params.products
			.filter((a) => a.productId)
			.map((a) => ({
				productId: a.productId,
				productName: a.productName,
			}))
			.concat({
				productId: params.recipeBase.baseProductId,
				productName: params.recipeBase.baseName,
			});

		const resultsByOrderFulfillment = this.validateSelectedOrderMatchesProducts(
			products,
			this.selectedOrder,
		);
		const anyOrderFulfillmentValid = resultsByOrderFulfillment.some(
			(orderFulfillment) => orderFulfillment.isValid,
		);
		const caloriesMatch = selectedOrder.calorie === params.calorieDensity;

		this.validationResults2 = [
			{
				milkBottleId: null,
				isValid: anyOrderFulfillmentValid && caloriesMatch,
				anyOrderFulfillmentValid,
				caloriesMatch,
				validationResultsByOrderFulfillment: resultsByOrderFulfillment,
			},
		];
	}

	// TODO: how is this different?
	validateSelectedOrderMatchesFeedPatientListItems(
		selectedPatientEhrOrder: PatientEhrOrderModel,
		feedPatientListItems: FeedPatientListItem[],
	): IOrderValidationResult2[] {
		// It's possible that the patient does not have an order.
		if (!selectedPatientEhrOrder) {
			return;
		}

		const resultsByFeedPatientListItem: IOrderValidationResult2[] =
			feedPatientListItems.map((item) => {
				// pull products and calories out of the FeedPatientListItem
				let products: Array<{
					productId: Product['id'];
					productName: Product['name'];
				}>;
				let calorie: number;

				switch (item.type) {
					case 'milkbottle':
						products = item.milkBottle.contents.map((c) => ({
							productId: c.contentProductId,
							productName: c.contentProductName,
						}));
						calorie = item.milkBottle.calorieDensity;
						break;
					case 'product':
						products = [
							{
								productId: item.product.id,
								productName: item.product.name,
							},
						];
						calorie = item.product.calorieDensity;
						break;
					default:
						assertExhaustive(item);
				}

				const resultsByOrderFulfillment =
					this.validateSelectedOrderMatchesProducts(
						products,
						selectedPatientEhrOrder,
					);
				const anyOrderFulfillmentValid = resultsByOrderFulfillment.some(
					(orderFulfillment) => orderFulfillment.isValid,
				);
				const caloriesMatch = calorie === selectedPatientEhrOrder.calorie;

				return {
					milkBottleId: item.type === 'milkbottle' ? item.milkBottle.id : null,
					key: item.key,
					isValid: anyOrderFulfillmentValid && caloriesMatch,
					anyOrderFulfillmentValid,
					caloriesMatch,
					validationResultsByOrderFulfillment: resultsByOrderFulfillment,
				};
			});

		return resultsByFeedPatientListItem;
	}

	/**
	 * Generic validation routine. Validates against products: Array<{productId: Product["id"]; productName: Product["name"]}>
	 *     FIXME: this is way too complicated. It should be simplified.
	 *
	 *     @deprecated - use validateContentProducts instead
	 */
	private validateSelectedOrderMatchesProducts(
		products: IContentProduct[],
		selectedOrder: PatientEhrOrderModel,
	): {
		ehrOrderFulfillmentId: string;
		orderFulfillmentNonBaseProductsNotInBottle: {
			productId: Product['id'];
			productName: Product['name'];
		}[];
		isValid: boolean;
		baseOrderedIsPresent: boolean;
		milkBottleContentItemsNotInOrderFulfillment: {
			productId: Product['id'];
			productName: Product['name'];
		}[];
	}[] {
		const resultsByOrderFulfillment = selectedOrder.fulfillments.map(
			(ehrOrderFulfillment) => {
				const ehrOrderFulfillmentContents = ehrOrderFulfillment.contents.filter(
					(o) => o.productId !== null,
				);
				const ehrOrderFulfillmentBases = ehrOrderFulfillmentContents.filter(
					(o) => o.isBase,
				);
				const ehrOrderFulfillNonBaseProducts = ehrOrderFulfillmentContents
					.filter((o) => o.isBase === false)
					.map((c) => ({
						productId: c.productId,
						productName: c.productName,
					}));

				// Check if all bottle contents (by productId) occur at least once in the selected order.
				// "For each bottle contents (all types): check if it is in the order’s contents"
				const milkBottleContentItemsNotInOrderFulfillment = _.chain(products)
					.filter(
						(milkBottleContentItem) => milkBottleContentItem.productId != null,
					)
					.filter(
						(milkBottleContentItem) =>
							false ===
							ehrOrderFulfillmentContents.some(
								(orderContentItem) =>
									milkBottleContentItem.productId ===
									orderContentItem.productId,
							),
					)
					.uniqBy((contentItem) => contentItem.productId)
					.map((contentItem) => ({
						productId: contentItem.productId,
						productName: contentItem.productName,
					}))
					.value();

				// From the order, is there at least one of the bases in the bottle? (use new property isBase = true to check this)
				const milkBottleContainsAnyOrderFulfillmentBase = products.some(
					(contentItem) =>
						ehrOrderFulfillmentBases.some(
							(orderBase) => orderBase.productId === contentItem.productId,
						),
				);
				const orderFulfillmentBaseIsPresentInMilkBottle =
					ehrOrderFulfillmentBases.length === 0 ||
					milkBottleContainsAnyOrderFulfillmentBase;

				// are all of the order's non-base products (isBase = false) in the bottle?
				const orderFulfillmentNonBaseProductsNotInBottle =
					ehrOrderFulfillNonBaseProducts.filter(
						(orderProduct) =>
							false ===
							products.some(
								(milkBottleProduct) =>
									milkBottleProduct.productId === orderProduct.productId,
							),
					);

				return {
					ehrOrderFulfillmentId: ehrOrderFulfillment.id,
					milkBottleContentItemsNotInOrderFulfillment,
					baseOrderedIsPresent: orderFulfillmentBaseIsPresentInMilkBottle,
					orderFulfillmentNonBaseProductsNotInBottle,
					isValid:
						milkBottleContentItemsNotInOrderFulfillment.length === 0 &&
						orderFulfillmentBaseIsPresentInMilkBottle &&
						orderFulfillmentNonBaseProductsNotInBottle.length === 0,
				};
			},
		);

		return resultsByOrderFulfillment;
	}

	//--------------------------------------------------------------------------
	// Refactor of validateSelectedOrderMatchesProducts
	//--------------------------------------------------------------------------
	/**
	 * Get the fortifiers/additives and the base from the scanned feed objects
	 * in order to compare it with the fulfillment options for the selected
	 * order.
	 *
	 * Get validation results that contain various info about what's missing
	 * from the scanned feed objects.
	 *
	 * Return a combined results that contains more various info about what's
	 * missing from the scanned feed objects.
	 *
	 * @param selectedOrder
	 * @param calorieDensity
	 * @param additives
	 * @param recipeBase
	 * @private
	 */
	private _validateOrderMatchesRecipeCalculation(
		selectedOrder: PatientEhrOrderModel,
		calorieDensity: number,
		additives: IAdditive[],
		recipeBase: RecipeBase,
	): IOrderValidationResult2[] {
		// If no order was selected because there are no orders, don't validate
		if (!selectedOrder) {
			return [];
		}

		// if (!hasMissingValue(selectedOrder, calorieDensity, additives)) {
		// 	return [];
		// }

		const products = createContentProductsList(additives, recipeBase);

		const results = selectedOrder.fulfillments.map((fulfillment) =>
			validateContentProducts(products, fulfillment),
		);

		const hasValidFulfillment = results.some((v) => v.isValid);
		const caloriesMatch = selectedOrder.calorie === calorieDensity;

		/*
			The only difference between isValid and anyOrderFulfillmentValid is that
			isValid also checks if the calories match, which is kind of redundant.
		 */
		return [
			{
				milkBottleId: null, // why is this set to null?
				isValid: hasValidFulfillment && caloriesMatch, // TODO: remove this one
				anyOrderFulfillmentValid: hasValidFulfillment,
				caloriesMatch,
				validationResultsByOrderFulfillment: results,
			},
		];
	}
}

/**
 * @deprecated
 */
export interface IOrderValidationResult2 {
	milkBottleId?: string;
	key?: string; // unique id using nano id
	isValid: boolean;
	caloriesMatch: boolean;
	anyOrderFulfillmentValid: boolean;
	validationResultsByOrderFulfillment: Array<{
		ehrOrderFulfillmentId: string;
		milkBottleContentItemsNotInOrderFulfillment: {
			productId: Product['id'];
			productName: Product['name'];
		}[];
		baseOrderedIsPresent: boolean;
		orderFulfillmentNonBaseProductsNotInBottle: {
			productId: Product['id'];
			productName: Product['name'];
		}[];
		isValid: boolean;
	}>;
}

export interface IContentProduct {
	productId: Product['id'];
	productName: Product['name'];
}

export interface FulfillmentValidationResult {
	ehrOrderFulfillmentId: string;
	milkBottleContentItemsNotInOrderFulfillment: IContentProduct[];
	baseOrderedIsPresent: boolean;
	orderFulfillmentNonBaseProductsNotInBottle: IContentProduct[];
	isValid: boolean;
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormGroup, FormArray, FormBuilder } from "@angular/forms";
import { PatientModel, ISibling } from "../../models/patient.model";
import { distinctUntilChanged, throttleTime } from "rxjs";

export interface IPatientLabelQuantity {
	patient: PatientModel | ISibling;
	labelQuantity: number;
}

@Component({
	selector: "app-label-quantity-list",
	templateUrl: "./label-quantity-list.component.html",
	styleUrls: ["./label-quantity-list.component.scss"],
})
export class LabelQuantityListComponent implements OnInit {
	@Input() patient: PatientModel;
	@Output() valueChange = new EventEmitter<IPatientLabelQuantity[]>();

	labelQuantityFormGroup: FormGroup;

	get items() {
		return (this.labelQuantityFormGroup.get("items") as FormArray).controls;
	}

	constructor(private formBuilder: FormBuilder) {}

	ngOnInit() {
		this.buildForm();
	}

	buildForm() {
		// Create the form
		this.labelQuantityFormGroup = this.formBuilder.group({
			items: this.formBuilder.array([]),
		});

		// Subscribe to form changes to emit data
		this.labelQuantityFormGroup.valueChanges
			.pipe(distinctUntilChanged(), throttleTime(100))
			.subscribe((data) => {
				console.log("data.items :>> ", data.items);
				this.valueChange.emit(data.items);
			});

		// Generate form data
		const items = this.labelQuantityFormGroup.get("items") as FormArray;
		const patients = this.getInitialFormData(
			this.patient,
			this.patient?.siblings
		);

		patients.forEach((patient) => {
			items.push(this.formBuilder.group(patient));
		});
	}

	getInitialFormData(patient, siblings): IPatientLabelQuantity[] {
		const patientDefaultLabelQuantity = 1;
		const siblingDefaultLabelQuantity = 0;

		const patientQty: IPatientLabelQuantity[] = patient
			? [
					{
						patient,
						labelQuantity: patientDefaultLabelQuantity,
					},
			  ]
			: [];

		const siblingQtys: IPatientLabelQuantity[] = siblings
			? siblings.map((sibling) => ({
					patient: sibling,
					labelQuantity: siblingDefaultLabelQuantity,
			  }))
			: [];

		return [...patientQty, ...siblingQtys];
	}

	/**
	 * Displays patient data in the view.
	 * POTTER, Lily (1234001)
	 */
	getPatient(index: number): string {
		const patient: PatientModel | ISibling = this.items[index.toString()]
			.value.patient as PatientModel;
		return `${patient.lastName.toUpperCase()}, ${patient.firstName} ${
			patient.mrn
		}`;
	}

	/**
	 * Prevent users from entering negative bottle numbers.
	 */
	handleInput($event: Event, index: number) {
		let value = parseInt(($event.target! as HTMLInputElement).value);
		const minimumLabelQuantity = 0;

		if (value < minimumLabelQuantity) {
			value = minimumLabelQuantity;
		}

		this.items[index.toString()].patchValue({
			labelQuantity: value,
		});
	}
}

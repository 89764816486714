import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import type { IonInput } from "@ionic/angular";

@Component({
	selector: "app-label-quantity",
	templateUrl: "./label-quantity.component.html",
	styleUrls: ["./label-quantity.component.scss"],
})
export class LabelQuantityComponent implements OnInit {
	@ViewChild("ionInputEl", { static: true }) ionInputEl!: IonInput;

	@Input() minimumValue = 1;
	@Input() maximumValue;
	@Input() initialValue: number;
	@Input() disabled: boolean;
	@Input() step = "1";

	@Output() changeLabelQuantity = new EventEmitter<number>();
	@Output() changeValidity = new EventEmitter<boolean>();

	input: number;
	hasError = false;
	errorMessage: string = ErrorMessages.Default;

	ngOnInit(): void {
		const isValidInitialValue = (this.initialValue && this.initialValue >= this.minimumValue);
		this.input = isValidInitialValue ? this.initialValue : this.minimumValue;
		this.changeValidity.emit(true);
	}

	handleLabelQuantity($event: number) {
		if (!this.isValidValue($event, this.minimumValue, this.maximumValue)) {
			this.hasError = true;
			this.changeValidity.emit(false);
			return;
		}

		this.hasError = false;
		this.changeLabelQuantity.emit($event);
		this.changeValidity.emit(true);
	}

	isValidValue = (value: number, minimumValue: number, maximumValue: number): boolean => {
		if(!value || !Number.isInteger(value) || value < minimumValue) {
			this.errorMessage = ErrorMessages.Default
			return false;
		}
		if(value > maximumValue) {
			this.errorMessage = ErrorMessages.Maximum + this.maximumValue
			return false;
		}
		return true;
	}
}

enum ErrorMessages {
	Default = "Invalid Number",
	Maximum = "Quantity entered exceeds the limit of ",
}

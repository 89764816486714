import { Component, Input, ViewChild } from "@angular/core";
import { ModalController } from "@ionic/angular";
import dayjs, { Dayjs } from "dayjs";
import { Product } from "src/app/models/product.model";
import { InlineDateComponent } from "../../components/inline-date/inline-date.component";
import { getMinDate } from "src/app/app.util";
import { StorageService } from "src/app/services";

/**
 * @deprecated Remove as a part of ML-1361. Use EditMilkModal instead.
 */
@Component({
	templateUrl: "./edit-scanned-product.modal.html",
	styleUrls: ["./edit-scanned-product.modal.scss"],
})
export class EditScannedProductModal {
	@ViewChild("datePicker") datePicker: InlineDateComponent;

	@Input() title = "edit milk data";
	@Input() header: string;
	@Input() message: string;
	@Input() doneText = "save";

	@Input() product: Product;

	isThawDateValid: boolean;

	thawedDate: dayjs.Dayjs = null;
	dayjs = dayjs;

	/**
	 * This is used to set the valid date ranges for updates to the relevant datetime properties,
	 * based on the current milk state.
	 */
	milkBankProductValidUpdateDateRanges = {
		thawed: {
			min: getMinDate(StorageService.expirationPolicy.thawedDbm),
			max: dayjs().endOf("day"),
		}
	};

	get minDate() {
		return this.milkBankProductValidUpdateDateRanges.thawed.min;
	}

	get maxDate() {
		return this.milkBankProductValidUpdateDateRanges.thawed.max;
	}

	constructor(public modalCtrl: ModalController) {}

	clear() {
		this.thawedDate = null;
	}

	cancel() {
		this.thawedDate = null;
		return this.modalCtrl.dismiss(null, "cancel");
	}

	handleValidity = (value: boolean) => (this.isThawDateValid = value);

	isValid() {
		return this.thawedDate;
	}

	/**
	 * Programmatically click "done" for each datetime component.
	 */
	async confirmDatePickerSelection() {
		this.datePicker.done();
	}

	async confirm() {
		await this.confirmDatePickerSelection();

		const confirmData: EditScannedProductConfirmed = {
			thawedDate: this.thawedDate,
		};

		return this.modalCtrl.dismiss(confirmData, "confirm");
	}
}

export type EditScannedProductConfirmed = {
	thawedDate: Dayjs;
};

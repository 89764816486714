<ion-grid *ngIf="patient">
	<ng-container *ngFor="let prop of getDisplayedProperties(patient, excludes)">
		<div class="patient-details">
			<div class="patient-details__icon">
				<fa-icon [icon]="prop.icon" [fixedWidth]="true" size="sm"></fa-icon>
			</div>
			<div class="patient-details__label">{{ prop.label }}</div>
			<div>
				<ng-container *ngFor="let value of prop.getValues(patient)">
					<div class="patient-details__value">{{ value }}</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ion-grid>

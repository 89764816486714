import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBottleModel } from "../../models/milk.model";
import { ScanMilkBottleValidationError } from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";

export function RejectFed2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject } = obj;

			// determine feed object type and check if it was fed
			if (feedObject) {
				if (feedObject instanceof MilkBottleModel) {
					if (feedObject && feedObject.usedDate) {
						throw new ScanMilkBottleValidationError(
							ScanLogType.Milk_Bottle_Previously_Fed,
							modalMessage.milk_fed()
						);
					}
				} else {
					console.error(
						`RejectFed: feedObject is not a MilkBottleModel`
					);
				}
			} else {
				console.error(`RejectFed: feedObject is undefined`);
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectFed() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (obj.milkBottle && obj.milkBottle.usedDate) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Previously_Fed,
						modalMessage.milk_fed()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

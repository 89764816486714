import {
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
} from "@angular/core";
import { IonAccordionGroup, IonDatetime } from "@ionic/angular";

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import {
	MaskitoElementPredicate,
	MaskitoOptions,
	MaskitoPreprocessor,
} from "@maskito/core";
import timeMask from "../../masks/time.mask";
import { StorageService } from "../../services/storage.service";
import { maskitoDateOptionsGenerator } from "@maskito/kit";
import { BaseService } from "../../services/base.service";
import { environment } from "../../../environments/environment";

dayjs.extend(customParseFormat);

/**
 * @deprecated Use InlineDateComponent
 */
@Component({
	selector: "app-inline-date-picker",
	templateUrl: "./inline-date-picker.component.html",
	styleUrls: ["./inline-date-picker.component.scss"],
})
export class InlineDatePickerComponent implements OnInit {
	@ViewChild("datetime") datetime: IonDatetime;
	@ViewChild("accordionGroup") accordionGroup: IonAccordionGroup;

	@Input() autofocusState: boolean = false;
	@Input() title: string;
	@Input() defaultDate: dayjs.Dayjs;
	@Input() minDate: string;
	@Input() maxDate: string;
	@Input() presentation = "date-time";
	@Input() isTimeEndOfDay = false;
	@Input() readonly = false;
	@Output() dateTimeChange = new EventEmitter<dayjs.Dayjs | null>();
	@Output() inputFocus = new EventEmitter<"focus" | "blur">();
	@Output() dateValid = new EventEmitter<boolean>();

	isYearValid: boolean = true;
	private updateDateValidity(isValid: boolean): void {
		this.dateValid.emit(isValid);
	}

	_dateTimeString: string;
	get dateString(): string {
		return this._dateTimeString;
	}

	/**
	 * Both date and time must be valid, but if both are empty, then it's valid.
	 *
	 * @param value
	 */
	@Input()
	set dateString(value: string) {
		if (value) {
			this._dateTimeString = this.isTimeEndOfDay
				? dayjs(value).endOf("day").format()
				: dayjs(value).format();

			// update dateValue and timeValue
			this.dateValue = dayjs(value).format("MM/DD/YYYY");
			this.timeValue = dayjs(value).format("HH:mm");

			// emit date
			this.dateTimeChange.emit(dayjs(this._dateTimeString));
			console.log(`emit date: ${this._dateTimeString}`);
		} else {
			if (!this.dateValue && !this.timeValue) {
				this.dateValue = "";
				this.timeValue = "";
			} else {
				// datepicker
				this._dateTimeString = null;
			}

			// emit date
			this.dateTimeChange.emit(null);
			console.log(`emit date: null`);
		}
	}

	dateValue: string;
	timeValue: string;

	readonly maskPredicate: MaskitoElementPredicate = async (el) =>
		(el as HTMLIonInputElement)?.getInputElement();

	dateMaskOptions: MaskitoOptions;
	timeMaskOptions: MaskitoOptions;

	StorageService = StorageService;
	environment = environment;

	constructor(public base: BaseService) {
		/*
		 There's an issue where if the dateString is initialized without a value,
		 it causes an error. To resolve this, initialize the dateString with a valid
		 date string, then set it to null. On ngOnInit, the dateString should be set
		 to the Input value.
		 */
		// this._dateTimeString = dayjs().toISOString();
		// this._dateTimeString = null;
	}

	async ngOnInit() {
		// await this.base.presentLoading("Loading...");
		await this.initDateMaskOptions();
		await this.initTimeMaskOptions();
		await this.initIonDatetime();
		await this.initDefaultDate();
		// await this.base.dismissLoading();
	}

	async initTimeMaskOptions() {
		const handleSingleNInput: MaskitoPreprocessor = ({
			elementState,
			data,
		}) => {
			let { value, selection } = elementState;

			// Check for 'n' input and replace with the current time
			if (data.toLowerCase() === "n") {
				value = this.transformCharToTime(data);
				selection = [value.length, value.length];
			}

			return {
				elementState: {
					selection,
					value: value,
				},
				data,
			};
		};

		this.timeMaskOptions = {
			...timeMask,
			preprocessors: [handleSingleNInput],
		};
	}

	async initDateMaskOptions() {
		/*
			Initialize the dateMaskOptions with the minDate and maxDate.
		 */

		const handleSingleTInput: MaskitoPreprocessor = ({
			elementState,
			data,
		}) => {
			let { value, selection } = elementState;

			// Check for 't' input and replace with the current date
			if (data.toLowerCase() === "t") {
				value = this.transformCharToDate(data);
				selection = [value.length, value.length];
			}

			return {
				elementState: {
					selection,
					value: value,
				},
				data,
			};
		};

		if (this.minDate && this.maxDate) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			const generatedOptions = maskitoDateOptionsGenerator({
				mode: "mm/dd/yyyy",
				separator: "/",
				max: dayjs(this.maxDate).toDate(),
				min: dayjs(this.minDate).toDate(),
			});

			this.dateMaskOptions = {
				...generatedOptions,
				preprocessors: [handleSingleTInput],
			};
		} else if (this.minDate && !this.maxDate) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			const generatedOptions = maskitoDateOptionsGenerator({
				mode: "mm/dd/yyyy",
				separator: "/",
				min: dayjs(this.minDate).toDate(),
			});

			this.dateMaskOptions = {
				...generatedOptions,
				preprocessors: [handleSingleTInput],
			};
		} else if (this.maxDate && !this.minDate) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			const generatedOptions = maskitoDateOptionsGenerator({
				mode: "mm/dd/yyyy",
				separator: "/",
				max: dayjs(this.maxDate).toDate(),
			});

			this.dateMaskOptions = {
				...generatedOptions,
				preprocessors: [handleSingleTInput],
			};
		} else {
			console.warn("doesn't have minDate or maxDate");
		}
	}

	async initIonDatetime() {
		/*
			Initialize IonDatetime min and max properties with the minDate and maxDate.
		 */
		if (
			environment.settings.showMobileDateTimePicker ||
			!StorageService.isWeb
		) {
			if (this.minDate && this.maxDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.min = this.minDate;
				this.datetime.max = this.maxDate;
			} else if (this.minDate && !this.maxDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.min = this.minDate;
			} else if (this.maxDate && !this.minDate) {
				await new Promise((resolve) => setTimeout(resolve, 1000));
				this.datetime.max = this.maxDate;
			} else {
				console.warn("doesn't have minDate or maxDate");
			}
		}
	}

	async initDefaultDate() {
		// TODO: I don't think it's necessary to copy defaultDate but I'm leaving it here for now
		const defaultDate = this.defaultDate;

		// wait 1 second before setting the default date
		if (defaultDate && defaultDate.isValid()) {
			await new Promise((resolve) => setTimeout(resolve, 500));
			console.log("has defaultDate: " + defaultDate.format());
			this.dateString = defaultDate.format();
		}
	}

	// IonDatetime Button Handlers

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to emit the ionCancel event.
	 */
	cancel() {
		this.datetime.cancel();
		this.closeAccordionGroup();
	}

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to resets the internal state of the datetime.
	 * We must manually reset the value since reset() does not update the value.
	 */
	clear() {
		this.datetime.reset();
		this.datetime.value = null;
		this.dateString = null;

		// set input fields as precaution
		this.dateValue = null;
		this.timeValue = null;

		this.closeAccordionGroup();
	}

	/**
	 * When using custom buttons with ionic datetime,
	 * call the built-in method to confirm the selected datetime value
	 * and update the value property.
	 *
	 * For pages that rely heavily on datetime (ex: Edit and Reprint),
	 * we call `done()` externally to save the fields since users
	 * may glance over the done button. ML-1896
	 */
	done() {
		if (!this.datetime) return;

		this.datetime.confirm();
		this.datetime.value = this.dateString;
		// eslint-disable-next-line no-self-assign
		this.dateString = this.dateString; // Fire the event emitters again.
		this.closeAccordionGroup();
	}

	closeAccordionGroup() {
		this.accordionGroup.value = null;
	}

	displayDate(dateString: string) {
		return dayjs(dateString).format("MM/DD/YYYY, HH:mm");
	}

	/**
	 * We expect year in the YYYY format. Some users may only enter
	 * YY. In that case, we'll convert it to YYYY.
	 *
	 * We're not expecting any date to be before 2000.
	 * TODO: Validation: ML-1227
	 */
	formatYear(dateString: string): string {
		const date = dateString.split("/"); // ["01", "01", "23"]
		const year = date[2];

		const validity = this.checkYear(year);
		this.updateDateValidity(validity);
		this.isYearValid = validity;

		return dateString;
	}

	checkYear = (year: string) => year && year.length === 4;

	/**
	 * If the dateValue change is valid, then update the dateTime
	 * with the date value while preserving the timeValue.
	 *
	 * @param dateString
	 */
	handleDateChange(dateString: string) {
		this.inputFocus.emit("blur");
		if (!dateString.trim()) {
			this.dateString = "";
			return;
		}

		if (!dayjs(dateString).isValid()) {
			return;
		}

		this.dateValue = this.formatYear(dateString);
		const isFullDateMatch = /^\d{2}\/\d{2}\/\d{4}$/.test(dateString);
		const date = dayjs(dateString);

		if (isFullDateMatch && date.isValid()) {
			this.dateString =
				this.getDateTime(this.dateValue, this.timeValue) || "";
		} else {
			this.dateString = "";
		}
	}

	/**
	 * If the timeValue change is valid, then update the dateTime
	 * with the time value while preserving the dateValue.
	 *
	 * If the time field is empty, then set the dateTime to null.
	 *
	 * @param timeString
	 */
	handleTimeChange(timeString: string) {
		if (!timeString.trim()) {
			this.dateString = "";
			return;
		}

		if (!dayjs(timeString, "HH:mm").isValid()) {
			return;
		}

		this.timeValue = timeString;
		const isFullTimeMatch = /^\d{2}:\d{2}$/.test(timeString);
		const tempDate = dayjs().format("MM/DD/YYYY"); // need in order to create a dayjs object
		const time = dayjs(`${tempDate} ${timeString}`, "HH:mm");

		if (isFullTimeMatch && time.isValid()) {
			this.dateString =
				this.getDateTime(this.dateValue, this.timeValue) || "";
		} else {
			this.dateString = "";
		}
	}

	transformCharToDate(char: string) {
		if (char.toLowerCase() === "t") {
			return dayjs().format("MM/DD/YYYY");
		}
		return char;
	}

	transformCharToTime(char: string) {
		if (char.toLowerCase() === "n") {
			return dayjs().format("HH:mm");
		}
		return char;
	}

	getDateTime(dateString, timeString): string {
		const dateTime = dayjs(
			`${dateString} ${timeString}`,
			"MM/DD/YYYY HH:mm",
			true
		);

		console.log(
			`getDateTime ${dateString} ${timeString} ${
				dateTime.isValid() ? "valid" : "invalid"
			}`
		);
		if (dateTime.isValid()) {
			return dateTime.format();
		} else {
			return;
		}
	}

	/**
	 * This is used as a dev/debugging tool to set the date to today.
	 */
	setToToday() {
		this.dateString = dayjs().toISOString();
	}
}

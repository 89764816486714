import { IConfigs } from "./config.model";

/**
 * Tenant specific configurations. These items are configured in the system admin portal.
 *
 *  - allow manufacturer combinations
 *  - enforce badge scanning
 *  - enforce manual entry validation
 *  - expiration policy
 *  - feature flags
 *  - order validation
 *  - product calorie range
 *  - regexes
 *     - badges
 *     - wrist
 */
export class TenantConfigs {
	info: TenantInfo = new TenantInfo();
	expirationPolicy: ExpirationPolicy = new ExpirationPolicy();
	allowMultiManufacturerCombinations: boolean;
	badgeRegex: string; // "@\"^\\d{10}$\""
	wristbandRegex: string;
	enforceBadgeScanning: boolean;
	productCalorieMax: number;
	productCalorieMin: number;
	validateOrders: boolean;
	enforceManualEntryOnValidationFailure: boolean;
	defaultDivideMilkToggleValue: boolean;
	nurseValidLoginExpiration: number; //168

	constructor(initial: IConfigs = {} as IConfigs) {
		const { tenant } = initial;

		if (!tenant) {
			return;
		}

		// Set TenantInfo
		this.info = new TenantInfo(tenant);

		// Set ExpirationPolicy
		this.expirationPolicy = new ExpirationPolicy(tenant);

		Object.keys(tenant).forEach((key) => {
			this[key] = tenant[key];
		});
	}

	toJSON(): ITenantConfigs {
		return {
			...this.info.toJSON(),
			...this.expirationPolicy.toJSON(),
			allowMultiManufacturerCombinations:
				this.allowMultiManufacturerCombinations,
			badgeRegex: this.badgeRegex,
			wristbandRegex: this.wristbandRegex,
			enforceBadgeScanning: this.enforceBadgeScanning,
			productCalorieMax: this.productCalorieMax,
			productCalorieMin: this.productCalorieMin,
			validateOrders: this.validateOrders,
			enforceManualEntryOnValidationFailure:
				this.enforceManualEntryOnValidationFailure,
			defaultDivideMilkToggleValue: this.defaultDivideMilkToggleValue,
			nurseValidLoginExpiration: this.nurseValidLoginExpiration
		};
	}
}

/**
 * Opted on calling this "configs" instead of "settings" to imply that these are
 * system-type configurations and not user configurations.
 */
export interface ITenantConfigs {
	active: boolean;
	allowMultiManufacturerCombinations: boolean;
	badgeRegex: string;
	wristbandRegex: string;
	created: string;
	createdBy: string;
	enforceBadgeScanning: boolean;
	expirationHoursFormula: number;
	expirationHoursFreshEbm: number;
	expirationHoursFreshEbmFortifiedWithHM: number;
	expirationHoursFreshEbmFortifiedWithoutHM: number;
	expirationHoursFrozenEbm: number;
	expirationHoursThawedDbm: number;
	expirationHoursThawedDbmFortifiedWithHM: number;
	expirationHoursThawedDbmFortifiedWithoutHM: number;
	expirationHoursThawedEbm: number;
	expirationHoursThawedEbmFortified: number;
	id: string;
	modified: string;
	modifiedBy: string;
	name: string;
	productCalorieMax: number;
	productCalorieMin: number;
	validateOrders: boolean;
	enforceManualEntryOnValidationFailure: boolean;
	defaultDivideMilkToggleValue: boolean;
	nurseValidLoginExpiration: number;
}

export class TenantInfo {
	active: boolean;
	created: string;
	createdBy: string;
	id: string;
	modified: string;
	modifiedBy: string;
	name: string;

	constructor(initial: ITenantInfo = {} as ITenantInfo) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toJSON(): ITenantInfo {
		return {
			active: this.active,
			created: this.created,
			createdBy: this.createdBy,
			id: this.id,
			modified: this.modified,
			modifiedBy: this.modifiedBy,
			name: this.name,
		};
	}
}

export interface ITenantInfo {
	active: boolean;
	created: string;
	createdBy: string;
	id: string;
	modified: string;
	modifiedBy: string;
	name: string;
}

/**
 * Expiration policy are the tenant-defined expiration duration (in hours) for
 * each product type.
 */
export class ExpirationPolicy {
	formula: number;
	freshEbm: number;
	freshEbmFortifiedWithHM: number;
	freshEbmFortifiedWithoutHM: number;
	frozenEbm: number;
	thawedDbm: number;
	thawedDbmFortifiedWithHM: number;
	thawedDbmFortifiedWithoutHM: number;
	thawedEbm: number;
	thawedEbmFortified: number;
	constructor(initial: IExpirationPolicy = {} as IExpirationPolicy) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			// biome-ignore lint/nursery/noSecrets: <explanation>
			if (key === "expirationHoursFormula") {
				this.formula = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursFreshEbm") {
				this.freshEbm = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursFreshEbmFortifiedWithHM") {
				this.freshEbmFortifiedWithHM = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursFreshEbmFortifiedWithoutHM") {
				this.freshEbmFortifiedWithoutHM = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursFrozenEbm") {
				this.frozenEbm = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursThawedDbm") {
				this.thawedDbm = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursThawedDbmFortifiedWithHM") {
				this.thawedDbmFortifiedWithHM = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursThawedDbmFortifiedWithoutHM") {
				this.thawedDbmFortifiedWithoutHM = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursThawedEbm") {
				this.thawedEbm = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "expirationHoursThawedEbmFortified") {
				this.thawedEbmFortified = initial[key];
			} else {
				this[key] = initial[key];
			}
		});
	}

	toJSON(): IExpirationPolicy {
		return {
			expirationHoursFormula: this.formula,
			expirationHoursFreshEbm: this.freshEbm,
			expirationHoursFreshEbmFortifiedWithHM:
				this.freshEbmFortifiedWithHM,
			expirationHoursFreshEbmFortifiedWithoutHM:
				this.freshEbmFortifiedWithoutHM,
			expirationHoursFrozenEbm: this.frozenEbm,
			expirationHoursThawedDbm: this.thawedDbm,
			expirationHoursThawedDbmFortifiedWithHM:
				this.thawedDbmFortifiedWithHM,
			expirationHoursThawedDbmFortifiedWithoutHM:
				this.thawedDbmFortifiedWithoutHM,
			expirationHoursThawedEbm: this.thawedEbm,
			expirationHoursThawedEbmFortified: this.thawedEbmFortified,
		};
	}
}

export interface IExpirationPolicy {
	expirationHoursFormula: number;
	expirationHoursFreshEbm: number;
	expirationHoursFreshEbmFortifiedWithHM: number;
	expirationHoursFreshEbmFortifiedWithoutHM: number;
	expirationHoursFrozenEbm: number;
	expirationHoursThawedDbm: number;
	expirationHoursThawedDbmFortifiedWithHM: number;
	expirationHoursThawedDbmFortifiedWithoutHM: number;
	expirationHoursThawedEbm: number;
	expirationHoursThawedEbmFortified: number;
}

import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MilkBottleViewModel } from "src/app/manage-labels/edit-reprint-label/edit-reprint-label.page";
import {
	getBottleNumberText,
	getMilkTypeText,
	getStatusColor,
} from "../../utils/milk-label.util";
import {
	expirationDateChipColor,
	getRelativeExpirationTime,
	isExpired,
} from "../../utils/expiration.util";
import { MilkBottleModel } from "../../models/milk.model";
import { NO_RESULTS } from "src/app/app.constants";

@Component({
	selector: "app-edit-reprint-label-listitem",
	templateUrl: "./edit-reprint-label-listitem.component.html",
	styleUrls: ["./edit-reprint-label-listitem.component.scss"],
})
export class EditReprintLabelListitemComponent implements OnInit {
	@Input() header: string;
	@Input() milkBottles?: MilkBottleViewModel[];
	@Output() editClicked: EventEmitter<MilkBottleViewModel> = new EventEmitter();
	@Output() reprintClicked: EventEmitter<MilkBottleViewModel> =
		new EventEmitter();

	getMilkTypeText = getMilkTypeText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	getStatusColor = getStatusColor;
	expirationDateChipColor = expirationDateChipColor;
	isExpired = isExpired;
	getBottleNumberText = getBottleNumberText;

	get isMessageVisible(): boolean {
		return this.milkBottles?.length === 0;
	}

	noResultsMessage = NO_RESULTS;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	edit(milkBottle: MilkBottleViewModel) {
		this.editClicked.emit(milkBottle);
	}

	goToReprint(milkBottle: MilkBottleViewModel) {
		this.reprintClicked.emit(milkBottle);
	}

	getContents = (milk: MilkBottleModel): string =>
		milk.contents
			.map((x) => x.contentProductCode)
			.filter((id, index, arr) => arr.indexOf(id) === index) // dedupe
			.join(", ");
}

import { Injectable } from "@angular/core";
import {
	HttpClient,
	HttpErrorResponse,
	HttpHeaders,
} from "@angular/common/http";
import { lastValueFrom, Observable, throwError, timer } from "rxjs";
import { catchError, concatMap, timeout } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { StorageService } from "src/app/services/storage.service";

@Injectable({
	providedIn: "root",
})
export class RestApiService {
	headers: HttpHeaders;

	constructor(private http: HttpClient) {
		/*
			https://angular.io/guide/http#setup
		*/
		this.headers = new HttpHeaders({
			Accept: "application/json",
			"Content-Type": "application/json",
		});
	}

	get(url: string, params?: any): Observable<any> {
		return this.http
			.get(
				`${environment.apiUrl}/${StorageService.activeTenant.tenantId}${url}`,
				{
					headers: this.headers,
					params,
				}
			)
			.pipe(
				timeout(
					StorageService.appConfigs.timeoutDurationInMilliSeconds
				),
				catchError((error) => this.handleError(error, `GET ${url}`))
			);
	}

	post(url: string, payload: any): Observable<any> {
		return this.http
			.post(
				`${environment.apiUrl}/${StorageService.activeTenant.tenantId}${url}`,
				payload,
				{ headers: this.headers }
			)
			.pipe(
				timeout(
					StorageService.appConfigs.timeoutDurationInMilliSeconds
				),
				catchError((error) => this.handleError(error, `POST ${url}`))
			);
	}

	put(url: string, payload: any): Observable<any> {
		return this.http
			.put(
				`${environment.apiUrl}/${StorageService.activeTenant.tenantId}${url}`,
				payload,
				{ headers: this.headers }
			)
			.pipe(
				timeout(
					StorageService.appConfigs.timeoutDurationInMilliSeconds
				),
				catchError((error) => this.handleError(error, `PUT ${url}`))
			);
	}

	/**
	 * Timeout looks like:
	 * {"message":"Timeout has occurred","name":"TimeoutError"}
	 * When we have a "network timeout error, also log the url so we can see it in tests."
	 */
	private handleError(
		error: any | HttpErrorResponse | TimeoutErrorResponse,
		url: string
	) {
		if (error.name === "TimeoutError") {
			console.error("Network Timeout Error", url);
			alert("Network Timeout Error");
		} else if (error.status === 500) {
			alert("Internal Server Error");
		}
		return throwError(() => {
			const e: any = new Error(error?.message);
			e.timestamp = Date.now();
			return error;
		});
	}

	getHTTPErrorResponse(status: number): Promise<any> {
		const url = `${environment.apiUrl}/throw-error/${status}`;
		return lastValueFrom(
			this.http.get(url, { headers: this.headers }).pipe(
				timeout(
					StorageService.appConfigs.timeoutDurationInMilliSeconds
				),
				catchError((error) => this.handleError(error, `GET ${url}`))
			)
		);
	}
}

interface TimeoutErrorResponse {
	name: string;
	message: string;
}

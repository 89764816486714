import { Component, Input, OnInit } from "@angular/core";
import { MilkBottleModel } from "../../models/milk.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import {
	getCalorieDensity,
	getMilkContents,
	getDischargeDate,
	getDistributedTo,
	getExpendedDate,
	getExpirationDate,
	getFrozenDate,
	getLotNumber,
	getOpenedDate,
	getProductCalorieDensity,
	getProductManufacturer,
	getProductName,
	getReceivedDate,
	getVolume,
	getLocation,
	getThawedDate,
} from "../../utils/milk-and-product-details.util";
import { MilkDetailItem, MilkProductDetails } from "./milk-details.model";
import { MilkType, ProductType } from "src/app/app.enums";

@Component({
	selector: "app-milk-details",
	templateUrl: "./milk-details.component.html",
	styleUrls: ["./milk-details.component.scss"],
})
export class MilkDetailsComponent implements OnInit {
	@Input() milk: MilkBottleModel;
	@Input() product: MilkBankProductModel;
	@Input() excludes: string[] = [];

	products: MilkProductDetails[] = [];
	milks: MilkProductDetails[] = [];
	feedings: MilkProductDetails[] = [];

	constructor() {}

	ngOnInit() {
		this.displayMilk();
		this.displayProducts();
	}

	displayReceivedDate(milk: MilkBottleModel): boolean {
		// Don't display received date if it is formula
		return !(milk && milk.feedType === MilkType.Formula);
	}

	displayOpenedDate(milk: MilkBottleModel): boolean {
		if (!milk) return false;

		const containsRtf = milk.contents.some(
			(content) => content.contentProductType === ProductType.RTF
		);

		// If the feed type is Formula
		if (milk.feedType === MilkType.Formula) {
			// If it is formula and contains RTF then display opened date
			// If it is formula and does not contain RTF then do not display opened date
			return containsRtf;
		}
		// Always display if not formula
		return true;
	}

	displayMilk() {
		if (!this.milk) {
			return;
		}

		this.milks = [
			...getDischargeDate(this.milk.dischargeDate),
			...getExpirationDate(this.milk.expirationDate),
			...getOpenedDate(
				this.displayOpenedDate(this.milk) ? this.milk.openedDate : null
			),
			...getFrozenDate(this.milk.frozenDate),
			...getThawedDate(this.milk.thawedDate),
			...getExpendedDate(this.milk.expendedDate),
			...getReceivedDate(
				this.displayReceivedDate(this.milk)
					? this.milk.receivedDate
					: null
			),
			...getLocation(this.milk.location),
			...getCalorieDensity(this.milk.calorieDensity),
			...getVolume(this.milk.volume),
			...getMilkContents(this.milk, this.excludes),
			...getDistributedTo(this.milk.distributedTo),
		];
	}

	displayProducts() {
		if (!this.product) {
			return;
		}

		this.products = [
			...getExpirationDate(this.product.expirationDate),
			...getExpendedDate(this.product.expendedDate),
			...getReceivedDate(this.product.receivedDate),
			...getOpenedDate(this.product.openedDate),
			...getProductCalorieDensity(this.product.calorieDensity),
			...getProductManufacturer(this.product.productManufacturer),
			...(!this.excludes.includes(MilkDetailItem.PRODUCT_NAME)
				? getProductName(this.product.name)
				: []),
			...getLotNumber(this.product.lotNumber),
		];
	}
}

import { ProductType } from "src/app/app.enums";
import { IProduct, Product } from "src/app/models/product.model";
import { ITenantConfigs, TenantConfigs } from "./tenant-configs.model";
import { IPrinter } from "./printer.model";
import { IFeatureFlag } from "../features.enums";
import { ILatchQualityGroup, LatchQualityGroup } from "./latch-quality.model";

/**
 * This contains facility (hospital) configurations, which includes certain naming schemes, and data sets.
 */
export class ConfigModel {
	latchQualityGroups: LatchQualityGroup[];
	defectiveReasons: IDefectiveReason[];
	manufacturers: IManufacturer[];
	overrideReasons: string[];
	printers: IPrinter[];
	tenant: TenantConfigs;
	features: IFeatureFlag;

	constructor(initial: IConfigs = {} as IConfigs) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			if (key === "tenant") {
				this.tenant = new TenantConfigs(initial);
			} else if (key === "breastfeedLatchQualityGroups") {
				this.latchQualityGroups = initial[key].map(
					(g) => new LatchQualityGroup(g)
				);
			} else {
				this[key] = initial[key];
			}
		});
	}

	toString(): string {
		return JSON.stringify({
			breastfeedLatchQualityGroups: this.latchQualityGroups.map((g) =>
				g.toJSON()
			),
			defectiveReasons: this.defectiveReasons,
			manufacturers: this.manufacturers,
			overrideReasons: this.overrideReasons,
			printers: this.printers,
			tenant: this.tenant.toJSON(),
			features: this.features,
		} as IConfigs);
	}

	/**
	 * Sort products by name.
	 *
	 * FIXME: this should be stored instead of computing each time.
	 */
	get products(): Product[] {
		return this.manufacturers
			? this.manufacturers
					.map((m) => m.products.map((p) => new Product(p)))
					.reduce((prev, curr) => prev.concat(curr), [])
					.sort((a, b) => a.name.localeCompare(b.name))
			: [];
	}

	/**
	 * Flatten and get all products in a single array.
	 */
	getAllProducts(): Product[] {
		return this.manufacturers
			.map((m) => m.products.map((p) => new Product(p)))
			.reduce((prev, curr) => prev.concat(curr), []);
	}

	getProductsOfType(type: ProductType): Product[] {
		const products: Product[] = this.getAllProducts();
		return products.filter((p) => p.type === type);
	}

	getProduct(productId: string): Product {
		const products: Product[] = this.getAllProducts();
		const product = products.find((p) => p.id === productId);
		if (!product) {
			console.error(
				`ConfigModel.getProduct: cannot find product with id ${productId}`
			);
		}
		return product;
	}

	getCalories(): number[] {
		const calories = this.getAllProducts().map((p) => p.calorieDensity);
		return calories
			.filter((num, index) => calories.indexOf(num) === index) // remove dupes
			.filter((c) => c) // remove falsy
			.sort();
	}

	// TODO: this can probably be optimized and/or moved? (ML-1249)
	getProductRegexes(): {
		manufacturerName: string;
		product: Product;
		pattern: RegExp;
	}[] {
		const products = [];
		for (const m of this.manufacturers) {
			for (const p of m.products) {
				for (const r of p.regexes) {
					products.push({
						manufacturerName: m.name,
						product: new Product(p),
						pattern: r,
					});
				}
			}
		}
		return products;
	}
}

export interface IConfigs {
	breastfeedLatchQualityGroups: ILatchQualityGroup[];
	defectiveReasons: IDefectiveReason[];
	manufacturers: IManufacturer[];
	overrideReasons: string[];
	printers: IPrinter[];
	tenant: ITenantConfigs;
	features: IFeatureFlag;
}

/**
 * @deprecated TODO: this is currently not used
 */
export interface IRole {
	id: string;
	name: string; // "sys-admin";
	adGroupName: string; // "sa";
	permissions: string[];
}

export interface IDefectiveReason {
	reason: string;
	discard: boolean;
}

export interface IManufacturer {
	id: string;
	name: string;
	products: IProduct[];
}

/**
 * More like system configurations but naming is hard. This contains
 * information about the app version and build number but also contains
 * some settings that are used throughout the app.
 */
export class AppConfigs {
	// Info
	tenant: string;
	environment: string;
	appVersion: string;
	appBuildNumber: string;
	dbVersion: string;
	dbLastUpdated: string;

	// Configs
	sessionLengthInMinutes: number;
	timeoutDurationInMilliSeconds: number;
	enableBadgeScanning: boolean;
	enableSelectMilk: boolean; // FIXME: deprecated
	authGuard: boolean;
	bypassExpiration: boolean;
	bypassOrderMatching: boolean;

	constructor(initial: IAppConfigs = {} as IAppConfigs) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toString(): string {
		return JSON.stringify({
			tenant: this.tenant,
			environment: this.environment,
			appVersion: this.appVersion,
			appBuildNumber: this.appBuildNumber,
			dbVersion: this.dbVersion,
			dbLastUpdated: this.dbLastUpdated,
			// production: this.production,
			sessionLengthInMinutes: this.sessionLengthInMinutes,
			timeoutDurationInMilliSeconds: this.timeoutDurationInMilliSeconds,
			enableBadgeScanning: this.enableBadgeScanning,
			enableSelectMilk: this.enableSelectMilk,
			authGuard: this.authGuard,
			bypassExpiration: this.bypassExpiration,
			bypassOrderMatching: this.bypassOrderMatching,
		} as IAppConfigs);
	}
}

/**
 * This is the data model around the /version endpoint.
 * See `getAppInfo()` in `auth.service.ts` for more info.
 */
export interface IAppConfigs {
	tenant: string; // TODO: ignore this because tenant name is already defined in ConfigModel
	environment: string;
	appVersion: string;
	appBuildNumber: string;
	dbVersion: string;
	dbLastUpdated: string;
	// production: boolean; // TODO: this is already defined in environment.ts
	sessionLengthInMinutes: number;
	timeoutDurationInMilliSeconds: number;
	enableBadgeScanning: boolean;
	enableSelectMilk: boolean;
	authGuard: boolean;
	bypassExpiration: boolean;
	bypassOrderMatching: boolean;
}

export interface IBuildNumber {
	GIT_SHA: string;
	VERSION: string;
}

import { Component, Input } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { modalMessage } from "src/app/app.modal-messages";
import { PatientModel } from "src/app/models/patient.model";
import { InventoryService, ModalService } from "src/app/services";
import {
	FeedPatientListItem,
	MilkBottleFeedPatientListItem,
} from "../../administer-feed/feed-patient/list-item";

@Component({
	selector: "app-manual-entry-bottle-input",
	templateUrl: "./manual-entry-bottle-input.component.html",
	styleUrls: ["./manual-entry-bottle-input.component.scss"],
})
export class ManualEntryBottleInputComponent {
	@Input() patient: PatientModel;
	@Input() manualEntryForm: FormGroup;

	bottleNumber: string;
	disabled = false;

	faPlus = faPlus;

	public constructor(
		private inventoryService: InventoryService,
		private modalService: ModalService,
		private formBuilder: FormBuilder
	) {}

	async validateBottleNumber(bottleNumber: string) {
		try {
			this.disabled = true;

			if (!bottleNumber) {
				return;
			}

			// Bottle numbers aren't duplicated for milk bottles.
			const duplicate = (
				this.manualEntryForm.value.items as FeedPatientListItem[]
			).find((item: FeedPatientListItem) => {
				if ((item as MilkBottleFeedPatientListItem)?.milkBottle) {
					return (
						(
							item as MilkBottleFeedPatientListItem
						)?.milkBottle.bottleNumber.toString() === bottleNumber
					);
				}

				return false;
			});

			if (duplicate) {
				const { header, message, modalType } =
					modalMessage.milk_duplicate(
						(duplicate as MilkBottleFeedPatientListItem).milkBottle
					);
				await this.modalService.presentOverlayModal(modalType, header, [
					message,
				]);
				this.disabled = false;
				return;
			}

			const milkBottles = await this.inventoryService.getMilkBottles(
				{ mrn: this.patient.mrn },
				{
					includeExpended: false,
					includeExpired: false,
					includeUsed: true,
					bottleNumber: parseInt(bottleNumber),
				}
			);

			if (milkBottles.length === 0) {
				const { header, message, modalType } =
					modalMessage.form_validation_milk_not_found(bottleNumber);
				await this.modalService.presentOverlayModal(modalType, header, [
					message,
				]);
				this.disabled = false;
				return;
			}

			const milkBottleListItem = new MilkBottleFeedPatientListItem(
				milkBottles[0]
			);

			(this.manualEntryForm.get("items") as FormArray).push(
				this.formBuilder.control(milkBottleListItem)
			);
		} catch (error) {
			console.log("validateBottleNumber: ", error);
			// Add error handling
		} finally {
			this.disabled = false;
		}
	}
}

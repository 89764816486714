<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="prev()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div *ngIf="state === 1" class="ion-text-wrap">select additive(s)</div>
			<div *ngIf="state === 2" class="ion-text-wrap">scan additive(s)</div>
		</ion-title>
		<ion-buttons slot="end">
			<ion-button *ngIf="state === 1 && mode === RecipeMode.MP" (click)="selectMultiple()">next</ion-button>
		</ion-buttons>
	</ion-toolbar>
	<ion-toolbar *ngIf="state === 1" color="primary" class="secondary-toolbar">

		<div class="secondary-toolbar__patient-info">
			<app-patient-info [patients]="patients"
							  [isHeaderVisible]="false"
							  [isToolbar]="true">
			</app-patient-info>
		</div>

		<div *ngIf="allowOrderValidation" class="secondary-toolbar__order-info">
			<ion-item *ngIf="selectedOrder" lines="none">
				<ion-input [disabled]="true" [value]="displaySelectedOrder(selectedOrder)" readonly></ion-input>
			</ion-item>
			<ion-item *ngIf="!selectedOrder" lines="none">
				<ion-text class="validation-text">
					This patient has no active orders OR has an active NPO order
				</ion-text>
			</ion-item>
		</div>

		<ion-searchbar
			type="text"
			debounce="500"
			showClearButton="true"
			(ionInput)="getItems($event)"
			#selectAdditiveModalSearchInput>
		</ion-searchbar>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen *ngIf="state === 1 && mode !== RecipeMode.MP">
	<ion-list *ngIf="isItemAvailable">
		<ion-item *ngFor="let s of filteredSelectableRecipes; let i = index"
				  button (click)="selectSingle(s.recipe)"
				  lines="full"
				  detail>
			<ion-label class="ion-text-wrap" slot="start" [attr.data-testid]="'select_additive_option_' + i">
				{{displayAdditives({ additives: s.recipe.additives })}}
				<p>with {{s.recipe.baseName}}</p>
			</ion-label>
<!--			<ion-text class="ion-text-wrap" slot="end">{{r | json}}</ion-text>-->
		</ion-item>
	</ion-list>
	<ion-list *ngIf="!isItemAvailable">
		<ion-item *ngFor="let s of selectableRecipes; let i = index"
				  button (click)="selectSingle(s.recipe)"
				  lines="full"
				  detail>
			<ion-label class="ion-text-wrap" slot="start" [attr.data-testid]="'select_additive_option_' + i">
				{{displayAdditives({ additives: s.recipe.additives })}}
				<p>with {{s.recipe.baseName}}</p>
			</ion-label>
<!--			<ion-text class="ion-text-wrap" slot="end">{{r | json}}</ion-text>-->
		</ion-item>
	</ion-list>
</ion-content>

<!-- Select Additive (Multiple) -->
<ion-content fullscreen *ngIf="state === 1 && mode === RecipeMode.MP">
	<ion-list *ngIf="isItemAvailable">
		<ion-item *ngFor="let s of filteredSelectableRecipes; let i = index" lines="full">
			<ion-label class="ion-text-wrap" slot="start" [attr.data-testid]="'select_additive_option_' + i">
				{{displayAdditives({ additives: s.recipe.additives })}}
				<p>with {{s.recipe.baseName}}</p>
			</ion-label>
			<ion-checkbox slot="end" [(ngModel)]="s.isChecked" (ionChange)="toggleSelected(s.recipe, $event)"></ion-checkbox>
		</ion-item>
	</ion-list>
	<ion-list *ngIf="!isItemAvailable">
		<ion-item *ngFor="let s of selectableRecipes; let i = index" lines="full">
			<ion-label class="ion-text-wrap" slot="start" [attr.data-testid]="'select_additive_option_' + i">
				{{displayAdditives({ additives: s.recipe.additives })}}
				<p>with {{s.recipe.baseName}}</p>
			</ion-label>
			<ion-checkbox slot="end" [(ngModel)]="s.isChecked" (ionChange)="toggleSelected(s.recipe, $event)"></ion-checkbox>
		</ion-item>
	</ion-list>
</ion-content>

<!-- Scan Additive -->
<ion-content fullscreen *ngIf="state === 2">
	<ion-list>
		<ion-item *ngFor="let s of getUnscannedAdditives()" class="scan-additive">
			<ion-label>
				{{s.additive.productName}}
			</ion-label>
			<ion-chip *ngIf="s.additive.productEnforceScanning" color="warning">Required</ion-chip>
			<ion-chip *ngIf="!s.additive.productEnforceScanning">Optional</ion-chip>
		</ion-item>
		<ion-item *ngFor="let o of scannedProducts" class="scanned-additive">
			<!-- Products -->
			<ion-label *ngIf="o.scanType !== 'milk-label'">
				{{o.product.name}}
				<div *ngIf="!o.match.groups.upc">
					<p>Lot #: {{o.match.groups.lotCode}}</p>
					<p>Bottle #: {{ getBottleNumberText(parseInt(o.match.groups.bottleNumber)) }}</p>
					<p>Expiration: {{ displayDate(handleExpirationDateFromScanGroups(o.match?.groups)) }}</p>
				</div>
				<div *ngIf="o.match.groups.upc">
					<p>UPC: {{o.match.groups.upc}}</p>
				</div>
			</ion-label>
			<ion-chip *ngIf="o.scanType !== 'milk-label'" color="success">
				{{ o.milkBankProduct ? "Received" : "Scanned" }}
			</ion-chip>

			<!-- Milk Bank Products -->
			<ion-label *ngIf="o.scanType === 'milk-label'">
				{{o.milkBankProduct.name}}
				<div>
					<p>Lot #: {{o.milkBankProduct.lotNumber}}</p>
					<p>Bottle #: {{ getBottleNumberText(o.milkBankProduct.bottleNumber) }}</p>
					<p>Expiration: {{ displayDate(o.milkBankProduct.expirationDate) }}</p>
				</div>
			</ion-label>
			<ion-chip *ngIf="o.scanType === 'milk-label'" color="success">
				Scanned
			</ion-chip>
		</ion-item>
	</ion-list>

<!--	<p>{{selectedAdditives | json}}</p>-->
<!--	<p>{{scannedProducts | json}}</p>-->
	<div class="buttons">
		<button (click)="done()" [disabled]="hasUnscanned()" class="nav-btn" data-testid="additive_modal_continue_button">Continue</button>
	</div>
</ion-content>

<app-scan-footer (handleScan)="handleScannedObject($event)" [scannedObjects]="scannedObjects" [option]="option" #scanner></app-scan-footer>

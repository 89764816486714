<ion-list-header *ngIf="isHeaderVisible" class="milk-header">
	<ion-label>
		{{ title }}
		<ion-chip color="primary">
			<ion-label>{{ milkBankProducts?.length }}</ion-label>
		</ion-chip>
	</ion-label>
	<ion-button *ngIf="StorageService.permissions?.global_SelectMilk"
	            [disabled]="!patient"
	            (click)="selectMilk()"
	            class="select-milk-btn">
		Select Milk
	</ion-button>
</ion-list-header>
<ion-accordion-group
	*ngIf="milkBankProducts"
	[multiple]="true"
	class="milk-info"
>
	<ion-accordion *ngFor="let m of milkBankProducts; trackBy: trackById">
		<div slot="header">
			<app-product-info-collapsed [milkBankProduct]="m" [expirationDate]="getExpirationDate(m)">
				<app-milk-modifiers
					*ngIf="isPartiallyUsable"
					[options]="options"
					(optionChange)="selectMilkModifier($event, m)"
					[disabledOptions]="getDisabledOptions(m)"
				>
				</app-milk-modifiers>
			</app-product-info-collapsed>
		</div>

		<ion-list slot="content">
			<ion-grid class="milk-info__content">
				<app-milk-details [product]="m"></app-milk-details>
				<ion-row *ngIf="hasButtons">
					<ion-col>
						<ion-button (click)="remove(m)" color="danger">Remove</ion-button>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-list>
	</ion-accordion>
</ion-accordion-group>

<ion-grid *ngIf="!milkBankProducts" class="milk-info--empty">
	<ion-row>
		<span>{{ instructions }}</span>
	</ion-row>
</ion-grid>

import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

import { DebugSelectMilkModal } from "./debug-select-milk/debug-select-milk.modal";
import { EditMilkModal } from "./edit-milk/edit-milk.modal";
import { EditScannedProductModal } from "./edit-scanned-product/edit-scanned-product.modal";
import { ReceiveProductModal } from "./receive-product/receive-product.modal";
import { ReceiveProductFeedPatientComponent } from "./receive-product-feed-patient/receive-product-feed-patient.component";
import { RecipeCalculatorModal } from "./recipe-calculator/recipe-calculator.modal";
import { SelectAdditiveModal } from "./select-additive/select-additive.modal";
import { SelectItemModal } from "./select-item/select-item.modal";
import { SelectPatientModal } from "./select-patient/select-patient.modal";
import { SelectProductModal } from "./select-product/select-product.modal";
import { ComponentsModule } from "../components/components.module";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { InlineThawMilkModal } from "./inline-thaw-milk/inline-thaw-milk.modal";

const modals = [
	DebugSelectMilkModal,
	EditMilkModal,
	EditScannedProductModal,
	ReceiveProductModal,
	ReceiveProductFeedPatientComponent,
	RecipeCalculatorModal,
	SelectAdditiveModal,
	SelectItemModal,
	SelectPatientModal,
	SelectProductModal,
	InlineThawMilkModal
];

@NgModule({
	declarations: modals,
	exports: modals,
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		ComponentsModule,
		FontAwesomeModule,
	],
})
export class ModalsModule {}

<ion-grid>
	<ng-container *ngFor="let prop of getDisplayedProperties(feed, excludes)">
		<div class="milk-details">
			<div class="milk-details__icon">
				<fa-icon [icon]="prop.icon" [fixedWidth]="true" size="sm"></fa-icon>
			</div>
			<div class="milk-details__label">{{ prop.label }}</div>
			<div>
				<ng-container *ngFor="let value of prop.getValues(feed)">
					<div class="milk-details__value">{{ value }}</div>
				</ng-container>
			</div>
		</div>
	</ng-container>
</ion-grid>

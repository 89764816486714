<ng-container *ngIf="hasPermissionMessage">
	<p class="required-message">Manual Entry is required because you do not have permission to select a patient in this workflow.</p>
</ng-container>

<ion-accordion-group class="bottle-number-entry">
	<ion-accordion>
	  <ion-item slot="header" color="administer-feed">
		<ion-label>Add a Patient Milk Bottle</ion-label>
	  </ion-item>
	  <div class="ion-padding" slot="content">
		<p>
			Enter the bottle number from a patient milk bottle below and click "Add"
		</p>

		<app-manual-entry-bottle-input
			[patient]="patient"
			[manualEntryForm]="manualEntryForm"
		></app-manual-entry-bottle-input>
	  </div>
	</ion-accordion>
</ion-accordion-group>

<form [formGroup]="manualEntryForm">
	<div class="list-items">
		<ng-container *ngFor="let item of items?.value; let i = index">
			<!-- Milk Bottles -->
			<ng-container *ngIf="item?.milkBottle">
				<app-bottle-info
					[milks]="[item.milkBottle]"
					[patient]="patient"
					[isContentVisible]="false"
					[isDischarging]="false"
					[hasButtons]="true"
					[isHeaderVisible]="false"
					[isPartiallyUsable]="!isOralCare"
					[options]="['feed all', 'has remaining']"
					[isInstructionsVisible]="false"
					(removeButtonPressed)="handleRemoveMilkBottle($event, i)"
					(modifierPress)="setMilkBottleActionItem($event, i)"
					>
				</app-bottle-info>
			</ng-container>

			<!-- Products -->
			<ng-container *ngIf="item?.product">
				<ion-accordion-group>
					<ion-accordion>
					<ion-item class="milk-info__header" slot="header">
						<ion-grid>
							<ion-row>
								<ion-col
									[ngClass]="getProductColor(item.productState)"
									size="auto"
								></ion-col>
								<ion-col>
									<ion-row>
										<ion-col
											class="milk-info__header__detail"
											size="auto"
										>
											<p class="milk-info__header__detail__label">
												bottle #
											</p>
											<p class="milk-info__header__detail__value">
												<span><b>{{ getBottleNumberText(item.bottleNumber) }}</b></span>
											</p>
										</ion-col>

										<ion-col size="auto">
											<div class="vertical-divider"></div>
										</ion-col>

										<ion-col
											class="milk-info__header__detail"
											size="auto"
										>
											<p class="milk-info__header__detail__label">
												{{ item.productState }}
											</p>
											<span class="milk-info__header__detail__state">
												{{ item.product.name }}
											</span>
										</ion-col>
									</ion-row>

									<ion-row *ngIf="!isOralCare"> <!--All scanned items in Oral Care workflows have ScannedItem.action = oral-care.  -->
										<ion-col>
											<div class="scanned-item-actions">
												<button
													(click)="setProductActionItem($event, item, 'feed all')"
													[ngClass]="{ 'selected': item.action === 'feed all' }"
												>
													Feed All
												</button>
												<button
													(click)="setProductActionItem($event, item, 'has remaining')"
													[ngClass]="{ 'selected': item.action === 'has remaining' }"
												>
													Has Remaining
												</button>
											</div>
										</ion-col>
									</ion-row>
								</ion-col>
							</ion-row>
						</ion-grid>
					</ion-item>

					<ion-list slot="content" class="product-list-contents ">
						<ion-grid class="milk-info__content">
							<div class="milk-info__content__details">
								<!-- Expiration -->
									<ng-container *ngIf="item.thawedDate">
										<div class="milk-details">
											<div class="milk-details__icon">
												<fa-icon [icon]="faClock" [fixedWidth]="true" size="sm"></fa-icon>
											</div>
											<div class="milk-details__label">Expiration</div>
											<div>
												<div class="milk-details__value">{{ getExpirationDate(item) }}</div>
											</div>
										</div>
									</ng-container>

									<ng-container *ngIf="item.openedDate">
										<div class="milk-details">
											<div class="milk-details__icon">
												<fa-icon [icon]="faClock" [fixedWidth]="true" size="sm"></fa-icon>
											</div>
											<div class="milk-details__label">Expiration</div>
											<div>
												<div class="milk-details__value">{{ getExpirationDate(item) }}</div>
											</div>
										</div>
									</ng-container>

								<div class="milk-details">
									<div class="milk-details__icon">
										<fa-icon [icon]="faClock" [fixedWidth]="true" size="sm"></fa-icon>
									</div>
									<div class="milk-details__label">Manufacturer Expiration</div>
									<div>
										<div class="milk-details__value">{{ displayDate(item.manufacturerExpirationDate) }}</div>
									</div>
								</div>

								<!-- Manufacturer -->
								<div class="milk-details">
									<div class="milk-details__icon">
										<fa-icon [icon]="faIndustry" [fixedWidth]="true" size="sm"></fa-icon>
									</div>
									<div class="milk-details__label">Manufacturer</div>
									<div>
										<div class="milk-details__value">{{ item.product.manufacturerName }}</div>
									</div>
								</div>

								<!-- Lot Number -->
								<div class="milk-details">
									<div class="milk-details__icon">
										<fa-icon [icon]="faRectangleList" [fixedWidth]="true" size="sm"></fa-icon>
									</div>
									<div class="milk-details__label">Lot Number</div>
									<div>
										<div class="milk-details__value">{{ item.lotNumber }}</div>
									</div>
								</div>

								<!-- Type -->
								<div class="milk-details">
									<div class="milk-details__icon">
										<fa-icon [icon]="faRectangleList" [fixedWidth]="true" size="sm"></fa-icon>
									</div>
									<div class="milk-details__label">Type</div>
									<div>
										<div class="milk-details__value">{{ item.product.type | titlecase }}</div>
									</div>
								</div>

								<!-- Calories -->
								<div class="milk-details">
									<div class="milk-details__icon">
										<fa-icon [icon]="faUtensils" [fixedWidth]="true" size="sm"></fa-icon>
									</div>
									<div class="milk-details__label">Calories</div>
									<div>
										<div class="milk-details__value">{{ getCaloricDensityText(item.product.calorieDensity) }}</div>
									</div>
								</div>
							</div>
							<ion-button (click)="handleRemoveProduct(i)" color="danger">Remove</ion-button>
						</ion-grid>
					</ion-list>
					</ion-accordion>
				</ion-accordion-group>
			</ng-container>
		</ng-container>
	</div>

	<ion-item class="select-item" lines="none">
		<ion-label>
			<p class="select-item__title">Reason</p>
			<ion-grid>
				<ion-row>
					<ion-col>
						<ion-select [interfaceOptions]="defaultSelectOptions"
									formControlName="reason"
									aria-label="reason"
									fill="outline"
									labelPlacement="stacked"
									interface="popover"
									placeholder="Select a reason">
							<ion-select-option *ngFor="let reason of reasons" [value]="reason">
								{{ reason }}
							</ion-select-option>
						</ion-select>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-label>
	</ion-item>

	<ion-item *ngIf="isOtherReason" lines="none" class="input-field">
		<ion-label>
			<p>Other Reason</p>
			<ion-input
				formControlName="otherReason"
				aria-label="other reason"
				placeholder="Enter other reason"
			>
			</ion-input>
		</ion-label>
	</ion-item>

	<ion-item lines="none" class="input-field">
		<ion-label>
			<p>MRN</p>
			<ion-input
				aria-label="MRN"
				placeholder="MRN"
				[value]="patient.mrn"
				[disabled]="true"
			>
			</ion-input>
		</ion-label>
	</ion-item>

	<ion-item *ngIf="hasSecondNurse" lines="none" class="input-field">
		<ion-label>
			<p>Second Nurse Id</p>
			<ion-input
				aria-label="Second Nurse Id"
				placeholder="Second Nurse Id"
				[value]="secondNurse.id"
				[disabled]="true"
			>
			</ion-input>
		</ion-label>
	</ion-item>
</form>

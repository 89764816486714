import { ScanLogType, ScanLogWorkflow } from "src/app/app.enums";
import { MilkBottleModel } from "./milk.model";
import { PatientModel } from "./patient.model";
import { MilkBankProductModel } from "./milk-bank-product.model";
import { Product } from "./product.model";

export abstract class LogObjectModel {
	type: ScanLogType;
	workflow: ScanLogWorkflow;

	toPayload() {
		throw new Error("Implement in subclass");
	}
}

export class LogAttemptModel extends LogObjectModel {
	constructor(initial: ILogAttemptModel = {} as ILogAttemptModel) {
		super();
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toPayload(): ILogAttemptModelPayload {
		return {
			reason: this.workflow,
			result: this.type,
		};
	}
}

export class LogMilkBottleModel extends LogObjectModel {
	milkBottleId: MilkBottleModel["id"];
	patientId: PatientModel["id"];
	isOverride: false;

	constructor(initial: ILogMilkBottleModel = {} as ILogMilkBottleModel) {
		super();
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toPayload(): ILogMilkBottleModelPayload {
		return {
			milkBottleId: this.milkBottleId,
			patientId: this.patientId,
			reason: this.workflow,
			result: this.type,
		};
	}
}

export class LogMilkBankProductModel extends LogObjectModel {
	milkBankProductId: MilkBankProductModel["id"];
	patientId: PatientModel["id"];

	constructor(
		initial: ILogMilkBankProductModel = {} as ILogMilkBankProductModel
	) {
		super();
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toPayload(): ILogMilkBankProductModelPayload {
		return {
			milkBankProductId: this.milkBankProductId,
			patientId: this.patientId,
			reason: this.workflow,
			result: this.type,
		};
	}
}

export class LogScannedObjectModel extends LogObjectModel {
	productId: Product["id"];

	constructor(
		initial: ILogScannedObjectModel = {} as ILogScannedObjectModel
	) {
		super();
		Object.keys(initial).forEach((key) => {
			this[key] = initial[key];
		});
	}

	toPayload(): ILogScannedObjectModelPayload {
		return {
			productId: this.productId,
			reason: this.workflow,
			result: this.type,
		};
	}
}

export interface ILogAttemptModel {
	type: ScanLogType;
	workflow: ScanLogWorkflow;
}

interface ILogAttemptModelPayload {
	reason: ScanLogWorkflow;
	result: ScanLogType;
}

export interface ILogMilkBottleModel {
	type: ScanLogType;
	workflow: ScanLogWorkflow;
	milkBottleId: MilkBottleModel["id"];
	patientId: PatientModel["id"];
}

interface ILogMilkBottleModelPayload {
	milkBottleId: MilkBottleModel["id"];
	patientId: PatientModel["id"];
	reason: ScanLogWorkflow;
	result: ScanLogType;
}

export interface ILogMilkBankProductModel {
	type: ScanLogType;
	workflow: ScanLogWorkflow;
	milkBankProductId: MilkBankProductModel["id"];
	patientId: PatientModel["id"];
}

interface ILogMilkBankProductModelPayload {
	milkBankProductId: MilkBankProductModel["id"];
	patientId: PatientModel["id"];
	reason: ScanLogWorkflow;
	result: ScanLogType;
}

export interface ILogScannedObjectModel {
	type: ScanLogType;
	workflow: ScanLogWorkflow;
	productId: Product["id"];
}

interface ILogScannedObjectModelPayload {
	productId: Product["id"];
	reason: ScanLogWorkflow;
	result: ScanLogType;
}

/**
 * This contains the criteria for direct breastfeeding latch quality measurements.
 */
export class LatchQualityGroup {
	id: string;
	name: string;
	options: ILatchQuality[];

	constructor(initial: ILatchQualityGroup = {} as ILatchQualityGroup) {
		Object.keys(initial).forEach((key) => {
			// biome-ignore lint/nursery/noSecrets: <explanation>
			if (key === "breastfeedLatchQualityOptions") {
				this.options = initial[key];
				// biome-ignore lint/nursery/noSecrets: <explanation>
			} else if (key === "breastfeedLatchQualityId") {
				this.id = initial[key];
			} else if (key === "groupName") {
				this.name = initial[key];
			} else {
				this[key] = initial[key];
			}
		});
	}

	toJSON(): ILatchQualityGroup {
		return {
			breastfeedLatchQualityId: this.id,
			groupName: this.name,
			breastfeedLatchQualityOptions: this.options,
		};
	}
}

export interface ILatchQualityGroup {
	breastfeedLatchQualityId: string;
	groupName: string;
	breastfeedLatchQualityOptions: ILatchQuality[];
}

/**
 * option is the name of the latch quality option.
 * description is additional information about the option.
 */
export interface ILatchQuality {
	option: string;
	description: string;
	value: number;
}

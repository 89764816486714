import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBottleModel } from "src/app/models/milk.model";
import { defaultSelectOptions } from "src/app/components/default-options";
import {
	FeedState,
	MilkState,
	MilkType,
} from "src/app/app.enums";
import { capitalize } from "src/app/app.util";
import {
	isFresh,
	isFrozen,
	isPrepared,
	isPrepared2,
	isThawed,
} from "../../utils/inventory.util";
import { ScanningService } from "../../services/scanning.service";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { ValidProductStates } from "../../models/product.model";

@Component({
	selector: "app-feed-state-select",
	templateUrl: "./feed-state-select.component.html",
	styleUrls: ["./feed-state-select.component.scss"],
})
export class FeedStateSelectComponent implements OnInit {
	@Input() title: string;
	@Input() isDisabled: boolean = false;
	@Input() presetState: FeedState;
	@Input() state: FeedState;
	@Output() stateChange = new EventEmitter<FeedState>();

	milkBottle: MilkBottleModel;
	milkBankProduct: MilkBankProductModel;
	capitalize = capitalize;
	defaultSelectOptions = defaultSelectOptions;
	validProductStates = ValidProductStates;

	get feed() {
		if (this.milkBottle) {
			return this.milkBottle;
		} else if (this.milkBankProduct) {
			return this.milkBankProduct;
		} else {
			throw Error("ngOnInit: milkBottle xor milkBankProduct not found.");
		}
	}

	@Input()
	set feed(value: MilkBottleModel | MilkBankProductModel) {
		if (value instanceof MilkBottleModel) {
			this.milkBottle = value;
			this.milkBankProduct = null;
		} else if (value instanceof MilkBankProductModel) {
			this.milkBottle = null;
			this.milkBankProduct = value;
		} else {
			throw Error("ngOnInit: milkBottle xor milkBankProduct not found.");
		}
	}

	constructor(public scanningService: ScanningService) {}

	ngOnInit() {
		// do nothing
	}

	/**
	 * Check if the feed state is MilkState or ProductState
	 * @param state
	 */
	handleFeedStateChange(state: FeedState) {
		this.state = state;
		this.stateChange.emit(state);
	}

	/** Returns the MilkStates that a milk bottle can be assigned.
	 *
	 * milk bottles that are PREPARED cannot have their state modified
	 * milk bottles that contain ONLY EBM can be frozen, thawed, or fresh
	 * milk bottles that are Stable or Opened can only be changed between these two statuses
	 * milk bottles that contain DM cannot be set to fresh
	 *   - Dm can come in a default state of frozen or stable.
	 *   - If the DM comes in default state of frozen, it can be set to that or thawed.
	 *   - If it comes in default state of stable, it can be set to stable or opened.
	 *
	 * TODO: this can go in a util file?
	 */
	validMilkStates(milkBottle: MilkBottleModel): MilkState[] {
		// Prepared
		if (isPrepared(milkBottle)) {
			return [];
		}

		// Frozen donor milk bottles
		if (
			milkBottle.milkType === MilkType.DM &&
			(milkBottle.milkState === MilkState.Frozen ||
				milkBottle.milkState === MilkState.Thawed)
		) {
			return [MilkState.Frozen, MilkState.Thawed];
		}

		// Stable or Open milk bottles
		if (
			milkBottle.milkState === MilkState.Stable ||
			milkBottle.milkState === MilkState.Opened
		) {
			return [MilkState.Opened, MilkState.Stable];
		}

		// Contains ONLY EBM can be frozen, thawed, or fresh
		if (
			isFrozen(milkBottle) ||
			isThawed(milkBottle) ||
			isFresh(milkBottle)
		) {
			return [MilkState.Fresh, MilkState.Frozen, MilkState.Thawed];
		}
	}

	protected readonly isPrepared = isPrepared;
	protected readonly ValidProductStates = ValidProductStates;
	protected readonly isPrepared2 = isPrepared2;
}

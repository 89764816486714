<ion-item [disabled]="isDisabled || isPrepared2(feed)" class="feed-state-select-item" lines="none">
  <ion-label>
    <p class="feed-state-select-item__title">{{ title }}</p>
    <ion-grid *ngIf="milkBottle">
      <ion-row>
        <ion-col>
          <ion-select aria-label="milk state"
                      fill="outline"
                      labelPlacement="stacked"
                      interface="popover"
                      [placeholder]="capitalize(milkBottle.milkState) || 'Change milk state'"
                      [interfaceOptions]="defaultSelectOptions"
                      (ionChange)="handleFeedStateChange($any($event.target).value)"
                      [(ngModel)]="milkBottle.milkState"
                      data-testid="milk_bottle__milk_state_select">
            <ion-select-option *ngFor="let milkState of validMilkStates(milkBottle); let i = index"
                               [value]="milkState"
                               [attr.data-testid]="'milk_bottle__milk_state_option_' + i"
                               >
              {{ capitalize(milkState) }}
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
    </ion-grid>

    <ion-grid *ngIf="milkBankProduct">
      <ion-row>
        <ion-col>
          <ion-select aria-label="product state"
                      fill="outline"
                      labelPlacement="stacked"
                      interface="popover"
                      [placeholder]="capitalize(milkBankProduct.feedState) || 'Change product state'"
                      [interfaceOptions]="defaultSelectOptions"
                      (ionChange)="handleFeedStateChange($any($event.target).value)"
                      [(ngModel)]="milkBankProduct.productState">
            <ion-select-option *ngFor="let productState of validProductStates.get(milkBankProduct.defaultState)"
                               [value]="productState">
              {{ capitalize(productState) }}
            </ion-select-option>
          </ion-select>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-label>
</ion-item>

import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { MaskitoDirective, MaskitoPipe } from "@maskito/angular";

import { BottleInfoComponent } from "./bottle-info/bottle-info.component";
import { ColorBarComponent } from "./color-bar/color-bar.component";
import { EditReprintLabelListitemComponent } from "./edit-reprint-label-listitem/edit-reprint-label-listitem.component";
import { EditReprintUnassignedListComponent } from "./edit-reprint-unassigned-list/edit-reprint-unassigned-list.component";
import { FeedInfoComponent } from "./feed-info/feed-info.component";
import { FeedInfoHeaderComponent } from "./feed-info-header/feed-info-header.component";
import { FeedOverrideFormComponent } from "./feed-override-form/feed-override-form.component";
import { FeedStateSelectComponent } from "./feed-state-select/feed-state-select.component";
import { FooterComponent } from "./footer/footer.component";
import { InlineDatePickerComponent } from "./inline-date-picker/inline-date-picker.component";
import { MilkBankProductEditComponent } from "./milk-bank-product-edit/milk-bank-product-edit.component";
import { MilkbottleEditComponent } from "./milkbottle-edit/milkbottle-edit.component";
import { MilkDetailsComponent } from "./milk-details/milk-details.component";
import { MilkModifiersComponent } from "./milk-modifiers/milk-modifiers.component";
import { NavbarComponent } from "./navbar/navbar.component";
import { NurseInfoComponent } from "./nurse-info/nurse-info.component";
import { OfflineComponent } from "./offline/offline.component";
import { OrderMatchComponent } from "./order-match/order-match.component";
import { OrderSelectComponent } from "./order-select/order-select.component";
import { PatientInfoComponent } from "./patient-info/patient-info.component";
import { PatientInfoContentComponent } from "./patient-info-content/patient-info-content.component";
import { PrintLabelWithRetryComponent } from "./print-label-with-retry/print-label-with-retry.component";
import { ProductInfoComponent } from "./product-info/product-info.component";
import { RecipeInfoComponent } from "./recipe-info/recipe-info.component";
import { ScanFooterComponent } from "./scan-footer/scan-footer.component";
import { ProductInfoCollapsedComponent } from "./product-info-collapsed/product-info-collapsed.component";
import { ProductInventoryComponent } from "./product-inventory/product-inventory.component";
import { MilkBottleInventoryComponent } from "./milkbottle-inventory/milkbottle-inventory.component";
import { InputPositiveNumberComponent } from "./input-positive-numbers/input-positive-numbers.component";
import { LabelQuantityComponent } from "./label-quantity/label-quantity.component";
import { FeedInfoContentComponent } from "./feed-info-content/feed-info-content.component";
import { OrderFulfillmentComponent } from "./order-fulfillment/order-fulfillment.component";
import { LabelQuantityListComponent } from "./label-quantity-list/label-quantity-list.component";
import { BarcodeScannerComponent } from "./barcode-scanner/barcode-scanner.component";
import { BarcodeScannerMultipleComponent } from "./barcode-scanner-multiple/barcode-scanner-multiple.component";
import { BottleNumberInputComponent } from "./bottle-number-input/bottle-number-input.component";
import { LogoutWarningComponent } from "./logout-warning/logout-warning.component";
import { InlineDateComponent } from "./inline-date/inline-date.component";
import { FeedManualEntryComponent } from "./feed-manual-entry/feed-manual-entry.component";
import { ManualEntryBottleInputComponent } from "./manual-entry-bottle-input/manual-entry-bottle-input.component";

const all = [
	BottleInfoComponent,
	ColorBarComponent,
	EditReprintLabelListitemComponent,
	EditReprintUnassignedListComponent,
	FeedInfoComponent,
	FeedInfoHeaderComponent,
	FeedOverrideFormComponent,
	FeedStateSelectComponent,
	FooterComponent,
	InlineDateComponent,
	InlineDatePickerComponent,
	InputPositiveNumberComponent,
	LabelQuantityComponent,
	LabelQuantityListComponent,
	FeedManualEntryComponent,
	ManualEntryBottleInputComponent,
	MilkBankProductEditComponent,
	MilkbottleEditComponent,
	MilkBottleInventoryComponent,
	MilkDetailsComponent,
	MilkModifiersComponent,
	NavbarComponent,
	NurseInfoComponent,
	OfflineComponent,
	OrderFulfillmentComponent,
	OrderMatchComponent,
	OrderSelectComponent,
	PatientInfoComponent,
	PatientInfoContentComponent,
	PrintLabelWithRetryComponent,
	ProductInfoCollapsedComponent,
	ProductInfoComponent,
	ProductInventoryComponent,
	RecipeInfoComponent,
	ScanFooterComponent,
	BarcodeScannerComponent,
	BarcodeScannerMultipleComponent,
	BottleNumberInputComponent
];

const customAlertModals = [
	LogoutWarningComponent
]

const components = [
	...all,
	...customAlertModals
]

@NgModule({
	declarations: [
		components,
		MilkModifiersComponent,
		FeedInfoContentComponent,
	],
	exports: [components],
	imports: [
		CommonModule,
		IonicModule,
		FormsModule,
		ReactiveFormsModule,
		FontAwesomeModule,
		MaskitoDirective,
		MaskitoPipe
	],
})
export class ComponentsModule {}

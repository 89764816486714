<ion-list-header *ngIf="milkBottles?.length > 0">
	<ion-label>
		{{ header }}
	</ion-label>
</ion-list-header>
<ion-accordion-group
	*ngIf="milkBottles"
	[multiple]="true"
	class="milk-info"
>
	<ion-accordion *ngFor="let m of milkBottles; let i = index">
		<ion-item class="milk-info__header" slot="header" [attr.data-testid]="'bottle_info__item_' + i">
			<ion-grid>
				<ion-row>
					<ion-col
						[ngClass]="getStatusColor(m)"
						size="auto"
					></ion-col>
					<ion-col>
						<ion-row>
							<ion-col
								class="milk-info__header__detail"
								size="auto"
							>
								<p class="milk-info__header__detail__label">
									bottle #
								</p>
								<p class="milk-info__header__detail__value" [attr.data-testid]="'bottle_info__number_' + i">
									<span
										><b>{{ getBottleNumberText(m.bottleNumber) }}</b></span
									>
								</p>
							</ion-col>

							<ion-col size="auto">
								<div class="vertical-divider"></div>
							</ion-col>

							<ion-col
								class="milk-info__header__detail"
								size="auto"
							>
								<p class="milk-info__header__detail__label">
									milk type
								</p>
								<p class="milk-info__header__detail__value" [attr.data-testid]="'bottle_info__milk_type_' + i">
									<span>{{ getMilkTypeText(m) }}</span>
								</p>
							</ion-col>

							<ion-col class="milk-info__header__chip">
								<ion-chip
									[color]="
										expirationDateChipColor(
											m.expirationDate
										)
									"
								>
									<span
										[class]="{
											expired: isExpired(m.expirationDate)
										}"
										>{{
										getRelativeExpirationTime(
												m.expirationDate
											)
										}}
									</span>
								</ion-chip>
							</ion-col>
						</ion-row>
            <ion-row class="milk-info__secondary">
              <ion-col class="milk-info__secondary__contents" [attr.data-testid]="'bottle_info__secondary_contents_' + i">
                Contents:&nbsp;
                {{ getContents(m) }}
              </ion-col>
            </ion-row>
					</ion-col>
				</ion-row>
			</ion-grid>
		</ion-item>
		<ion-list slot="content">
			<ion-grid class="milk-info__content">
				<div class="milk-info__content__details">
					<app-milk-details [milk]="m" ></app-milk-details>
				</div>
				<ion-item>
					<ion-button (click)="edit(m)" size="default" [attr.data-testid]="'bottle_info__edit_button_' + i"
						>Edit</ion-button
					>
					<ion-button
						[disabled]="isExpired(m.expirationDate)"
						(click)="goToReprint(m)"
						size="default"
						[attr.data-testid]="'bottle_info__reprint_button_' + i"
						>Reprint</ion-button
					>
				</ion-item>
			</ion-grid>
		</ion-list>
	</ion-accordion>
</ion-accordion-group>

<ion-grid *ngIf="isMessageVisible && !milkBottles?.length" class="milk-info--empty">
	<ion-row>
		<span>{{ noResultsMessage }}</span>
	</ion-row>
</ion-grid>

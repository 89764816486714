import { Injectable } from "@angular/core";
import { NavigationExtras } from "@angular/router";
import {
	MenuController,
	ModalController,
	NavController,
	Platform,
} from "@ionic/angular";

import { assertExhaustive } from "src/app/app.enums";

import { MilkBottleModel } from "src/app/models/milk.model";
import { MilkTrackerMilkBottleQRCode } from "./scanning.service";

import {
	AuthService,
	InventoryService,
	LabelService,
	LoggingService,
	ModalService,
	PatientService,
	StorageService,
	ToastService,
	UiMessagesService,
} from ".";
import { Observable, fromEvent } from "rxjs";
import { NurseValidationService } from "./nurse-validation.service";
import { environment } from "src/environments/environment";

@Injectable({
	providedIn: "root",
})
export class BaseService {
	private _toast: HTMLIonToastElement;

	constructor(
		public platform: Platform,
		public authService: AuthService,
		public inventoryService: InventoryService,
		public loggingService: LoggingService,
		public menuCtrl: MenuController,
		public modalCtrl: ModalController,
		private navCtrl: NavController,
		public patientService: PatientService,
		public alerts: UiMessagesService,
		public labelService: LabelService,
		public modalService: ModalService,
		public toastService: ToastService,
		public nurseValidationService: NurseValidationService
	) {}

	async logout(keepSubscriptionsActive?: boolean) {
		await this.authService.logout(keepSubscriptionsActive ?? false);
		window.location.href = environment.settings.clerkSignOutURL;
		return;
	}

	clean() {
		this.alerts.clean();
		this.cleanModals();
		this.toastService.cleanToast();

		StorageService.unsubscribeAll();
	}

	cleanModals() {
		if (this.modalService.modal) {
			this.modalService.modal.dismiss();
			this.modalService.modal = null;
			console.log("BaseService: Clear _modal");
		}
	}

	public async listenForInternetConnection() {
		const online$: Observable<Event> = fromEvent(window, "online");
		const offline$: Observable<Event> = fromEvent(window, "offline");

		StorageService.addSubscription(
			online$.subscribe(() => {
				console.log("BaseService.listenForInternetConnection: Online");
				this.modalService.dismissModal();
			})
		);

		// This catches the internet connection dropping while in the app after reload
		StorageService.addSubscription(
			offline$.subscribe(async () => {
				await this.logout(true);
				console.log(
					"BaseService.listenForInternetConnection: Offline - Lost internet connection"
				);
				await this.modalService.presentApplicationOffline();
			})
		);
	}

	/*
		LoadingController
	 */
	async presentLoading(message = "Loading") {
		return await this.alerts.presentLoading(message);
	}

	async dismissLoading() {
		await this.alerts.dismissLoading();
	}

	/*
		NavController
	 */
	navigateForward(path: string, options?: NavigationExtras) {
		return this.navCtrl.navigateForward(path, options);
	}

	navigateBack(path: string, options?: NavigationExtras) {
		return this.navCtrl.navigateBack(path, options);
	}

	navigateRoot(path: string, options?: NavigationExtras) {
		return this.navCtrl.navigateRoot(path, options);
	}

	pop() {
		return this.navCtrl.pop();
	}

	/*
		Inventory Service
	 */
	async getMilkBottleByQRCode(
		milkBottleQRCode: MilkTrackerMilkBottleQRCode
	): Promise<MilkBottleModel> {
		try {
			await this.presentLoading("Getting milk info...");

			const milkBottleQRCodeIdType = milkBottleQRCode.id.type;

			switch (milkBottleQRCodeIdType) {
				case "guid":
					return await this.inventoryService.getMilkBottle(
						milkBottleQRCode.id.milkBottleId
					);
				case "unifiedId":
					return await this.inventoryService.getMilkBottleByUnifiedId(
						milkBottleQRCode.id.milkBottleUnifiedId
					);
				default:
					assertExhaustive(milkBottleQRCodeIdType);
			}
		} finally {
			await this.dismissLoading();
		}
	}

	updateMilkBottle(milkBottle: MilkBottleModel): Promise<MilkBottleModel> {
		return this.inventoryService.updateMilkBottle3(milkBottle);
	}
}

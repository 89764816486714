<ion-list-header>
	<ion-label>Label Quantity</ion-label>
</ion-list-header>

<ion-item [disabled]="disabled" class="qty" lines="none">
	<app-input-positive-number
		[minimumValue]="minimumValue"
		[maximumValue]="maximumValue"
		[initialValue]="initialValue"
		(valueChange)="handleLabelQuantity($event)"
		ariaLabel="Label Quantity"
		data-testid="label_quantity_input_container"
		[step]="step"
		>
	</app-input-positive-number>
</ion-item>
<ion-note *ngIf="hasError" slot="helper" color="danger">
	{{ errorMessage }}
</ion-note>

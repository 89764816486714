import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BaseService } from "../../services/base.service";
import { PatientModel } from "../../models/patient.model";
import { GetMilkBottleOptions, InventoryService } from "../../services/inventory.service";
import {
	createFeedObjectBarcode,
	getBottleNumberText,
	getFeedTypeText,
	getMilkTypeText,
} from "../../utils/milk-label.util";
import {
	getRelativeExpirationTime,
	isExpired,
} from "../../utils/expiration.util";
import * as dayjs from "dayjs";
import { FeedObjectModel } from "../../models/feed-object.model";
import { PaginationAction } from "src/app/app.enums";


@Component({
	selector: "app-debug-select-milk",
	templateUrl: "./debug-select-milk.modal.html",
	styleUrls: ["./debug-select-milk.modal.scss"],
})
export class DebugSelectMilkModal implements OnInit {
	@Input() patient: PatientModel;
	@Input() feedType: "milkbottle" | "milkbankproduct";

	list: FeedObjectModel[];
	filteredList: FeedObjectModel[];
	isItemAvailable = false;
	searchValue: string;
	hasMoreBottles: boolean;
	searchInput: string;

	getFeedTypeText = getFeedTypeText;
	getMilkTypeText = getMilkTypeText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	getBottleNumberText = getBottleNumberText;

	constructor(
		private base: BaseService,
		public inventoryService: InventoryService,
		private modalCtrl: ModalController
	) {}

	async ngOnInit() {
		try {
			await this.base.presentLoading("Retrieving list...");
			switch (this.feedType) {
				case "milkbottle": {
					const options = this.getDefaultMilkBottleOptions();
					const paginatedResponse = await this.inventoryService.getPatientInventory(
						this.patient,
						PaginationAction.New,
						options
					);
					this.list = paginatedResponse.items;
					this.hasMoreBottles = paginatedResponse.hasNext;
				}
					break;
				case "milkbankproduct":
					// Get all milk bank products that are not assigned to a patient and aren't expired
					this.list = (
						await this.inventoryService.getMilkBankProducts({
							isAssigned: false,
						})
					)
						.filter((m) => !isExpired(m.expirationDate))
						.filter((m) => !m.expendedDate);
					break;
				default:
					{
						const options = this.getDefaultMilkBottleOptions();
						const paginatedResponse = await this.inventoryService.getPatientInventory(
							this.patient,
							PaginationAction.New,
							options
						);
						this.list = [
							...(paginatedResponse.items),
							...(await this.inventoryService.getMilkBankProducts({
								isAssigned: false,
							})),
						];
					// throw new Error("Invalid feed type");
					}
			}

			// sort by latest expiration
			this.list = this.list.sort((a, b) =>
				dayjs(a.expirationDate).isBefore(b.expirationDate) ? 1 : -1
			);
		} finally {
			await this.base.dismissLoading();
		}
	}

	initializeItems() {
		this.filteredList = this.list;
	}

	async handleMilkBottleSearch() {
		try {
			if (!this.searchInput) {
				this.handleClearSearch();
				return;
			}
			this.filteredList = await this.filterMilkBottlesBySearchInput(
				this.searchInput
			);

			this.isItemAvailable = this.filteredList.length > 0;
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Filters milk bottles based on the provided search input.
	 * @param searchInput - The search input to filter the milk bottles.
	 * @returns An array of filtered milk bottles.
	 */
	async filterMilkBottlesBySearchInput(searchInput: string) {
		if (!searchInput) {
			return;
		}

		try {
			const options = this.getDefaultMilkBottleOptions(searchInput);

			const searchMatch = await this.base.inventoryService.searchPatientInventory(
				this.patient,
				PaginationAction.New,
				options
			);

			if (!searchMatch) {
				return;
			}

			this.hasMoreBottles = searchMatch.hasNext;
			return searchMatch.items;
		} catch (error) {
			console.error(error);
		}
	}

	handleClearSearch() {
		this.searchInput = null;
		this.initializeItems();
	}

	async handleViewMoreClicked() {
		try {
			if (this.searchInput) {
				await this.loadMoreMilkBottlesBySearchQuery(this.searchInput);
				return;
			}

			await this.loadMoreMilkBottles();
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Loads more milk bottles for the patient inventory.
	 */
	async loadMoreMilkBottles() {
		try {
			const options = this.getDefaultMilkBottleOptions();

			const additionalBottles = await this.base.inventoryService.getPatientInventory(
				this.patient,
				PaginationAction.Append,
				options
			);

			if (!additionalBottles) {
				return;
			}

			this.hasMoreBottles = additionalBottles.hasNext;
			this.list = [
				...this.list,
				...additionalBottles.items,
			];
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Loads more milk bottles based on the search query.
	 * If the search string is an integer, it searches for the bottle number and returns fuzzy matches.
	 * If the search string is not an integer it will search the lot number, and product name.
	 * @param searchString
	 */
	async loadMoreMilkBottlesBySearchQuery(searchString: string) {
		try {
			const options = this.getDefaultMilkBottleOptions(searchString);
			const additionalBottles = await this.base.inventoryService.searchPatientInventory(
				this.patient,
				PaginationAction.Append,
				options
			);

			if (!additionalBottles) {
				return;
			}

			this.hasMoreBottles = additionalBottles.hasNext;
			this.filteredList = [
				...this.filteredList,
				...additionalBottles.items,
			];
		} catch (error) {
			console.error(error);
		}
	}

	async select(feed: FeedObjectModel) {
		await this.modalCtrl.dismiss(createFeedObjectBarcode(feed));
	}

	async cancel() {
		await this.modalCtrl.dismiss();
	}

	private getDefaultMilkBottleOptions(searchInput?: string): GetMilkBottleOptions {
		const options: GetMilkBottleOptions = {
			includeDischarged: false,
			includeUsed: false,
			includeExpended: false,
			includeExpired: false,
		};

		if (searchInput) {
			options.searchInput = searchInput;
		}
		return options;
	}
}

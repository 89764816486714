import { Component, Input, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { BaseService } from "../../services/base.service";
import { InventoryService } from "../../services/inventory.service";
import {
	getBottleNumberText,
	getFeedTypeText,
	getMilkTypeText,
} from "../../utils/milk-label.util";
import { getRelativeExpirationTime } from "../../utils/expiration.util";

@Component({
	selector: "app-select-item",
	templateUrl: "./select-item.modal.html",
	styleUrls: ["./select-item.modal.scss"],
})
export class SelectItemModal implements OnInit {
	@Input() title = "select item";
	@Input() items: any[]; // Pass your items as input
	@Input() searchProperties: {
		title: string;
		subtitle: string;
		endText: string;
	} = {
		title: "title",
		subtitle: "subtitle",
		endText: "",
	}; // Array of properties to search on

	searchTerm: string = "";
	filteredItems: any[];

	getFeedTypeText = getFeedTypeText;
	getMilkTypeText = getMilkTypeText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	getBottleNumberText = getBottleNumberText;

	constructor(
		private base: BaseService,
		public inventoryService: InventoryService,
		private modalCtrl: ModalController
	) {}

	ngOnInit() {
		// Initialize the filtered items with all items
		this.filteredItems = this.items;
	}

	filterItems() {
		// check if a property in items matches the search term
		this.filteredItems = this.items.filter((item) =>
			Object.keys(item).some((key) => {
				if (typeof item[key] === "string") {
					return item[key]
						.toLowerCase()
						.includes(this.searchTerm.toLowerCase());
				}
			})
		);
	}

	selectItem(item: any) {
		// Close the modal and pass the selected item back
		this.modalCtrl.dismiss(item);
	}

	async cancel() {
		await this.modalCtrl.dismiss();
	}
}

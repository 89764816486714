import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBottleModel } from "../../models/milk.model";
import { ScanMilkBottleValidationError } from "./scan-validation-error";
import { IScanValidation } from "./scan-validation.model";

export function RejectReceived() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			// Only accept a milk bottle with a received date if it has a discharge date to allow re-receiving
			if (obj.milkBottle instanceof MilkBottleModel) {
				if (obj.milkBottle && (obj.milkBottle.receivedDate && !obj.milkBottle.dischargeDate)) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Previously_Received,
						modalMessage.milk_already_received()
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

import ScanbotSDK from "scanbot-web-sdk/ui";
import { UIConfig } from "scanbot-web-sdk/@types";

export function applySingleScanningUseCase(
	config: UIConfig.BarcodeScannerScreenConfiguration
) {
	const useCase = new ScanbotSDK.UI.Config.SingleScanningMode();

	useCase.barcodeSubtitle.visible = true;
	useCase.barcodeSubtitle.color = "#165d73";

	useCase.cancelButton.text = "Close";
	useCase.cancelButton.foreground.color = "#ffffff";
	useCase.cancelButton.background.fillColor = "#165d73";

	useCase.submitButton.text = "Submit";
	useCase.submitButton.foreground.color = "#ffffff";
	useCase.submitButton.background.fillColor = "#165d73";

	config.useCase = useCase;
}

import {
	capitalize,
	displayDate,
	getCaloricDensityText,
	getVolumeText,
} from "../app.util";
import {
	MilkDetailItem,
	MilkProductDetails,
} from "../components/milk-details/milk-details.model";
import { displayAddedContents } from "./milk-label.util";
import { MilkBottleModel } from "../models/milk.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import {
	faGlassWaterDroplet,
	faTrash,
	faSnowflake,
	faClock,
	faCalendarXmark,
	faBoxOpen,
	faGears,
	faBottleDroplet,
	faIndustry,
	faUtensils,
	faHandHoldingMedical,
	faJar,
	faArrowsSplitUpAndLeft,
	faRectangleList,
	faLocationDot,
	faFire,
} from "@fortawesome/free-solid-svg-icons";
import { TitleCasePipe } from "@angular/common";
import { displayContents } from "./feed-info-content.util";
import dayjs from "dayjs";

/**
 * Calorie Density
 * Contents
 * Discharge Date
 * Distributed To
 * Expended Date
 * Expiration Date
 * Frozen Date
 * Lot Number
 * Opened Date
 * Product Calorie
 * Product Manufacturer
 * Product Name
 * Product State
 * Received Date
 * Volume
 */

export const getCalorieDensity = (calorieDensity): MilkProductDetails[] => [
	{
		label: "Caloric Density",
		icon: faUtensils,
		values: [getCaloricDensityText(calorieDensity)],
	},
];

export const getMilkContents = (
	milk: MilkBottleModel,
	excludes?: string[]
): MilkProductDetails[] =>
	excludes?.includes(MilkDetailItem.CONTENTS)
		? []
		: [
				{
					label: "Contents",
					icon: faJar,
					values: [
						...displayContents(milk.contents),
						...displayAddedContents(milk).map(
							(x) =>
								`${x.contentProductName} (from #${x.fromBottleNumber})`
						),
					],
				},
		  ];

export const getMilkBankProductContents = (
	milkBankProduct: MilkBankProductModel
): MilkProductDetails[] => {
	const contentNames = displayContents(milkBankProduct.contents);
	console.log("contentNames", contentNames);
	return [
		{
			label: "Contents",
			icon: faJar,
			values: [...contentNames],
		},
	];
};

export const getDischargeDate = (dischargeDate): MilkProductDetails[] => {
	if (dischargeDate) {
		return [
			{
				label: "Discharged",
				icon: faCalendarXmark,
				values: [displayDate(dischargeDate)],
			},
		];
	}

	return [];
};

export const getDistributedTo = (distributedTo): MilkProductDetails[] => {
	if (distributedTo.length > 0) {
		return [
			{
				label: "Distributed To",
				icon: faArrowsSplitUpAndLeft,
				values: distributedTo.map(
					(x) => `${x.bottleNumber} ${x.milkType}`
				),
			},
		];
	}

	return [];
};

export const getExpendedDate = (expendedDate): MilkProductDetails[] => {
	if (expendedDate) {
		return [
			{
				label: "Expended",
				icon: faTrash,
				values: [displayDate(expendedDate)],
			},
		];
	}

	return [];
};

export const getExpirationDate = (expirationDate): MilkProductDetails[] => [
	{
		label: "Expiration",
		icon: faClock,
		values: [displayDate(expirationDate)],
	},
];

export const getFrozenDate = (frozenDate): MilkProductDetails[] => {
	if (frozenDate) {
		return [
			{
				label: "Frozen",
				icon: faSnowflake,
				values: [displayDate(frozenDate)],
			},
		];
	}

	return [];
};

export const getLocation = (location): MilkProductDetails[] => {
	const titleCasePipe = new TitleCasePipe();
	const titleCaseLocation = titleCasePipe.transform(location);

	return [
		{
			label: "Location",
			icon: faLocationDot,
			values: [titleCaseLocation],
		},
	];
};

export const getLotNumber = (lotNumber): MilkProductDetails[] => [
	{
		label: "Lot Number",
		icon: faRectangleList,
		values: [lotNumber],
	},
];

export const getOpenedDate = (openedDate): MilkProductDetails[] => {
	if (openedDate) {
		return [
			{
				label: "Opened",
				icon: faBoxOpen,
				values: [displayDate(openedDate)],
			},
		];
	}

	return [];
};

export const getProductCalorieDensity = (
	productCalorieDensity
): MilkProductDetails[] => [
	{
		label: "Caloric Density",
		icon: faUtensils,
		values: [getCaloricDensityText(productCalorieDensity)],
	},
];

export const getProductManufacturer = (
	productManufacturer
): MilkProductDetails[] => [
	{
		label: "Manufacturer",
		icon: faIndustry,
		values: [productManufacturer],
	},
];

export const getProductName = (name): MilkProductDetails[] => [
	{
		label: "Product",
		icon: faBottleDroplet,
		values: [name],
	},
];

export const getProductState = (state): MilkProductDetails[] => [
	{
		label: "State",
		icon: faGears,
		values: [capitalize(state)],
	},
];

export const getReceivedDate = (receivedDate): MilkProductDetails[] => {
	if (receivedDate) {
		return [
			{
				label: "Received",
				icon: faHandHoldingMedical,
				values: [displayDate(receivedDate)],
			},
		];
	}

	return [];
};

export const getThawedDate = (thawedDate: dayjs.Dayjs): MilkProductDetails[] => {
	if (!thawedDate) {
		return [];
	}

	return [
		{
			label: "Thawed",
			icon: faFire,
			values: [displayDate(thawedDate)],
		},
	];
};

export const getVolume = (volume): MilkProductDetails[] => [
	{
		label: "Volume",
		icon: faGlassWaterDroplet,
		values: [getVolumeText(volume)],
	},
];

import * as dayjs from "dayjs";
import * as _ from "lodash";
import {
	ContentType,
	FeedLabelStatus,
	MilkLocation,
	MilkState,
	MilkType,
	ScanLogType,
	ScanLogWorkflow,
} from "src/app/app.enums";
import { IPatient, PatientModel } from "src/app/models/patient.model";
import { FeedObjectModel } from "./feed-object.model";

export class MilkBottleModel extends FeedObjectModel {
	motherId: string;
	milkType: MilkType;
	milkState: MilkState;
	isCombined: boolean;
	isDivided: boolean; // used for partial feed/pulling feed
	isFortified: boolean;
	location: MilkLocation;
	volume: number;
	pumpDate: dayjs.Dayjs | null;
	frozenDate?: dayjs.Dayjs;
	thawedDate?: dayjs.Dayjs;
	isExpended: boolean;
	receivedDate: dayjs.Dayjs;
	dischargeDate: dayjs.Dayjs;
	openedDate?: dayjs.Dayjs;
	patients: PatientModel[];
	scans: IScan[];
	usedDate: dayjs.Dayjs;
	sourceMilkBottleIds: string[];

	// front-end only
	prepType: PrepType;
	milkAdded: MilkBottleModel[] = [];
	distributedTo: MilkBottleModel[] = [];
	isInlineThawed?: boolean = false;

	// biome-ignore lint/suspicious/noExplicitAny: allow extra properties
	[propName: string]: any;

	constructor(initial: IMilkBottle = {} as IMilkBottle) {
		super();
		Object.keys(initial).forEach((key) => {
			if (key === "calorie") {
				this.calorieDensity = initial[key];
			} else if (key === "pumpDate") {
				this.pumpDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "frozenDate") {
				this.frozenDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "thawedDate") {
				this.thawedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "expendedDate") {
				this.expendedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "expirationDate") {
				this.expirationDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "receivedDate") {
				this.receivedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "dischargeDate") {
				this.dischargeDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "openedDate") {
				this.openedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "usedDate") {
				this.usedDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "created") {
				this.created = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "modified") {
				this.modified = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "patients") {
				this.patients = initial[key]
					? initial[key].map((p) => new PatientModel(p))
					: null;
			} else if (key === "milkType") {
				this.feedType = initial[key];
				this[key] = initial[key];
			} else if (key === "milkState") {
				this.feedState = initial[key];
				this[key] = initial[key];
			} else {
				this.labelStatus = this.hasBeenCreated(this)
					? FeedLabelStatus.New
					: FeedLabelStatus.Updated;

				this[key] = initial[key];
			}
		});
	}
}

/**
 * DistributedMilkModel is a FeedObjectModel that has been added to the
 * distributedTo array of another MilkBottleModel. DistributedMilkModel
 * does not have the distributedTo array.
 *
 * Currently the only important properties are:
 * - bottleNumber
 * - feedType
 */
export class DistributedMilkModel extends FeedObjectModel {}

export interface IMilkBottle {
	id: string;
	unifiedId: string; // HEX32 string, 13 characters
	motherId: string;
	bottleNumber: number;
	milkType: MilkType;
	milkState: MilkState;
	isCombined: boolean;
	isDivided: boolean;
	isFortified: boolean;
	location: MilkLocation;
	volume: number;
	calorie: number;
	pumpDate: string;
	expendedDate: string;
	expirationDate: string;
	frozenDate: string;
	thawedDate: string;
	receivedDate: string;
	dischargeDate: string;
	openedDate: string;
	patients: IPatient[];
	usedDate: string;
	scans: IScan[];
	contents: IContent[];
	sourceMilkBottleIds: string[];
}

export interface IScan {
	id: string;
	created: string;
	createdBy: string;
	patientId: string;
	isOverride: boolean;
	secondNurseBadge: string;
	result: ScanLogType;
	reason: ScanLogWorkflow;
}

/**
 * @deprecated use Content model in content.model.ts instead
 */
export interface IContent {
	id: string; // if null, then it is a new content
	milkBottleId: string;
	copiedFromMilkBottleId: string;
	contentType: ContentType;
	milkBankProductId?: string;
	/** the productId when contentType is Product, you probably want to use contentProductId instead */
	productId?: string;
	contentProductType: string;
	contentProductName: string;
	/** coalesce of MilkBottleContent.ProductId and MilkBottleContent.MilkBankProduct.ProductId */
	contentProductId?: string;
	copiedFromMilkBottleContentId?: string;
	copiedFromMilkBottleNumber?: number; // only shows up on feeding entry?
	contentProductCode: string;
	mfgLotNumber?: string;
	mfgBottleNumber?: number;
	containsHumanMilk?: boolean;
	recalled?: boolean;
}

export interface PaginatedMilkBottlesResponse {
	items: MilkBottleModel[];
	totalItemCount: number;
	pageNumber: number;
	pageSize: number;
	hasPrevious: boolean;
	hasNext: boolean;
}

export enum PrepType {
	Updated = "updated",
	New = "new",
	Combined = "combined",
}

// TODO: https://angeleyehealth.atlassian.net/browse/ML-2895
export function getUniquePatientIds(milkBottles: MilkBottleModel[]): string[] {
	return _.chain(milkBottles)
		.flatMap((b) => b.patients)
		.map((p) => p.id)
		.uniq()
		.value();
}

import {
	AfterViewInit,
	Component,
	Input,
	OnDestroy,
	OnInit,
	ViewChild,
} from "@angular/core";
import { IonSearchbar, ModalController } from "@ionic/angular";

import { BaseService } from "src/app/services/base.service";
import {
	RecipeMode,
	RecipePageType,
	ScanLogType,
	ScanLogWorkflow,
} from "../../app.enums";
import {
	ScannedProduct,
	ScanningService,
} from "../../services/scanning.service";
import {
	Recipe,
	RecipeBase,
	ScannedAdditive,
	ScannedRecipe,
	ScannedRecipeData,
} from "src/app/models/recipe.model";
import {
	IOrderFulfillment,
	PatientEhrOrderModel,
	PatientModel,
} from "../../models/patient.model";
import {
	BaseSelectProduct,
	isDuplicate,
	isExpended,
	isFrozen,
	isMilkBankProductExpired,
	isRecalled,
} from "../select-product/base-select-product";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import {
	LogMilkBankProductModel,
	LogScannedObjectModel,
} from "../../models/log-scan.model";
import { displayDate } from "../../app.util";
import { handleExpirationDateFromScanGroups } from "../../utils/expiration.util";
import { ScanFooterComponent } from "../../components/scan-footer/scan-footer.component";
import { Subscription } from "rxjs";
import { Features } from "../../features.enums";
import { FeatureFlagService } from "../../services/feature-flag.service";
import { SoundService } from "../../services/sound.service";
import { FeedObject } from "../../models/feed-object.model";
import { StorageService } from "../../services";
import { Product } from "src/app/models/product.model";
import {
	FrozenProductValidationError,
	ScanMilkBankProductValidationError,
	ScannedObjectError,
} from "src/app/decorators/scan-validation/scan-validation-error";
import { modalMessage } from "src/app/app.modal-messages";
import { ScanOptions } from "src/app/base.page";

@Component({
	selector: "app-select-additive",
	templateUrl: "./select-additive.modal.html",
	styleUrls: ["./select-additive.modal.scss"],
})
export class SelectAdditiveModal
	extends BaseSelectProduct
	implements OnInit, OnDestroy, AfterViewInit
{
	// biome-ignore lint/nursery/noSecrets: <explanation>
	@ViewChild("selectAdditiveModalSearchInput", { static: false })
	searchInput: IonSearchbar;
	@ViewChild("scanner") public scanner: ScanFooterComponent;

	@Input() pageType: RecipePageType;
	@Input() mode: RecipeMode;
	@Input() recipes: Recipe[];
	@Input() recipeBase: RecipeBase;
	@Input() calorieDensity: number;
	@Input() patients: PatientModel[] = [];
	@Input() selectedOrder: PatientEhrOrderModel;
	@Input() selectedFulfillment: IOrderFulfillment;

	private scanSubscription: Subscription;

	selectableRecipes: SelectableRecipe[] = [];
	filteredSelectableRecipes: SelectableRecipe[] = [];
	selectedRecipes: ScannedRecipe[] = []; // used with scanning
	scannedObjects: FeedObject[] = [];

	workflow = ScanLogWorkflow.Select_Additive;

	option: ScanOptions = {} as ScanOptions;

	displayDate = displayDate;
	handleExpirationDateFromScanGroups = handleExpirationDateFromScanGroups;

	constructor(
		public base: BaseService,
		public scanningService: ScanningService,
		public modalCtrl: ModalController,
		public soundService: SoundService
	) {
		super(base, scanningService, modalCtrl);
	}

	async ngOnInit() {
		this.scanSubscription = this.scanningService.scanReady$.subscribe(
			(isReady) => {
				if (isReady) {
					this.scanner.startScan();
				}
			}
		);

		// If formula prep, filter on base type as well
		if (this.pageType === RecipePageType.Formula) {
			console.log(
				JSON.stringify(
					this.recipes.filter((r) => r.baseName !== "Water"),
					null,
					2
				)
			);
			this.selectableRecipes = this.recipes
				.filter(
					(r) => r.baseProductId === this.recipeBase.baseProductId
				)
				.filter((r) => r.calorieDensity === this.calorieDensity)
				.map((r) => ({
					recipe: r,
					additiveName: r.additives
						.map((a) => a.productName)
						.join(", "),
					isChecked: false,
				}))
				.sort((a, b) => a.additiveName.localeCompare(b.additiveName));
		} else {
			this.selectableRecipes = this.recipes
				.filter((r) => r.calorieDensity === this.calorieDensity)
				.map((r) => ({
					recipe: r,
					additiveName: r.additives
						.map((a) => a.productName)
						.join(", "),
					isChecked: false,
				}))
				.sort((a, b) => a.additiveName.localeCompare(b.additiveName));
		}

		// If fulfillment was passed in, select it by default
		if (this.selectedFulfillment) {
			const recipe = getRecipe(
				this.selectedFulfillment.contents
					.filter((c) => !c.isBase)
					.map((content) => content.productId),
				this.recipes
			);

			if (recipe) {
				this.selectSingle(recipe);
			}
		}
	}

	async ngAfterViewInit() {
		await this.onViewLoad();
	}

	ngOnDestroy() {
		this.scanSubscription.unsubscribe();
	}

	// View management

	async onViewLoad() {
		// wait 1 seconds just in case
		await new Promise((resolve) => setTimeout(resolve, 1000));

		// Ensure that the modal has finished loading otherwise
		// searchInput will be undefined.
		if (this.searchInput) {
			await this.searchInput.setFocus();
		}
	}

	handleLeaving() {
		super.handleLeaving();
		this.selectedRecipes = [];
		this.scannedObjects = [];
	}

	async done() {
		await this.modalCtrl.dismiss({
			selectedRecipes: this.selectedRecipes,
			scannedProducts: this.scannedProducts,
		} as ScannedRecipeData);
		this.handleLeaving();
	}

	// Navigation

	async prev() {
		await super.prev();
	}

	next() {
		super.next();

		if (this.state === 2) {
			this.startScanListener();
			this.resetFilter();
		}
	}

	// Scan processing

	findSelectedProduct(productId: Product["id"]): ScannedAdditive {
		return this.selectedRecipes
			.map((selected) => selected.additives)
			.reduce((prev, curr) => prev.concat(curr), [])
			.find((s) => s.additive.productId === productId);
	}

	/**
	 * Save scanned product's original and current state and add it to the list
	 * of scanned products.
	 */
	async handleValidationSuccess(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: ScannedAdditive;
		milkBankProduct: MilkBankProductModel;
	}) {
		const { scannedProduct, selectedProduct, milkBankProduct } = params;

		// web
		if (StorageService.isWeb) {
			await this.base.modalService.presentVerified();
		}

		// mobile
		if (!StorageService.isWeb) {
			if (FeatureFlagService.isLatest(Features.Mobile_Scanner)) {
				this.scannedObjects.push(milkBankProduct);
				await this.soundService.playSuccess();
				this.scanningService.setScanReady(true);
			}

			if (FeatureFlagService.isStable(Features.Mobile_Scanner)) {
				await this.base.modalService.presentVerified();
			}
		}

		scannedProduct.originalMilkBankProduct =
			JSON.stringify(milkBankProduct);
		scannedProduct.milkBankProduct = milkBankProduct;
		selectedProduct.isScanned = true;

		this.scannedProducts.push(scannedProduct);

		await this.base.loggingService.logScan(
			new LogScannedObjectModel({
				type: ScanLogType.Product_Success,
				workflow: this.workflow,
				productId: selectedProduct.additive.productId,
			})
		);
	}

	/**
	 * Update milk bank product with thawed date
	 * Handle validation success
	 */
	async handleThawProduct(params: {
		scannedProduct: ScannedProduct;
		selectedProduct: ScannedAdditive;
		milkBankProduct: MilkBankProductModel;
	}) {
		const updated = await this.base.inventoryService.updateMilkBankProduct(
			params.milkBankProduct
		);
		updated.isInlineThawed = true;
		await this.handleValidationSuccess({
			scannedProduct: params.scannedProduct,
			selectedProduct: params.selectedProduct,
			milkBankProduct: updated,
		});
	}

	/**
	 * On single select: array of 1
	 * Create a ScannedRecipe object used to keep track of its scan status
	 */
	selectSingle(r: Recipe) {
		this.selectedRecipes = [
			{
				recipe: r,
				additives: r.additives.map(
					(a) =>
						({
							additive: a,
							volume: 0,
							volumeUnit: a.productMeasuredUnit,
							isScanned: false,
						}) as ScannedAdditive
				),
			},
		];
		this.next();
	}

	/**
	 * On multiple checkbox list: get all the isChecked additives
	 */
	selectMultiple() {
		this.selectedRecipes = this.selectableRecipes
			.filter((r) => r.isChecked)
			.map((selectable) => ({
				recipe: selectable.recipe,
				additives: selectable.recipe.additives.map(
					(a) =>
						({
							additive: a,
							volume: 0,
							volumeUnit: a.productMeasuredUnit,
							isScanned: false,
						}) as ScannedAdditive
				),
			}));
		this.next();
	}

	getItems(ev) {
		this.filteredSelectableRecipes = this.selectableRecipes; // Reset items back to all of the items
		const val = ev.target.value; // set val to the value of the searchbar

		// if the value is an empty string don't filter the items
		if (val && val.trim() !== "") {
			this.isItemAvailable = true;
			this.filteredSelectableRecipes = this.selectableRecipes.filter(
				(r) =>
					r.recipe.additives.filter(
						(a) =>
							a.productName
								.toLowerCase()
								.indexOf(val.toLowerCase()) > -1
					).length
			);
		} else {
			this.isItemAvailable = false;
		}
	}

	toggleSelected(selectedRecipe: Recipe, ev) {
		const isChecked = ev.detail.checked;

		this.selectableRecipes.find((r) => {
			if (r.recipe === selectedRecipe) {
				console.log(`found ${ev.detail.checked}`);
				r.isChecked = isChecked;
			}
		});

		this.filteredSelectableRecipes.find((r) => {
			if (r.recipe === selectedRecipe) {
				console.log(`found filtered ${ev.detail.checked}`);
				r.isChecked = isChecked;
			}
		});
	}

	/**
	 * Clears selected and resets filter
	 */
	resetFilter() {
		this.selectableRecipes.map((r) => {
			r.isChecked = false;
		});
		this.filteredSelectableRecipes = this.selectableRecipes; // reset filter
	}

	/**
	 * Returns only selected additives that haven't been scanned yet
	 */
	getUnscannedAdditives(): ScannedAdditive[] {
		return this.selectedRecipes
			.map((selected) => selected.additives)
			.reduce((prev, curr) => prev.concat(curr), [])
			.filter((scanned) => !scanned.isScanned);
	}

	/**
	 * Only if all required additives have been scanned can you continue
	 */
	hasUnscanned(): boolean {
		return !!this.getUnscannedAdditives().filter(
			(s) => !s.isScanned && s.additive.productEnforceScanning
		).length;
	}

	async handleMilkBankProductScanned(scannedProduct: ScannedProduct) {
		let milkBankProduct: MilkBankProductModel = null;
		let selectedProduct: ScannedAdditive = null;

		try {
			milkBankProduct =
				await this.base.inventoryService.getMilkBankProductByQRCode(
					scannedProduct.milkTrackerQRCode
				);

			isExpended(milkBankProduct);
			isDuplicate(this.scannedProducts, milkBankProduct);
			isMilkBankProductExpired(milkBankProduct);
			isRecalled(milkBankProduct);
			isFrozen(milkBankProduct);

			selectedProduct = this.findSelectedProduct(
				milkBankProduct.productId
			);

			if (!selectedProduct) {
				throw new ScannedObjectError(
					ScanLogType.Product_Invalid,
					modalMessage.scan_product_invalid(scannedProduct)
				);
			}

			scannedProduct.product = StorageService.configs.getProduct(
				(selectedProduct as ScannedAdditive).additive.productId
			);

			// Passed all validation checks
			await this.handleValidationSuccess({
				scannedProduct,
				selectedProduct,
				milkBankProduct,
			});
		} catch (error) {
			if (error instanceof FrozenProductValidationError) {
				this.base.loggingService.logScan(
					new LogMilkBankProductModel({
						type: ScanLogType.Product_Incompatible_State,
						workflow: this.workflow,
						milkBankProductId: milkBankProduct.id,
						patientId: this.patients[0].id,
					})
				);

				await this.presentThawProductModal({
					scannedProduct,
					selectedProduct,
					milkBankProduct,
				});

				return;
			}

			if (error instanceof ScanMilkBankProductValidationError) {
				const { header, message, modalType } = error.modal;
				await this.base.modalService.presentOverlayModal(
					modalType,
					header,
					[message]
				);

				// It's possible that we don't have a milk bank product id
				// since the response for an expended mbp returns null.
				await this.base.loggingService.logScan(
					new LogMilkBankProductModel({
						type: error.scanLogType,
						workflow: this.workflow,
						patientId: this.patients[0].id,
						milkBankProductId: milkBankProduct?.id || null,
					})
				);

				return;
			}

			// TODO - Add error handling https://angeleyehealth.atlassian.net/browse/ML-2928
			console.error(error);
		}
	}
}

export interface SelectableRecipe {
	recipe: Recipe;
	additiveName: string; // comma delimited
	isChecked: boolean;
}

export const getRecipe = (
	ids: Product["id"][],
	recipes: Recipe[]
): Recipe | undefined => {
	for (const recipe of recipes) {
		// Check if the current recipe's additive array matches the given ids
		if (
			ids.every((id) =>
				recipe.additives.some((additive) => additive.productId === id)
			)
		) {
			return recipe;
		}
	}
	return undefined; // Return undefined if no matching recipe is found
};

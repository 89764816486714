import { Component, Input } from "@angular/core";
import { MilkBottleModel } from "../../models/milk.model";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import {
	FeedProperty,
	FeedPropertyItem,
	hasReceivedDate,
	hasOpenedDate,
	FEED_PROPERTIES,
} from "../../utils/feed-info-content.util";
import { FeedObject } from "../../models/feed-object.model";
import { Product } from "../../models/product.model";

@Component({
	selector: "app-feed-info-content",
	templateUrl: "./feed-info-content.component.html",
	styleUrls: ["./feed-info-content.component.scss"],
})
export class FeedInfoContentComponent {
	@Input() feed: FeedObject;
	@Input() excludes: FeedProperty[] = [];

	constructor() {}

	/**
	 * Based on the feed type, return the appropriate default properties.
	 * Displays in order.
	 */
	getDisplayedProperties(
		feed: FeedObject,
		excludes: FeedProperty[]
	): FeedPropertyItem[] {
		let props: FeedProperty[] = [];
		if (feed instanceof MilkBottleModel) {
			props = [
				FeedProperty.DischargedDate,
				FeedProperty.ExpirationDate,
				hasOpenedDate(feed) ? FeedProperty.OpenedDate : null,
				FeedProperty.FrozenDate,
				FeedProperty.ExpendedDate,
				hasReceivedDate(feed) ? FeedProperty.ReceivedDate : null,
				FeedProperty.Location,
				FeedProperty.CalorieDensity,
				FeedProperty.Volume,
				FeedProperty.FeedContents,
				FeedProperty.DistributedTo,
			];
		} else if (feed instanceof MilkBankProductModel) {
			props = [
				FeedProperty.ExpirationDate,
				FeedProperty.ExpendedDate,
				FeedProperty.ReceivedDate,
				FeedProperty.OpenedDate,
				FeedProperty.CalorieDensity,
				FeedProperty.Manufacturer,
				FeedProperty.ProductName,
				FeedProperty.LotNumber,
			];
		} else if (feed instanceof Product) {
			props = [
				FeedProperty.ExpirationDate,
				FeedProperty.Manufacturer,
				FeedProperty.LotNumber,
				FeedProperty.ProductType,
				FeedProperty.CalorieDensity,
			];
		}
		props = props.filter((p) => p); // clear null values
		props = this.applyExclusion(props, excludes);
		return this.getFeedPropertyItems(props);
	}

	applyExclusion(props: FeedProperty[], excludes: FeedProperty[]) {
		return props.filter((p) => !excludes.includes(p));
	}

	/**
	 * Matches the FeedProperty to the FeedPropertyItem.
	 */
	getFeedPropertyItems = (props: FeedProperty[]): FeedPropertyItem[] =>
		props.map((prop) => FEED_PROPERTIES.find((x) => x.label === prop));
}

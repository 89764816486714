export class WhoIsModel {
	email: string;
	authorizedTenants: Array<{
        tenantId: string;
        permissions: TenantPermission[];
    }>;

	constructor(initial?: Partial<WhoIsModel>) {
        Object.assign(this, initial);
	}

    hasAdministerFeedPermission(tenantId: string) {
    return this.authorizedTenants
        .some(t => t.tenantId.toString() === tenantId.toString()
                && t.permissions.some(p => p === "AdministerFeed"));
    }
}

export type TenantPermission =
    "AdministerFeed" |
    "AdministerFeed.FeedPatient" |
    "AdministerFeed.OralCare";

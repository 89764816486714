<ion-header class="ion-no-border" color="primary">
	<ion-toolbar color="primary">
		<ion-buttons slot="start">
			<ion-button (click)="cancel()">cancel</ion-button>
		</ion-buttons>
		<ion-title>
			<div class="ion-text-wrap">{{ title }}</div>
		</ion-title>
		<ion-buttons slot="end"></ion-buttons>
	</ion-toolbar>
	<ion-toolbar color="primary">
		<ion-searchbar type="text"
		               debounce="500"
		               showClearButton="true"
		               [(ngModel)]="searchTerm"
		               (ionInput)="filterItems()">
		</ion-searchbar>
	</ion-toolbar>
</ion-header>

<ion-content fullscreen>
	<ion-list *ngFor="let item of filteredItems">
		<ion-item (click)="selectItem(item)">
			<ion-label class="ion-text-wrap" slot="start">
				{{ item[searchProperties.title] }}
				<p>
					{{ item[searchProperties.subtitle] }}
				</p>
			</ion-label>
			<ion-text *ngIf="item[searchProperties.endText]" class="ion-text-wrap" slot="end">
				{{ item[searchProperties.endText] }}
			</ion-text>
		</ion-item>
	</ion-list>
</ion-content>

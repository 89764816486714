import * as dayjs from "dayjs";
import { ProductType } from "../app.enums";
import { Product } from "./product.model";
import { Recipe } from "./recipe.model";

export class PatientModel {
	id: string;
	tenantId: string;
	active: boolean;
	motherId: string;
	firstName: string;
	middleName: string;
	lastName: string;
	dateOfBirth: dayjs.Dayjs;
	sex: string;
	mrn: string;
	ecd: string;
	bedId: string;
	admitted: dayjs.Dayjs;
	discharged: dayjs.Dayjs;
	siblings: ISibling[] = [];
	orders: IOrder[];
	inventory: IPatientInventoryCount;

	constructor(initial: IPatient = {} as IPatient) {
		Object.keys(initial).forEach((key) => {
			if (key === "gender") {
				this.sex = initial[key];
			} else if (key === "dateOfBirth") {
				this.dateOfBirth = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "admitted") {
				this.admitted = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "discharged") {
				this.discharged = initial[key] ? dayjs(initial[key]) : null;
			} else {
				this[key] = initial[key];
			}
		});

		if (initial.orders) {
			for (const order of initial.orders) {
				order.startDateAsDayJs = dayjs(order.startDate);
				order.startDateAsDate = new Date(order.startDate);
			}
		}
	}

	/** The backend sorts the orders by StartDate desc, so the latest order is the first element  */
	public get latestOrder(): IOrder {
		return this.orders[0];
	}
}

export interface IPatient {
	id: string;
	tenantId: string;
	active: boolean;
	motherId: string;
	firstName: string;
	middleName: string;
	lastName: string;
	dateOfBirth: string;
	gender: string;
	mrn: string;
	ecd: string;
	bedId: string;
	admitted: string;
	discharged: string;
	siblings: ISibling[];
	orders: IOrder[];
	inventory: IPatientInventoryCount;
}

export interface ISibling {
	id: string;
	tenantId: string;
	active: boolean;
	motherId: string;
	firstName: string;
	middleName: string;
	lastName: string;
	dateOfBirth: string;
	gender: string;
	mrn: string;
	ecd: string;
	bedId: string;
	admitted: string;
	discharged: string;
}

export interface IOrder {
	id: string;
	orderNumber: string;
	startDate: string;
	startDateAsDate?: Date;
	startDateAsDayJs?: dayjs.Dayjs;
	endDate: string;
	route: string;
	base: string;
	calorie: number;
	amount: number;
	measuredUnit: string;
	quantity: number;
	interval: string;
	priority: string;
	standingStatus: string;
	contents: IOrderFulfillmentContent[];
}

export interface IPatientInventoryCount {
	prepared: number;
	fresh: number;
	thawed: number;
	frozen: number;
	stable: number;
	recalled: number;
	discharged: number;
	home: number;
	unpreparedBottlesRemaining: number;
}

/**
 * A patient's EHR order. Orders can only be fuzzy matched, and all potential patches are in
 * IPatientEhrOrder.fulfillments
 *
 * Matches the backend's MilkTracker.Api.Areas.Api.V1.PatientOrders.Get.Result type.
 */
export interface IPatientEhrOrder {
	/** matches backend EHROrder.Id */
	id: string;
	orderNumber: string;
	startDate: string;
	endDate: string;
	route: string;
	base: string;
	calorie: number;
	amount?: number;
	measuredUnit?: string;
	quantity?: number;
	interval?: string;
	priority?: string;
	standingStatus?: string;
	/** All the potential matches for the patient's order */
	fulfillments?: IOrderFulfillment[];
	occurrences?: Array<{
		orderOccurrenceNumber: string;
		created: string;
	}>;
	contents?: string;
	instructions: string;
}

/** A potential match of a patient EHR order.
 *
 * Matches MilkTracker.Api.Areas.Api.V1.PatientOrders.Get.Result.OrderFulfillment */
export interface IOrderFulfillment {
	/** matches backend EhrOrderFulfillment.Id */
	id: string;
	recipeId?: Recipe["id"];
	contents?: IOrderFulfillmentContent[];
	// front-end only
	label?: string;
}

export interface IOrderFulfillmentContent {
	productId?: Product["id"]; // if null, the backend didn't match
	type?: ProductType; // additive, rtf, formula, ebm
	productName: string; // Product naming: ML-1824
	isBase: boolean;
}

export class PatientEhrOrderModel {
	/** matches backend EHROrder.Id */
	id: string;
	orderNumber: string;
	startDate: dayjs.Dayjs;
	endDate: dayjs.Dayjs;
	route: string;
	base: string;
	calorie: number;
	amount?: number; // volume
	measuredUnit?: string;
	quantity?: number;
	interval?: string;
	priority?: string;
	standingStatus?: string;
	instructions: string;
	contents?: string;
	duration: string; // TODO: is this in hours?
	/** All the potential matches for the patient's order */
	fulfillments?: IPatientEhrOrder["fulfillments"]; // why are these optional?
	occurrences?: IPatientEhrOrder["occurrences"]; // why are these optional?

	constructor(initial: IPatientEhrOrder = {} as IPatientEhrOrder) {
		Object.keys(initial).forEach((key) => {
			if (key === "startDate") {
				this.startDate = initial[key] ? dayjs(initial[key]) : null;
			} else if (key === "endDate") {
				this.endDate = initial[key] ? dayjs(initial[key]) : null;
			} else {
				this[key] = initial[key];
			}
		});
	}
}

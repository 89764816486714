export class PrinterModel {
	id: string;
	name: string;
	ordinal: number;
	networkRoute: string;
	xOffset: number;
	yOffset: number;
	labelSize?: string;
	model?: string;
	manufacturer?: string;
	revert?: boolean;
	isVirtual?: boolean;

	constructor(initial: IPrinter = {} as IPrinter) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			/**
			 * If the id is 0, then it is a virtual printer.
			 * `isVirtual` property is not on the server so we have to fake it.
			 */
			if (key === "id" && initial[key] === "0") {
				// TODO: Abby test
				this.isVirtual = true;
				this[key] = initial[key];
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface IPrinter {
	id: string;
	name: string;
	ordinal: number;
	networkRoute: string;
	isVirtual: boolean;
	xOffset: number;
	yOffset: number;
	labelSize?: string;
	model?: string;
	manufacturer?: string;
	invert: boolean;
}

import { capitalize, displayDate } from "../app.util";
import {
	faCalendarDays,
	faBed,
	faMarsAndVenus,
	faUserGroup,
	faCalculator,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { PatientModel } from "../models/patient.model";

export enum PatientProperty {
	AdmittedDate = "Admitted",
	BedId = "Bed ID",
	Gender = "Gender",
	Siblings = "Siblings",
	BottlesRemaining = "Unprepared Bottles Remaining",
}

export interface PatientPropertyItem {
	icon: IconDefinition;
	label: PatientProperty;
	getValues: (p: PatientModel) => string[];
}

export const PATIENT_PROPERTIES: PatientPropertyItem[] = [
	{
		icon: faCalendarDays,
		label: PatientProperty.AdmittedDate,
		getValues: (patient) => [displayDate(patient.admitted)],
	},
	{
		icon: faBed,
		label: PatientProperty.BedId,
		getValues: (patient) => [patient.bedId ?? "Unknown"],
	},
	{
		icon: faMarsAndVenus,
		label: PatientProperty.Gender,
		getValues: (patient) => [capitalize(patient.sex)],
	},
	{
		icon: faUserGroup,
		label: PatientProperty.Siblings,
		getValues: (patient) => {
			if (!patient?.siblings.length) {
				return ["None"];
			}

			return patient.siblings.map(
				(x) => `${x.lastName}, ${x.firstName} (MRN: ${x.mrn})`
			);
		},
	},
	{
		icon: faCalculator,
		label: PatientProperty.BottlesRemaining,
		getValues: (patient) => [
			patient?.inventory?.unpreparedBottlesRemaining?.toString() || "None",
		],
	},
];

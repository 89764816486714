export const DATE_FORMATS = {
	FULL: "MM/DD/YYYY HH:mm",
	DATE: "MM/DD/YYYY",
	TIME: "HH:mm",
};

export const ERROR_MESSAGE = {
	INVALID_DATE: "Invalid Date",
	INCOMPLETE_DATE: `Must be ${DATE_FORMATS.DATE}`,
	INVALID_TIME: "Invalid Time",
	INCOMPLETE_TIME: `Must be ${DATE_FORMATS.TIME}`,
};

export const PLACEHOLDER_TEXT = {
	STANDARD: {
		date: `${DATE_FORMATS.DATE} or 'T' for Today`,
		time: `${DATE_FORMATS.TIME} or 'N' for Now`,
	},
	EXPIRATION: {
		date: DATE_FORMATS.DATE,
		time: DATE_FORMATS.TIME,
	},
};

export const END_OF_DAY = "23:59";

export const ACCORDION = {
	dateFormat: "MM/DD/YYYY, HH:mm",
};

export const PRECISION_MILLISECOND = "millisecond";
export const PRECISION_MINUTE = "minute";
export const PRECISION_DAY = "day"

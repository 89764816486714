import { Component, EventEmitter, Input, Output, QueryList, ViewChildren } from "@angular/core";
import { MilkBankProductModel } from "src/app/models/milk-bank-product.model";
import { defaultSelectOptions } from "src/app/components/default-options";
import * as dayjs from "dayjs";
import { capitalize } from "src/app/app.util";
import { ProductState } from "src/app/app.enums";
import { ValidProductStates } from "src/app/models/product.model";
import { isPrepared } from "../../utils/inventory.util";
import { InlineDateComponent } from "../inline-date/inline-date.component";
import { MAX_MANUFACTURER_EXPIRATION_DATE } from "src/app/app.constants";


@Component({
	selector: "app-milk-bank-product-edit",
	templateUrl: "./milk-bank-product-edit.component.html",
	styleUrls: ["./milk-bank-product-edit.component.scss"],
})
export class MilkBankProductEditComponent {
	@ViewChildren(InlineDateComponent) inlineDateComponents: QueryList<InlineDateComponent>

	@Input() milkBankProduct: MilkBankProductModel;
	@Output() milkBankProductChange = new EventEmitter<MilkBankProductModel>();
	@Output() inputFocus = new EventEmitter<"focus" | "blur">();

	defaultSelectOptions = defaultSelectOptions;

	capitalize = capitalize;
	protected readonly isPrepared = isPrepared;

	productStates = Object.values(ProductState);
	ProductState = ProductState;
	validProductStates = ValidProductStates;

	/**
	 * Need to do +1 because the date range has to show the current day + the max date range.
	 *
	 * ML-147 remove minimum date restriction per Andrew Fiedler and Kat Kays
	 * https://angeleyehealth.atlassian.net/browse/ML-147?focusedCommentId=11780
	 */
	get maxDate(): dayjs.Dayjs {
		return dayjs().endOf("day");
	}

	get minManufacturerExpirationDate(): dayjs.Dayjs {
		return dayjs().endOf("day");
	}

	get maxManufacturerExpirationDate(): dayjs.Dayjs {
		return MAX_MANUFACTURER_EXPIRATION_DATE;
	}

	constructor() {}

	emit() {
		this.milkBankProductChange.emit(this.milkBankProduct);
	}

	handleInputFocus(event: "focus" | "blur") {
		this.inputFocus.emit(event);
	}

	handleDateTimeChange(property: string, value: dayjs.Dayjs) {
		this.milkBankProduct[property] = value;
		this.milkBankProductChange.emit(this.milkBankProduct);
	}
}

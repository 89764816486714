import { Injectable } from "@angular/core";
import { ModalController, ModalOptions } from "@ionic/angular";
import { ModalType, OVERLAY_PROPERTY } from "../app.constants";
import { createOverlayComponentProps, displayDate } from "../app.util";
import { OverlayModal } from "../overlay/overlay.modal";
import * as dayjs from "dayjs";
import { ScanLogType, ScanLogWorkflow } from "../app.enums";
import { modalMessage } from "../app.modal-messages";
import { PatientModel } from "../models/patient.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { MilkBottleModel } from "../models/milk.model";
import { SoundService } from "./sound.service";
import { LoggingService } from "./logging.service";
import {
	LogAttemptModel,
	LogMilkBankProductModel,
} from "../models/log-scan.model";
import { IAuthorizedTenant, UserProfileModel } from "../models/user-profile.model";

@Injectable({
	providedIn: "root",
})
export class ModalService {
	modal: HTMLIonModalElement;

	constructor(
		private modalCtrl: ModalController,
		public soundService: SoundService,
		public loggingService: LoggingService
	) {}

	/*
		ModalController
	 */

	/**
	 * This is used to pass the modal in order to customize callbacks like on onDidDismiss
	 * from the child page (see usage in BasePage). This means it doesn't present unless
	 * explicitly invoking it using its present() method.
	 *
	 * Example:
	 * const modal = await this.base.presentModal({...})
	 * modal.present();
	 */
	async presentModal(options: {
		component: any;
		cssClass: ModalType;
		componentProps?: any;
		animated?: boolean;
	}) {
		const modalOptions: ModalOptions = {
			component: options.component,
			cssClass: options.cssClass.toString(),
			componentProps: options.componentProps,
			animated: options.animated ? options.animated : true // default: true
		 }

		this.modal = await this.modalCtrl.create(modalOptions);

		return this.modal;
	}

	async presentOverlayModal(
		cssClass: ModalType,
		header?: string,
		messages?: string[],
		title?: string,
		actionOptions?: {
			hasActionButton: boolean;
			actionButtonText: string;
			onDidDismiss: (data) => void;
			hasDismissButton?: boolean;
		}
	) {
		// If the modal type is warning or info, play the warning sound
		if (cssClass === ModalType.Verified) {
			await this.soundService.playSuccess();
		} else if (cssClass === ModalType.Error) {
			await this.soundService.playError();
		} else if (
			cssClass === ModalType.Warning ||
			cssClass === ModalType.Info
		) {
			await this.soundService.playWarning();
		}
		// wait for the sound to finish playing before showing the modal
		await new Promise((resolve) => setTimeout(resolve, 500));

		const overlayProperties = createOverlayComponentProps(
			cssClass,
			header,
			messages,
			title
		);

		const actionProperties = {
			hasActionButton: actionOptions?.hasActionButton,
			actionButtonText: actionOptions?.actionButtonText,
			hasDismissButton: actionOptions?.hasDismissButton ?? true
		};

		const modal = await this.modalCtrl.create({
			component: OverlayModal,
			cssClass: cssClass.toString(),
			componentProps: Object.assign(overlayProperties, actionProperties),
		});

		modal.onDidDismiss().then((data) => {
			actionOptions?.onDidDismiss(data);
		});

		return await modal.present();
	}

	async dismissModal() {
		await this.modalCtrl.dismiss();
	}

	async dismissModalWithData(data?: any): Promise<boolean> {
		return await this.modalCtrl.dismiss(data);
	}

	async presentMilkExpiredModal2(
		{
			expirationDate,
		}: {
			expirationDate: dayjs.Dayjs;
		},
		actionOptions?: {
			hasActionButton: boolean;
			actionButtonText: string;
			onDidDismiss: (data) => void;
		}
	) {
		const header = "Expired";
		const msg = `This milk expired on ${displayDate(
			expirationDate
		)} and cannot be used`;

		await this.presentOverlayModal(
			ModalType.Error,
			header,
			[msg],
			"",
			actionOptions
		);
	}

	async presentVerificationFailed(
		patient: PatientModel,
		milkBottle: MilkBottleModel
	) {
		const { header, message, modalType } = modalMessage.scan_mrn_mismatch(
			patient,
			milkBottle
		);
		await this.presentOverlayModal(modalType, header, [message]);
	}

	// Sort
	async presentVerified() {
		await this.presentOverlayModal(ModalType.Verified);
	}

	async presentFailure() {
		await this.presentOverlayModal(ModalType.VerificationFailed);
	}

	async presentPatientNotFoundModal(params: {
		mrn: string;
		tenant: IAuthorizedTenant;
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } =
			modalMessage.patient_not_found_with_mrn(params.mrn, params.tenant);
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Patient_Required,
				workflow: params.workflow,
			})
		);
	}

	async presentPatientRequired(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } = modalMessage.patient_required();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Patient_Required,
				workflow: params.workflow,
			})
		);
	}

	async presentUnknownMilk(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } = modalMessage.scan_unknown_milk();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Milk_Bottle_Unknown,
				workflow: params.workflow,
			})
		);
	}

	async presentUnknownProduct(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } = modalMessage.scan_unknown_milk();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Product_Unknown,
				workflow: params.workflow,
			})
		);
	}

	async presentDuplicatePatient(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } = modalMessage.patient_duplicate();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Patient_Duplicate,
				workflow: params.workflow,
			})
		);
	}

	async presentDuplicateNurseId(params: {
		user: UserProfileModel;
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } = modalMessage.nurse_duplicate(
			params.user
		);
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Nurse_Duplicate,
				workflow: params.workflow,
			})
		);
	}

	async presentInvalidSecondBadgeModal(params: {
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } = modalMessage.nurse_invalid();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Nurse_Invalid,
				workflow: params.workflow,
			})
		);
	}

	async presentScanUnknownProduct(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } =
			modalMessage.scan_unknown_product();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Product_Unknown,
				workflow: params.workflow,
			})
		);
	}

	async presentProductNotReceived(params: {
		milkBankProduct: MilkBankProductModel;
		workflow: ScanLogWorkflow;
		patient: PatientModel;
	}) {
		const { header, message, modalType } =
			modalMessage.product_not_received();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Product_Not_Received,
				workflow: params.workflow,
			})
		);
	}

	async presentProductUnassigned(params: {
		milkBankProduct: MilkBankProductModel;
		workflow: ScanLogWorkflow;
		patient: PatientModel;
	}) {
		const { header, message, modalType } =
			modalMessage.product_unassigned();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogMilkBankProductModel({
				milkBankProductId: params.milkBankProduct?.id,
				type: ScanLogType.Product_Unassigned,
				workflow: params.workflow,
				patientId: params.patient.id,
			})
		);
	}

	async presentAssignedMilkScanned(params: {
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } =
			modalMessage.assigned_milk_scanned();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Bottle_Already_Assigned,
				workflow: params.workflow,
			})
		);
	}

	async presentUnknownScanError(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } = modalMessage.scan_unknown();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Scanned_Object_Invalid,
				workflow: params.workflow,
			})
		);
	}

	async presentMilkExpiredModal(params: {
		expirationDate: dayjs.Dayjs;
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } = modalMessage.milk_expired(
			params.expirationDate
		);
		await this.presentOverlayModal(modalType, header, [message]);
		// Logging happens at each page level because implementation varies slightly.
	}

	async presentProductReceiveRequired(params: { workflow: ScanLogWorkflow }) {
		const { header, message, modalType } =
			modalMessage.product_receive_required();
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Product_Not_Received,
				workflow: params.workflow,
			})
		);
	}

	// Form Validation
	async presentBottleNotFound(params: {
		bottleNumber: string;
		workflow: ScanLogWorkflow;
	}) {
		const { header, message, modalType } =
			modalMessage.form_validation_milk_not_found(params.bottleNumber);
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Nurse_Invalid,
				workflow: params.workflow,
			})
		);
	}

	// Form Validation
	async presentPatientMRNMismatch(params: {
		mrn: string;
		patient: PatientModel;
		workflow: ScanLogWorkflow;
	}) {
		const { mrn, patient } = params;
		const { header, message, modalType } =
			modalMessage.form_validation_mrn_mismatch({ mrn, patient });
		await this.presentOverlayModal(modalType, header, [message]);

		this.loggingService.logScan(
			new LogAttemptModel({
				type: ScanLogType.Nurse_Invalid,
				workflow: params.workflow,
			})
		);
	}

	// App Offline
	async presentApplicationOffline() {
		this.modalCtrl.dismiss();
		await this.presentOverlayModal(
			ModalType.Offline,
			OVERLAY_PROPERTY.OFFLINE.title,
			OVERLAY_PROPERTY.OFFLINE.messages,
			OVERLAY_PROPERTY.OFFLINE.title,
			{
				hasDismissButton: false,
				hasActionButton: false,
				actionButtonText: "Retry",
				onDidDismiss: null
			}
		)
	}


}

import * as dayjs from "dayjs";
import { IContent, MilkBottleModel } from "../models/milk.model";
import { RecipeData } from "../models/recipe.model";
import { getContentsFromRecipe } from "./milk-label.util";
import { containsHumanMilk, convertYYtoYYYY } from "../app.util";
import {
	FeedState,
	MilkState,
	MilkType,
	ProductState,
	ProductType,
} from "../app.enums";
import { StorageService } from "../services/storage.service";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { FeedObjectModel } from "../models/feed-object.model";
import { DATE_FORMATS } from "../components/inline-date/utils/date-time-text.util";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

/**
 * Parses a Julian date string and returns a Dayjs object representing the date and time.
 *
 * @param julianDate - The Julian date string to parse. The format is expected to be:
 *                     - 1 character for the century (e.g., '2' for 2000-2099)
 *                     - 2 characters for the year within the century (e.g., '21' for 2021)
 *                     - 3 characters for the day of the year (e.g., '001' for January 1st)
 *                     - 2 characters for the hour (e.g., '14' for 2 PM)
 *                     - 2 characters for the minute (e.g., '30' for 30 minutes past the hour)
 * @returns A Dayjs object representing the parsed date and time.
 */
export const parseJulianDate = (julianDate: string): dayjs.Dayjs | null => {
	if (julianDate.length !== 10) {
		console.error("Invalid Julian date format: must be 10 characters");
		return null;
	}

	if (!/^\d+$/.test(julianDate)) {
        console.error("Invalid Julian date format: must contain only numeric characters.");
        return null;
    }

    const century = parseInt(julianDate.substring(0, 1));
    const year = 2000 + century * 100 + parseInt(julianDate.substring(1, 3));
    const dayOfYear = parseInt(julianDate.substring(3, 6));
    const hour = parseInt(julianDate.substring(6, 8));
    const minute = parseInt(julianDate.substring(8, 10));

	if (dayOfYear < 1 || dayOfYear > 366) {
		console.error("Invalid day of year: must be between 1 and 366");
		return null;
	}

	if (hour < 0 || hour > 23) {
		console.error("Invalid hour: must be between 0 and 23");
		return null;
	}

	if (minute < 0 || minute > 59) {
		console.error("Invalid minute: must be between 0 and 59");
		return null;
	}

    // Create a dayjs object for January 1st of the year and add the day of the year minus 1
    const date = dayjs(`${year}-01-01`, "YYYY-MM-DD").add(dayOfYear - 1, "day");

    // Add the hours and minutes
    const finalDate = date.hour(hour).minute(minute);

    return finalDate;
};

export const getSoonestExpirationDate2 = (
	feed: FeedObjectModel[]
): dayjs.Dayjs =>
	feed
		.map((f) => f.expirationDate)
		.reduce((pre, cur) => (pre.isAfter(cur) ? cur : pre));

// TODO: this is a duplicate of getSoonestExpirationDate2
export const getSoonestExpiringMilk = (
	milkBottles: MilkBottleModel[]
): MilkBottleModel =>
	milkBottles.reduce((pre, cur) =>
		pre.expirationDate.isAfter(cur.expirationDate) ? cur : pre
	);

/**
 * Get the expiration date based on start date (pump date, created date, bottled date, etc.)
 */
export const getExpirationDate = (
	startDate: dayjs.Dayjs,
	milkObject: {
		milkBottle?: MilkBottleModel;
		milkBankProduct?: MilkBankProductModel;
	}
): dayjs.Dayjs => {
	if (!startDate) {
		return null;
	}

	if (milkObject.milkBottle && !milkObject.milkBankProduct) {
		const expirationDuration = getExpirationDuration({
			milkType: milkObject.milkBottle.milkType,
			milkState: milkObject.milkBottle.milkState,
			isFortified: milkObject.milkBottle.isFortified,
			isCombined: milkObject.milkBottle.isCombined,
			contents: milkObject.milkBottle.contents,
		});
		return startDate.add(expirationDuration, "hour");
	} else if (!milkObject.milkBottle && milkObject.milkBankProduct) {
		throw new Error(
			"this shouldn't be used anymore for milk bank products"
		);
	} else {
		console.error(`getExpirationDate: invalid milk object`);
	}
};

/**
 * Returns duration in hours.
 *
 * EBM:
 *	expirationHoursFrozenEbm,
 * 	expirationHoursFreshEbm,
 * 	expirationHoursFreshEbmFortifiedWithHM,
 * 	expirationHoursFreshEbmFortifiedWithoutHM,
 * 	expirationHoursThawedEbm,
 *  expirationHoursThawedEbmFortified,
 *
 * DM:
 * 	expirationHoursThawedDbm,
 * 	expirationHoursThawedDbmFortifiedWithHM,
 *  expirationHoursThawedDbmFortifiedWithoutHM
 *
 * Formula:
 *  expirationHoursFormula
 *
 * TODO: is a better place for this in ConfigModel?
 */
export const getExpirationDuration = (milkObject: {
	milkType: MilkType | ProductType;
	milkState: FeedState;
	isFortified: boolean;
	isCombined: boolean;
	contents: IContent[];
}): number => {
	const {
		formula,
		freshEbm,
		freshEbmFortifiedWithHM,
		freshEbmFortifiedWithoutHM,
		thawedEbm,
		thawedEbmFortified,
		frozenEbm,
		thawedDbm,
		thawedDbmFortifiedWithHM,
		thawedDbmFortifiedWithoutHM,
	} = StorageService.expirationPolicy;

	if (milkObject.milkType === ProductType.Donor) {
		if (milkObject.milkState === ProductState.Thawed) {
			return thawedDbmFortifiedWithoutHM;
		}
	}

	const isCombined =
		milkObject.milkType === MilkType.EBM_DM ||
		milkObject.milkType === MilkType.DM_DM ||
		milkObject.milkType === MilkType.EBM_EMB ||
		milkObject.milkType === MilkType.EBM_DM_Formula ||
		milkObject.milkType === MilkType.DM_Formula ||
		milkObject.milkType === MilkType.EBM_Formula;

	if (milkObject.milkType === MilkType.EBM || isCombined) {
		if (milkObject.milkState === MilkState.Frozen) {
			return frozenEbm;
		} else if (
			milkObject.milkState === MilkState.Fresh &&
			!milkObject.isFortified
		) {
			return freshEbm;
		} else if (
			milkObject.milkState === MilkState.Fresh &&
			milkObject.isFortified
		) {
			if (containsHumanMilk(milkObject.contents)) {
				return freshEbmFortifiedWithHM;
			}
			return freshEbmFortifiedWithoutHM;
		} else if (
			milkObject.milkState === MilkState.Thawed &&
			!milkObject.isFortified
		) {
			return thawedEbm;
		} else if (
			milkObject.milkState === MilkState.Thawed &&
			milkObject.isFortified
		) {
			return thawedEbmFortified;
		}
	} else if (milkObject.milkType === MilkType.DM) {
		if (
			milkObject.milkState === MilkState.Thawed ||
			milkObject.milkState === MilkState.Opened
		) {
			if (milkObject.isFortified) {
				if (containsHumanMilk(milkObject.contents)) {
					return thawedDbmFortifiedWithHM;
				}
				return thawedDbmFortifiedWithoutHM;
			}
			return thawedDbm;
		} else if (milkObject.milkState === MilkState.Stable) {
			// do nothing
		} else {
			console.error(
				`getExpirationDuration: unknown expiration duration for DM`
			);
		}
	} else if (milkObject.milkType === MilkType.Formula) {
		return formula;
	} else if (milkObject.milkType === ProductType.Additive) {
		switch (milkObject.milkState as ProductState) {
			case ProductState.Frozen:
				// TODO: there is currently no expiration policy for frozen additive?
				return frozenEbm;
			case ProductState.Thawed:
				// TODO: there is currently no expiration policy for thawed additive?
				return thawedEbm;
			case ProductState.Opened:
			case ProductState.Stable:
			default:
				throw Error(`unknown ProductState ${milkObject.milkState}`);
		}
	} else {
		console.error(`getExpirationDuration: unknown expiration duration`);
	}
};

export const getFreshExpirationDate = (recipeData: RecipeData): dayjs.Dayjs => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData);
	const duration = getExpirationDuration({
		milkType: MilkType.EBM,
		milkState: MilkState.Fresh,
		isFortified: !!contentsFromRecipe.length,
		isCombined: false,
		contents: [...contentsFromRecipe],
	});
	return dayjs().add(duration, "hour");
};

/**
 * FIXME: because returning dayjs() uses a dynamic date, when invoking this function multiple times, "now" will
 *  change slightly.
 */
export const getFortifiedExpirationDate = (
	milkBottle: MilkBottleModel,
	recipeData: RecipeData
): dayjs.Dayjs => {
	const contentsFromRecipe = getContentsFromRecipe(recipeData);
	const duration = getExpirationDuration({
		milkType: milkBottle.milkType,
		milkState: milkBottle.milkState,
		isFortified: !!contentsFromRecipe.length,
		isCombined: milkBottle.isCombined,
		contents: [...contentsFromRecipe, ...milkBottle.contents],
	});
	return dayjs().add(duration, "hour");
};

/**
 * This is currently intended for non-fortified milk
 */
export const getUnfortifiedExpirationDate = (
	milk: MilkBottleModel
): dayjs.Dayjs => {
	const duration = getExpirationDuration({
		milkType: milk.milkType,
		milkState: milk.milkState,
		isFortified: false,
		isCombined: milk.isCombined,
		contents: [...milk.contents],
	});
	return dayjs().add(duration, "hour");
};

/**
 * Displays relative expiration
 *
 * Examples:
 * Expired 2d ago
 * Expired 5h ago
 * Expires in 3d
 * Expires in 4h
 * No Expiration
 */
export const getRelativeExpirationTime = (date: dayjs.Dayjs): string => {
	if (!date) {
		return "No Expiration";
	}

	const diff = dayjs().diff(date, "hour");
	// const diff = date.diff(dayjs(), "hour");
	if (diff > 0) {
		if (diff > 72) {
			return `Expired ${Math.floor(diff / 24)}d ago`;
		}
		return `Expired ${diff}h ago`;
	} else {
		const absDiff = Math.abs(diff);
		if (absDiff > 72) {
			return `Expires in ${Math.floor(absDiff / 24)}d`;
		} else {
			return `Expires in ${absDiff}h`;
		}
	}
};

/**
 * Basic check if expiration date comes after today and return a boolean value.
 */
export const isExpired = (date: dayjs.Dayjs): boolean => {
	if (!date) {
		return false;
	}
	const today = dayjs();
	const expiration = dayjs(date, "MMMM D, YYYY");
	return today.isAfter(expiration);
};

/**
 * Check all scanned milk to determine their threshold.
 * If a milk has an expiration date that is sooner than the calculated fortified expiration date, mark it red.
 * If all milk have expiration dates that are sooner than the calculated fortified expiration date, don't mark it.
 * If all milk have expiration dates that are after the calculated fortified expiration date, don't mark it.
 */
export const isSoonerExpiration = (
	milkBottle: MilkBottleModel,
	milkBottles: MilkBottleModel[],
	recipeData: RecipeData
): boolean => {
	const fortified = getFortifiedExpirationDate(milkBottle, recipeData);

	const thresholds = [];
	for (const m of milkBottles) {
		thresholds.push({
			isSoonerExpiration: m.expirationDate.isBefore(fortified),
			id: m.id,
		});
	}

	const allBelow =
		thresholds.length ===
		thresholds.filter((t) => t.isSoonerExpiration).length;
	const allAbove =
		thresholds.length ===
		thresholds.filter((t) => !t.isSoonerExpiration).length;
	if (allBelow || allAbove) {
		return false;
	}

	return thresholds.find((t) => milkBottle.id === t.id).isSoonerExpiration;
};

export const handleExpirationDateFromScanGroups = (matchGroups: any): dayjs.Dayjs | undefined => {
    const expirationDateUCHMB = matchGroups?.expirationDateUCHMB;
    const expirationDate = matchGroups?.expirationDate;

    if (expirationDateUCHMB) {
        return parseJulianDate(expirationDateUCHMB);
    }

	if (expirationDate) {
        return getScannedExpirationDate(expirationDate);
    }

    console.error("No expiration date found in groups");
    return;
};

/**
 * Parses MMDDYYHHmm or YYMMDD from scanning milk labels and returns dayjs object. If time is
 * missing, set to current time.
 *
 * @param dateString
 *
 * https://day.js.org/docs/en/get-set/month
 * https://day.js.org/docs/en/get-set/date
 */
export const getScannedExpirationDate = (dateString: string): dayjs.Dayjs => {
	if (!dateString) {
		return;
	}

	let date: dayjs.Dayjs;
	if (dateString.length === 10) {
		const month = parseInt(dateString.substr(0, 2));
		const day = parseInt(dateString.substr(2, 2));
		const year = parseInt(dateString.substr(4, 2));
		const hour = parseInt(dateString.substr(6, 2));
		const minute = parseInt(dateString.substr(8, 2));
		date = dayjs()
			.set("month", month - 1)
			.set("date", day)
			.set("year", convertYYtoYYYY(year))
			.set("hour", hour)
			.set("minute", minute);
		return date;
	} else if (dateString.length === 6) {
		const year = parseInt(dateString.substr(0, 2));
		const month = parseInt(dateString.substr(2, 2));
		const day = parseInt(dateString.substr(4, 2));
		const hour = dayjs().hour();
		const minute = dayjs().minute();
		date = dayjs()
			.set("month", month - 1)
			.set("date", day)
			.set("year", convertYYtoYYYY(year))
			.set("hour", hour)
			.set("minute", minute);
		return date;
	}
	throw Error("getScannedExpirationDate: parsing failed");
};

export const getEarliestDayjs = <T>(
	arr: T[],
	iteratee: (item: T) => dayjs.Dayjs | null | undefined
) => {
	const dates = arr.map(iteratee).filter((v) => v != null);
	return dates.length
		? dates.reduce((pre, cur) => (pre.isAfter(cur) ? cur : pre))
		: null;
};

export const expirationDateChipColor = (
	expirationDate?: dayjs.Dayjs
): string | undefined => {
	if (!expirationDate) {
		return undefined;
	}
	return isExpired(expirationDate) ? "danger" : undefined;
};

export const getFormattedExpirationDate = (date, policyHours) => dayjs(date).add(policyHours, "hours").format(DATE_FORMATS.FULL);

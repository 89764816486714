import dayjs from "dayjs";
import { DATE_FORMATS, ERROR_MESSAGE } from "./date-time-text.util";
import { MaskitoMask, MaskitoOptions } from "@maskito/core";

export const transformCharToTime = (character: string) => {
	if (character.toLowerCase() === "n") {
		return dayjs().format("HH:mm");
	}

	return character;
};

export const timePreprocessor = ({ elementState, data }) => {
	let { value, selection } = elementState;

	// Check for 'n' input and replace with the current date
	if (data.toLowerCase() === "n") {
		value = transformCharToTime(data);
		selection = [value.length, value.length];
	}

	return {
		elementState: {
			selection,
			value: value,
		},
		data,
	};
};

export const isCompleteTime = (value: string, mask: MaskitoMask): boolean => {
	const isValid =
		value.length > 0 && value.length == (mask as Array<string>).length;

	if (!isValid) {
		throw new Error(ERROR_MESSAGE.INCOMPLETE_TIME);
	}

	return isValid;
};

export const isValidTime = (time: dayjs.Dayjs) => {
	if (!time.isValid()) {
		throw new Error(ERROR_MESSAGE.INVALID_TIME);
	}
};

/**
 *  Users can type "N" to autocomplete current time
 */
export const setTimeProcessors = () => ({
		mask: [/\d/, /\d/, ":", /\d/, /\d/],
		preprocessors: [timePreprocessor],
	} as MaskitoOptions);

/**
 * Do not allow users to type in "N" to autocomplete to current time
 */
export const setExpiredTimeProcessors = () => ({
		mask: [/\d/, /\d/, ":", /\d/, /\d/],
	} as MaskitoOptions);

/*
  Example param time string => "23:59"

  Converts time string to millisecond representation

  Returns the time of day in millis without a date (time only)
  This gives a value that can be added to another date currently at "start of day"
  to match that of the time-string passed in
*/
export const timeMillisFromTimeStr = (timeStr: string): number => {
	const time = dayjs(timeStr, DATE_FORMATS.TIME, true).toDate().getTime();
	const startOfDay = dayjs().startOf("date").toDate().getTime();
	return time - startOfDay;
}

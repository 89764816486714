import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import {
	getRelativeExpirationTime,
	isExpired,
} from "../../utils/expiration.util";
import {
	getBottleNumberText,
	getMilkTypeText,
} from "../../utils/milk-label.util";
import { getRelativeReceived } from "../../app.util";
import {
	displayNewChip,
	getFeedObjectColor,
	getFeedTypeText,
} from "../../utils/feed-info.util";
import { FeedObject } from "../../models/feed-object.model";
import {
	FeedModifierEvent,
	ModifierOption,
} from "../milk-modifiers/milk-modifiers.component";

@Component({
	selector: "app-feed-info-header",
	templateUrl: "./feed-info-header.component.html",
	styleUrls: ["./feed-info-header.component.scss"],
})
export class FeedInfoHeaderComponent implements OnInit {
	@Input() feed: FeedObject;
	@Input() isDischarging = false;
	@Input() isContentVisible = true;
	@Output() modifierPress = new EventEmitter<FeedModifierEvent>();

	displayNewChip = displayNewChip;
	getBottleNumberText = getBottleNumberText;
	getFeedObjectColor = getFeedObjectColor;
	getFeedTypeText = getFeedTypeText;
	getMilkTypeText = getMilkTypeText;
	getRelativeExpirationTime = getRelativeExpirationTime;
	getRelativeReceived = getRelativeReceived;
	isExpired = isExpired;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	selectMilkModifier(option: ModifierOption, feedObject: FeedObject) {
		this.modifierPress.emit({ option, feedObject });
	}
}

<ion-item *ngIf="StorageService.isWeb"
          lines="none"
          class="datetime-item">
	<ion-label>
		<p class="datetime-item__title">{{ title }}</p>
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-label>
						<ion-input label="DATE"
								   labelPlacement="stacked"
								   fill="outline"
								   placeholder="MM/DD/YYYY or 'T' for Today"
								   errorText="Invalid date"
								   debounce="500"
								   [autofocus]="autofocusState"
								   [maskito]="dateMaskOptions"
								   [maskitoElement]="maskPredicate"
								   [value]="dateValue"
								   (ionChange)="handleDateChange($any($event.target).value)"
								   (ionFocus)="inputFocus.emit('focus')"
								   (ionBlur)="inputFocus.emit('blur')"
								   [disabled]="readonly"
								   aria-label="Date"
								   class="input-field"
								   data-testid="date_input_field"
						>
						</ion-input>
					</ion-label>
					<ion-note *ngIf="!isYearValid" color="danger">
						Year must have 4 digits
					</ion-note>
				</ion-col>
				<ion-col>
					<ion-input label="TIME"
					           labelPlacement="stacked"
					           fill="outline"
					           placeholder="HH:mm or 'N' for Now"
					           errorText="Invalid time"
					           debounce="500"
					           [maskito]="timeMaskOptions"
					           [maskitoElement]="maskPredicate"
					           [value]="timeValue"
					           (ionInput)="handleTimeChange($any($event.target).value)"
					           (ionFocus)="inputFocus.emit('focus')"
					           (ionBlur)="inputFocus.emit('blur')"
							   [disabled]="readonly"
							   aria-label="Time"
							   data-testid="time_input_field"
							   >
					</ion-input>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-label>
</ion-item>
<ion-item *ngIf="environment.settings.showMobileDateTimePicker || !StorageService.isWeb"
          lines="none"
          class="datetime-item">
	<ion-label>
		<p class="datetime-item__title">{{ title }}</p>
		<ion-accordion-group #accordionGroup>
			<ion-accordion value="field" [readonly]="readonly">
				<ion-item slot="header" lines="none">
						<span [ngClass]="dateString ? '' : 'placeholder'">
							{{ dateString ? displayDate(dateString):"MM/DD/YYYY, HH:mm" }}
						</span>
				</ion-item>
				<ion-datetime #datetime
				              [presentation]="presentation"
				              slot="content"
				              [(ngModel)]="dateString"
				              size="cover">
					<ion-buttons slot="buttons">
						<ion-button (click)="cancel()"
						            color="primary"
						            fill="clear"
									tappable
									>
							Cancel
						</ion-button>
						<ion-button (click)="clear()"
						            color="danger"
						            fill="clear"
									tappable
									>
							Clear
						</ion-button>
						<ion-button (click)="done()"
						            color="primary"
						            fill="clear"
									tappable
									>
							Done
						</ion-button>
					</ion-buttons>
				</ion-datetime>
			</ion-accordion>
		</ion-accordion-group>
	</ion-label>
</ion-item>

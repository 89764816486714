import { MilkBottleModel } from "../models/milk.model";
import { PatientModel } from "../models/patient.model";
import { FeedObject } from "../models/feed-object.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import { Product } from "../models/product.model";
import { RecipeData } from "../models/recipe.model";
import { MilkState } from "../app.enums";
import { IPatientLabelQuantity } from "../components/label-quantity-list/label-quantity-list.component";
import { ILatchQuality } from "../models/latch-quality.model";

/**
 * Return list of scanned milks that were opened by checking the originally
 * scanned milk and seeing if it was stable and changed to opened.
 *
 * This can be done by checking if it has an opened date or not.
 */
export const getOpenedRemainingMilks = (
	originalMilks: string[],
	milks: MilkBottleModel[]
): MilkBottleModel[] => {
	const results = [];
	const originals = originalMilks.map(
		(o) => new MilkBottleModel(JSON.parse(o))
	);

	for (const original of originals) {
		const current = milks.find((m) => m.id === original.id);

		if (
			!original.openedDate &&
			current.openedDate &&
			!current.expendedDate
		) {
			results.push(current);
		}
	}
	return results;
};

/**
 * If patient is null, return false.
 *
 * TODO: reconcile all the duplicate functions
 */
export const isPatientMatch = (
	patient: PatientModel,
	feedObject: FeedObject
): boolean => {
	if (feedObject instanceof MilkBottleModel) {
		return !!feedObject.patients.find((p) => p.mrn === patient.mrn);
	} else {
		console.error("isPatientMatch: feedObject is not an assigned feed");
		return false;
	}
};

/**
 * Assigned Feeds (milk bottles) have a patient and potentially siblings.
 * Milk Bank Products have a receivedDate while Unreceived Products do not
 * Unreceived Products have a productCode
 *
 * // TODO: determine the type of the original object. Definitely unit test this.
 *
 * @param serialized is a stringified object
 */
export const deserializeFeedObject = (serialized: string): FeedObject => {
	const obj = JSON.parse(serialized);
	let result;
	if (Object.prototype.hasOwnProperty.call(obj, "patients")) {
		result = new MilkBottleModel(obj);
	} else if (Object.prototype.hasOwnProperty.call(obj, "receivedDate")) {
		result = new MilkBankProductModel(obj);
	} else if (Object.prototype.hasOwnProperty.call(obj, "productCode")) {
		result = new Product(obj);
	} else {
		throw new Error("Unknown object type");
	}
	result.modifierOption = obj.modifierOption;
	return result;
};

/**
 * When the patient has a sibling, users must select
 * which patient the bottle should be assigned to.
 */
export const getAssignedPatients = (params: {
	recipeData: RecipeData;
	patient: PatientModel;
	assignedPatients: PatientModel[];
	milkBottle: MilkBottleModel;
}): PatientModel[] => {
	const { recipeData, patient, assignedPatients, milkBottle } = params;

	if (recipeData && patient.siblings.length) {
		return assignedPatients;
	}

	return milkBottle.patients;
};

/**
 * label quantities are divided by the total quantities of all patients
 * @param labelQuantities
 */
export const getTotalQuantity = (
	labelQuantities: IPatientLabelQuantity[]
): number => labelQuantities.reduce((acc, curr) => acc + curr.labelQuantity, 0);

/**
 * If any assigned feed is thawed, the combined state is considered thawed.
 */
export const getCombinedFeedState = (feedObjects: FeedObject[]) => {
	const assignedFeeds = feedObjects.filter(
		(f) => f instanceof MilkBottleModel || f instanceof MilkBankProductModel
	) as FeedObject[];

	// handle the case when all milk bottles are the same state
	if (assignedFeeds.every((m) => m.feedState === MilkState.Opened)) {
		return MilkState.Opened;
	} else if (assignedFeeds.every((m) => m.feedState === MilkState.Fresh)) {
		return MilkState.Fresh;
	} else if (assignedFeeds.every((m) => m.feedState === MilkState.Stable)) {
		return MilkState.Stable;
	} else if (assignedFeeds.every((m) => m.feedState === MilkState.Frozen)) {
		return MilkState.Frozen;
	} else if (assignedFeeds.every((m) => m.feedState === MilkState.Thawed)) {
		return MilkState.Thawed;
	}

	// handle the mixed case

	const anyFresh = assignedFeeds.some((m) => m.feedState === MilkState.Fresh);
	const anyThawed = assignedFeeds.some(
		(m) => m.feedState === MilkState.Thawed
	);
	const anyStable = assignedFeeds.some(
		(m) => m.feedState === MilkState.Stable
	);

	// Per Fiedler users will only feed a single Stable bottle,
	// and won't scan and feed other bottles in fresh/thawed states.
	// So we don't need to handle the logic for multiple scanned bottles.
	if (anyStable) {
		return MilkState.Stable;
	}

	return anyFresh && !anyThawed ? MilkState.Fresh : MilkState.Thawed;
};

/**
 * TODO: Is there a need to check for negative latch scores?
 *
 * @param selectedLatchQualities
 */
export const getLatchQualityScore = (
	selectedLatchQualities: ILatchQuality[]
): number =>
	selectedLatchQualities.reduce(
		(sum: number, selectedLatchQuality: any) =>
			sum + selectedLatchQuality.value,
		0
	);

import dayjs from "dayjs";

export const MDM_BASE_URL_KEY = "baseUrl";

/**
 * This is the url path for the data matrix api in order to generate a qr code.
 */
export const DATA_MATRIX_URL_PATH = "/qrcode/create?data=";

export enum ModalType {
	SelectPatientList = "select-patient-modal",
	Verified = "verified-modal",
	VerificationFailed = "verification-failed-modal",
	Error = "error-modal",
	RecipeCalculator = "recipe-calculator-modal",
	ReceiveProduct = "receive-product-modal",
	NotReceived = "not-received-modal",
	DateTime = "datetime-modal",
	Info = "info-modal",
	Warning = "warning-modal",
	BarcodeScanner = "barcode-scanning-modal",
	BottleNumber = "bottle-number",
	LogoutWarning = "logout-warning",
	Offline = "offline-modal",
	Fullscreen = "fullscreen-modal",
}

export interface OverlayConfig {
	type: ModalType;
	iconPath: string;
	title: string;
	messages: string[];
	buttonText: string;
}

// Component properties used when presenting the overlay modal.
export const OVERLAY_PROPERTY: { [key: string]: OverlayConfig } = {
	VERIFIED: {
		type: ModalType.Verified,
		iconPath: "assets/imgs/icon-check.png",
		title: "Verified Match",
		messages: [],
		buttonText: "Continue",
	},
	FAILED: {
		type: ModalType.VerificationFailed,
		iconPath: "assets/imgs/icon-cross.png",
		title: "Verification Failed",
		messages: ["test 1", "test 2"],
		buttonText: "Go Back",
	},
	ERROR: {
		type: ModalType.Error,
		iconPath: "assets/imgs/icon-cross.png",
		title: "",
		messages: [],
		buttonText: "Go Back",
	},
	INFO: {
		type: ModalType.Info,
		iconPath: "assets/imgs/icon-question-mark.png",
		title: "Verification Failed",
		messages: [],
		buttonText: "Go Back",
	},
	WARNING: {
		type: ModalType.Warning,
		iconPath: "assets/imgs/icon-exclamation-point.png",
		title: "Warning",
		messages: [],
		buttonText: "Go Back",
	},
	OFFLINE: {
		type: ModalType.Offline,
		iconPath: "assets/imgs/wifi-exclamation.svg",
		title: "Oops, looks like you've lost connection.",
		messages: [
			"A stable internet connection is required to use MilkTracker. Check your internet connection while we try to reconnect you.",
		],
		buttonText: null,
	},
};

export const FEEDING_REPORT_DURATION = 48;

export const EDIT_REPRINT_EXCLUDE_EXPIRED_GREATER_THAN = 30;

export const SECONDS_UNTIL_MODAL_CLOSES = 1;

export const NO_RESULTS = "No results.";

export const MAX_MANUFACTURER_EXPIRATION_DATE = dayjs().add(5, "year");

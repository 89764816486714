import { Component, Input, OnDestroy, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import _ from "lodash";

import { ModalType } from "src/app/app.constants";
import {
	assertExhaustive,
	BaseType,
	ProductType,
	RecipeCalculatorType,
	RecipeMode,
	RecipePageType,
} from "src/app/app.enums";
import { defaultSelectOptions } from "src/app/components/default-options";
import { capitalize, getNumberRange } from "src/app/app.util";

import {
	Recipe,
	RecipeBase,
	RecipeData,
	RecipePermissions,
	ScannedRecipeData,
	SelectedRecipe,
} from "src/app/models/recipe.model";
import {
	IOrderFulfillment,
	PatientEhrOrderModel,
	PatientModel,
} from "src/app/models/patient.model";

import { BaseService } from "src/app/services/base.service";
import { InventoryService } from "src/app/services/inventory.service";

import {
	calculateDesiredVolume,
	calculateVolumeOnHand,
	displayAdditives,
	getAdditivesByCalorieDensity,
	getCalorieRangeFromRecipe,
	getFinalVolume,
	getMinimumPossibleVolume,
	getRecipeBaseByProduct,
	getScanDataAdditives,
	getTotalAdditiveAmount,
} from "src/app/utils/recipe-calculator.util";
import { SelectAdditiveModal } from "src/app/modals/select-additive/select-additive.modal";
import { StorageService } from "src/app/services/storage.service";
import { Product } from "src/app/models/product.model";
import { environment } from "src/environments/environment";
import { SelectProductModal } from "../select-product/select-product.modal";
import { Additive, IAdditive } from "../../models/additive.model";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { isPrepared2 } from "../../utils/inventory.util";
import { OrderSelectComponent } from "../../components/order-select/order-select.component";
import { isBaseInOrderFulfillment } from "../../utils/orders.util";

@Component({
	selector: "app-recipe-calculator",
	templateUrl: "./recipe-calculator.modal.html",
	styleUrls: ["./recipe-calculator.modal.scss"],
})
export class RecipeCalculatorModal implements OnDestroy {
	@ViewChild("orderSelectRef") orderSelectRef: OrderSelectComponent;

	@Input() pageType: RecipePageType;
	@Input() recipeType: RecipeCalculatorType;
	@Input() recipePermissions: RecipePermissions;
	@Input() patients: PatientModel[] = [];
	@Input() baseProductId: Product["id"];
	@Input() selectedOrder: PatientEhrOrderModel;
	@Input() orders: PatientEhrOrderModel[];
	@Input() recipeData: RecipeData; // saved recipe data
	@Input() volume = 0;

	defaultSelectOptions = defaultSelectOptions;
	hasChanges = false;
	mode: RecipeMode;
	recipes: Recipe[];

	form: FormGroup;
	baseOptions: RecipeBase[];
	calorieDensityOptions: number[];
	additiveTypeOptions: IAdditive[];

	scanData: ScannedRecipeData;
	scannedAdditives: IAdditive[] = [];
	scannedProducts: Product[] = [];

	additiveCandidates: AdditiveCandidate[] = [];

	finalVolume = 0; // = "--";
	baseNeeded = 0; // "--";

	RecipeMode = {
		VOH: RecipeMode.VOH,
		DV: RecipeMode.DV,
		MP: RecipeMode.MP,
	};

	capitalize = capitalize;
	environment = environment;
	faChevronRight = faChevronRight;
	isPrepared2 = isPrepared2;
	StorageService = StorageService;

	constructor(
		public base: BaseService,
		private fb: FormBuilder,
		private modalCtrl: ModalController,
		private inventoryService: InventoryService
	) {}

	/**
	 * All RecipeBases from the selectedRecipes. This is unique on RecipeBase.baseProductId.
	 * Currently, there should only be one RecipeBase when calculating and validating.
	 */
	get selectedRecipesBases(): RecipeBase[] {
		if (this.mode === RecipeMode.MP) {
			return [this.form.value.recipeBase];
		}

		if (!this.scanData?.selectedRecipes) {
			return null;
		}
		return _.chain(this.scanData.selectedRecipes)
			.flatMap((recipe) => recipe.recipe)
			.uniqBy((recipe) => recipe.baseProductId)
			.map((recipe) => ({
				baseProductId: recipe.baseProductId,
				baseName: recipe.baseName,
				baseType: recipe.baseType,
			}))
			.value();
	}

	ionViewDidEnter() {
		this.buildForm();

		if (StorageService.configs) {
			this.initPermissions();
			this.initModeSelection(this.mode);
			this.loadCurrentRecipe();
		}
		this.initOrders();
	}

	async ngOnDestroy() {
		await this.base.clean();
	}

	buildForm() {
		this.form = this.fb.group({
			recipeBase: this.hasSelectableBase()
				? [null, Validators.required]
				: [null],
			volume: [this.volume, Validators.required],
			calorieDensity: [null, Validators.required],
			recipes: this.fb.array([
				this.fb.group({
					additive: [null, Validators.required],
				}),
			]),
			products: this.fb.array([
				this.fb.group({
					product: [null, Validators.required],
				}),
			]),
		});
	}

	async initPermissions() {
		const showVOH = this.recipePermissions?.volumeOnHand;
		const showDV = this.recipePermissions?.desiredVolume;
		const showMP = this.recipePermissions?.manualPrep;
		if (showVOH && !showDV && !showMP) {
			this.mode = RecipeMode.VOH;
		} else if (!showVOH && showDV && !showMP) {
			this.mode = RecipeMode.DV;
		} else if (!showVOH && !showDV && showMP) {
			this.mode = RecipeMode.MP;
		} else if (!showVOH && !showDV && !showMP) {
			await this.modalCtrl.dismiss();
			alert(
				"ERROR: You don't have permission to access the recipe calculator. Please contact your administrator."
			);
		}
	}

	/**
	 * Some users may only have permission for one recipe mode (VOH, DV, MP).
	 * In that case, we bypass the recipe mode selection and load the page.
	 */
	async initModeSelection(mode: RecipeMode) {
		if (mode) {
			await this.handleModeSelected(this.mode);
		}
	}

	async initOrders() {
		// assign destructured first item in the array
		const [patient] = this.patients;

		if (patient) {
			try {
				await this.base.presentLoading("Loading Orders...");

				// get orders if not already loaded
				// Milk Room Prep already preloads orders
				if (!this.orders) {
					if (environment.settings.removeAllOrders) {
						console.warn("[Dev Settings] Removing all orders");
						this.orders = [];
					} else {
						this.orders =
							await this.base.patientService.getPatientEhrOrders(
								patient.id
							);
					}
				}

				// default to the first order unless there is a selected order
				if (!this.selectedOrder) {
					this.selectedOrder = this.orders[0];
				}
			} catch (e) {
				console.error(e);
			} finally {
				await this.base.dismissLoading();
			}
		}
	}

	/**
	 * Upon loading the modal, get the list of recipes (VOH/DV) or the list of products (MP) based on
	 * the base product id. If there are multiple bases (combined milk), prioritize EBM--but this can
	 * be passed through the parent page via this.productId?.
	 */
	async getRecipes(
		pageType: RecipePageType,
		baseProductId: Product["id"]
	): Promise<Recipe[]> {
		if (
			pageType === RecipePageType.EBM ||
			pageType === RecipePageType.DM ||
			pageType === RecipePageType.Milk_Room_Prep
		) {
			return this.inventoryService.getRecipes({
				BaseProductId: baseProductId,
			});
		}

		if (pageType === RecipePageType.Formula) {
			return [
				...(await this.inventoryService.getRecipes({
					BaseType: BaseType.Water,
				})),
				...(await this.inventoryService.getRecipes({
					BaseType: BaseType.RTF,
				})),
			];
		}
	}

	/**
	 * If the user is coming from the Formula Prep workflow, then the base product id
	 * is passed in from the parent page. Otherwise, the base product id is derived from
	 * the scanned milk.
	 *
	 * If coming from Milk Room Prep, recipeData will already be set with
	 * the selectedFulfillment and scanData. With that, the calorie density and
	 * volume can be auto-populated. If the scanned base doesn't match the
	 * selected order's base however, then don't auto-populate the calorie
	 * density or the volume.
	 */
	async loadCurrentRecipe() {
		if (this.recipeData) {
			await this.handleModeSelected(this.recipeData.mode);

			/*
				Make sure to set the default selected order based on the one
				selected from Milk Room Prep.

				Don't need to set the recipe base because its done when the mode is selected?
			 */

			// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
			let recipeBase;
			if (
				this.recipeData.selectedFulfillment &&
				!this.recipeData.scanData?.selectedRecipes
			) {
				// Auto-fill the calorie density and volume if the base matches the selected order's base
				const recipeBase = getRecipeBaseByProduct(
					StorageService.configs.getProduct(this.baseProductId)
				);

				// Ff the base matches the selected order's base, then auto-fill the calorie density and volume
				//  and also select the additive
				const isBaseMatch = isBaseInOrderFulfillment(
					recipeBase,
					this.recipeData.selectedFulfillment
				);

				if (isBaseMatch) {
					// set calorie density
					this.form.patchValue({
						calorieDensity: this.recipeData.calorieDensity || null,
					});

					// set volume
					this.form.patchValue({
						volume: this.recipeData.volume,
					});

					// Open additive modal
					await this.handleSelectAdditivePressed(
						this.recipeData.selectedFulfillment
					);
				}
			} else {
				recipeBase = this.getBaseFromSelectedRecipe(
					this.recipeData.scanData.selectedRecipes,
					this.baseOptions
				);
				this.form.patchValue({ recipeBase });

				// set calorie density
				this.form.patchValue({
					calorieDensity: this.recipeData.calorieDensity || null,
				});

				// set additive
				this.handleSelectAdditive(this.recipeData.scanData);

				// set volume
				this.form.patchValue({
					volume: this.recipeData.volume,
				});
			}

			this.calculate();
		}
	}

	/**
	 * Since all recipes use the same base, we can just get the base from the first
	 *
	 * @param selectedRecipes
	 */
	getBaseFromSelectedRecipe(
		selectedRecipes: SelectedRecipe[],
		baseOptions: RecipeBase[]
	): RecipeBase {
		const first = selectedRecipes.map(
			(scannedRecipe) => scannedRecipe.recipe
		)[0];

		return baseOptions.find(
			(baseOption) => baseOption.baseProductId === first.baseProductId
		);
	}

	getBaseFromFullfillment(
		selectedFulfillment: IOrderFulfillment,
		baseOptions: RecipeBase[]
	): RecipeBase {
		console.log(JSON.stringify(this.baseOptions, null, 2));
		const first = selectedFulfillment.contents.map((content) => content)[0];

		return baseOptions.find(
			(baseOption) => baseOption.baseProductId === first.productId
		);
	}

	async back() {
		if (this.mode) {
			this.mode = null;
		} else if (this.hasChanges) {
			await this.showUnsavedChangesAlert();
		} else {
			await this.modalCtrl.dismiss();
		}
	}

	/**
	 * RecipeBaseOptions are determined by both the mode and the page type.
	 * In Manual Prep, RecipeBases are derived from products, not recipes. And
	 * if the user is coming from the Formula Prep workflow, the list of products
	 * has to further be filtered to only include Water and RTF.
	 */
	getRecipeBaseOptions(
		mode: RecipeMode,
		data: {
			recipes?: Recipe[];
			products?: Product[];
		}
	): RecipeBase[] {
		let result: RecipeBase[];
		if (mode === RecipeMode.MP) {
			if (this.pageType === RecipePageType.Formula) {
				data.products = data.products.filter(
					(p) =>
						p.type === ProductType.Water ||
						p.type === ProductType.RTF
				);
			}
			result = data.products.map(
				(p) =>
					({
						baseName: p.name,
						baseProductId: p.id,
						baseType: p.type,
					}) as RecipeBase
			);
		} else if (mode === RecipeMode.VOH || mode === RecipeMode.DV) {
			if (this.pageType === RecipePageType.Formula) {
				data.recipes = data.recipes.filter(
					(r) =>
						r.baseType === BaseType.Water ||
						r.baseType === BaseType.RTF
				);
			}
			result = data.recipes.map((r) => ({
				baseName: r.baseName,
				baseProductId: r.baseProductId,
				baseType: r.baseType,
			}));
		} else {
			assertExhaustive(mode);
		}

		// remove dupes
		return result.filter(
			(base, index, arr) =>
				arr
					.map((mappedBase) => mappedBase.baseProductId)
					.indexOf(base.baseProductId) === index
		);
	}

	resetForm() {
		this.form.reset();

		// Because the form resets when users switch between recipes modes,
		// we need to manually set the volume in case they entered it from
		// a previous screen (Print Fresh)
		this.form.patchValue({
			volume: this.volume,
		});
	}

	/**
	 * When selecting the recipe mode (VOH, DV)
	 * 1. Set the [filtered] recipe list
	 * 2. Set the base options (only allow selecting on Formula)
	 * 3. Set the available calorie density options based on the [filtered] recipe list
	 *
	 * When selecting the recipe mode for Manual Prep:
	 * 1. Filtering the product list is not necessary (?)
	 * 2. Set the base options (get only Water and RTF if from Formula Prep)
	 * 3. Set the available calorie density options based on all products
	 *
	 * Formula Prep is primarily used to create a feeding type with water or RTF
	 * formula as its base substrate (rather than EBM or PDHM milk).
	 */
	async handleModeSelected(mode: RecipeMode) {
		await this.base.presentLoading("Loading Recipes...");
		this.mode = mode;

		if (mode === RecipeMode.MP) {
			this.baseOptions = this.getRecipeBaseOptions(mode, {
				products: StorageService.configs.getAllProducts(),
			});
			this.calorieDensityOptions = getNumberRange(
				StorageService.configs.tenant.productCalorieMin,
				StorageService.configs.tenant.productCalorieMax
			);
			this.resetForm();
		} else if (mode === RecipeMode.VOH || mode === RecipeMode.DV) {
			this.recipes = await this.getRecipes(
				this.pageType,
				this.baseProductId
			);
			this.baseOptions = this.getRecipeBaseOptions(mode, {
				recipes: this.recipes,
			});
			this.resetForm();
			const isRecipeEdit = this.recipes && this.recipeData;
			this.calorieDensityOptions = isRecipeEdit
				? getCalorieRangeFromRecipe(this.recipes, this.recipeData.base)
				: []; // Updated to recipe values on base selection
		} else {
			assertExhaustive(mode);
		}

		/*
		 	Set default Recipe Base derived from scanned milk.
		 	Note: Prep Formula and Print Fresh don't have a derived base
		 */
		if (this.pageType !== RecipePageType.Formula) {
			console.log(`set base product id = ${this.baseProductId}`);
			const recipeBase = this.baseOptions.find(
				(baseOption) => baseOption.baseProductId === this.baseProductId
			);
			console.log(
				`handleModeSelected selected base: ${JSON.stringify(
					recipeBase,
					null,
					2
				)}`
			);

			this.form.patchValue({
				recipeBase,
				volume: this.volume,
			});

			if (mode !== RecipeMode.MP) {
				// Also load the calorie density options
				this.calorieDensityOptions = getCalorieRangeFromRecipe(
					this.recipes,
					recipeBase
				);
			}
		}

		await this.base.dismissLoading();
	}

	// order validation

	handleSelectOrderChanged(ev) {
		console.log(`handleSelectOrderChanged: ${JSON.stringify(ev, null, 2)}`);
		this.calculate();
	}

	// isMilkBottleOrderMatch(milkBottleId: string): boolean {
	// 	return isMilkBottleOrderMatch(milkBottleId, this.validationResults);
	// }

	validateRecipeMatch() {
		if (!StorageService.isOrderValidationEnforced) {
			console.log("Skip validation because no orders");
			return;
		}

		this.throwIfSelectedRecipesBasesIsInvalid();

		this.orderSelectRef.validateOrderMatchesRecipeCalculation({
			calorieDensity: this.form.value.calorieDensity,
			additives: this.scannedAdditives,
			base: this.selectedRecipesBases[0],
		});
	}

	validateSelectedOrderMatchesManualPrep() {
		if (!StorageService.isOrderValidationEnforced) {
			console.log("Skip validation because no orders");
			return;
		}

		this.throwIfSelectedRecipesBasesIsInvalid();

		const calorieDensity = this.form.value.calorieDensity as number;
		const products = this.scannedProducts.map((p) => ({
			productId: p.id,
			productName: p.name,
		}));
		const base = this.selectedRecipesBases[0];

		this.orderSelectRef.validateSelectedOrderMatchesManualPrep({
			calorieDensity,
			products,
			recipeBase: base,
		});
	}

	// additive stuff

	formRecipes(): FormArray {
		return this.form.get("recipes") as FormArray;
	}

	newAdditive(): FormGroup {
		return this.fb.group({
			additive: "",
		});
	}

	addAdditive() {
		this.formRecipes().push(this.newAdditive());
	}

	removeAdditiveQuantity(i: number) {
		this.formRecipes().removeAt(i);
	}

	resetAdditive() {
		for (let i = 0; i < this.formRecipes().length; i++) {
			if (i === 0) {
				this.formRecipes().at(i).patchValue({ additive: null });
			} else {
				this.removeAdditiveQuantity(i);
			}
		}

		this.scanData = null;
		this.scannedAdditives = [];
		this.clearCalculationResults();
	}

	// product stuff

	formProducts(): FormArray {
		return this.form.get("products") as FormArray;
	}

	addProduct() {
		this.formProducts().push(this.newProduct());
	}

	newProduct(): FormGroup {
		return this.fb.group({
			product: "",
		});
	}

	resetProduct() {
		for (let i = 0; i < this.formProducts().length; i++) {
			if (i === 0) {
				this.formProducts().at(i).patchValue({ product: null });
			} else {
				this.removeProductQuantity(i);
			}
		}

		this.scanData = null;
		this.scannedAdditives = [];
		this.clearCalculationResults();
	}

	removeProductQuantity(i: number) {
		this.formProducts().removeAt(i);
	}

	/**
	 * If a fulfillment is passed, then we're in the Select Recipe section.
	 */
	async handleSelectAdditivePressed(selectedFulfillment?: IOrderFulfillment) {
		// assign destructured first item in the array
		const [patient] = this.patients;
		console.log(
			`handleSelectAdditivePressed: ${JSON.stringify(
				selectedFulfillment,
				null,
				2
			)}`
		);
		if (this.mode === RecipeMode.MP) {
			await this.handleSelectProductPressed();
			return;
		}

		if (!this.form.value.calorieDensity) {
			throw Error("No calorie set");
		}

		const modal = await this.base.modalService.presentModal({
			component: SelectAdditiveModal,
			cssClass: ModalType.SelectPatientList,
			componentProps: {
				pageType: this.pageType,
				mode: this.mode,
				recipes: this.recipes,
				recipeBase: this.form.value.recipeBase,
				calorieDensity: this.form.value.calorieDensity,
				patients: [patient],
				selectedOrder: this.selectedOrder,
				selectedFulfillment,
			},
		});

		modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.handleSelectAdditive(data.data);
			} else {
				console.log(
					`${this.constructor.name}.presentSelectPatientModal onDidDismiss: no data passed`
				);
			}
		});

		return await modal.present().then(() => {
			// ...
		});
	}

	/**
	 * Used by Manual Prep
	 */
	async handleSelectProductPressed() {
		if (!this.form.value.calorieDensity) {
			return;
		}

		const modal = await this.base.modalService.presentModal({
			component: SelectProductModal,
			cssClass: ModalType.SelectPatientList,
			componentProps: {
				patients: this.patients,
				selectedOrder: this.selectedOrder,
			},
		});

		modal.onDidDismiss().then((data) => {
			if (data.data) {
				this.handleSelectProduct(data.data);
			} else {
				console.log(
					`${this.constructor.name}.presentSelectPatientModal onDidDismiss: no data passed`
				);
			}
		});

		return await modal.present();
	}

	throwIfSelectedRecipesBasesIsInvalid() {
		if (
			!this.selectedRecipesBases ||
			this.selectedRecipesBases.length === 0
		) {
			// biome-ignore lint/nursery/noSecrets: <explanation>
			throw new Error("selectedRecipesBases is null or empty");
		}
		// we're suppose to only have one baseProductId at this point in the workflow
		if (this.selectedRecipesBases.length > 1) {
			throw new Error(
				// biome-ignore lint/nursery/noSecrets: <explanation>
				"there's more than one baseProductId in selectedRecipesBases"
			);
		}
	}

	async onSubmit() {
		// console.log(JSON.stringify(this.additiveCandidates, null, 2));
		this.throwIfSelectedRecipesBasesIsInvalid();
		const selectedRecipeBase = this.selectedRecipesBases[0];
		console.log(
			`onSubmit: base = ${JSON.stringify(selectedRecipeBase, null, 2)}`
		);

		await this.modalCtrl.dismiss({
			pageType: this.pageType,
			mode: this.mode,
			base: selectedRecipeBase,
			calorieDensity: this.form.value.calorieDensity,
			volume: Number.parseInt(this.form.value.volume),
			finalVolume: this.finalVolume,
			baseAmount: this.baseNeeded,
			scanData: this.scanData,
			additiveCandidates: this.additiveCandidates,
		} as RecipeData);
	}

	// on change
	/**
	 * Whenever base type changes,
	 * every other field needs to be cleared
	 * calorie options need to be recalculated
	 *
	 * None of this applies on manual prep mode
	 */
	baseOptionChanged(mode: RecipeMode, recipeBase: RecipeBase) {
		console.log(
			`RecipeCalculatorModal.baseOptionChanged: base = ${JSON.stringify(
				recipeBase,
				null,
				2
			)}`
		);

		if (mode === RecipeMode.MP) {
			console.warn(
				`RecipeCalculatorModal.baseOptionChanged: don't filter on calories when base is changed in manual prep mode.`
			);
		} else if (mode === RecipeMode.VOH || mode === RecipeMode.DV) {
			this.calorieDensityOptions = getCalorieRangeFromRecipe(
				this.recipes,
				recipeBase
			);
			this.form.patchValue({
				calorieDensity: null,
			});
			this.resetAdditive();
			this.resetProduct();
		} else {
			assertExhaustive(mode);
		}
	}

	volumeChanged() {
		console.log(this.form.value.volume);
		this.calculate();
	}

	/**
	 * ML-707 Additive should be cleared when selecting a new calorie density
	 */
	calorieDensityChanged(ev) {
		ev.preventDefault();
		const calorieDensity = ev.target.value;
		this.form.patchValue({
			calorieDensity: calorieDensity || null,
		});
		console.log(
			`RecipeCalculatorModal.calorieDensityChanged: calorie density = ${calorieDensity}`
		);

		if (this.mode === RecipeMode.MP) {
			console.warn(
				`RecipeCalculatorModal.calorieDensityChanged: don't filter products when calorie is changed in manual prep mode.`
			);
		} else {
			this.additiveTypeOptions = getAdditivesByCalorieDensity(
				this.recipes,
				calorieDensity
			);
			this.resetAdditive();
		}

		this.calculate();
	}

	handleSelectAdditive(scanData: ScannedRecipeData) {
		this.resetAdditive();

		console.log(
			`handleSelectAdditive: scanned objects: ${JSON.stringify(
				scanData,
				null,
				2
			)}`
		);
		for (const [i, recipe] of scanData.selectedRecipes.entries()) {
			if (i > 0) {
				this.addAdditive();
			}
			this.formRecipes()
				.at(i)
				.patchValue({
					additive: displayAdditives({
						recipe: recipe.recipe,
					}),
				});
		}
		this.scanData = scanData;
		this.scannedAdditives = getScanDataAdditives(scanData);
		this.calculate();
	}

	handleSelectProduct(scanData: ScannedRecipeData) {
		this.resetProduct();

		console.log(
			`handleSelectProduct: scanned objects: ${JSON.stringify(
				scanData,
				null,
				2
			)}`
		);
		for (const [
			i,
			selectedProduct,
		] of scanData.selectedProducts.entries()) {
			if (i > 0) {
				this.addProduct();
			}
			this.formProducts()
				.at(i)
				.patchValue({ product: selectedProduct.product.name });
		}
		this.scanData = scanData;
		this.scannedProducts = scanData.selectedProducts.map((s) => s.product);
		// this.scannedAdditives = getScanDataAdditives(scanData);
		this.calculate();
	}

	/**
	 * Use the volume increments of the additive to recalculate the additive amount, base amount, and total volume
	 * (adjusted volume) For Volume On Hand, if the base volume is less than the increment amount
	 *
	 * If recipe form is empty, make sure results are cleared
	 */
	calculate() {
		const calorieDensity = this.form.value.calorieDensity;
		const recipe = this.scannedAdditives;
		const volume = Number.parseInt(this.form.value.volume);

		if (calorieDensity && recipe.length) {
			this.validateRecipeMatch();
		}

		if (
			this.mode === RecipeMode.MP &&
			calorieDensity &&
			this.scannedProducts?.length
		) {
			this.validateSelectedOrderMatchesManualPrep();
		}

		if (calorieDensity && recipe.length && volume) {
			const additives = recipe.map((r) => {
				const additive = new Additive(r);
				additive.product = StorageService.configs.getProduct(
					r.productId
				);
				additive.increment = additive.product.additiveIncrement;
				return additive;
			});

			if (this.mode === RecipeMode.VOH) {
				// this.calculateVolumeOnHand(volume, recipe);
				this.handleVolumeOnHandChange(volume, additives);
			} else if (this.mode === RecipeMode.DV) {
				// this.calculateDesiredVolume(volume, recipe);
				this.handleDesiredVolumeChange(volume, additives);
			}
		}
	}

	handleVolumeOnHandChange(
		volumeOnHand: number,
		scannedAdditives: Additive[]
	) {
		const minimumPossibleVolume =
			getMinimumPossibleVolume(scannedAdditives);
		if (volumeOnHand < minimumPossibleVolume) {
			this.showMinimumPossibleVolumeAlert(minimumPossibleVolume);
		} else {
			this.additiveCandidates = calculateVolumeOnHand(
				volumeOnHand,
				scannedAdditives
			);
			const baseAmount = this.additiveCandidates[0].baseAmount;

			const totalAdditiveAmount = getTotalAdditiveAmount(
				baseAmount,
				scannedAdditives
			);

			// Always round up to the nearest 0.01 for final volume
			const finalVolume = getFinalVolume(
				baseAmount,
				scannedAdditives,
				0.01
			);

			console.log(
				`base_amt=${baseAmount}, ttl_add_amt=${totalAdditiveAmount}, final_vol=${finalVolume}`
			);

			this.setCalculationResults(baseAmount, finalVolume);
		}
	}
	handleDesiredVolumeChange(
		desiredVolume: number,
		scannedAdditives: Additive[]
	) {
		// const minimumPossibleVolume =
		// 	getMinimumPossibleVolume(scannedAdditives);
		// if (volumeOnHand < minimumPossibleVolume) {
		// 	this.showMinimumPossibleVolumeAlert(minimumPossibleVolume);
		// } else {
		this.additiveCandidates = calculateDesiredVolume(
			desiredVolume,
			scannedAdditives
		);
		const baseAmount = this.additiveCandidates[0].baseAmount;

		const totalAdditiveAmount = getTotalAdditiveAmount(
			baseAmount,
			scannedAdditives
		);

		// Always round up to the nearest 0.01 for final volume
		const finalVolume = getFinalVolume(baseAmount, scannedAdditives, 0.01);

		console.log(
			`base_amt=${baseAmount}, ttl_add_amt=${totalAdditiveAmount}, final_vol=${finalVolume}`
		);

		this.setCalculationResults(baseAmount, finalVolume);
		// }
	}

	/**
	 * Final Volume must be rounded 2 decimal places
	 */
	setCalculationResults(baseAmount: number, finalVolume: number) {
		this.baseNeeded = baseAmount;
		console.table({ finalVolume });
		this.finalVolume = finalVolume; // Math.round(finalVolume * 100) / 100;
	}

	clearCalculationResults() {
		this.additiveCandidates = null;
		this.baseNeeded = null;
		this.finalVolume = null;
	}

	/**
	 * This is basically only relevant to rounding increments in grams.
	 * If Volume On Hand, round down to the nearest increment
	 * If Desired Volume, round up to the nearest increment
	 *
	 * If additive amount is in mLs, this doesn't really do anything because the search algorithm will match the amount
	 * on whole numbers anyway.
	 */
	getAdditiveAmount(mode: RecipeMode, amount: number): number {
		if (mode === RecipeMode.VOH) {
			return Math.floor(amount * 10000) / 10000;
		}

		if (mode === RecipeMode.DV) {
			return Math.ceil(amount * 10000) / 10000;
		}

		return amount;
	}

	// Booleans

	hasRecipeBase(): boolean {
		return this.form.value.recipeBase;
	}

	hasCalorieDensity(): boolean {
		return this.form.value.calorieDensity;
	}

	hasSelectableBase(): boolean {
		return this.pageType === RecipePageType.Formula;
	}

	canSubmit(mode: RecipeMode, form: FormGroup): boolean {
		const isDefaultFormFilled =
			form.value.volume && form.value.calorieDensity;
		const hasProducts = form.value.products[0].product !== null; // products array default = [{product: null}]
		const hasAdditives = form.value.recipes[0].additive !== null; // recipes array default = [{additive: null}]
		const hasCalculations = this.finalVolume && this.baseNeeded;

		// biome-ignore lint/suspicious/noImplicitAnyLet: <explanation>
		let result;
		if (mode === RecipeMode.DV || mode === RecipeMode.VOH) {
			result = isDefaultFormFilled && hasAdditives && hasCalculations;
		} else if (mode === RecipeMode.MP) {
			result = isDefaultFormFilled && hasProducts;
		}
		return result;
	}

	// Alerts

	/**
	 * Going back will remove all entered recipe information. Continue?
	 */
	async showUnsavedChangesAlert() {
		await this.base.alerts.presentAlert({
			header: "Unsaved Changes",
			subHeader: "",
			message:
				"Going back will remove all entered recipe information. Continue?",
			backdropDismiss: false,
			buttons: [
				{
					text: "No",
					role: "cancel",
				},
				{
					text: "Yes",
					handler: () => {
						this.modalCtrl.dismiss();
					},
				},
			],
		});
	}

	async showInvalidBaseAmountAlert(baseVolume: number) {
		await this.base.alerts.presentAlert({
			header: "Invalid Base Amount",
			message: `The base volume of ${baseVolume} mL cannot be less than the minimum required volume for this recipe.`,
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					handler: () => {
						this.form.patchValue({ volume: null });
					},
				},
			],
		});
	}

	async showMinimumPossibleVolumeAlert(minimumPossibleVolume: number) {
		await this.base.alerts.presentAlert({
			header: "Invalid Base Amount",
			message: `The base volume entered is less than the minimum amount required for this recipe (${minimumPossibleVolume}mL)`,
			backdropDismiss: false,
			buttons: [
				{
					text: "OK",
					handler: () => {
						this.form.patchValue({ volume: null });
					},
				},
			],
		});
	}
}

/**
 * @deprecated - use AdditiveCandidate2
 */
export interface AdditiveCandidate {
	additive: IAdditive;
	product?: Product;
	additiveAmount: number;
	baseAmount: number;
}

export interface AdditiveCandidate2 {
	additive: Additive;
	additiveAmount: number;
	baseAmount: number;
}

import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { BaseService } from "src/app/services/base.service";
import { StorageService } from "src/app/services/storage.service";
import { defaultSelectOptions } from "src/app/components/default-options";
import { PatientService } from "src/app/services/patient.service";
import { InventoryService } from "src/app/services/inventory.service";
import { PatientModel } from "src/app/models/patient.model";
import { MilkBottleModel } from "src/app/models/milk.model";
import { ScanningService } from "src/app/services/scanning.service";
import { Subscription } from "rxjs";
import { WhoIsModel } from "src/app/models/whois.model";
import { IOrderValidationResult2 } from "../order-match/order-match.component";
import {
	FeedPatientListItemAction,
	ProductFeedPatientListItem,
} from "src/app/administer-feed/feed-patient/list-item";
import { environment } from "src/environments/environment";
import { Nurse } from "../nurse-info/nurse-info.component";
import { ScanLogWorkflow } from "../../app.enums";

export interface IManualEntryForm {
	reason: string | null;
	otherReason: string | null;
	secondNurse: Nurse | null;
	action: FeedPatientListItemAction | null;
	mrn: string | null;
	wasScanned: boolean;
}

export type ManualEntryForm =
	| MilkBottleManualEntryForm
	| ProductManualEntryForm;

export interface MilkBottleManualEntryForm extends IManualEntryForm {
	type: "milkbottle";
	bottleNumber: string | null;
}
export interface ProductManualEntryForm extends IManualEntryForm {
	type: "product";
	productListItem: ProductFeedPatientListItem;
}

@Component({
	selector: "app-feed-override-form",
	templateUrl: "./feed-override-form.component.html",
	styleUrls: ["./feed-override-form.component.scss"],
})
export class FeedOverrideFormComponent implements OnInit {
	@Input() patient: PatientModel | null;
	@Input() manualEntryForm: ManualEntryForm;
	@Input() validationResults: IOrderValidationResult2[];
	@Input() hasPermissionMessage = false;
	@Output() manualEntryFormChange = new EventEmitter<ManualEntryForm>();
	@Output() isPartiallyValidChange = new EventEmitter<boolean>();
	@Output() inputFocused = new EventEmitter();
	@Output() inputBlurred = new EventEmitter();

	isWeb: boolean;
	scanListenerSubscription: Subscription;

	isLoaded = false;
	milkBottles: MilkBottleModel[];

	defaultSelectOptions = defaultSelectOptions;
	environment = environment;

	workflow = ScanLogWorkflow.Feed_Override_Form;

	constructor(
		public base: BaseService,
		public patientService: PatientService,
		public inventoryService: InventoryService,
		public authService: AuthService,
		public scanningService: ScanningService
	) {
		this.isWeb = StorageService.isWeb;
	}

	get reasons() {
		return [...(StorageService.configs?.overrideReasons || []), "Other"];
	}

	get showOtherReason() {
		return this.manualEntryForm.reason === "Other";
	}

	/**
	 * TODO ML-539 The feature to bypass second nurse in manual entry if the order
	 *  matches will be split into a separate card.
	 *
	 * TODO (ML-1245)
	 */
	get isSecondNurseBadgeRequired(): boolean {
		return true;
		// if (!this.manualEntryForm.wasScanned) {
		// 	return true;
		// }

		// const requireSecondNurseBadgeOnOrderValidationFailure =
		// 	StorageService.configs.tenant.enforceManualEntryOnValidationFailure;
		// const milkBottle = this.milkBottles.filter(
		// 	(m) =>
		// 		m.bottleNumber === parseInt(this.manualEntryForm.bottleNumber)
		// )[0];
		// let milkBottleMatchesOrder = false;
		// if (milkBottle && this.validationResults) {
		// 	milkBottleMatchesOrder = this.validationResults.some(
		// 		(v) => v.milkBottleId === milkBottle.id && v.isValid
		// 	);
		// }

		// DO require a second nurse badge if it doesn’t match the order OR if there are no active orders
		// if (
		// 	requireSecondNurseBadgeOnOrderValidationFailure &&
		// 	!milkBottleMatchesOrder
		// ) {
		// 	return true;
		// }
	}

	/** True if all the necessary form fields are entered. More validation is needed before saving. */
	get isPartiallyValid(): boolean {
		return (
			(this.manualEntryForm.type !== "milkbottle" ||
				!!this.manualEntryForm.bottleNumber) &&
			!!this.manualEntryForm.mrn &&
			!!this.manualEntryForm.reason &&
			(!this.isSecondNurseBadgeRequired ||
				!!this.manualEntryForm.secondNurse) &&
			!!this.manualEntryForm.action
		);
	}

	ngOnInit() {
		this.init();
	}

	async init() {
		if (!this.manualEntryForm) {
			throw new Error("feedOverrideForm is required");
		}

		if (this.patient) {
			this.milkBottles = await this.inventoryService.getMilkBottles(
				this.patient,
				{
					includeExpended: true,
					includeExpired: true,
					includeUsed: true,
				}
			);
		}
		this.isLoaded = true;
	}

	handleOnFocus() {
		console.log("on focus");
		this.inputFocused.emit();
	}

	handleOnBlur() {
		console.log("on blur");
		this.inputBlurred.emit();
	}

	emit() {
		this.manualEntryFormChange.emit(this.manualEntryForm);
		this.isPartiallyValidChange.emit(this.isPartiallyValid);
	}

	reset() {
		this.manualEntryForm = {
			type: "milkbottle",
			bottleNumber: null,
			mrn: null,
			reason: null,
			otherReason: null,
			secondNurse: null,
			wasScanned: null,
			action: null,
		};
		this.emit();
	}

	async validateBeforeSave(): Promise<boolean> {
		if (!this.isPartiallyValid) {
			return false;
		}

		// milk bottle manual entry form validation
		if (this.manualEntryForm.type === "milkbottle") {
			const bottleNumberFound = this.milkBottles.some(
				(m) =>
					m.bottleNumber ===
					Number.parseInt(
						(this.manualEntryForm as MilkBottleManualEntryForm)
							.bottleNumber
					)
			);
			if (!bottleNumberFound) {
				await this.base.modalService.presentBottleNotFound({
					bottleNumber: this.manualEntryForm.bottleNumber,
					workflow: this.workflow,
				});
				return false;
			}

			const mrnMatchesPatient =
				this.manualEntryForm.mrn === this.patient.mrn.toString();
			if (!mrnMatchesPatient) {
				await this.base.modalService.presentPatientMRNMismatch({
					mrn: this.manualEntryForm.mrn,
					patient: this.patient,
					workflow: this.workflow,
				});

				return false;
			}
		}

		// validate that the override user id exists and has the AdministerFeed permission,
		// and does not match the logged in user

		// attempt to load the second nurse badge user
		let secondNurseBadgeWhoIsResult: WhoIsModel;
		if (this.manualEntryForm.secondNurse) {
			secondNurseBadgeWhoIsResult = await this.authService.whois({
				secondaryId: this.manualEntryForm.secondNurse.id,
				username: this.manualEntryForm.secondNurse.email,
			});
		}

		// only validate the second Nurse ID if required
		if (this.isSecondNurseBadgeRequired) {
			if (
				secondNurseBadgeWhoIsResult === null ||
				false ===
					secondNurseBadgeWhoIsResult.hasAdministerFeedPermission(
						StorageService.activeTenant.tenantId
					)
			) {
				await this.base.modalService.presentInvalidSecondBadgeModal({
					workflow: this.workflow,
				});
				return false;
			}
		}

		// invalid if the current logged in user matches the second Nurse ID
		if (
			secondNurseBadgeWhoIsResult &&
			secondNurseBadgeWhoIsResult.email === StorageService.user.email
		) {
			await this.base.modalService.presentDuplicateNurseId({
				user: StorageService.user,
				workflow: this.workflow,
			});
			return false;
		}

		return true;
	}
}

<div *ngIf="recipeData">
	<ion-list-header>
		<ion-label>Recipe</ion-label>
	</ion-list-header>
	<ion-list class="recipe-info">
		<!-- Volume On Hand or Desired Volume -->
		<ion-grid class="recipe-info__header">
			<ion-row class="ion-align-items-start">
				<ion-col>
					<ion-item>
						<ion-label class="recipe-info__header__name">
							{{ recipeData.mode }}
						</ion-label>
						<ion-text class="recipe-info__header__value" slot="end" data-testid="recipe_info__volume">
							{{ getVolumeText(recipeData.volume) }}
						</ion-text>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row class="ion-align-items-start">
				<ion-col>
					<ion-item>
						<ion-label class="recipe-info__header__name">
							Calorie Density
						</ion-label>
						<ion-text class="recipe-info__header__value" slot="end" data-testid="recipe_info__calorie_density">
							{{ getCaloricDensityText(recipeData.calorieDensity) }}
						</ion-text>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row
				*ngIf="recipeData.mode !== RecipeMode.MP"
				class="ion-align-items-start"
			>
				<ion-col>
					<ion-item>
						<ion-label class="recipe-info__header__name">
							Final Volume
						</ion-label>
						<ion-text class="recipe-info__header__value" slot="end" data-testid="recipe_info__final_volume">
							{{ getVolumeText(recipeData.finalVolume) }}
						</ion-text>
					</ion-item>
				</ion-col>
			</ion-row>

			<ion-row
				*ngIf="
					recipeData.mode !== RecipeMode.MP &&
					recipeData.scanData.selectedRecipes
				"
				class="ion-align-items-start"
			>
				<ion-col>
					<ion-item-group>
						<ion-item-divider>
							<ion-label
								class="recipe-info__header__group-name ion-text-wrap"
							>
								Base Needed
							</ion-label>
						</ion-item-divider>
						<ion-item
							*ngFor="
								let s of recipeData.scanData.selectedRecipes; let i = index
							"
						>
							<ion-label
								class="recipe-info__header__name ion-text-wrap"
								[attr.data-testid]="'recipe_info__base_needed_name_' + i"
							>
								{{ s.recipe.baseName }}
							</ion-label>
							<ion-text
								class="recipe-info__header__value"
								slot="end"
								[attr.data-testid]="'recipe_info__base_needed_amount_' + i"
							>
								{{ getVolumeText(recipeData.baseAmount) }}
							</ion-text>
						</ion-item>
					</ion-item-group>
				</ion-col>
			</ion-row>

			<ion-row
				*ngIf="
					recipeData.mode !== RecipeMode.MP &&
					recipeData.scanData.selectedRecipes
				"
				class="ion-align-items-start"
			>
				<ion-col>
					<ion-item-group>
						<ion-item-divider>
							<ion-label class="recipe-info__header__group-name">
								Additive Needed
							</ion-label>
						</ion-item-divider>
						<ion-item
							*ngFor="let a of recipeData.additiveCandidates; let i = index"
						>
							<ion-label class="recipe-info__header__name" [attr.data-testid]="'recipe_info__additive_needed_name_' + i">
								{{ a.additive.productName }}
							</ion-label>
							<ion-text
								*ngIf="recipeData.mode !== RecipeMode.MP"
								class="recipe-info__header__value"
								slot="end"
								[attr.data-testid]="'recipe_info__additive_needed_amount_' + i"
							>
								{{ displayAdditiveAmount(a.additiveAmount) }}
								{{ a.additive.productMeasuredUnit }}
							</ion-text>
						</ion-item>
					</ion-item-group>
				</ion-col>
			</ion-row>

			<!-- Manual Prep -->
			<ion-row
				*ngIf="
					recipeData.mode === RecipeMode.MP &&
					recipeData.scanData.selectedProducts
				"
				class="ion-align-items-start"
			>
				<ion-col>
					<ion-item-group>
						<ion-item-divider>
							<ion-label class="recipe-info__header__group-name">
								Base Needed
							</ion-label>
						</ion-item-divider>
						<ion-item>
							<ion-label class="recipe-info__header__name">
								{{ recipeData.base.baseName }}
							</ion-label>
						</ion-item>
					</ion-item-group>
				</ion-col>
			</ion-row>

			<ion-row
				*ngIf="
					recipeData.mode === RecipeMode.MP &&
					recipeData.scanData.selectedProducts
				"
				class="ion-align-items-start"
			>
				<ion-col>
					<ion-item-group>
						<ion-item-divider>
							<ion-label class="recipe-info__header__group-name">
								Product(s)
							</ion-label>
						</ion-item-divider>
						<ion-item
							*ngFor="
								let s of recipeData.scanData.selectedProducts
							"
						>
							<ion-label class="recipe-info__header__name">
								{{ s.product.name }}
							</ion-label>
						</ion-item>
					</ion-item-group>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-list>

	<div *ngIf="recipeData && recipeData.scanData.scannedProducts.length">
		<ion-list-header>
			<ion-label>Scanned Additives</ion-label>
		</ion-list-header>
		<ion-accordion-group class="recipe-info--scanned-product">
			<ion-accordion
				*ngFor="
					let p of recipeData.scanData.scannedProducts;
					let i = index
				"
			>
				<ion-item class="milk-info__header" slot="header">
					<ion-grid>
						<ion-row>
							<ion-col>
								<ion-row>
									<ion-col class="milk-info__header__detail" size="auto">
										<p class="milk-info__header__detail__label">
											{{
												p.milkBankProduct
													?.productState || "name"
											}}
										</p>
										<p class="milk-info__header__detail__value">
											<span>{{ getProductName(p) }}</span>
										</p>
									</ion-col>

									<ion-col *ngIf="!!getBottleNumber(p)" size="auto">
										<div class="vertical-divider"></div>
									</ion-col>

									<ion-col *ngIf="!!getBottleNumber(p)"
											 class="milk-info__header__detail"
											 size="auto">
										<p class="milk-info__header__detail__label">
											bottle #
										</p>
										<p class="milk-info__header__detail__value">
											<span><b>{{ getBottleNumber(p) }}</b></span>
										</p>
									</ion-col>

									<ion-col *ngIf="!!getLotNumber(p)" size="auto">
										<div class="vertical-divider"></div>
									</ion-col>

									<ion-col *ngIf="!!getLotNumber(p)"
											 class="milk-info__header__detail"
											 size="auto">
										<p class="milk-info__header__detail__label">
											lot number
										</p>
										<p class="milk-info__header__detail__value">
											<span>{{ getLotNumber(p) }}</span>
										</p>
									</ion-col>
								</ion-row>

								<app-milk-modifiers *ngIf="p.milkBankProduct"
													[(option)]="p.modifierOption"
													[options]="modifierOptions"
													(optionChange)="selectProductModifier($event, p)">
								</app-milk-modifiers>
							</ion-col>
						</ion-row>
					</ion-grid>
				</ion-item>

				<ion-list slot="content">
					<ion-grid class="recipe-info__content">
						<ion-row *ngIf="p.match?.groups?.upc" class="ion-align-items-start">
							<ion-col class="recipe-info__content__name" size="4">
								<fa-icon [icon]="faQrcode" [fixedWidth]="true" size="sm"></fa-icon>
								&nbsp;<span>UPC</span>
							</ion-col>
							<ion-col class="recipe-info__content__value">
								{{ p.match.groups.upc }}
							</ion-col>
						</ion-row>

						<ion-row *ngIf="p.milkBankProduct?.expendedDate" class="ion-align-items-start">
							<ion-col class="recipe-info__content__name" size="4">
								<fa-icon [icon]="faTrash" [fixedWidth]="true" size="sm"></fa-icon>
								&nbsp;<span>Expended</span>
							</ion-col>
							<ion-col class="recipe-info__content__value">
								{{ displayDate(p.milkBankProduct?.expendedDate) }}
							</ion-col>
						</ion-row>

						<ion-row *ngIf="p.milkBankProduct?.thawedDate" class="ion-align-items-start">
							<ion-col class="recipe-info__content__name" size="4">
								<fa-icon [icon]="faTemperatureFull" [fixedWidth]="true" size="sm"></fa-icon>
								&nbsp;<span>Thawed</span>
							</ion-col>
							<ion-col class="recipe-info__content__value">
								{{ displayDate(p.milkBankProduct?.thawedDate) }}
							</ion-col>
						</ion-row>

						<ion-row *ngIf="p.milkBankProduct?.openedDate" class="ion-align-items-start">
							<ion-col class="recipe-info__content__name" size="4">
								<fa-icon [icon]="faBoxOpen" [fixedWidth]="true" size="sm"></fa-icon>
								&nbsp;<span>Opened</span>
							</ion-col>
							<ion-col class="recipe-info__content__value">
								{{ displayDate(p.milkBankProduct?.openedDate) }}
							</ion-col>
						</ion-row>

						<ion-row *ngIf="p.milkBankProduct?.manufacturerExpirationDate" class="ion-align-items-start">
							<ion-col class="recipe-info__content__name" size="4">
								<fa-icon [icon]="faClock" [fixedWidth]="true" size="sm"></fa-icon>
								&nbsp;<span>Manufacturer Expiration</span>
							</ion-col>
							<ion-col class="recipe-info__content__value">
								{{ displayDate(p.milkBankProduct?.manufacturerExpirationDate) }}
							</ion-col>
						</ion-row>
						<!--						{{ p.originalMilkBankProduct }}-->
					</ion-grid>
				</ion-list>
			</ion-accordion>
		</ion-accordion-group>
	</div>
</div>

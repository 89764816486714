<ion-item class="milk-info__header" *ngIf="productInfo">
	<ion-grid>
		<ion-row>
			<ion-col [ngClass]="productInfo.productColor" size="auto">
				<div></div>
			</ion-col>
			<div></div>

			<ion-col>
				<ion-chip size="small" color="success" *ngIf="productInfo.displayNewChip">New</ion-chip>

				<ion-row>
					<ion-col class="milk-info__header__detail" size="auto">
						<p class="milk-info__header__detail__label">
							{{ productInfo.detailLabel }}
						</p>
						<p class="milk-info__header__detail__value">
							<span><b>{{ productInfo.detailValue }}</b></span>
						</p>
					</ion-col>

					<ion-col size="auto">
						<div class="vertical-divider"></div>
					</ion-col>

					<ion-col class="milk-info__header__detail" size="auto">
						<p class="milk-info__header__detail__label">
							{{ productInfo.productState }}
						</p>
						<p class="milk-info__header__detail__value">
							<span>{{ productInfo.productTypeText }}</span>
						</p>
					</ion-col>

					<ion-col class="milk-info__header__chip">
						<ion-chip color="{{ productInfo.expirationChipColor }}">
							<span>{{ productInfo.expirationDate }}</span>
						</ion-chip>
					</ion-col>
				</ion-row>
				<!-- Milk Modifier -->
				<ng-content></ng-content>
			</ion-col>
		</ion-row>
	</ion-grid>
</ion-item>

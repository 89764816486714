import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { PatientModel } from "../../models/patient.model";
import { ProductState } from "../../app.enums";
import { getRelativeReceived } from "../../app.util";
import {
	MilkModifierEvent,
	ModifierOption,
} from "../milk-modifiers/milk-modifiers.component";
import {
	getRelativeExpirationTime,
} from "../../utils/expiration.util";
import { ScannedObject } from "../../services/scanning.service";
import { StorageService } from "../../services/storage.service";

@Component({
	selector: "app-product-info",
	templateUrl: "./product-info.component.html",
	styleUrls: ["./product-info.component.scss"],
})
export class ProductInfoComponent implements OnInit {
	@Input() isHeaderVisible = true;
	@Input() isDischarging = false;
	@Input() isPartiallyUsable = false;
	@Input() hasButtons = true;
	@Input() patient: PatientModel;
	@Input() title = "Milk Info";
	@Input() instructions = "Step 2: Scan milk bank products";
	@Input() options: ModifierOption[];
	@Input() milkBankProducts: MilkBankProductModel[];
	@Output() modifierPress = new EventEmitter<MilkModifierEvent>();
	@Output() selectMilkPress = new EventEmitter<ScannedObject>();
	@Output() removeButtonPress = new EventEmitter<MilkBankProductModel>();

	StorageService = StorageService;

	constructor() {}

	ngOnInit() {
		// do nothing
	}

	selectMilkModifier(option: ModifierOption, product: MilkBankProductModel) {
		this.modifierPress.emit({ option, milkBankProduct: product });
	}

	remove(milkBankProduct: MilkBankProductModel) {
		this.removeButtonPress.emit(milkBankProduct);
	}

	selectMilk() {
		this.selectMilkPress.emit();
	}

	getExpirationDate(milkBankProduct: MilkBankProductModel): string {
		if (!this.isDischarging) {
			return getRelativeExpirationTime(milkBankProduct.expirationDate);
		}

		return getRelativeReceived(milkBankProduct.receivedDate);
	}

	/**
	 * Frozen:
	 *   Prevent users from selecting "Has Remaining" for a frozen product
	 * 	 since the entire container is assigned to an individual patient.
	 *
	 * Inline Thawed:
	 *   When assigning a product, user's can inline thaw a frozen milk bottle.
	 *   When they inline thaw, the entire container should be assigned.
	 */
	getDisabledOptions(
		milkBankProduct: MilkBankProductModel
	): ModifierOption[] {
		if (milkBankProduct.productState === ProductState.Frozen) {
			return ["has remaining"];
		}

		if (milkBankProduct.isInlineThawed) {
			return ["has remaining"]
		}

		return [];
	}

	trackById(index, milkBankProduct) {
		return (milkBankProduct as MilkBankProductModel).unifiedId;
	}
}

import dayjs from "dayjs";

export class MilkBottleExpiringSoonest {
	unifiedId: string;
	id: string;
	bottleNumber: number;
	expirationDate: dayjs.Dayjs;

	constructor(
		initial: IMilkBottleExpiringSoonest = {} as IMilkBottleExpiringSoonest
	) {
		if (!initial) {
			return;
		}

		Object.keys(initial).forEach((key) => {
			if (key === "expirationDate") {
				this.expirationDate = initial[key] ? dayjs(initial[key]) : null;
			} else {
				this[key] = initial[key];
			}
		});
	}
}

export interface IMilkBottleExpiringSoonest {
	id: string;
	unifiedId: string;
	bottleNumber: number;
	expirationDate: string;
}

import { UIConfig } from "scanbot-web-sdk/@types";

export function applyActionBarConfig(
	config: UIConfig.BarcodeScannerScreenConfiguration
) {
	config.actionBar.flashButton.visible = true;
	config.actionBar.flashButton.backgroundColor = "#165d73"; // primary color
	config.actionBar.flashButton.foregroundColor = "#ffffff"; // primary contrast

	config.actionBar.flashButton.activeBackgroundColor = "#16a54e";
	config.actionBar.flashButton.activeForegroundColor = "#ffffff";

	config.actionBar.zoomButton.visible = true;
	config.actionBar.zoomButton.backgroundColor = "#165d73";
	config.actionBar.zoomButton.foregroundColor = "#ffffff";

	config.actionBar.flipCameraButton.visible = false;
}

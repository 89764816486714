import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, timeout } from "rxjs/operators";
import { lastValueFrom, of, throwError } from "rxjs";
import { BaseService } from "./base.service";
import { PrinterModel } from "../models/printer.model";
import { environment } from "../../environments/environment";
import { datadogLogs } from "@datadog/browser-logs";

const ZEBRA_PRINTER_TIMEOUT = 10 * 1000;
const PRINT_SERVER_TIMEOUT = 30 * 1000;

const httpOptions = {
	headers: new HttpHeaders({
		Accept: "*/*",
		"Content-Type": "text/plain; charset=utf-8",
	}),
};

@Injectable({
	providedIn: "root",
})
export class PrinterService {
	constructor(
		private http: HttpClient,
		private base: BaseService
	) {}

	/**
	 * This is the http post method that is used to send the zpl to the printer.
	 * The printers don't set CORS headers, so all requests from the app
	 * will error with status: 0, even when the print succeeded. Ignore the
	 * error. The printer might have succeeded or failed, we can't tell.
	 *
	 *
	 *
	 * @param url
	 * @param zpl
	 */
	print(printer: PrinterModel, zpl: string): Promise<any> {
		const url = printer.networkRoute;
		const timeoutDuration = printer.networkRoute.includes("pstprnt")
			? ZEBRA_PRINTER_TIMEOUT
			: PRINT_SERVER_TIMEOUT;
		console.log(
			`PrinterService.print: attempting print with timeout duration: ${
				timeoutDuration / 1000
			} seconds`
		);
		return lastValueFrom(
			this.http.post(url, zpl, httpOptions).pipe(
				timeout(timeoutDuration),
				catchError((error) => {
					if (error?.status === 0) {
						return of(null); // ignore the error with success
					} else {
						return this.handleError(error);
					}
				})
			)
		);
	}

	// TODO: duplicate as in rest api service
	getHTTPErrorResponse(status: number): Promise<any> {
		const url = `${environment.apiUrl}/throw-error/${status}`;
		return lastValueFrom(
			this.http.get(url, httpOptions).pipe(
				timeout(PRINT_SERVER_TIMEOUT),
				catchError((error) => {
					if (error?.status === 0) {
						/*
							The printers don't set CORS headers, so all requests from the app
							will error with status: 0, even when the print succeeded. Ignore the
							error. The printer might have succeeded or failed, we can't tell.
						 */
						return of(null); // Return a dummy value or use 'of(null)' to ignore the error
					} else {
						return this.handleError(error);
					}
				})
			)
		);
	}

	/**
	 * Error handler when printing. This makes sure the correct error message is
	 * being thrown.
	 *
	 * TimeoutError
	 * 404
	 * 500
	 *
	 * @param error
	 * @param context
	 */
	handleError(error: any) {
		console.log(
			`PrinterService.handleError: error: ${JSON.stringify(
				error,
				null,
				2
			)}`
		);

		let message;
		if (error.name === "TimeoutError") {
			message = "Print request has timed out. ";
		} else if (error.status === 404 || error.status == 500) {
			message = error.error.detail;
			console.error(
				`PrinterService.handleError: ${error.status} error: ${message}`
			);
		} else {
			message = "Unknown error. ";
			console.error(`PrinterService.handleError: ${message}`);
		}

		datadogLogs.logger.error(error?.message || error);

		return throwError(() => {
			const error: any = new Error(message);
			error.timestamp = Date.now();
			return error;
		});
	}
}

import * as dayjs from "dayjs";
import {
	ModalType,
	OVERLAY_PROPERTY,
	OverlayConfig,
} from "src/app/app.constants";
import { ContentProductType, ContentType } from "src/app/app.enums";

import { StorageService } from "src/app/services/storage.service";
import { IContent } from "src/app/models/milk.model";
import { PatientEhrOrderModel } from "./models/patient.model";
import { TitleCasePipe } from "@angular/common";

/**
 * Convert and determine a 2-digit year number to the full year.
 *
 * @param yy
 *
 * If the 2-digit year is equal to next year or before then it must be within this century. But if the 2-digit year is after next year,
 *     then assume the date is from the last century.
 *
 * For example:
 * If the year is 2021, and the `forwardThreshold` is 5, then 26 would still be considered the year 2026 because it is within the accepted
 *     bounds. But if the 2-digit year was 27, then it would be considered 1927.
 *
 * This will primarily be used to calculate expiration dates which should never be a few days beyond the open/thaw date of the milk, so the
 *     reasonable threshold would be 1 to account for crossing through into the new year (e.g. 2020 into 2021).
 */
export const convertYYtoYYYY = (yy: number): number => {
	const forwardThreshold = 10;
	const currentYY = Number.parseInt(dayjs().format("YY"));
	if (yy <= currentYY + forwardThreshold) {
		return 2000 + yy;
	}

	if (yy > currentYY + forwardThreshold) {
		return 1900 + yy;
	}

	throw Error(`convertYYtoYYYY(): invalid number: ${yy}`);
};

/**
 * Convert format YYYY-MM-DDTHH:mm:ss (e.g. "2021-03-18T16:20:58") into dayjs
 */
export const getDate = (date: string): dayjs.Dayjs =>
	dayjs(date, "YYYY-MM-DDTHH:mm:ss");

export const displayDate = (date: dayjs.Dayjs): string =>
	date ? date.format("MM/DD/YY, HH:mm") : "none";

export const displayDateOfBirth = (date: dayjs.Dayjs): string =>
	dayjs(date).isValid() ? dayjs(date).format("MM/DD/YY") : "none";

export const displayFortifiers = (contents: IContent[]): string =>
	contents
		.filter((c) => c.contentType === ContentType.Product)
		.map((c) => ` ${c.contentProductName}`)
		.toString() || "none";

/**
 * @deprecated - for most cases, use displayContents() in feed-info-content.util.ts
 * Currently used in Feeding Report, so this can't quite be deprecated yet.
 */
export const displayContents = (contents: IContent[]): string[] => {
	const contentsStrings = contents.map((c) => c.contentProductName);
	return contentsStrings
		.filter((num, index) => contentsStrings.indexOf(num) === index)
		.sort(); // remove dupes
};

export const getRelativeFeedingTime = (date: dayjs.Dayjs): string => {
	const diff = dayjs().diff(date, "hour");
	if (diff === 0) {
		const minDiff = dayjs().diff(date, "minute");
		return `${minDiff}m ago`;
	}

	if (diff > 0) {
		if (diff > 72) {
			return `${Math.floor(diff / 24)}d ago`;
		}
		return `${diff}h ago`;
	}
};

export const getRelativeReceived = (date: dayjs.Dayjs): string => {
	const diff = dayjs().diff(date, "hour");
	if (diff === 0) {
		const minDiff = dayjs().diff(date, "minute");
		return `Received ${minDiff}m ago`;
	}

	if (diff > 0) {
		if (diff > 72) {
			return `Received ${Math.floor(diff / 24)}d ago`;
		}
		return `Received ${diff}h ago`;
	}
};

/**
 * Do not consider contentProductType = ebm
 *
 * @param contents
 */
export const containsHumanMilk = (contents: IContent[]): boolean =>
	!!contents.find((c) => {
		if (c.contentProductType === ContentProductType.Additive) {
			return StorageService.configs.getProduct(c.productId)
				.containsHumanMilk;
		}
	});

/**
 * Generates Overlay component property object use in ModalController.
 * This is used to customize the Overlay modal style Verified or Failed modals.

 * @param cssClass is ModalType
 * @param header: Optional for Verified and Verification Failed.
 * @param messages: Optional. Shows messages under the header.
 */
export const createOverlayComponentProps = (
	cssClass: ModalType,
	header?: string,
	messages?: string[],
	title?: string
): OverlayConfig => {
	let componentProps: OverlayConfig;
	switch (cssClass) {
		case ModalType.Verified: {
			const verifiedProps = OVERLAY_PROPERTY.VERIFIED;
			componentProps = {
				type: verifiedProps.type,
				iconPath: verifiedProps.iconPath,
				title: verifiedProps.title,
				messages,
				buttonText: verifiedProps.buttonText,
			};
			break;
		}
		case ModalType.VerificationFailed: {
			const failedProps = OVERLAY_PROPERTY.FAILED;
			componentProps = {
				type: failedProps.type,
				iconPath: failedProps.iconPath,
				title: failedProps.title,
				messages,
				buttonText: failedProps.buttonText,
			};
			break;
		}
		case ModalType.Error: {
			const errorProps = OVERLAY_PROPERTY.ERROR;
			componentProps = {
				type: errorProps.type,
				iconPath: errorProps.iconPath,
				title: header,
				messages,
				buttonText: errorProps.buttonText,
			};
			break;
		}
		case ModalType.Info: {
			componentProps = {
				...OVERLAY_PROPERTY.INFO,
				title: header,
				messages,
			};
			break;
		}
		case ModalType.Warning: {
			componentProps = {
				...OVERLAY_PROPERTY.WARNING,
				title: header,
				messages,
			};
			break;
		}
		case ModalType.Offline: {
			componentProps = {
				...OVERLAY_PROPERTY.OFFLINE,
				title: header,
				messages,
			};
			break;
		}
		default:
			console.error("createOverlayComponentProps");
	}
	return componentProps;
};
export const getMinDate = (expirationDuration: number): dayjs.Dayjs =>
	dayjs().subtract(expirationDuration, "hour");

export const getMinDateString = (expirationDuration: number): string =>
	dayjs().subtract(expirationDuration, "hour").format();

export const getMaxDate = (): dayjs.Dayjs => dayjs().add(1, "minute");

export const capitalize = (s: string): string =>
	s.charAt(0).toUpperCase() + s.slice(1);

export const isNumber = (str: string): boolean => {
	if (typeof str !== "string") {
		return false;
	}

	if (str.trim() === "") {
		return false;
	}

	return !Number.isNaN(Number(str));
};

export const displaySelectedOrder = (
	selectedOrder: PatientEhrOrderModel
): string => {
	let orderString = `${selectedOrder.route}`;
	orderString += ` ${selectedOrder.base}`;
	orderString += ` ${selectedOrder.calorie} cal`;
	orderString += ` ${displayDateOfBirth(selectedOrder.startDate)}:`;

	orderString += ` ${selectedOrder.contents}`;

	return orderString;
};

export const getCaloricDensityText = (caloricDensity: number): string =>
	caloricDensity ? `${caloricDensity} kcal/oz` : "Unknown";

export const getLocationText = (location: string): string =>
	location ? new TitleCasePipe().transform(location) : "Unknown";

/**
 * Get a number range from min to max (inclusive).
 */
export const getNumberRange = (min: number, max: number): number[] => {
	const range = max - min + 1; // inclusive
	return Array.from({ length: range }, (e, i) => i + min).sort();
};

export const getVolumeText = (volume: number): string =>
	volume ? `${volume} mL` : "Unknown";

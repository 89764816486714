import {
	capitalize,
	displayDate,
	getCaloricDensityText,
	getLocationText,
	getVolumeText,
} from "../app.util";
import { displayAddedContents } from "./milk-label.util";
import { IContent, MilkBottleModel } from "../models/milk.model";
import { MilkBankProductModel } from "../models/milk-bank-product.model";
import {
	faGlassWaterDroplet,
	faTrash,
	faSnowflake,
	faClock,
	faCalendarXmark,
	faBoxOpen,
	faGears,
	faBottleDroplet,
	faIndustry,
	faUtensils,
	faHandHoldingMedical,
	faJar,
	faArrowsSplitUpAndLeft,
	faRectangleList,
	faLocationDot,
} from "@fortawesome/free-solid-svg-icons";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FeedObject } from "../models/feed-object.model";
import { ContentProductType, MilkType, ProductType } from "../app.enums";
import { Product } from "../models/product.model";

export enum FeedProperty {
	CalorieDensity = "Calorie Density",
	FeedContents = "Contents",
	DischargedDate = "Discharged",
	DistributedTo = "Distributed To",
	ExpendedDate = "Expended",
	ExpirationDate = "Expiration",
	FrozenDate = "Frozen",
	Location = "Location",
	LotNumber = "Lot Number",
	OpenedDate = "Opened",
	Manufacturer = "Product Manufacturer",
	ProductName = "Product",
	ProductState = "State",
	ReceivedDate = "Received Date",
	ProductType = "Type",
	Volume = "Volume",
}

export interface FeedPropertyItem {
	icon: IconDefinition; // Replace IconType with the actual type for icons (e.g., FontAwesomeIcon type)
	label: FeedProperty;
	getValues: (f: FeedObject) => string[];
}

export const FEED_PROPERTIES: FeedPropertyItem[] = [
	{
		icon: faUtensils,
		label: FeedProperty.CalorieDensity,
		getValues: (feed) => {
			// TODO: simplify this
			if (feed instanceof MilkBottleModel) {
				return [
					getCaloricDensityText(
						(feed as MilkBottleModel).calorieDensity
					),
				];
			} else if (feed instanceof MilkBankProductModel) {
				return [
					getCaloricDensityText(
						(feed as MilkBankProductModel).calorieDensity
					),
				];
			} else if (feed instanceof Product) {
				return [
					getCaloricDensityText((feed as Product).calorieDensity),
				];
			}
		},
	},
	{
		icon: faCalendarXmark,
		label: FeedProperty.DischargedDate,
		getValues: (feed) => [
			displayDate((feed as MilkBottleModel).dischargeDate),
		],
	},
	{
		icon: faTrash,
		label: FeedProperty.ExpendedDate,
		getValues: (feed) => [displayDate(feed.expendedDate)],
	},
	{
		icon: faClock,
		label: FeedProperty.ExpirationDate,
		getValues: (feed) => {
			if (feed instanceof Product) {
				return [displayDate(feed.manufacturerExpirationDate)];
			}

			return [displayDate(feed.expirationDate)];
		},
	},
	{
		icon: faSnowflake,
		label: FeedProperty.FrozenDate,
		getValues: (feed) => [
			displayDate((feed as MilkBottleModel).frozenDate),
		],
	},
	{
		icon: faLocationDot,
		label: FeedProperty.Location,
		getValues: (feed) => [
			getLocationText((feed as MilkBottleModel).location),
		],
	},
	{
		icon: faRectangleList,
		label: FeedProperty.LotNumber,
		getValues: (feed) => [feed.lotNumber || "None"],
	},
	{
		icon: faBoxOpen,
		label: FeedProperty.OpenedDate,
		getValues: (feed) => [displayDate(feed.openedDate)],
	},
	{
		icon: faGears,
		label: FeedProperty.ProductState,
		getValues: (feed) => [
			capitalize((feed as MilkBankProductModel).productState),
		],
	},
	{
		icon: faHandHoldingMedical,
		label: FeedProperty.ReceivedDate,
		getValues: (feed) => [displayDate(feed.receivedDate)],
	},
	{
		icon: faGlassWaterDroplet,
		label: FeedProperty.Volume,
		getValues: (feed) => [getVolumeText((feed as MilkBottleModel).volume)],
	},
	{
		icon: faIndustry,
		label: FeedProperty.Manufacturer,
		getValues: (feed) => {
			if (feed instanceof MilkBankProductModel) {
				return [feed.productManufacturer];
			}

			if (feed instanceof Product) {
				return [feed.manufacturerName];
			}

			return [];
		},
	},
	{
		icon: faBottleDroplet,
		label: FeedProperty.ProductName,
		getValues: (feed) => [(feed as MilkBankProductModel).name],
	},
	{
		icon: faJar,
		label: FeedProperty.FeedContents,
		getValues: (feed) => {
			if (feed instanceof MilkBottleModel) {
				return [
					...displayContents(feed.contents),
					...displayAddedContents(feed).map(
						(x) =>
							`${x.contentProductName} (from #${x.fromBottleNumber})`
					),
				];
			} else if (feed instanceof MilkBankProductModel) {
				return displayContents((feed as MilkBankProductModel).contents);
			}
		},
	},
	{
		icon: faArrowsSplitUpAndLeft,
		label: FeedProperty.DistributedTo,
		getValues: (feed) =>
			getDistributedTo((feed as MilkBottleModel).distributedTo),
	},
	{
		icon: faGears,
		label: FeedProperty.ProductType,
		getValues: (feed) => [(feed as Product).type.toUpperCase()],
	},
];

/**
 * Don't display received date if it is formula
 * Otherwise, always display received date
 */
export const hasReceivedDate = (feed: FeedObject): boolean =>
	!(feed?.feedType === MilkType.Formula);

/**
 * Don't display opened date for formula with RTF
 */
export const hasOpenedDate = (feed: FeedObject): boolean => {
	if (!feed) return false;

	if (feed.feedType === MilkType.Formula) {
		// If it is formula and contains RTF then display opened date
		return hasProductType(feed, ProductType.RTF);
	}
	// Always display if not formula
	return true;
};

const hasProductType = (feed: FeedObject, productType: ProductType): boolean =>
	feed?.contents.some(
		(content) => content.contentProductType === productType
	);

/**
 * Display the base contents first.
 * This can be done by priority of product type:
 * 1. EBM
 * 2. Donor Milk
 * 3. Water
 * 4. RTF
 * 5. Formula
 * 6. Additive
 *
 * If the ContentProductType is
 * “ebm”, “donor”, or “water”, then it is a base.
 *
 * @param contents
 */
export const displayContents = (contents: IContent[]): string[] => {
	// sort contents in order of priority
	const getPriority = (contentProductType: ContentProductType): number => {
		switch (contentProductType) {
			case ContentProductType.EBM:
				return 1;
			case ContentProductType.DM:
				return 2;
			case ContentProductType.Water:
				return 3;
			case ContentProductType.RTF:
				return 4;
			case ContentProductType.Formula:
				return 5;
			case ContentProductType.Additive:
				return 6;
			default:
				return 0;
		}
	};
	return contents
		.sort((a, b) => {
			const aPriority = getPriority(
				a.contentProductType as ContentProductType
			);
			const bPriority = getPriority(
				b.contentProductType as ContentProductType
			);

			if (aPriority < bPriority) return -1;
			if (aPriority > bPriority) return 1;
			return 0;
		})
		.map((c) => c.contentProductName)
		.filter((v, i, a) => a.indexOf(v) === i);
};

const getDistributedTo = (distributedTo): string[] =>
	distributedTo.length
		? [distributedTo.map((x) => `${x.bottleNumber} ${x.milkType}`)]
		: null;

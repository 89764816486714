<ion-list-header *ngIf="isHeaderVisible">
	<ion-label>{{title}}</ion-label>
	<ion-button *ngIf="hasEditButton"
				(click)="editNurses()"
				class="select-patient-btn">
		{{ nurses.length ? "Edit" : "Authorize" }}
	</ion-button>
</ion-list-header>
<ion-list *ngIf="nurses.length" class="{{style}}">
	<ion-item *ngFor="let n of nurses; let i = index" class="{{style}}__header">
		<ion-grid>
			<ion-row>
				<ion-col class="{{style}}__header__detail" size="auto">
					<p class="{{style}}__header__detail__label">{{i === 0 ? "Current Nurse" : "Second Nurse"}}</p>
					<p class="{{style}}__header__detail__value">
						<span class="ion-text-lowercase">{{n.id}}</span>
					</p>
				</ion-col>

				<ion-col size="auto">
					<div class="vertical-divider"></div>
				</ion-col>

				<ion-col class="{{style}}__header__detail" size="6">
					<p class="{{style}}__header__detail__label">e-mail</p>
					<p class="{{style}}__header__detail__value">
						<span class="ion-text-lowercase">{{n.email}}</span>
					</p>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-item>
</ion-list>

<ion-grid *ngIf="!nurses.length" class="{{style}}--empty">
	<ion-row>
		<span>{{instructions}}</span>
	</ion-row>
</ion-grid>

import { ScanLogType } from "../../app.enums";
import { modalMessage } from "../../app.modal-messages";
import { MilkBankProductModel } from "../../models/milk-bank-product.model";
import { MilkBottleModel } from "../../models/milk.model";
import {
	ScanMilkBankProductValidationError,
	ScanMilkBottleValidationError,
} from "./scan-validation-error";
import { IScanFeedValidation, IScanValidation } from "./scan-validation.model";

export function RejectExpended2() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanFeedValidation[]) {
			const [obj] = args;

			// destructure the object
			const { feedObject } = obj;

			// determine feed object type and check if it was expended
			if (feedObject) {
				if (
					feedObject instanceof MilkBottleModel &&
					feedObject.expendedDate
				) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Expended,
						modalMessage.milk_expended(feedObject)
					);
				} else if (
					feedObject instanceof MilkBankProductModel &&
					feedObject.expendedDate
				) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Expended,
						modalMessage.product_expended_with_date(feedObject)
					);
				} else {
					console.error(
						`RejectExpended2: feedObject is not supported.`
					);
				}
			} else {
				console.error(`RejectExpended2: feedObject is undefined`);
			}

			return originalMethod.apply(this, args);
		};
	};
}

export function RejectExpended() {
	return function (
		target: any,
		propertyKey: string,
		descriptor: PropertyDescriptor
	) {
		const originalMethod = descriptor.value;

		descriptor.value = function (...args: IScanValidation[]) {
			const [obj] = args;

			if (obj.milkBottle instanceof MilkBottleModel) {
				if (obj.milkBottle && obj.milkBottle.expendedDate) {
					throw new ScanMilkBottleValidationError(
						ScanLogType.Milk_Bottle_Expended,
						modalMessage.milk_expended(obj.milkBottle)
					);
				}
			}

			if (obj.milkBankProduct instanceof MilkBankProductModel) {
				if (obj.milkBankProduct && obj.milkBankProduct.expendedDate) {
					throw new ScanMilkBankProductValidationError(
						ScanLogType.Product_Expended,
						modalMessage.product_expended_with_date(
							obj.milkBankProduct
						)
					);
				}
			}

			return originalMethod.apply(this, args);
		};
	};
}

<ion-item *ngIf="StorageService.isWeb" lines="none" class="datetime-item">
	<ion-label>
		<p class="datetime-item__title">{{ label }}</p>
		<ion-grid>
			<ion-row>
				<ion-col>
					<ion-label>
						<ion-input label="DATE"
								   labelPlacement="stacked"
								   fill="outline"
								   [placeholder]="placeholder.date"
								   aria-label="Date"
								   data-testid="date_input_field"
								   maxlength="10"
								   class="input-field"
								   [value]="date"
								   [autofocus]="autofocusState"
								   [disabled]="disabled"
								   (ionFocus)="inputFocus.emit('focus')"
								   (ionBlur)="inputFocus.emit('blur')"
								   (ionInput)="handleDateChange($event)"
								   [maskito]="dateMaskOptions"
								   [maskitoElement]="maskPredicate"
						>
						</ion-input>
					</ion-label>
					<ion-note *ngIf="dateErrorMessage" color="danger">
						{{ dateErrorMessage }}
					</ion-note>
				</ion-col>

				<ion-col *ngIf="!isExpirationDate">
					<ion-input label="TIME"
							   labelPlacement="stacked"
							   fill="outline"
							   aria-label="Time"
							   data-testid="time_input_field"
							   [value]="time"
							   [placeholder]="placeholder.time"
							   (ionFocus)="inputFocus.emit('focus')"
							   (ionBlur)="inputFocus.emit('blur')"
							   [disabled]="disabled"
							   (ionInput)="handleTimeChange($event)"
							   [maskito]="timeMaskOptions"
							   [maskitoElement]="maskPredicate"
							   class="input-field"
							   >
					</ion-input>
					<ion-note *ngIf="timeErrorMessage" color="danger">
						{{ timeErrorMessage }}
					</ion-note>
				</ion-col>
			</ion-row>
		</ion-grid>
	</ion-label>
</ion-item>

<ion-item *ngIf="environment.settings.showMobileDateTimePicker || !StorageService.isWeb"
          lines="none"
          class="datetime-item">
	<ion-label>
		<p class="datetime-item__title">{{ label }}</p>
		<ion-accordion-group #accordionGroup>
			<ion-accordion value="field" [readonly]="disabled">
				<ion-item slot="header" lines="none">
						<span>
							{{ accordionLabel }}
						</span>
				</ion-item>
				<ion-datetime #datetime
				              [presentation]="presentation"
				              slot="content"
				              [(ngModel)]="accordionDate"
				              size="cover">
					<ion-buttons slot="buttons">
						<ion-button (click)="cancel()"
						            color="primary"
						            fill="clear"
									tappable
									>
							Cancel
						</ion-button>
						<ion-button (click)="clear()"
						            color="danger"
						            fill="clear"
									tappable
									>
							Clear
						</ion-button>
						<ion-button (click)="done()"
						            color="primary"
						            fill="clear"
									tappable
									>
							Done
						</ion-button>
					</ion-buttons>
				</ion-datetime>
			</ion-accordion>
		</ion-accordion-group>
	</ion-label>
</ion-item>

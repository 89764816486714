import {
	Component,
	EventEmitter,
	Input,
	NgZone,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
} from "@angular/core";
import {
	BarcodeScanResult,
	ScannedObject,
	ScanningService,
} from "../../services/scanning.service";
import { environment } from "src/environments/environment";
import { UiMessagesService } from "../../services/ui-messages.service";
import { ScanOptions } from "../../base.page";
import { StorageService } from "../../services/storage.service";
import { Keyboard } from "@capacitor/keyboard";
import { Subscription } from "rxjs";
import { Platform } from "@ionic/angular";
import { ModalType } from "../../app.constants";
import { ModalService } from "../../services/modal.service";
import { formatScanData } from "../../utils/scanning.util";
import { Barcode } from "@capacitor-mlkit/barcode-scanning";
import { FeedObject } from "src/app/models/feed-object.model";
import { BarcodeScannerMultipleComponent } from "../barcode-scanner-multiple/barcode-scanner-multiple.component";
import { FeatureFlagService } from "../../services/feature-flag.service";
import { Features } from "../../features.enums";
import { AuthGuardService } from "../../services/guards";

@Component({
	selector: "app-scan-footer",
	templateUrl: "./scan-footer.component.html",
	styleUrls: ["./scan-footer.component.scss"],
})
export class ScanFooterComponent implements OnInit, OnChanges, OnDestroy {
	// eslint-disable-next-line @angular-eslint/no-output-on-prefix
	@Output() handleScan = new EventEmitter<ScannedObject>();

	@Input() option: ScanOptions;

	@Input() scannedObjects: FeedObject[] = [];

	isVisible: boolean;
	hasVisibleItems: boolean;

	isKeyboardVisible = false;
	keyboardSubscription: Subscription;

	isDebug: boolean;

	FeatureFlagService = FeatureFlagService;
	Features = Features;

	constructor(
		public platform: Platform,
		private scanningService: ScanningService,
		private alerts: UiMessagesService,
		private zone: NgZone,
		private modalService: ModalService
	) {
		this.isDebug = !environment.production;
	}

	ngOnInit() {
		this.isVisible = this.getVisibility(
			this.option,
			this.isKeyboardVisible
		);

		if (this.platform.is("cordova")) {
			Keyboard.addListener("keyboardWillShow", () => {
				// Force change detection so the dom will update
				this.zone.run(() => {
					this.isVisible = false;
					this.isKeyboardVisible = true;
				});
			});

			Keyboard.addListener("keyboardDidHide", () => {
				// Force change detection so the dom will update
				this.zone.run(() => {
					this.isVisible = true;
					this.isKeyboardVisible = false;
				});
			});
		}
	}

	async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.option) {
			this.isVisible = this.getVisibility(
				this.option,
				this.isKeyboardVisible
			);
			this.hasVisibleItems = this.option.items.some(
				(item) => item.isVisible
			); //Might be worth it to try and combine this functionality with isVisible
		}
	}

	ngOnDestroy(): void {
		if (this.platform.is("cordova")) {
			Keyboard.removeAllListeners();
		}
	}

	/**
	 * We want to hide the component in four scenarios:
	 *    1) When the component is viewed from the web
	 *    1) When the `state` is active
	 *    1) When the keyboard is visible (to prevent accidental clicks)
	 *    1) When all the labels are hidden (since the buttons are conditionally rendered,
	 * 		it's possible that they could both be `false`)
	 */
	getVisibility = (
		option: ScanOptions,
		keyboardVisible: boolean
	): boolean => {
		const { isWeb, currentStep, items } = option;
		const hasHiddenItems = items.every((x) => !x.isVisible);

		return !isWeb && currentStep && !(keyboardVisible || hasHiddenItems);
	};

	/**
	 * Mobile implementation of startScanListener()
	 */
	async scan2() {
		if (this.isDebug && StorageService.isWeb) {
			await this.showDebugScanAlert();
		} else {
			this.scanningService.scan().then((barcode) => {
				const scannedObject = this.scanningService.getScannedObject(
					barcode.text
				);

				console.log(
					`getScannedObject: ${JSON.stringify(
						scannedObject,
						null,
						2
					)}`
				);
				this.handleScan.emit(scannedObject);
				AuthGuardService.appFocusGuard = true;
			});
		}
	}

	async scanBarcode() {
		try {
			const result = await this.scanningService.startScanbotScanner();
			let scannedObject: ScannedObject;
			if (result) {
				scannedObject = this.scanningService.getScannedObject(
					result?.items[0]?.barcode.text
				);
				this.handleScan.emit(scannedObject);
				AuthGuardService.appFocusGuard = true;
			}
		} catch (error) {
			console.error("Error scanning barcode:", error);
		}
	}

	async showDebugScanAlert() {
		await this.alerts.presentAlert({
			header: "MOBILE DEBUG SCAN",
			subHeader: "",
			message: "Scan barcode to simulate mobile scanning flow",
			backdropDismiss: false,
			buttons: [
				{
					text: "Cancel",
					role: "cancel",
					handler: () => {
						this.scanningService.stopScanListener();
					},
				},
			],
		});

		const sub = this.scanningService
			.startScanListener4()
			.subscribe((scannedObject) => {
				this.handleScan.emit(scannedObject);
				this.scanningService.stopScanListener();
				sub.unsubscribe();
				this.alerts.dismissAlert();
			});
	}

	public async scanMultiple(): Promise<BarcodeScanResult> {
		const element = await this.modalService.presentModal({
			component: BarcodeScannerMultipleComponent,
			cssClass: ModalType.BarcodeScanner,
			animated: false,
			componentProps: {
				feedObjects: this.scannedObjects,
			},
		});

		return new Promise<BarcodeScanResult>((resolve, reject) => {
			// dismissing the modal from barcode scanner multiple component
			element.onDidDismiss().then((result) => {
				const barcode: Barcode | undefined = result.data?.barcode;
				if (barcode) {
					resolve({
						format: barcode.format,
						cancelled: false,
						text: formatScanData(barcode.displayValue), // using displayValue instead of rawValue because rawValue can include encoding we don't want
					});
				} else {
					reject("No barcode content");
				}
			});

			element.present();
		});
	}

	public async startScan(): Promise<void> {
		this.scanningService.setScanReady(false);

		const barcode = await this.scanMultiple();
		const scannedObject = this.scanningService.getScannedObject(
			barcode.text
		);

		// Emit the scan
		this.handleScan.emit(scannedObject);
		AuthGuardService.appFocusGuard = true;
	}
}

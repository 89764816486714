import { Component, EventEmitter, Input, Output } from "@angular/core";
import { RecipeData, ScannedRecipe } from "src/app/models/recipe.model";
import { assertExhaustive, ProductState, RecipeMode } from "src/app/app.enums";
import { ScannedProduct } from "src/app/services/scanning.service";
import {
	displayDate,
	getCaloricDensityText,
	getVolumeText,
} from "../../app.util";
import { ModifierOption } from "../milk-modifiers/milk-modifiers.component";
import dayjs from "dayjs";
import { getBottleNumberText } from "../../utils/milk-label.util";
import {
	MilkBankProductModel,
	transitionStableMilkBankProductsToOpened,
} from "../../models/milk-bank-product.model";
import {
	faBoxOpen,
	faClock,
	faQrcode,
	faTemperatureFull,
	faTrash,
} from "@fortawesome/free-solid-svg-icons";
import {
	getBottleNumber,
	getLotNumber,
	getProductName,
} from "../../utils/milk-bank-product.util";

@Component({
	selector: "app-recipe-info",
	templateUrl: "./recipe-info.component.html",
	styleUrls: ["./recipe-info.component.scss"],
})
export class RecipeInfoComponent {
	@Input() recipeData: RecipeData;
	@Output() recipeDataChange = new EventEmitter<RecipeData>();

	modifierOptions: ModifierOption[] = ["use all", "partial use"];

	RecipeMode = {
		VOH: RecipeMode.VOH,
		DV: RecipeMode.DV,
		MP: RecipeMode.MP,
	};

	displayDate = displayDate;
	getBottleNumberText = getBottleNumberText;
	getCaloricDensityText = getCaloricDensityText;
	getProductName = getProductName;
	getVolumeText = getVolumeText;
	parseInt = parseInt;

	faClock = faClock;
	faQrcode = faQrcode;
	faTrash = faTrash;
	faTemperatureFull = faTemperatureFull;
	faBoxOpen = faBoxOpen;

	constructor() {}

	selectProductModifier(
		option: ModifierOption,
		scannedProduct: ScannedProduct
	) {
		if (scannedProduct.match?.groups.upc) {
			console.log("selectProductModifier: has UPC");
			return;
		}

		switch (option) {
			case "partial use":
				this.changeProductState(scannedProduct.milkBankProduct);
				scannedProduct.milkBankProduct.modifierOption = option;
				scannedProduct.milkBankProduct.expendedDate = null;

				transitionStableMilkBankProductsToOpened([
					scannedProduct.milkBankProduct,
				]);

				console.log(
					`partial use = ${JSON.stringify(
						scannedProduct.milkBankProduct,
						null,
						2
					)}`
				);
				break;
			case "use all":
				this.changeProductState(scannedProduct.milkBankProduct);
				scannedProduct.milkBankProduct.modifierOption = option;
				scannedProduct.milkBankProduct.expendedDate = dayjs();
				console.log(
					`use all = ${JSON.stringify(
						scannedProduct.milkBankProduct,
						null,
						2
					)}`
				);
				break;
			case "assign all":
			case "emptied":
			case "has remaining":
			case "not fortified":
			case "fortified":
			case "feed all":
				throw Error("wrong modifier");
			default:
				assertExhaustive(option);
		}

		for (let s of this.recipeData.scanData.scannedProducts) {
			if (s.milkBankProduct.id === scannedProduct.milkBankProduct.id) {
				s = scannedProduct;
			}
		}
		this.recipeDataChange.emit(this.recipeData);
	}

	changeProductState(milkBankProduct: MilkBankProductModel) {
		switch (milkBankProduct.productState) {
			case ProductState.Frozen:
				milkBankProduct.productState = ProductState.Thawed;
				milkBankProduct.thawedDate = dayjs();
				milkBankProduct.openedDate = null;
				break;
			case ProductState.Stable:
				milkBankProduct.productState = ProductState.Opened;
				milkBankProduct.thawedDate = null;
				milkBankProduct.openedDate = dayjs();
				break;
			case ProductState.Opened:
			case ProductState.Thawed:
				break;
			default:
				assertExhaustive(milkBankProduct.productState);
				break;
		}
	}

	getUniqueBases(scannedRecipes: ScannedRecipe[]) {
		const baseStrings = scannedRecipes.map((s) => s.recipe.baseName);
		return baseStrings.filter(
			(item, index) => baseStrings.indexOf(item) === index
		);
	}

	displayAdditiveAmount(amount: number): number {
		return Math.round(amount * 10000) / 10000;
	}

	protected readonly getBottleNumber = getBottleNumber;
	protected readonly getLotNumber = getLotNumber;
}

import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-offline",
	templateUrl: "./offline.component.html",
	styleUrls: ["./offline.component.scss"],
})
export class OfflineComponent implements OnInit {

	constructor() {
	}

	ngOnInit() {
	}

}
